import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { SelectMerchant } from "~/components/CommonFormControls/SelectMerchant";
import { SelectKey } from "~/components/CommonFormControls/SelectPurposeKey";
import OutboundTerminalsBinding from "./OutboundTerminalsBinding";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateInboundVirtualTerminalDialogProps
  extends Omit<DialogProps, "onClose"> {
  inboundVirtualTerminal?: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
  onClose?: (
    inboundVirtualTerminal?: InboundVirtualTerminalsApi.InboundVirtualTerminalDto
  ) => void;
}

type FromState =
  | InboundVirtualTerminalsApi.CreateInboundVirtualTerminal
  | InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal;

const defaultValues: FromState = {
  merchantId: "",
  enabled: false,
  signKeyId: "",
  verifyKeyId: "",
  outboundTerminals: [],
  description: ""
};

/**
 * @memberof InboundVirtualTerminals
 * @component
 * @desc Dialog for creating or editing a InboundVirtualTerminal.
 * @property {InboundVirtualTerminalsApi.InboundVirtualTerminalDto} inboundVirtualTerminal a InboundVirtualTerminal data
 * @property {Function} onClose - passes InboundVirtualTerminal data if the InboundVirtualTerminal was created/updated successfully. (inboundVirtualTerminal?: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => void;
 */

const CreateOrUpdateInboundVirtualTerminalDialog = ({
  inboundVirtualTerminal,
  open,
  onClose
}: CreateOrUpdateInboundVirtualTerminalDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    merchantId: yup.string().required(),
    signKeyId: yup.string().required(),
    verifyKeyId: yup.string().required(),
    outboundTerminals: yup.array().min(1),
    description: yup.string()
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      setLoading(true);
      const formData = {
        ...values
      };
      try {
        let result;
        if (isEditMode && inboundVirtualTerminal) {
          const { data } = await api.inboundVirtualTerminals.update(
            inboundVirtualTerminal.id,
            formData as InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal
          );
          result = data.data;
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          const { data } = await api.inboundVirtualTerminals.create(
            formData as InboundVirtualTerminalsApi.CreateInboundVirtualTerminal
          );
          result = data.data;
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose &&
          onClose(result);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, inboundVirtualTerminal, onClose, t]
  );

  useEffect(() => {
    if (inboundVirtualTerminal) {
      const {
        merchantId,
        enabled,
        signKeyId,
        verifyKeyId,
        outboundTerminals,
        description
      } = inboundVirtualTerminal;
      setInitialValues({
        merchantId,
        enabled,
        signKeyId,
        verifyKeyId,
        outboundTerminals,
        description
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [inboundVirtualTerminal]);

  return (
    <Dialog
      open={open}
      title={
        isEditMode
          ? t("title.editInboundVirtualTerminal")
          : t("title.addInboundVirtualTerminal")
      }
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ handleSubmit, setFieldValue, errors }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {inboundVirtualTerminal && (
                  <FormControlLabel label="ID">
                    {inboundVirtualTerminal.id}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.merchantName")}>
                  <SelectMerchant
                    inputValue={
                      inboundVirtualTerminal &&
                      inboundVirtualTerminal.merchantName
                    }
                    onChange={(merchant) =>
                      setFieldValue("merchantId", merchant ? merchant.id : "")
                    }
                    error={errors && errors["merchantId"]}
                    touched={Boolean(errors && errors["merchantId"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                {/* <FormControlLabel label={t("label.accountNumber")}>
                  <OutlinedInput name="accountNumber" formikControll />
                </FormControlLabel> */}
                {/* <FormControlLabel label={t("label.routingType")}>
                  <OutlinedInput name="routingType" formikControll />
                </FormControlLabel> */}
                <FormControlLabel label={t("label.signKeyName")}>
                  <SelectKey
                    keyPurpose={KEY_PURPOSE.INBOUND_VT_SIGN_KEY}
                    inputValue={
                      inboundVirtualTerminal &&
                      inboundVirtualTerminal.signKeyName
                    }
                    onChange={(key) =>
                      setFieldValue("signKeyId", key ? key.keyId : "")
                    }
                    error={errors && errors["signKeyId"]}
                    touched={Boolean(errors && errors["signKeyId"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.verifyKeyName")}>
                  <SelectKey
                    keyPurpose={KEY_PURPOSE.INBOUND_VT_VERIFY_KEY}
                    inputValue={
                      inboundVirtualTerminal &&
                      inboundVirtualTerminal.verifyKeyName
                    }
                    onChange={(key) =>
                      setFieldValue("verifyKeyId", key ? key.keyId : "")
                    }
                    error={errors && errors["verifyKeyId"]}
                    touched={Boolean(errors && errors["verifyKeyId"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
              </Box> 
              <OutboundTerminalsBinding
                terminalId={inboundVirtualTerminal && inboundVirtualTerminal.id}
                error={errors && errors["outboundTerminals"]}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateInboundVirtualTerminalDialog;
