import { Typography, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export interface Props {
  title?: string;
  value?: number;
  maxValue?: number;
  color?: "primary" | "secondary" | "warning" | "info" | "success" | "error";
  className?: string;
}

const ProgressBarChart = styled("div")(
  ({
    theme,
    value = 0,
    maxValue = 100,
    color = "primary",
  }: Props & { theme: CustomTheme.RootObject }) => {
    return {
      width: "100%",
      height: 7,
      backgroundColor: theme.palette.background.level1,
      borderRadius: theme.shape.borderRadius,
      "&:after": {
        display: "block",
        content: '""',
        width: `${(value / maxValue) * 100}%`,
        height: 7,
        backgroundColor: theme.palette[color].main,
        borderRadius: theme.shape.borderRadius,
      },
    };
  }
);

const Container = ({
  title,
  className,
  value = 0,
  maxValue = 100,
  ...other
}: Props) => (
  <div className={className}>
    <Grid container justifyContent="space-between">
      <Grid>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid>
        <Typography variant="body2" color="textSecondary">
          {(value / maxValue) * 100}%
        </Typography>
      </Grid>
    </Grid>
    <ProgressBarChart
      {...other}
      {...{
        value,
        maxValue,
      }}
    />
  </div>
);

export default Container;
