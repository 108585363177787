import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  ColorPicker,
  FormControlLabel,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik, Field, FieldProps } from "formik";
import RolesManagement, {
  Role,
  getActivatedRoles,
} from "~/components/RolesManagement";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface UpdateRoleProps extends Omit<DialogProps, "onClose" | "role"> {
  group?: GroupsApi.GroupDto;
  onClose?: (result?: boolean) => void;
}

interface FromData extends GroupsApi.UpdateGroupRoles {}

const defaultValues = {
  color: "transparent",
  roles: [],
};

/**
 * @memberof Groups
 * @component
 * @desc Edit Roles of Group Dialog.
 * @property {GroupsApi.GroupDto} group - Group data;
 * @property {Function} onClose - passes true if the group was edit successfully. (result?: boolean) => void;
 */

const RolesManagementDialog = ({ group, open, onClose }: UpdateRoleProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [roles, setRoles] = useState<Role[]>();
  const [loading, setLoading] = useState(false);
  const [loadingGroupDetails, setLoadingGroupDetails] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!group) return;
      setLoading(true);
      try {
        await api.groups.updateGroupRoles(group.groupId, formData);
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, group]
  );

  const validationSchema = yup.object().shape({});

  const getGroupDetails = useCallback(async (groupId: string) => {
    setLoadingGroupDetails(true);
    try {
      const {
        data: {
          data: { roles, ...other },
        },
      } = await api.groups.getGroupRoles(groupId);
      setRoles(roles);
      setInitialValues({ ...other, roles: getActivatedRoles(roles) });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingGroupDetails(false);
    }
  }, []);

  useEffect(() => {
    if (group) {
      getGroupDetails(group.groupId);
    } else {
      setInitialValues(defaultValues);
    }
  }, [group, getGroupDetails]);

  return (
    <Dialog
      open={open}
      title={`${t("title.groupSettings")}: ${group && group.name}`}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Box mx={4}>
          <LinearProgress hidden={!loadingGroupDetails} />
        </Box>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                <Box pb={4}>
                  <FormControlLabel label={t("label.colorGroup")}>
                    <Field
                      name="color"
                      component={({ field }: FieldProps<string>) => (
                        <ColorPicker
                          defaultValue={field.value}
                          onChange={(color) => setFieldValue(field.name, color)}
                        />
                      )}
                    />
                  </FormControlLabel>
                  {roles && (
                    <RolesManagement
                      roles={roles}
                      onChange={(roles) => {
                        setFieldValue("roles", roles);
                      }}
                    />
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.save")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default RolesManagementDialog;
