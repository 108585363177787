import MUIListItem from "@material-ui/core/ListItem";
import { withStyles, createStyles } from "@material-ui/core/styles";

const ListItem = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.text.icon,
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
  })
)(MUIListItem);

export default ListItem;
