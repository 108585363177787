import React from "react";
import clsx from "clsx";
import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface Props {
  size?: "small" | "large";
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.level1,
    },
    lg: {
      width: 70,
      height: 70,
    },
    sm: {
      width: 44,
      height: 44,
    },
  })
);

const CustomAvatar = ({ size = "large", ...other }: AvatarProps & Props) => {
  const classes = useStyles();
  return (
    <Avatar
      classes={{
        root: clsx(classes.root, {
          [classes.sm]: size === "small",
          [classes.lg]: size === "large",
        }),
      }}
      {...other}
    />
  );
};

export default CustomAvatar;
