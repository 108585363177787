import { useState, useEffect } from "react";
import { GridList, GridListTile } from "~/components/UI";
import Incidents from "./Incidents";
import { WIDGET_ENTITY, JournalEntitys } from "~/api/dashboardSettings/enums";

const getWidgetComponent = (
  setting: DashboardSettingsApi.Widget
): (() => JSX.Element | null) => {
  switch (setting.entity) {
    case WIDGET_ENTITY.JOURNAL_INCIDENTS:
      return Incidents;
    default:
      return () => null;
  }
};

interface JournalsProps {
  settings?: DashboardSettingsApi.Widget[];
}

const Journals = ({ settings }: JournalsProps) => {
  const [components, setComponents] = useState<Array<() => JSX.Element | null>>(
    []
  );

  useEffect(() => {
    if (settings) {
      const components = settings
        .filter((s) => JournalEntitys.includes(s.entity) && s.enabled)
        .map((s) => getWidgetComponent(s));
      setComponents(components);
    }
  }, [settings]);

  return (
    <GridList cols={4} spacing={20} cellHeight="auto">
      {components &&
        components.map((WidgetComponent, index) => (
          <GridListTile key={index} cols={4}>
            <WidgetComponent />
          </GridListTile>
        ))}
    </GridList>
  );
};

export default Journals;
