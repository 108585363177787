import Table, { TableProps } from "~/components/UI/Table/Table";
import UserStatus from "./UserStatus";
import UserOptions from "./UserOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { phoneNumber } from "~/utils/format";

const defaultColumns = [
  {
    key: "username",
    sort: true,
    i18nKey: "userName",
  },
  {
    key: "userId",
    sort: true,
    title: "ID",
    cellOptions: {
      width: 350,
    },
  },
  {
    key: "email",
    sort: true,
    title: "Email",
  },
  {
    key: "status",
    sort: true,
    i18nKey: "status",
    render: (status: UsersApi.eUserStatus) => <UserStatus status={status} />,
    cellOptions: {
      width: 125,
    },
  },
  {
    key: "phone",
    i18nKey: "phone",
    render: phoneNumber,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "position",
    sort: true,
    i18nKey: "position",
    cellOptions: {
      width: 200,
    },
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Users
 * @component
 * @desc Render table of Users.
 * @property {UsersApi.UserDto[]} dataSource - Users data
 * @property {Function} onEdit - callback for open the Edit User dialog. (data: UsersApi.UserDto) => void
 * @property {Function} onUpdate - callback for update a user. (id: string, formData: UsersApi.UpdateUserStatus) => void
 * @property {Function} onDelete - callback for the delete a user. (data: UsersApi.UserDto) => Promise<undefined> | void
 */

const RolesTable = ({
  onSort,
  onEdit,
  onUpdate,
  onUpdateItem,
  onDelete,
  ...props
}: Omit<TableProps<UsersApi.UserDto>, "columns"> & {
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (data: UsersApi.UserDto) => void;
  onUpdateItem?: (data: UsersApi.UserDto) => void;
  onUpdate?: (id: string, formData: UsersApi.UpdateUserStatus) => void;
  onDelete?: (data: UsersApi.UserDto) => Promise<void> | void;
}) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, user: UsersApi.UserDto) => (
            <UserOptions
              {...{ user, onEdit, onUpdate, onUpdateItem, onDelete }}
            />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table {...props} columns={columns} onRequestSort={onSort} stickyHeader />
  );
};

export default RolesTable;
