import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { GROUP_STATUS } from "~/api/groups/enums";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface GroupOptionsProps {
  group: GroupsApi.GroupDto;
  onEdit?: (data: GroupsApi.GroupDto) => void;
  onUpdateItem?: (data: GroupsApi.GroupDto) => void;
  onUpdate?: (
    groupId: string,
    data: GroupsApi.UpdateGroup
  ) => Promise<void> | void;
  onDelete?: (data: GroupsApi.GroupDto) => Promise<void> | void;
}

/**
 * @memberof Groups
 * @component
 * @desc Group options.
 * @property {GroupsApi.GroupDto} group - Group data;
 * @property {Function} onEdit - callback for open the Edit Group dialog. (data: GroupsApi.GroupDto) => void
 * @property {Function} onUpdate - callback for update a Group. (groupId: string, data: GroupsApi.UpdateGroup) => Promise<void> | void
 * @property {Function} onDelete - callback for the delete a group. (data: GroupsApi.GroupDto) => Promise<undefined> | void
 */

const GroupOptions = ({
  group,
  onEdit,
  onUpdateItem,
  onUpdate,
  onDelete,
}: GroupOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.GROUP);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (group.incidents) {
        group.incidents.count++;
        group.incidents.incidentIds.push(incident.incidentId);
      } else {
        group.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(group);
    },
    [group, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.DELETE_GROUP,
      PERMISSION.EDIT_GROUP,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.settings"),
      hidden: !hasAll(PERMISSION.EDIT_GROUP),
      onClick: () => onEdit && onEdit(group),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_GROUP),
      onClick: () => onDelete && onUpdateHandler(onDelete(group)),
    },
    {
      label:
        group.status === GROUP_STATUS.ACTIVE
          ? t("option.block")
          : t("option.active"),
      hidden: !hasAll(PERMISSION.EDIT_GROUP),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(
            onUpdate(group.groupId, {
              status:
                group.status === GROUP_STATUS.ACTIVE
                  ? GROUP_STATUS.BLOCKED
                  : GROUP_STATUS.ACTIVE,
            })
          ),
      },
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(group.groupId, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default GroupOptions;
