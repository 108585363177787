import Table, { TableProps } from "~/components/UI/Table/Table";
import { Tag } from "~/components/UI";
import CollapsibleTagsGroup from "~/components/CollapsibleTagsGroup";
import { getContrastYIQ } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import GroupOptions from "./GroupOptions";
import GroupStatus from "./GroupStatus";

const Roles = ({
  roles,
  group: { color },
}: {
  roles: Array<string | GroupsApi.GroupRolesDto>;
  group: GroupsApi.GroupDto;
}) => {
  const { t } = useTranslation();
  return (
    <CollapsibleTagsGroup
      displayByDefault={4}
      showAllBtnText={t("button.showAll")}
    >
      {roles &&
        roles
          .filter((r) => (typeof r === "string" ? r : r.status))
          .map((r, i) => (
            <Tag
              key={`${i}-group`}
              bgcolor={color}
              color={color ? getContrastYIQ(color) : "inherit"}
            >
              {typeof r === "string" ? r : r.name}
            </Tag>
          ))}
    </CollapsibleTagsGroup>
  );
};

const defaultColumns = [
  {
    key: "name",
    sort: true,
    i18nKey: "groupName",
    cellOptions: {
      width: 250,
    },
  },
  {
    key: "groupId",
    sort: true,
    title: "ID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "status",
    i18nKey: "status",
    sort: true,
    cellOptions: {
      width: 125,
    },
    render: (status: GroupsApi.eGroupStatus) => <GroupStatus status={status} />,
  },
  {
    key: "roles",
    title: "Roles",
    i18nKey: "roles",
    render: (roles: string[], row: GroupsApi.GroupDto) => (
      <Roles roles={roles} group={row} />
    ),
    cellOptions: {
      width: 400,
    },
  },
  {
    key: "description",
    i18nKey: "description",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Groups
 * @component
 * @desc Render table of Groups.
 * @property {GroupsApi.GroupDto[]} dataSource - Groups data
 * @property {Function} onEdit - callback for open the Edit Group dialog. (data: GroupsApi.GroupDto) => void
 * @property {Function} onUpdate - callback for update a Group. (groupId: string, data: GroupsApi.UpdateGroup) => Promise<void> | void
 * @property {Function} onDelete - callback for the delete a group. (data: GroupsApi.GroupDto) => Promise<undefined> | void
 */

const GroupsTable = ({
  onSort,
  onEdit,
  onUpdateItem,
  onUpdate,
  onDelete,
  ...props
}: Omit<TableProps<GroupsApi.GroupDto>, "columns"> & {
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (data: GroupsApi.GroupDto) => void;
  onUpdateItem?: (data: GroupsApi.GroupDto) => void;
  onUpdate?: (
    groupId: string,
    data: GroupsApi.UpdateGroup
  ) => Promise<void> | void;
  onDelete?: (data: GroupsApi.GroupDto) => Promise<void> | void;
}) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, group: GroupsApi.GroupDto) => (
            <GroupOptions
              {...{ group, onEdit, onUpdate, onUpdateItem, onDelete }}
            />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      rowKey="groupId"
      onRequestSort={onSort}
      stickyHeader
    />
  );
};

export default GroupsTable;
