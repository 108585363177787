import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, createStyles } from "@material-ui/core/styles";

const MUIFormControlLabel = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      verticalAlign: "middle",
      marginLeft: 0,
    },
    label: {
      marginLeft: theme.spacing(2),
      fontSize: theme.typography.fontSize,
      fontWeight: "bold",
      fontFamily: '"PlumbBoldC", sans-serif',
      color: theme.palette.text.primary,
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
  })
)(FormControlLabel);

export default MUIFormControlLabel;
