import { useState, useEffect } from "react";
import Select, { SelectProps, OptionProps } from "~/components/UI/Select";
import { LinearProgress } from "~/components/UI";
import api from "~/api";
import { useField } from "formik";

interface SelectAcquirerEndpointProps extends SelectProps {
  acquirerId?: string;
  onUpdateEndpointData?: (endpoint?: AcquirersApi.EndpointDto) => void
}

const SelectAcquirerEndpoint = ({
  acquirerId,
  name,
  onUpdateEndpointData,
  ...otherProps
}: SelectAcquirerEndpointProps) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionProps[]>([]);
  const [acquirerEndpoints, setAcquirerEndpoints] = useState<AcquirersApi.EndpointDto[]>([]);
  const [field] = useField(name || '')

  const refreshOptions = async (acquirerId?: string) => {
    if (acquirerId) {
      try {
        setLoading(true);
        const {
          data: { data }
        } = await api.acquirers.getAcquirerEndpoints(acquirerId);
        setAcquirerEndpoints(data)
        setOptions(data.map((e) => ({ label: e.url, value: e.endpointId })));
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  };

  const updateEndpointData = (endpointId?: string) => {
    const endpoint = acquirerEndpoints.find(e => e.endpointId === endpointId)
    onUpdateEndpointData && onUpdateEndpointData(endpoint)
  }

  useEffect(() => {
    updateEndpointData(field.value)
  }, [field.value, acquirerEndpoints])

  useEffect(() => {
    refreshOptions(acquirerId);
  }, [acquirerId]);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Select {...otherProps} name={name} options={options} variant="outlined" />
    </>
  );
};

export default SelectAcquirerEndpoint;
