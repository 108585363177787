import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getTheme } from "~/themes";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
}

const CustomThemeProvider = ({ children }: Props) => {
  const currentTheme = useSelector(
    (store: ReduxStore.State) => store.settings.theme
  );
  const theme = getTheme(currentTheme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
