import { useState, useCallback, useEffect } from "react";
import {
  FormControlLabel,
  Button,
  DialogActions,
  DialogContent,
  OutlinedInput
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";
import { currencyString } from '~/utils/format';

interface ReversalPaymentModalProps
  extends Omit<DialogProps, "onClose" | "role"> {
  financialTransaction?: FinancialTransactionsApi.FinancialTransactionDto &
    FinancialTransactionsApi.FinancialTransactionDetailsDto;
  onClose?: (result?: boolean) => boolean | void;
}

interface FromData extends FinancialTransactionsApi.ReversalPaymentRequest {}

const defaultValues = {
  rrn: "",
  approvalCode: ""
};

/**
 * @memberof FinancialTransactions
 * @component
 * @desc create a refund request.
 * @property {FinancialTransactionsApi.FinancialTransactionDto} financialTransaction - financial transaction data;
 * @property {Function} onClose - (financialTransaction?: FinancialTransactionsApi.FinancialTransactionDto) => void;
 */

const ReversalPaymentModal = ({
  financialTransaction,
  open,
  onClose
}: ReversalPaymentModalProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!financialTransaction) return;
      setLoading(true);
      const {
        id: transactionId,
      } = financialTransaction;
      const { rrn, approvalCode, refundReason } = formData;

      try {
        await api.payment.reversal({
          transactionId,
          rrn,
          approvalCode,
          refundReason
        });
        onClose && onClose(true);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t, financialTransaction]
  );

  const validationSchema = yup.object().shape({
    // rrn: yup.string().max(12).min(12).required(),
    // approvalCode: yup.string().max(6).min(6).required(),
    refundReason: yup.string().required()
  });

  useEffect(() => {
    if (financialTransaction) {
      setInitialValues({
        rrn: financialTransaction.rrn,
        approvalCode: financialTransaction.approvalCode
      });
    } else {
      setInitialValues(defaultValues);
    }
  }, [financialTransaction]);

  if (!financialTransaction) return null;

  return (
    <Dialog
      open={open}
      title={t("title.reversalPayment")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true
          }}
        >
          {({ handleSubmit }) => (
            <>
              <DialogContent>
                <FormControlLabel label={t("label.transactionId")}>
                  {financialTransaction.id}
                </FormControlLabel>
                <FormControlLabel label={t("label.amount")}>
                  { financialTransaction.amount
                      ? currencyString(Number(financialTransaction.amount))
                      : ''
                  }
                </FormControlLabel>
                <FormControlLabel label="RRN">
                  {financialTransaction.rrn}
                  {/* <OutlinedInput name="rrn" formikControll /> */}
                </FormControlLabel>
                <FormControlLabel label={t("label.approvalCode")}>
                  {financialTransaction.approvalCode}
                  {/* <OutlinedInput name="approvalCode" formikControll /> */}
                </FormControlLabel>
                <FormControlLabel label={t("label.reason")}>
                  <OutlinedInput
                    name="refundReason"
                    multiline
                    rows={8}
                    formikControll
                    fullWidth
                  />
                </FormControlLabel>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.submit")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default ReversalPaymentModal;
