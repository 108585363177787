import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";


export default class MetricsAPI {
  rootPath: string = "/metrics";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.metrics')
  }

  getTransactionCountByPaymentSystem = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.FinancialTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/transaction-count/payment-systems`, { params })
  getTransactionCountByWallets = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.FinancialTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/transaction-count/wallets`, { params })
  getTransactionCountByAcquirers = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.FinancialTransactionCountDto>> =>
    this.instance.get(`${this.rootPath}/transaction-count/acquirers`, { params })

  getClientPaymentDurationByAcquirers = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientPaymentDurationResponceDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration/acquirers`, { params })
  getClientPaymentDurationByWallets = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.ClientPaymentDurationResponceDto>> =>
    this.instance.get(`${this.rootPath}/client-payment-duration/wallets`, { params })

  getPSPPaymentDurationByAcquirers = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.PSPPaymentDuratioResponceDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration/acquirers`, { params })
  getPSPPaymentDurationByWallets = (params: MetricsAPI.GetMetricQuery): Promise<Api.GetOne<MetricsAPI.PSPPaymentDuratioResponceDto>> =>
    this.instance.get(`${this.rootPath}/psp-payment-duration/wallets`, { params })
}
