import { useState, useEffect, useCallback } from "react";
import {
  Box,
  SidebarGroup,
  Checkbox,
  MUIFormControlLabel,
} from "~/components/UI";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

/**
 * @memberof Filter
 * @component
 * @desc IncludeFieldGroup.
 * @property {string} label - label;
 * @property {string} fieldName - formik field name;
 */

interface IncludeFieldGroupProps {
  label?: string;
  fieldName: string;
}

const IncludeFieldGroup = ({ label, fieldName }: IncludeFieldGroupProps) => {
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(false);
  const { t } = useTranslation();
  const [field, , helpers] = useField(fieldName);

  const onChange = useCallback(
    (checkbox: "yes" | "no", value: boolean) => {
      switch (checkbox) {
        case "yes":
          if (field.value === undefined && value) {
            helpers.setValue(true);
            return;
          } else if (field.value === null && !value) {
            helpers.setValue(false);
            return;
          }
          break;
        case "no":
          if (field.value === undefined && value) {
            helpers.setValue(false);
            return;
          } else if (field.value === null && !value) {
            helpers.setValue(true);
            return;
          }
          break;
        default:
      }
      helpers.setValue(
        value && typeof field.value === "boolean" ? null : undefined
      );
    },
    [field]
  );

  useEffect(() => {
    if (typeof field.value === "boolean") {
      setYes(field.value === true);
      setNo(field.value === false);
    } else if (field.value === null) {
      setYes(true);
      setNo(true);
    } else {
      setYes(false);
      setNo(false);
    }
  }, [field.value]);

  return (
    <SidebarGroup label={label} collapsed defaultOpen={true}>
      <Box my={2}>
        <MUIFormControlLabel
          label={t("button.yes")}
          control={
            <Checkbox
              checked={yes}
              onChange={(e) => onChange("yes", e.target.checked)}
            />
          }
        />
      </Box>
      <Box my={2}>
        <MUIFormControlLabel
          label={t("button.no")}
          control={
            <Checkbox
              checked={no}
              onChange={(e) => onChange("no", e.target.checked)}
            />
          }
        />
      </Box>
    </SidebarGroup>
  );
};

export default IncludeFieldGroup;
