import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class MDESKeysApi {
  rootPath: string = "/mdes-keys";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.mdesKeys')
  }

  createKeys = (data: MDESKeysApi.CreateMDESKeys): Promise<Api.GetOne<MDESKeysApi.MDESKeysDto>> =>
    this.instance.post(this.rootPath, data);
  getAll = (): Promise<Api.GetOne<MDESKeysApi.MDESKeysDto>> =>
    this.instance.get(this.rootPath);
  updateKeys = (data: MDESKeysApi.UpdateMDESKeys): Promise<Api.GetOne<MDESKeysApi.MDESKeysDto>> =>
    this.instance.put(this.rootPath, data);
}
