import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "~/components/UI";
import { SOURCE_TYPE } from "~/api/common";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface LinkToObjectProps {
  sourceType: Api.eSourceType;
  sourceId: string;
  children?: React.ReactChild | React.ReactChild[];
}

const LinkToObject = ({
  sourceType,
  sourceId,
  children,
}: LinkToObjectProps) => {
  const { t } = useTranslation();
  let link = "";
  let value = sourceId;

  switch (sourceType) {
    case SOURCE_TYPE.CONTRACT:
      link = `${ROUTES.CONTRACTS}?contractId=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.MERCHANT:
      link = `${ROUTES.MERCHANTS}?id=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.MDES_MERCHANT:
      link = `${ROUTES.MDES_MERCHANTS}?mdesMerchantId=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.ACQUIRER:
      link = `${ROUTES.ACQUIRERS}?acquirerId=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL:
      link = `${ROUTES.INBOUND_VIRTUAL_TERMINALS}?id=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.OUTBOUND_VIRTUAL_TERMINAL:
      link = `${ROUTES.OUTBOUND_VIRTUAL_TERMINALS}?id=${sourceId}`;
      value = sourceId;
      break;
    case SOURCE_TYPE.USER:
    case SOURCE_TYPE.USER_ROLE:
      link = `${ROUTES.USERS}?userId=${sourceId}`;
      value = sourceId && `${t("option.user")} #${sourceId}`;
      break;
    case SOURCE_TYPE.GROUP:
      link = `${ROUTES.GROUPS}?groupId=${sourceId}`;
      value = sourceId && `${t("option.group")} #${sourceId}`;
      break;
    case SOURCE_TYPE.ROLE:
      link = `${ROUTES.USER_ROLES}?roleId=${sourceId}`;
      value = sourceId && `${t("option.role")} #${sourceId}`;
      break;
    default:
      link = "";
  }

  return (
    <RouterLink to={link}>
      <Link color="textPrimary" underline="always">
        {children || value}
      </Link>
    </RouterLink>
  );
};

export default React.memo(LinkToObject);
