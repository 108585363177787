import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getLogoUrl } from '~/utils/helpers';

export default class AuthApi {
  rootPath: string = "/auth";
  instance: AxiosInstance = {} as AxiosInstance;
  azureTokenInstance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    this.azureTokenInstance = axios.create(instance.defaults);
    this.azureTokenInstance.interceptors.response.use(
      (res) => res,
      (httpResponse: {
        response: AxiosResponse<Api.ErrorResponse>;
      }) => {
        const { response } = httpResponse;
        if(response && response.status === 403 && response.data?.errors?.find(e => e.code === "FORBIDDEN_RESOURCE")) {
          return Promise.reject({ code: "FORBIDDEN_RESOURCE" });
        }
        return Promise.reject(httpResponse);
      }
    );
  }

  connect = (azureToken: string): Promise<Api.Response<AuthApi.ConnectResponse>> => {
    this.azureTokenInstance.defaults.headers["Authorization"] = `Bearer ${azureToken}`;
    return this.azureTokenInstance.post(`${this.rootPath}/connect`);
  }

  refresh = (refreshToken: string, azureToken: string): Promise<Api.Response<AuthApi.RefreshResponse>> => {
    this.azureTokenInstance.defaults.headers["Authorization"] = `Bearer ${azureToken}`;
    return this.azureTokenInstance.post(`${this.rootPath}/refresh`, { refreshToken });
  }

  getUserData = (token: string): Promise<Api.GetOne<AuthApi.UserDto>> => {
    this.azureTokenInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
      return this.instance.get(`${this.rootPath}/me`).then((responce) => ({
        ...responce,
        data: {
          ...responce.data,
          data: { ...responce.data.data, companyLogo: getLogoUrl(responce.data.data.companyLogo)}
        }
      })
    );
  }

  logout = (): Promise<{}> =>
    this.instance.post(`${this.rootPath}/logout`);
}