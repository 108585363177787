import React from "react";
import Table, { TableProps } from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import RoleOptions from "./RoleOptions";

const defaultColumns = [
  {
    key: "name",
    sort: true,
    i18nKey: "roleName",
  },
  {
    key: "userCount",
    sort: true,
    i18nKey: "usersOfRole",
    cellOptions: {
      width: 250,
    },
  },
  {
    key: "groupCount",
    sort: true,
    i18nKey: "groups",
    cellOptions: {
      width: 250,
    },
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof Roles
 * @component
 * @desc Render table of Roles.
 * @property {RolesApi.RoleDto[]} dataSource - Roles data
 * @property {Function} onEdit - callback for open the Edit Role dialog. (data: RolesApi.RoleDto) => void
 * @property {Function} onDelete - callback for the delete a role. (data: RolesApi.RoleDto) => Promise<unknown> | void
 * @property {Function} onSelect - callback for update selected roles;
 */

interface TableRolesProps
  extends Omit<TableProps<RolesApi.RoleDto>, "columns" | "onSelect"> {
  onSort?: (field: string, order: "desc" | "asc") => void;
  onEdit?: (data: RolesApi.RoleDto) => void;
  onDelete?: (data: RolesApi.RoleDto) => Promise<unknown> | void;
}

const RolesTable = ({
  onEdit,
  onDelete,
  onSort,
  ...other
}: TableRolesProps) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, role: RolesApi.RoleDto) => (
            <RoleOptions {...{ role, onEdit, onDelete }} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...other}
      columns={columns}
      rowKey="roleId"
      onRequestSort={onSort}
      stickyHeader
    />
  );
};

export default React.memo(RolesTable);
