import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import DatePicker from "~/components/CommonFormControls/DatePicker";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import api from "~/api";
import { saveFile } from "~/utils/helpers";
import SelectPurposeKey from "~/components/CommonFormControls/SelectPurposeKey";

interface CreateKeyDialogProps extends Omit<DialogProps, "onClose"> {
  onClose?: (key?: KeyManagementApi.KeyDto) => void;
}

const defaultValues: KeyManagementApi.CreateKey = {
  expirationDate: new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).toISOString(),
  name: "",
  content: "",
};

const ExpirationDatePickerOptions = {
  minDate: "today",
};

/**
 * @memberof KeyManagementPage
 * @component
 * @desc Dialog for creating a Key.
 * @property {Function} onClose - passes key data if the key was created. (key?: KeyManagementApi.KeyDto) => void;
 */

const CreateKeyDialog = ({ open, onClose }: CreateKeyDialogProps) => {
  const [initialValues, setInitialValues] =
    useState<KeyManagementApi.CreateKey>(defaultValues);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    expirationDate: yup.string().required(),
    name: yup.string().required(),
    purpose: yup.string().required(),
    content: yup
      .string()
      .noCyrillic()
      .when("purpose", {
        is: (value: KeyManagementApi.eKeyPurpose) =>
          value !== KEY_PURPOSE.MASTER_PASS_ENCRYPTION_KEY &&
          value !== KEY_PURPOSE.MASTER_PASS_MAC_KEY,
        then: () => yup.string().noCyrillic().required(),
      })
      .when("isGenerate", {
        is: (value: boolean) => value,
        then: () => yup.string(),
      }),
  });

  const onSubmit = useCallback(
    async ({ purpose, isGenerate, relationKeyId, ...data }) => {
      const formData = {
        ...data,
        purpose,
        ...(purpose === KEY_PURPOSE.INBOUND_VT_SIGN_KEY ||
        purpose === KEY_PURPOSE.OUTBOUND_VT_SIGN_KEY ||
        purpose === KEY_PURPOSE.PAYMENT_ADMIN_PANEL_SIGN_KEY ||
        purpose === KEY_PURPOSE.PAYMENT_SERVICE_SIGN_KEY
          ? { isGenerate }
          : {}),
        ...(purpose === KEY_PURPOSE.APPLE_PUBLIC_KEY ||
        purpose === KEY_PURPOSE.APPLE_PRIVATE_KEY ||
        purpose === KEY_PURPOSE.GOOGLE_PUBLIC_KEY ||
        purpose === KEY_PURPOSE.GOOGLE_PRIVATE_KEY
          ? { relationKeyId }
          : {}),
        content: btoa(data.content),
      };
      setLoading(true);
      try {
        const { data } = await api.keyManagement.createKey(formData);
        if (data && data.data && data.data.dataUrl && data.data.filename) {
          const { dataUrl, filename } = data.data;
          dataUrl &&
            filename &&
            saveFile(
              dataUrl,
              filename.slice(0, -4) + "(public_key)" + filename.slice(-4)
            );
        }
        onClose && onClose(data.data);
      } finally {
        setLoading(false);
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (open) {
      setInitialValues(defaultValues);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t("title.addKey")}
      onClose={() => !loading && onClose && onClose()}
      closable>
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}>
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label={t("label.keyName")}>
                  <OutlinedInput
                    name="name"
                    formikControll
                    fullWidth
                    autoComplete="false"
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.keyPurpose")}>
                  <SelectValue
                    name="purpose"
                    loadOptions={() =>
                      api.keyManagement
                        .getKeyPurposes()
                        .then(({ data }) =>
                          [...data.data]
                            .sort()
                            .map((o) => ({ label: o, value: o }))
                        )
                    }
                    formikControll
                    fullWidth
                  />
                </FormControlLabel>
                {(values.purpose === KEY_PURPOSE.INBOUND_VT_SIGN_KEY ||
                  values.purpose === KEY_PURPOSE.OUTBOUND_VT_SIGN_KEY ||
                  values.purpose === KEY_PURPOSE.PAYMENT_ADMIN_PANEL_SIGN_KEY ||
                  values.purpose === KEY_PURPOSE.PAYMENT_SERVICE_SIGN_KEY) && (
                  <FormControlLabel label={t("label.isGenerate")}>
                    <Checkbox name="isGenerate" size="medium" formikControll />
                  </FormControlLabel>
                )}
                {(values.purpose === KEY_PURPOSE.APPLE_PUBLIC_KEY ||
                  values.purpose === KEY_PURPOSE.APPLE_PRIVATE_KEY ||
                  values.purpose === KEY_PURPOSE.GOOGLE_PUBLIC_KEY ||
                  values.purpose === KEY_PURPOSE.GOOGLE_PRIVATE_KEY) && (
                  <FormControlLabel label={t("label.relationKeyId")}>
                    <SelectPurposeKey
                      name="relationKeyId"
                      keyPurpose={values.purpose}
                    />
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.keyContent")}>
                  <OutlinedInput
                    name="content"
                    disabled={
                      values.isGenerate === true &&
                      (values.purpose === KEY_PURPOSE.INBOUND_VT_SIGN_KEY ||
                        values.purpose === KEY_PURPOSE.OUTBOUND_VT_SIGN_KEY ||
                        values.purpose ===
                          KEY_PURPOSE.PAYMENT_ADMIN_PANEL_SIGN_KEY ||
                        values.purpose === KEY_PURPOSE.PAYMENT_SERVICE_SIGN_KEY)
                    }
                    formikControll
                    multiline
                    rows={10}
                    fullWidth
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.expirationDate")}>
                  <DatePicker
                    name="expirationDate"
                    options={ExpirationDatePickerOptions}
                  />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}>
                {t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}>
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateKeyDialog;
