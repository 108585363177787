import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";
import SystemLogs from "./SystemLogs";

/**
 * @namespace SystemLog
 */

const SystemLogPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.journals") }, { label: t("title.systemLog") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <SystemLogs />
    </>
  );
};

export default SystemLogPage;
