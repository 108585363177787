import { useState, useCallback } from "react";
import { Formik } from "formik";
import { Box, Button, FormControlLabel } from "~/components/UI";
import SelectOutboundVT from "~/components/CommonFormControls/SelectOutboundVT";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";

interface AddOutboundTerminalProps {
  filter?: (
    option: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[]
  ) => OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[];
  onAddTerminal: (
    formData: InboundVirtualTerminalsApi.OutboundTerminalsDto
  ) => void;
}

const AddOutboundTerminal = ({
  filter,
  onAddTerminal
}: AddOutboundTerminalProps) => {
  const [initialValues] = useState({
    outboundTerminalId: "",
    accountNumber: "",
  } as InboundVirtualTerminalsApi.OutboundTerminalsDto);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (formData, actions) => {
      actions.resetForm({
        values: {}
      });
      onAddTerminal(formData);
    },
    [onAddTerminal]
  );

  const validationSchema = yup.object().shape({
    outboundTerminalId: yup.string().required()
    // priority: yup.string().required(),
  });

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false
      }}
    >
      {({ isValid, handleSubmit, setFieldValue }) => (
        <Box py={2}>
          <FormControlLabel label={t("label.outboundTerminalID")}>
            <SelectOutboundVT
              name="outboundTerminalId"
              filterOptions={filter}
              onChange={(o) => setFieldValue('accountNumber', o?.accountNumber)}
            />
          </FormControlLabel>
          <Box ml={32}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              {t("button.addOutboundTerminalBinding")}
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default AddOutboundTerminal;
