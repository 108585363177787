import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class MDESMerchantsApi {
  rootPath: string = "/mdes-merchants";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.mdesMerchants')
  }

  create = (data: MDESMerchantsApi.CreateMerchant) =>
    this.instance.post(this.rootPath, data);
  getAll = (limit: number = 10,
    page: number = 1,
    query?: MDESMerchantsApi.GetMDESMerchantsQuery) => {
      if (query && !query.sort) {
        query =  {...query, sort: "id"} 
      }
      return this.instance.get(this.rootPath, {
       params: {
         limit,
         skip: (page - 1) * limit,
         ...deleteEmptyFields(query || {}),
       },
     })};
  getMerchant = (merchantId: string) =>
    this.instance.get(`${this.rootPath}/${merchantId}`);
  update = (merchantId: string, data: MDESMerchantsApi.UpdateMerchant) =>
    this.instance.put(`${this.rootPath}/${merchantId}`, data);
  deleteMerchants = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (merchantId: string) =>
    this.instance.delete(`${this.rootPath}/${merchantId}`);
}
