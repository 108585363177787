import { useState, useCallback } from "react";
import { Formik } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  OutlinedInput,
  Switch,
} from "~/components/UI";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from '~/api'
import { CONNECTOR_TYPE_I18n } from "~/api/acquirers/enums";

interface AddAcquirerEndpointProps {
  onAddEndpoint: (formData: AcquirersApi.EndpointDto) => void;
}

const defaultValues = {
  url: "",
  enabled: false,
} as AcquirersApi.EndpointDto;

const AddAcquirerEndpoint = ({ onAddEndpoint }: AddAcquirerEndpointProps) => {
  const [initialValues] = useState(defaultValues);
  const { t } = useTranslation(["translation", "enums"]);

  const onSubmit = useCallback(
    (formData: AcquirersApi.EndpointDto, actions) => {
      actions.resetForm(defaultValues);
      onAddEndpoint(formData);
    },
    [onAddEndpoint]
  );

  const validationSchema = yup.object().shape({
    url: yup.string().website().required(),
    connectorType: yup.string().required(),
    enabled: yup.boolean().required(),
    requestTimeout: yup.number().max(120).required(),
  });

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
      }}
    >
      {({ isValid, handleSubmit }) => (
        <Box py={2}>
          <FormControlLabel label="URL">
            <OutlinedInput name="url" formikControll />
          </FormControlLabel>
          <FormControlLabel label={t("label.connectorType")}>
            <SelectValue
              name="connectorType"
              loadOptions={() =>
                api.acquirers
                  .getAcquirerEndpointTypes()
                  .then(({ data }) =>
                    [...data.data].sort().map((o) => ({
                      label: t(`enums:${CONNECTOR_TYPE_I18n[o]}`),
                      value: o
                    }))
                  )
              }
              formikControll
              fullWidth
            />
          </FormControlLabel>
          <FormControlLabel label="requestTimeout">
            <OutlinedInput type="number" name="requestTimeout" formikControll />
          </FormControlLabel>
          <FormControlLabel label={t("label.enabled")}>
            <Switch name="enabled" formikControll />
          </FormControlLabel>
          <FormControlLabel>
            <Button
              variant="contained"
              color="secondary"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              {t("button.addAcquirerEndpoint")}
            </Button>
          </FormControlLabel>
        </Box>
      )}
    </Formik>
  );
};

export default AddAcquirerEndpoint;
