import Constants from "./constants";
import SettingsConstants from '../settings/constants';

const ACTIONS = Object.assign(Constants.ACTIONS, SettingsConstants.ACTIONS) ;

const initialState: AlertsStore.State = {
  isOpenSideBar: false,
  alerts: [] as AlertsApi.AlertDto[],
};
const Reducer = (
  state = initialState,
  action: AlertsStore.Actions | SettingsStore.Actions
): AlertsStore.State => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        isOpenSideBar: true,
      };
    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpenSideBar: false,
      };
    case ACTIONS.SET_ALERTS:
      return {
        ...state,
        alerts: action.payload
      };
    case ACTIONS.ADD_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case ACTIONS.SKIP_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(a => a.alertId !== action.payload),
      };
    default:
      return state;
  }
};

export default Reducer;
