import { useState } from "react";
import { Tag, TagsContainer, styled } from "~/components/UI";

const ShowAllButton = styled(Tag)(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    cursor: "pointer",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  })
);

interface CollapsibleButtonGroupProps {
  displayByDefault: number;
  showAllBtnText: string;
  children: React.ReactChild[];
}

const CollapsibleButtonGroup = ({
  displayByDefault = 1,
  showAllBtnText = "Show All",
  children,
}: CollapsibleButtonGroupProps) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(true);
  };

  if (!children) return null;

  return (
    <TagsContainer>
      {showAll ? children : children.slice(0, displayByDefault)}
      {!showAll && displayByDefault < children.length && (
        <ShowAllButton key="show-all" onClick={handleShowAll}>
          {showAllBtnText} ({children.length - displayByDefault})
        </ShowAllButton>
      )}
    </TagsContainer>
  );
};

export default CollapsibleButtonGroup;
