import MUIListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles, createStyles } from "@material-ui/core/styles";

const ListItemIcon = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      fontSize: 21,
      minWidth: 50,
      color: "inherit",
      "&.active": {
        color: theme.palette.primary.main,
      },
      "@media (max-width:600px)": {
        display: "none"
      },
    },
  })
)(MUIListItemIcon);

export default ListItemIcon;
