import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import Incidents from "./Components/Incidents";
import { useTranslation } from "react-i18next";

/**
 * @namespace Incidents
 */

const IncidentsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.journals") }, { label: t("title.incidents") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Incidents />
    </>
  );
};

export default IncidentsPage;
