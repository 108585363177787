import MUIListItemText from "@material-ui/core/ListItemText";
import { withStyles, createStyles } from "@material-ui/core/styles";

const ListItemText = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      fontSize: "1em",
      fontFamily: theme.typography.fontFamily,
      "@media (max-width:600px)": {
        fontSize: 10,
      },
      "&::first-letter": {
        textTransform: "uppercase",
      },
      "&.active": {
        color: theme.palette.text.primary,
        fontWeight: "bold",
      },
    },
  })
)(MUIListItemText);

export default ListItemText;
