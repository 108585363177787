import { Switch } from "react-router";
import ROUTES from "~/constants/routes";
import FinancialTransactions from "./FinancialTransactions";
import Incidents from "./Incidents";
import UserActivityLog from "./UserActivityLog";
import SystemLog from "./SystemLog";
import EventsLog from "./EventsLog";
import AuditKeyManagement from "./AuditKeyManagement";
import PrivateRoute from "~/components/PrivateRoute";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const Journals = () => {
  const { hasAll } = usePermissions();

  return (
    <Switch>
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_FINANCIAL_TRANSACTIONS)}
        path={ROUTES.FINANCIAL_TRANSACTIONS}
        component={FinancialTransactions}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_EVENT_LOG)}
        path={ROUTES.EVENTS}
        component={EventsLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)}
        path={ROUTES.INCIDENTS}
        component={Incidents}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS)}
        path={ROUTES.USER_ACTIVITY}
        component={UserActivityLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_JOURNAL_SYSTEM_LOG)}
        path={ROUTES.SYSTEM_LOG}
        component={SystemLog}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_AUDIT_LOG_KEY_MANAGEMENT)}
        path={ROUTES.AUDIT_KEY_MANAGEMENT}
        component={AuditKeyManagement}
      />
    </Switch>
  );
};

export default Journals;
