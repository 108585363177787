import React from "react";
import { IconButton, Icon } from "../";
import { Typography, Grid } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";

const Wrapper = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(6),
      marginBottom: 0,
      paddingBottom: theme.spacing(5),
      width: `calc(100% - ${theme.spacing(8)}px)`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  })
)(Grid);

interface HeaderProps {
  title?: string;
  closeBtn?: boolean;
  onClose?: () => void;
}

const Header = ({ title, closeBtn, onClose }: HeaderProps) => (
  <Wrapper container justifyContent="space-between" alignItems="center">
    <Grid item>
      <Typography variant="h4">{title}</Typography>
    </Grid>
    <Grid item>
      {closeBtn && (
        <IconButton size="small" onClick={() => onClose && onClose()}>
          <Icon name="Close" />
        </IconButton>
      )}
    </Grid>
  </Wrapper>
);

export default Header;
