import { useCallback } from "react";
import { Box, IconButton, Badge, Icon } from "~/components/UI";
import { styled } from "@material-ui/core/styles";

export interface IncidentsBadgeProps {
  count?: number;
  size?: "small" | "default";
  onClick?: () => void;
}

const SMContainer = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    borderRadius: 20,
    backgroundColor: theme.palette.error.main,
    fontSize: 12,
    lineHeight: 1.22,
    fontWeight: "bold",
    color: theme.palette.error.contrastText,
    boxSizing: "border-box",
  })
);

const DefaultContainer = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxSizing: "border-box",
    borderRadius: "50%",
    padding: 4,
    fontSize: "1.3em",
    width: 34,
    height: 34,
    justifyContent: "center",
    boxShadow: theme.shadows[4],
  })
);

const SmallBadge = ({ count }: { count: number }) => (
  <SMContainer>
    <Icon name="AlertError" />
    <Box ml={1}>{count}</Box>
  </SMContainer>
);

const DefaultBadge = ({ count }: { count: number }) => (
  <Badge badgeContent={count} overlap="circular" color="primary">
    <DefaultContainer>
      <Icon name="AlertError" />
    </DefaultContainer>
  </Badge>
);

const IncidentsBadge = ({ count, size, onClick }: IncidentsBadgeProps) => {
  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onClick && onClick();
    },
    [onClick]
  );

  return count ? (
    <IconButton onClick={clickHandler}>
      {size === "small" ? (
        <SmallBadge count={count} />
      ) : (
        <DefaultBadge count={count} />
      )}
    </IconButton>
  ) : null;
};

export default IncidentsBadge;
