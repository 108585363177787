import MUIBreadcrumbs, {
  BreadcrumbsProps as MUIBreadcrumbsProps,
} from "@material-ui/core/Breadcrumbs";
import MUILink from "@material-ui/core/Link";
import MUITypography from "@material-ui/core/Typography";
import { withStyles, createStyles } from "@material-ui/core/styles";

export type LinkType = {
  label?: string;
  href?: string;
  onClick?: () => void;
};

const Link = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.secondary,
      "&::first-letter": {
        textTransform: "uppercase",
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
)(MUILink);

const Typography = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      fontSize: theme.typography.fontSize,
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
  })
)(MUITypography);

export interface BreadcrumbsProps extends MUIBreadcrumbsProps {
  links: LinkType[];
}

const Breadcrumbs = withStyles(
  createStyles({
    separator: {
      fontSize: 10,
      margin: "0 5px",
    },
  })
)(({ links, ...other }: BreadcrumbsProps) => (
  <MUIBreadcrumbs {...other} separator="/">
    {links && links.length
      ? links.map(({ label, href, onClick }, index, arr) =>
          href || onClick ? (
            <Link color="inherit" href={href} key={index} onClick={onClick}>
              {label}
            </Link>
          ) : (
            <Typography
              key={index}
              color={arr.length === index + 1 ? "primary" : "initial"}
            >
              {label}
            </Typography>
          )
        )
      : null}
  </MUIBreadcrumbs>
));

export default Breadcrumbs;
