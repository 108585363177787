import { Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE_I18n } from "~/api/common";

interface UserActivitySourceProps {
  source: Api.eSourceType;
}

/**
 * @memberof UserActivity
 * @component
 * @desc Render source of UserActivity.
 * @property {Api.eSourceType} source - UserActivity source;
 */

const UserActivitySource = ({ source }: UserActivitySourceProps) => {
  const { t } = useTranslation(["enums"]);

  return (
    <Typography variant="body1" color="inherit">
      {t(SOURCE_TYPE_I18n[source])}
    </Typography>
  );
};

export default UserActivitySource;
