import { useState } from "react";
import { Tooltip } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import FinancialTransactionsDetails from "./FinancialTransactionsDetails";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import RefundRequestModal from "./RefundRequestModal";
import ReversalPaymentModal from "./ReversalPaymentModal";
import FinancialTransactionOptions from "./FinancialTransactionOptions";
import EnumValue from "~/components/EnumValue";
import TransactionStatus from "./TransactionStatus";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime, currencyString } from "~/utils/format";
import { TRANSACTION_TYPE_I18n } from "~/api/financialTransactions/enums";
// import LinkToObject from "~/components/LinkToObject";
// import { SOURCE_TYPE } from "~/api/common";

const defaultColumns = [
  {
    key: "createdAt",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "merchantName",
    i18nKey: "merchantName"
  },
  {
    key: "acquirerName",
    i18nKey: "acquirerName"
  },
  {
    key: "transactionType",
    i18nKey: "transactionType",
    render: (transactionType: FinancialTransactionsApi.eTransactionType) => (
      <EnumValue value={transactionType} enumI18n={TRANSACTION_TYPE_I18n} />
    )
  },
  {
    key: "transactionCode",
    i18nKey: "transactionCode",
    render: (
      transactionCode: string,
      item: FinancialTransactionsApi.FinancialTransactionDto
    ) => (
      <Tooltip title={item.comment} placement="top">
        <span>{transactionCode}</span>
      </Tooltip>
    )
  },
  {
    key: "paymentType",
    i18nKey: "paymentType"
  },
  {
    key: "status",
    sort: true,
    i18nKey: "status",
    render: TransactionStatus
  },
  {
    key: "amount",
    sort: true,
    i18nKey: "amount",
    cellOptions: {
      width: 150
    },
    render: (amount: string) =>
      Number.isInteger(Number(amount)) ? currencyString(Number(amount)) : ""
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

interface FinancialTransactionsTableProps
  extends Omit<
    TableProps<FinancialTransactionsApi.FinancialTransactionDto>,
    "columns"
  > {
  onUpdateItem?: (
    transaction: FinancialTransactionsApi.FinancialTransactionDto
  ) => void;
  onRefresh?: () => void;
}

/**
 * @memberof FinancialTransactions
 * @component
 * @desc Render table of FinancialTransactions.
 * @property {FinancialTransactionsApi.FinancialTransactionDto[]} dataSource - FinancialTransactions data
 */

const FinancialTransactionsTable = ({
  onUpdateItem,
  onRefresh,
  ...props
}: FinancialTransactionsTableProps) => {
  const [refundTransaction, setRefundTransaction] = useState<
    FinancialTransactionsApi.FinancialTransactionDto &
      FinancialTransactionsApi.FinancialTransactionDetailsDto
  >();
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [showReversalDialog, setShowReversalDialog] = useState(false);
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            transaction: FinancialTransactionsApi.FinancialTransactionDto
          ) => (
            <FinancialTransactionOptions
              transaction={transaction}
              onUpdateItem={onUpdateItem}
            />
          )
        };
      default:
        return column;
    }
  });

  return (
    <>
      <Table
        {...props}
        columns={columns}
        rowKey="id"
        expandable={{
          highlightRow: true,
          expandedRowRender: (
            financialTransaction: FinancialTransactionsApi.FinancialTransactionDto
          ) => (
            <FinancialTransactionsDetails
              financialTransaction={financialTransaction}
              onRefundTransaction={(ft) => {
                setRefundTransaction(ft);
                setShowRefundDialog(true);
              }}
              onReversalTransaction={(ft) => {
                setRefundTransaction(ft);
                setShowReversalDialog(true);
              }}
            />
          )
        }}
        stickyHeader
      />
      <RefundRequestModal
        open={showRefundDialog}
        financialTransaction={refundTransaction}
        onClose={(result) => {
          setShowRefundDialog(false);
          setRefundTransaction(undefined);
          onRefresh && result && onRefresh();
        }}
      />
      <ReversalPaymentModal
        open={showReversalDialog}
        financialTransaction={refundTransaction}
        onClose={(result) => {
          setShowReversalDialog(false);
          setRefundTransaction(undefined);
          onRefresh && result && onRefresh();
        }}
      />
    </>
  );
};

export default FinancialTransactionsTable;
