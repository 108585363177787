import { Switch } from "react-router";
import ROUTES from "~/constants/routes";
import CompanyProfile from "./CompanyProfile";
import IDPrefixes from "./IDPrefixes";
import PrivateRoute from "~/components/PrivateRoute";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const CatalogsRoutes = () => {
  const { hasAll } = usePermissions();

  return (
    <Switch>
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_SYSTEM_SETTINGS)}
        path={ROUTES.COMPANY_PROFILE}
        component={CompanyProfile}
      />
      <PrivateRoute
        canIUse={true}
        path={ROUTES.ID_PREFIXES}
        component={IDPrefixes}
      />
    </Switch>
  );
};

export default CatalogsRoutes;
