import { useState, useMemo } from "react";
import { Box, Grid, Slider } from "~/components/UI";
import { useField } from "formik";
import _ from "lodash";

type LabelFn = (value: number) => string;

interface RangeProps {
  min: number;
  max: number;
  minFieldName: string;
  maxFieldName: string;
  minFieldLabel: string | LabelFn;
  maxFieldLabel: string | LabelFn;
}

const Range = ({
  min,
  max,
  minFieldName,
  maxFieldName,
  minFieldLabel = (value: number) => `${value}`,
  maxFieldLabel = (value: number) => `${value}`
}: RangeProps) => {
  const [, , minFieldHelper] = useField(minFieldName);
  const [, , maxFieldHelper] = useField(maxFieldName);
  const [value, setValue] = useState([0, 100]);

  const updateFields = useMemo(
    () =>
      _.throttle((value: number[]) => {
        minFieldHelper.setValue(Math.floor((value[0] / 100) * (max - min)));
        maxFieldHelper.setValue(Math.floor((value[1] / 100) * max));
      }, 500),
    []
  );

  const handleChange = (
    _e: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    if (!Array.isArray(newValue)) return;
    setValue(newValue);
    updateFields(newValue);
  };

  return (
    <Box>
      <Box px={1}>
        <Slider
          value={value}
          onChange={handleChange}
          onChangeCommitted={() => updateFields(value)}
        />
      </Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          {typeof minFieldLabel === "string"
            ? minFieldLabel
            : minFieldLabel(Math.floor((value[0] / 100) * (max - min)))}
        </Grid>
        <Grid item>
          {typeof maxFieldLabel === "string"
            ? maxFieldLabel
            : maxFieldLabel(Math.floor((value[1] / 100) * max))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Range;
