import { ThunkAction } from 'redux-thunk';
import Constants from "./constants";
import api from '~/api';

const { ACTIONS } = Constants;

export const setProfileData = (
  payload: ProfileApi.MyProfileDto
): ProfileStore.SetProfileData => ({
  type: ACTIONS.SET_PROFILE_DATA,
  payload,
});

export const getProfile = (): ThunkAction<void, ReduxStore.State, SearchStore.eActionType, any> => async (dispatch) => {
  try {
    const { data: { data } } = await api.profile.getMyProfile();

    dispatch(setProfileData(data));
  } catch (e) {
    console.error(e);
  }
};
