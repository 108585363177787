import { useContext, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import InboundVirtualTerminalOptions from "./InboundVirtualsTerminalOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import InboundVirtualTerminalsContext from "./InboundVirtualsTerminalsContext";
import InboundVirtualTerminalDetails from "./InboundVirtualTerminalDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "id",
    title: "ID",
    sort: true,
    copy: true,
    cellOptions: {
      width: 120,
    },
  },
  {
    key: "merchantName",
    i18nKey: "merchantName",
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    sort: true,
    cellOptions: {
      width: 50,
    },
  },
  // {
  //   key: "accountNumber",
  //   sort: true,
  //   i18nKey: "accountNumber",
  // },
  // {
  //   key: "routingType",
  //   sort: true,
  //   i18nKey: "routingType",
  // },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "description",
    i18nKey: "description",
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

const InboundVirtualTerminalsTable = (
  props: Omit<
    TableProps<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>,
    "columns"
  >,
  ref: any
) => {
  const { onUpdate } = useContext(InboundVirtualTerminalsContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (
            enabled: boolean,
            inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto
          ) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_CONTRACT)}
              onConfirm={() =>
                onUpdate &&
                onUpdate(inboundVirtualTerminal.id, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_OUTBOUND_VIRTUAL_TERMINAL)}
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto
          ) => (
            <InboundVirtualTerminalOptions
              inboundVirtualTerminal={inboundVirtualTerminal}
            />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      ref={ref}
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (inboundVirtualTerminal) => (
          <InboundVirtualTerminalDetails
            inboundVirtualTerminal={inboundVirtualTerminal}
          />
        ),
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<
    TableProps<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>,
    "columns"
  >
>(InboundVirtualTerminalsTable);
