import { Icon, IconButton, Badge, styled } from "~/components/UI";
import { useSelector, useDispatch } from "react-redux";
import { openSidebar } from "~/store/alerts/actions";

const AlertIcon = styled(Icon)(() => ({
  fontSize: 24,
  marginRight: 5,
}));

const AlertsIcon = () => {
  const dispatch = useDispatch();
  const { isOpenSideBar, alerts } = useSelector(
    (store: ReduxStore.State) => store.alerts
  );

  if (isOpenSideBar) return null;

  return (
    <IconButton onClick={() => dispatch(openSidebar())}>
      <Badge badgeContent={alerts.length} color="error">
        <AlertIcon name="BellOutline" />
      </Badge>
    </IconButton>
  );
};

export default AlertsIcon;
