import { Route, Redirect, RouteProps } from "react-router";

interface PrivateRouteProps extends RouteProps {
  isAuthenticated?: boolean;
  canIUse?: boolean;
  redirect?: string;
}

/**
 * Checks if the user is already logged in, then provides access to the data to all children
 * @memberOf Components
 * @name PrivateRoute
 * @property {boolean} isAuthenticated
 * @property {string} redirect
 * @return {React.Component}
 */

const PrivateRoute = ({
  redirect,
  isAuthenticated,
  canIUse,
  ...other
}: PrivateRouteProps) => {
  return isAuthenticated || canIUse ? (
    <Route {...other} />
  ) : (
    <Redirect to={redirect || "/"} />
  );
};

export default PrivateRoute;
