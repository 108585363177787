import { styled } from "@material-ui/core/styles";

const FieldError = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    fontSize: theme.typography.fontSize - 2,
    color: theme.palette.error.main,
    "&::first-letter": {
      textTransform: "uppercase",
    },
  })
);

export default FieldError;
