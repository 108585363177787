
const ROUTES = {
  LOGIN: "/login",

  DASHBOARD: "/dashboard",
  SEARCH_RESULTS: "/search-results",

  CATALOGS: '/catalogs',

  CONTRACTS: '/catalogs/contracts',
  MERCHANTS: '/catalogs/merchants',
  MDES_MERCHANTS: '/catalogs/mdes-merchants',
  INBOUND_VIRTUAL_TERMINALS: '/catalogs/inbound-virtual-terminals',
  OUTBOUND_VIRTUAL_TERMINALS: '/catalogs/outbound-virtual-terminals',
  ACQUIRERS: '/catalogs/acquirers',
  BIN_RANGE: '/catalogs/bin-range',
  KEY_MANAGEMENT: '/catalogs/key-management',
  WALLET_CREDENTIALS: '/catalogs/wallet-credentials',
  CARD_ON_FILE: '/catalogs/card-on-file',

  USER_ROLES: "/catalogs/user-roles",
  USERS: "/catalogs/users",
  GROUPS: "/catalogs/groups",

  JOURNALS: "/journals",
  FINANCIAL_TRANSACTIONS: '/journals/financial-transactions',
  BUSINESS_OPERATIONS: '/journals/journal-business-operations',
  INCIDENTS: '/journals/incidents',
  USER_ACTIVITY: '/journals/user-activity',
  EVENTS: '/journals/events',
  SYSTEM_LOG: '/journals/system-log',
  AUDIT_KEY_MANAGEMENT: "/journals/audit-key-management",

  METRICS: "/metrics",
  MY_PROFILE: '/my-profile',

  SETTINGS: '/settings',
  COMPANY_PROFILE: '/settings/company-profile',
  ID_PREFIXES: '/settings/id-prefixes',

  LOGOUT: '/logout',
  FORBIDDEN: '/forbidden'
};


export default ROUTES;
