import { useState, useCallback, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  LinearProgress
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SelectKey } from "~/components/CommonFormControls/SelectPurposeKey";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateMDESMerchantKeysDialogProps
  extends Omit<DialogProps, "onClose"> {
  onClose?: (mdesKeys?: MDESKeysApi.MDESKeysDto) => void;
}

interface FromState extends MDESKeysApi.CreateMDESKeys {}

/**
 * @memberof MDESMerchants
 * @component
 * @desc Dialog for adding MDES Keys.
 * @property {Function} onClose - passes merchant data if the merchant was added successfully. () => void;
 */

const defaultValues = {
  signKey: "",
  consumerKey: "",
  encryptionKey: "",
  decryptionKey: ""
};

const CreateMDESMerchantKeysDialog = ({
  open,
  onClose
}: CreateMDESMerchantKeysDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [meta, setMeta] = useState<MDESKeysApi.MDESKeysDto>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    signKey: yup.string().required(),
    consumerKey: yup.string().required(),
    encryptionKey: yup.string().required(),
    decryptionKey: yup.string().required()
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      setLoading(true);
      try {
        if (meta) {
          await api.MDESKeys.updateKeys(values);
        } else {
          await api.MDESKeys.createKeys(values);
        }
        onClose && onClose();
      } finally {
        setLoading(false);
      }
    },
    [onClose, t, meta]
  );

  const syncMDESKeys = async () => {
    setSyncInProgress(true);
    try {
      const {
        data: { data }
      } = await api.MDESKeys.getAll();
      setMeta(data);
    } finally {
      setSyncInProgress(false);
    }
  };

  useEffect(() => {
    if (meta) {
      setInitialValues({
        signKey: meta && meta.signKey.keyId,
        consumerKey: meta && meta.consumerKey.keyId,
        encryptionKey: meta && meta.encryptionKey.keyId,
        decryptionKey: meta && meta.decryptionKey.keyId
      });
    } else {
      setInitialValues(defaultValues);
    }
  }, [meta]);

  useEffect(() => {
    syncMDESKeys();
  }, []);

  return (
    <Dialog
      open={open}
      title={t("title.mdesKeys")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <>
            <DialogContent>
              <LinearProgress hidden={!syncInProgress} />
              <>
                <FormControlLabel label={t("label.signingKey")}>
                  <SelectKey
                    inputValue={meta && meta.signKey.name}
                    name="signKey"
                    keyPurpose={KEY_PURPOSE.MDES_SIGNING_KEY}
                    onChange={(key) =>
                      setFieldValue("signKey", key ? key.keyId : "")
                    }
                    error={errors && errors["signKey"]}
                    touched={Boolean(errors && errors["signKey"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.consumerKey")}>
                  <SelectKey
                    inputValue={meta && meta.consumerKey.name}
                    name="consumerKey"
                    keyPurpose={KEY_PURPOSE.MDES_CONSUMER_KEY}
                    onChange={(key) =>
                      setFieldValue("consumerKey", key ? key.keyId : "")
                    }
                    error={errors && errors["consumerKey"]}
                    touched={Boolean(errors && errors["consumerKey"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.encryptionKey")}>
                  <SelectKey
                    inputValue={meta && meta.encryptionKey.name}
                    name="encryptionKey"
                    keyPurpose={KEY_PURPOSE.MDES_ENCRYPTION_KEY}
                    onChange={(key) =>
                      setFieldValue("encryptionKey", key ? key.keyId : "")
                    }
                    error={errors && errors["encryptionKey"]}
                    touched={Boolean(errors && errors["encryptionKey"])}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.decryptionKey")}>
                  <SelectKey
                    inputValue={meta && meta.decryptionKey.name}
                    name="decryptionKey"
                    keyPurpose={KEY_PURPOSE.MDES_DECRYPTION_KEY}
                    onChange={(key) =>
                      setFieldValue("decryptionKey", key ? key.keyId : "")
                    }
                    error={errors && errors["decryptionKey"]}
                    touched={Boolean(errors && errors["decryptionKey"])}
                  />
                </FormControlLabel>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {t("button.save&Create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateMDESMerchantKeysDialog;
