import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  SidebarMenu,
  SidebarMenuGroup,
  SidebarMenuItem
} from "~/components/UI";
import { useLocation } from "react-router-dom";
import ROUTES from "~/constants/routes";
import history from "~/packages/history";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const openDrawerWidth = 360;
const closedDrawerWidth = 80;

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%"
    },
    menu: {
      width: openDrawerWidth,
      height: "100%"
    },
    drawer: {
      backgroundColor: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.divider}`,
      overflow: "hidden",
      flexShrink: 0,
      whiteSpace: "nowrap",
      height: "100%",
      boxSizing: "border-box"
    },
    drawerOpen: {
      width: openDrawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflowX: "hidden",
      width: closedDrawerWidth,
      "@media (max-width:600px)": {
        width: 60,
      },
    },
    drawerPaper: {
      padding: "17px 19px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.default
    },
    content: {
      boxSizing: "border-box",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      flexGrow: 1,
      overflow: "auto",
      "@media (max-width:600px)": {
        overflow: "inherit",
      },
    }
  })
);

interface Props {
  alerts?: object[];
  children?: React.ReactChildren | React.ReactNode;
}

const SideMenu = ({ children }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { hasAll, hasAny } = usePermissions();

  const goTo = useCallback((path: string) => {
    history.push(path);
  }, []);

  const isActive = useCallback(
    (path: string) => {
      return !!pathname && new RegExp(`^${path}`).test(pathname);
    },
    [pathname]
  );

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
      >
        <nav className={classes.menu}>
          <SidebarMenu
            open={open}
            width={open ? openDrawerWidth : closedDrawerWidth}
            onChangeState={() => setOpen(!open)}
          >
            <SidebarMenuItem
              label={t("title.dashboard")}
              icon="Dashboard"
              active={isActive(ROUTES.DASHBOARD)}
              onClick={() => goTo(ROUTES.DASHBOARD)}
            />
            <SidebarMenuGroup
              label={t("title.catalogs")}
              icon="Catalogs"
              active={isActive(ROUTES.CATALOGS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_CONTRACT,
                  PERMISSION.READ_MERCHANT,
                  PERMISSION.READ_INBOUND_VIRTUAL_TERMINAL,
                  PERMISSION.READ_ACQUIRER,
                  PERMISSION.READ_BIN_RANGE,
                  PERMISSION.READ_OUTBOUND_VIRTUAL_TERMINAL,
                  PERMISSION.READ_KEY_MANAGEMENT,
                  PERMISSION.READ_WALLET_CREDENTIALS,
                  PERMISSION.READ_CARD_ON_FILE,
                  PERMISSION.READ_ROLE,
                  PERMISSION.READ_USER,
                  PERMISSION.READ_GROUP
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.acquirers")}
                active={isActive(ROUTES.ACQUIRERS)}
                onClick={() => goTo(ROUTES.ACQUIRERS)}
                hidden={!hasAll(PERMISSION.READ_ACQUIRER)}
                depth={1}
              />
              <SidebarMenuItem
                // TODO add label
                label={t("title.binRanges")}
                active={isActive(ROUTES.BIN_RANGE)}
                onClick={() => goTo(ROUTES.BIN_RANGE)}
                hidden={!hasAll(PERMISSION.READ_BIN_RANGE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.contracts")}
                active={isActive(ROUTES.CONTRACTS)}
                onClick={() => goTo(ROUTES.CONTRACTS)}
                hidden={!hasAll(PERMISSION.READ_CONTRACT)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.merchants")}
                active={isActive(ROUTES.MERCHANTS)}
                onClick={() => goTo(ROUTES.MERCHANTS)}
                hidden={!hasAll(PERMISSION.READ_MERCHANT)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.mdesMerchants")}
                active={isActive(ROUTES.MDES_MERCHANTS)}
                onClick={() => goTo(ROUTES.MDES_MERCHANTS)}
                hidden={!hasAll(PERMISSION.READ_MDES_MERCHANT)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.inboundVirtualTerminals")}
                active={isActive(ROUTES.INBOUND_VIRTUAL_TERMINALS)}
                onClick={() => goTo(ROUTES.INBOUND_VIRTUAL_TERMINALS)}
                hidden={!hasAll(PERMISSION.READ_INBOUND_VIRTUAL_TERMINAL)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.outboundVirtualTerminals")}
                active={isActive(ROUTES.OUTBOUND_VIRTUAL_TERMINALS)}
                onClick={() => goTo(ROUTES.OUTBOUND_VIRTUAL_TERMINALS)}
                hidden={!hasAll(PERMISSION.READ_OUTBOUND_VIRTUAL_TERMINAL)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.walletCredentials")}
                active={isActive(ROUTES.WALLET_CREDENTIALS)}
                onClick={() => goTo(ROUTES.WALLET_CREDENTIALS)}
                hidden={!hasAll(PERMISSION.READ_WALLET_CREDENTIALS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.keyManagement")}
                active={isActive(ROUTES.KEY_MANAGEMENT)}
                onClick={() => goTo(ROUTES.KEY_MANAGEMENT)}
                hidden={!hasAll(PERMISSION.READ_KEY_MANAGEMENT)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.cardOnFile")}
                active={isActive(ROUTES.CARD_ON_FILE)}
                onClick={() => goTo(ROUTES.CARD_ON_FILE)}
                hidden={!hasAll(PERMISSION.READ_CARD_ON_FILE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.userRoles")}
                active={isActive(ROUTES.USER_ROLES)}
                onClick={() => goTo(ROUTES.USER_ROLES)}
                hidden={!hasAll(PERMISSION.READ_ROLE)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.users")}
                active={isActive(ROUTES.USERS)}
                onClick={() => goTo(ROUTES.USERS)}
                hidden={!hasAll(PERMISSION.READ_USER)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.groups")}
                active={isActive(ROUTES.GROUPS)}
                onClick={() => goTo(ROUTES.GROUPS)}
                hidden={!hasAll(PERMISSION.READ_GROUP)}
                depth={1}
              />
            </SidebarMenuGroup>
            <SidebarMenuGroup
              label={t("title.journals")}
              icon="Book"
              active={isActive(ROUTES.JOURNALS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_JOURNAL_FINANCIAL_TRANSACTIONS,
                  PERMISSION.READ_EVENT_LOG,
                  PERMISSION.READ_JOURNAL_INCIDENTS,
                  PERMISSION.READ_JOURNAL_SYSTEM_LOG,
                  PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS,
                  PERMISSION.READ_AUDIT_LOG_KEY_MANAGEMENT
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.financialTransactions")}
                active={isActive(ROUTES.FINANCIAL_TRANSACTIONS)}
                onClick={() => goTo(ROUTES.FINANCIAL_TRANSACTIONS)}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_FINANCIAL_TRANSACTIONS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.eventsLog")}
                active={isActive(ROUTES.EVENTS)}
                onClick={() => goTo(ROUTES.EVENTS)}
                hidden={!hasAll(PERMISSION.READ_EVENT_LOG)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.incidents")}
                active={isActive(ROUTES.INCIDENTS)}
                onClick={() => goTo(ROUTES.INCIDENTS)}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.systemLog")}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_SYSTEM_LOG)}
                active={isActive(ROUTES.SYSTEM_LOG)}
                onClick={() => goTo(ROUTES.SYSTEM_LOG)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.userActivityLog")}
                active={isActive(ROUTES.USER_ACTIVITY)}
                onClick={() => goTo(ROUTES.USER_ACTIVITY)}
                hidden={!hasAll(PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.auditKeyManagement")}
                active={isActive(ROUTES.AUDIT_KEY_MANAGEMENT)}
                onClick={() => goTo(ROUTES.AUDIT_KEY_MANAGEMENT)}
                hidden={!hasAll(PERMISSION.READ_AUDIT_LOG_KEY_MANAGEMENT)}
                depth={1}
              />
            </SidebarMenuGroup>
            <SidebarMenuItem
              label={t("title.metrics")}
              icon="Chart"
              active={isActive(ROUTES.METRICS)}
              onClick={() => goTo(ROUTES.METRICS)}
            />
            <SidebarMenuGroup
              label={t("title.settings")}
              icon="Settings"
              active={isActive(ROUTES.SETTINGS)}
              hidden={
                !hasAny([
                  PERMISSION.READ_SYSTEM_SETTINGS,
                  PERMISSION.READ_ID_PREFIX
                ])
              }
            >
              <SidebarMenuItem
                label={t("title.companyProfile")}
                active={isActive(ROUTES.COMPANY_PROFILE)}
                onClick={() => goTo(ROUTES.COMPANY_PROFILE)}
                hidden={!hasAll(PERMISSION.READ_SYSTEM_SETTINGS)}
                depth={1}
              />
              <SidebarMenuItem
                label={t("title.prefixes")}
                active={isActive(ROUTES.ID_PREFIXES)}
                onClick={() => goTo(ROUTES.ID_PREFIXES)}
                hidden={!hasAll(PERMISSION.READ_ID_PREFIX)}
                depth={1}
              />
            </SidebarMenuGroup>
          </SidebarMenu>
        </nav>
      </div>
      <div className={clsx(classes.content)}>{children}</div>
    </div>
  );
};

export default SideMenu;
