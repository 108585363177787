import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class MerchantsApi {
  rootPath: string = "/merchants";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.merchants')
  }

  create = (data: MerchantsApi.CreateMerchant) =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: MerchantsApi.GetMerchantsQuery
  ) => {
    if(query?.search) {
      query.search += '%';
    }
    if (query && !query.sort) {
      query =  {...query, sort: "id"} 
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {})
      }
    });
  }
  getMerchant = (merchantId: string) =>
    this.instance.get(`${this.rootPath}/${merchantId}`);
  getMerchantWallets = (
    merchantId: string
  ): Promise<Api.GetAll<MerchantsApi.WalletDto>> =>
    this.instance.get(`${this.rootPath}/${merchantId}/wallets`);
  update = (merchantId: string, data: MerchantsApi.UpdateMerchant) =>
    this.instance.put(`${this.rootPath}/${merchantId}`, data);
  deleteMerchant = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (merchantId: string) =>
    this.instance.delete(`${this.rootPath}/${merchantId}`);
}
