import { useState, useEffect } from "react";
import { LinearProgress } from "~/components/UI";
import SystemSettingsForm from "./SystemSettingsForm";
import { useDispatch } from "react-redux";
import { setLogo } from "~/store/auth/actions";
import api from "~/api";

const SystemSettings = () => {
  const [loading, setLoading] = useState(false);
  const [systemSettings, setSystemSettings] =
    useState<SystemSettingsApi.Settings>();
  const dispatch = useDispatch();

  const getSystemSettings = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await api.systemSettings.getSettings();
      setSystemSettings(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (settings: SystemSettingsApi.Settings) => {
    dispatch(setLogo(settings.logoUrl || ""));
    setSystemSettings(settings);
  };

  useEffect(() => {
    getSystemSettings();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <SystemSettingsForm systemSettings={systemSettings} onChange={onChange} />
    </>
  );
};

export default SystemSettings;
