import { useEffect, useState } from "react";
import { LinearProgress, Box } from "~/components/UI";
import StatusBadge from '~/components/StatusBadge'
import Table from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import LinkToObject from "~/components/LinkToObject";
import Search from "~/components/Search";
import { SOURCE_TYPE } from "~/api/common";
import api from "~/api";

const defaultColumns = [
  {
    key: "inboundTerminalId",
    title: "ID",
    cellOptions: {
      width: 300
    },
    render: (inboundTerminalId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL}
        sourceId={inboundTerminalId}
      />
    )
  },
  {
    key: "merchantId",
    i18nKey: "merchantID",
    cellOptions: {
      width: 300
    },
    render: (merchantId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.MERCHANT}
        sourceId={merchantId}
      />
    )
  },
  {
    key: "status",
    i18nKey: "status",
    render: StatusBadge
  }
];

interface InboundTerminalsBindingProps {
  outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto;
}

const InboundTerminalsBinding = ({
  outboundVirtualTerminal
}: InboundTerminalsBindingProps) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [dataSource, setDataSource] =
    useState<OutboundVirtualTerminalsApi.InboundTerminalsBindingDto[]>();
  const { t } = useTranslation();

  const getInboundTerminals = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await api.outboundVirtualTerminals.getInboundTerminals(
        outboundVirtualTerminal.id,
        { search }
      );
      setDataSource(data);
    } finally {
      setLoading(false);
    }
  };


  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);

    return column;
  });

  useEffect(() => {
    getInboundTerminals();
  }, [search]);

  useEffect(() => {
    Boolean(outboundVirtualTerminal?.updatedAt) && getInboundTerminals();
  }, [outboundVirtualTerminal?.updatedAt]);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Box ml={4} width={375}>
        <Search placeholder={t("text.searchByID")} onSearch={setSearch} />
      </Box>
      <Box maxWidth={700}>
        <Table
          size="small"
          columns={columns}
          dataSource={dataSource}
          stickyHeader
        />
      </Box>
    </>
  );
};

export default InboundTerminalsBinding;
