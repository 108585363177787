import { useEffect, useState } from "react";
import { LinearProgress } from "~/components/UI";
import StatusBadge from '~/components/StatusBadge'
// import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table from "~/components/UI/Table/Table";
import EnumValue from '~/components/EnumValue';
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
// import { PERMISSION } from "~/api/permissions/enums";
// import usePermissions from "~/hook/usePermissions";
// import { updateArrayItem } from "~/utils/helpers";
import { CONNECTOR_TYPE_I18n } from "~/api/acquirers/enums";
import api from "~/api";

const defaultColumns = [
  {
    key: "endpointId",
    title: "ID",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "url",
    title: "URL",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    render: StatusBadge,
    cellOptions: {
      width: 50
    }
  },
  {
    key: "connectorType",
    i18nKey: "connectorType",
    render: (connectorType: string) => <EnumValue value={connectorType} enumI18n={CONNECTOR_TYPE_I18n} />
  }
];

interface AcquirerEndpointProps {
  outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto;
}

const AcquirerEndpoint = ({
  outboundVirtualTerminal
}: AcquirerEndpointProps) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] =
    useState<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDetailsDto[]>();
  const { t } = useTranslation();
  // const { hasAll } = usePermissions();

  const getAcquirerEndpoints = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await api.outboundVirtualTerminals.getTerminalDetails(
        outboundVirtualTerminal.id
      );
      setDataSource([data]);
    } finally {
      setLoading(false);
    }
  };

  // const onEnable = async (
  //   acquirerId: string,
  //   endpointId: string,
  //   acquirerEndpoint: AcquirersApi.UpdateEndpoint
  // ) => {
  //   const {
  //     data: { data }
  //   } = await api.acquirers.updateAcquirerEndpoint(
  //     acquirerId,
  //     endpointId,
  //     acquirerEndpoint
  //   );

  //   setDataSource((dataSource) =>
  //     dataSource ? updateArrayItem(dataSource, data, "endpointId") : []
  //   );
  // };

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    // switch (column.key) {
    //   case "enabled":
    // return {
    //   ...column,
    //   render: (
    //     enabled: boolean,
    //     acquirerEndpoint: AcquirersApi.EndpointDto
    //   ) => (
    // <ConfirmDialog
    //   placement="top"
    //   disabled={true}
    //   onConfirm={() => {
    //     const { acquirerId, endpointId } = acquirerEndpoint;

    //     onEnable &&
    //       acquirerId &&
    //       onEnable(acquirerId, endpointId, {
    //         enabled: !enabled,
    //       });
    //   }}
    // >
    // <Switch
    //   checked={enabled}
    //   size="small"
    //   disabled={true}
    // />
    // </ConfirmDialog>
    //   ),
    // };

    // default:
    // }
    return column;
  });

  useEffect(() => {
    getAcquirerEndpoints();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        stickyHeader
      />
    </>
  );
};

export default AcquirerEndpoint;
