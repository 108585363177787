import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateContractDialogProps
  extends Omit<DialogProps, "onClose"> {
  contract?: ContractsApi.ContractDto;
  onClose?: (contract?: ContractsApi.ContractDto) => void;
}

interface FromState
  extends Omit<
    ContractsApi.CreateContract | ContractsApi.UpdateContract,
    "merchants"
  > {
  merchants?: MerchantsApi.MerchantDto[];
}

const defaultValues: FromState = {
  contractNumber: "",
  contractName: "",
  enabled: false,
  description: ""
};

/**
 * @memberof Contracts
 * @component
 * @desc Dialog for creating or editing a Contract.
 * @property {ContractsApi.ContractDto} contract a Contract data
 * @property {Function} onClose - passes Contract data if the Contract was created/updated successfully. (result?: ContractsApi.ContractDto) => void;
 */

const CreateOrUpdateContractDialog = ({
  contract,
  open,
  onClose
}: CreateOrUpdateContractDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    contractNumber: yup.string().required(),
    contractName: yup.string().required(),
    description: yup.string()
  });

  const onSubmit = useCallback(
    async (formData: FromState) => {
      setLoading(true);
      try {
        if (isEditMode && contract) {
          await api.contracts.update(
            contract.contractId,
            formData as ContractsApi.UpdateContract
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.contracts.create(formData as ContractsApi.CreateContract);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as ContractsApi.ContractDto);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, contract, onClose, t]
  );

  useEffect(() => {
    if (contract) {
      const { contractName, contractNumber, enabled, description } = contract;
      setInitialValues({
        contractNumber,
        contractName,
        enabled,
        description
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [contract]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editContract") : t("title.addContract")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {contract && (
                  <FormControlLabel label="ID">
                    {contract.contractId}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.contractNumber")}>
                  <OutlinedInput name="contractNumber" formikControll  />
                </FormControlLabel>
                <FormControlLabel label={t("label.contractName")}>
                  <OutlinedInput name="contractName" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateContractDialog;
