import { useState, useCallback, useEffect } from "react";
import api from "~/api";
import {
  Box,
  Grid,
  Button,
  Typography,
  Label,
  FormControlLabel,
  OutlinedInput,
  FieldError,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import { phoneNumber } from "~/utils/format";

interface UserDetailsProps {
  user?: UsersApi.AzureUserDto;
}

const UserDetails = ({ user }: UserDetailsProps) => {
  const { t } = useTranslation();

  if (!user) return null;

  return (
    <>
      <Label text={t("label.userName")}>{user.name}</Label>
      <Label text="ID">{user.id}</Label>
      <Label text={t("label.phone")}>{user.phone}</Label>
      <Label text={t("label.businessPhones")}>
        {user.businessPhones &&
          user.businessPhones.map((p, index) => (
            <Typography key={index} variant="body1">
              {phoneNumber(p)}
            </Typography>
          ))}
      </Label>
      <Label text={t("label.position")}>{user.position}</Label>
    </>
  );
};

interface GetUserFromADProps {
  onChange?: (user: UsersApi.AzureUserDto) => void;
}

/**
 * @memberof Users
 * @component
 * @desc Form for finding a user in Azure AD.
 * @property {Function} onChange - call cb function when we found a user. (user: UsersApi.AzureUserDto) => void
 */

const GetUserFromAD = ({ onChange }: GetUserFromADProps) => {
  const [principleName, setPrincipleName] = useState<string>("");
  const [user, setUser] = useState<UsersApi.AzureUserDto>();
  const [error, setError] = useState<string>();
  const [loadingAzureUser, setLoadingAzureUser] = useState(false);
  const { t } = useTranslation();

  const getUserFromAD = useCallback(async () => {
    if (!principleName || !principleName.length) return;
    try {
      setLoadingAzureUser(true);
      const {
        data: { data },
      } = await api.users.getAzureUser(principleName);
      setUser(data);
      onChange && onChange(data);
    } catch (e: Api.ErrorResponse & any) {
      if (e.errors) setError(e.errors[0].message);
    } finally {
      setLoadingAzureUser(false);
    }
  }, [principleName]);

  useEffect(() => {
    if (error) setError("");
  }, [principleName]);

  return (
    <>
      <FormControlLabel label={t("label.userPrincipalName")}>
        <Grid container alignItems="flex-start">
          <Box display="inline-block">
            <OutlinedInput
              value={principleName}
              onChange={(e) => setPrincipleName(e.target.value)}
            />
            {error && <FieldError>{error}</FieldError>}
          </Box>
          <Box display="inline" ml={4}>
            <Button
              loading={loadingAzureUser}
              variant="contained"
              color="secondary"
              onClick={() => getUserFromAD()}
            >
              {t("button.getFromAD")}
            </Button>
          </Box>
        </Grid>
      </FormControlLabel>
      <UserDetails user={user} />
    </>
  );
};

export default GetUserFromAD;
