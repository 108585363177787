import { createContext } from 'react';

export interface  InboundVirtualsTerminalsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (inboundVirtualsTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => void;
  onEdit?: (inboundVirtualsTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => void;
  onUpdate?: (id: string, formData:  InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal) => Promise<void>;
  onDelete?: (inboundVirtualsTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => Promise<void>;
}

/**
 * @memberof InboundVirtualsTerminals
 * @component
 * @desc React context for providing methods of InboundVirtualsTerminal to child components.
 * @property {Function} onEdit - callback for open the Edit InboundVirtualsTerminal dialog.  (inboundVirtualsTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => void;
 * @property {Function} onUpdate - callback for update a InboundVirtualsTerminal. (id: string, formData:  InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a InboundVirtualsTerminal. (inboundVirtualsTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => Promise<void>;
 */

const InboundVirtualsTerminalsContext = createContext<InboundVirtualsTerminalsContextProps>({});


export default InboundVirtualsTerminalsContext;