import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class UserApi {
  rootPath: string = "/users";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.users')
  }

  addUser = (data: UsersApi.AddUser): Promise<Api.GetOne<UsersApi.UserDto>>  =>
    this.instance.post(this.rootPath, data);

  getUsers = (limit: number = 10, page: number = 1, query?: UsersApi.GetUsersQuery): Promise<Api.GetAll<UsersApi.UserDto>> => 
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  getUser = (id: string): Promise<Api.GetOne<UsersApi.UserDetailsDto>>  =>
    this.instance.get(`${this.rootPath}/${id}`);

  getUserRoles = (id: string): Promise<Api.GetAll<UsersApi.UserRolesDto>> =>
    this.instance.get(`${this.rootPath}/${id}/roles`);

  updateUserRoles = (
    id: string,
    data: UsersApi.UpdateUserRoles,
  ): Promise<Api.GetOne<UsersApi.UserDto>> =>
    this.instance.put(`${this.rootPath}/${id}/roles`, data);

  updateUserStatus = (
    id: string,
    data: UsersApi.UpdateUserStatus,
  ): Promise<Api.GetOne<UsersApi.UserDto>> =>
    this.instance.put(`${this.rootPath}/${id}/status`, data);

  deleteUsers = (ids: string[]): Promise<Api.GetOne<{}>> =>
    this.instance.delete(this.rootPath,  { params: { ids } });

  deleteUser = (id: string): Promise<{}>  =>
    this.instance.delete(`${this.rootPath}/${id}`);

  getAzureUser = (principleName: string): Promise<Api.GetOne<UsersApi.AzureUserDto>>  =>
    this.instance.get(`${this.rootPath}/azure/principle`, { params: { principleName } });
}