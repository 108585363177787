import React from "react";
import { makeStyles, createStyles } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import EventDetails from "./EventDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import EnumValue from "~/components/EnumValue";
import { PRIORITY_I18n } from "~/api/eventsLogJournal/enums";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flexGrow: 1
    }
  })
);

interface EventsTableProps
  extends Omit<
    TableProps<EventsLogJournalApi.EventDto>,
    "columns" | "rowSelection"
  > {
  onNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  // {
  //   key: "id",
  //   title: "ID",
  //   // sort: true,
  //   cellOptions: {
  //     width: 300
  //   }
  // },
  {
    key: "timestamp",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "eventType",
    i18nKey: "type"
  },
  {
    key: "priority",
    i18nKey: "priority",
    render: (priority: string) => (
      <EnumValue value={priority} enumI18n={PRIORITY_I18n} />
    )
  },
  {
    key: "path",
    i18nKey: "path"
  },
  {
    key: "merchantId",
    i18nKey: "merchant",
    // sort: true,
    render: (merchantId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.MERCHANT} sourceId={merchantId} />
    )
  },
  {
    key: "inboundVtId",
    i18nKey: "inboundVT",
    // sort: true,
    render: (inboundId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL}
        sourceId={inboundId}
      />
    )
  },
  {
    key: "outboundVtId",
    i18nKey: "outboundVT",
    // sort: true,
    render: (outboundId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.OUTBOUND_VIRTUAL_TERMINAL}
        sourceId={outboundId}
      />
    )
  }
];

/**
 * @memberof EventsLog
 * @component
 * @desc Render table of Events Log.
 * @property {EventsLogJournalApi.EventDto[]} dataSource - Event data
 */

const EventsTable = ({
  onSort,
  onNext,
  hasMore,
  dataLength,
  ...props
}: EventsTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return (
    <div className={classes.wrapper}>
      <Table
        {...props}
        columns={columns}
        expandable={{
          highlightRow: true,
          expandedRowRender: (data: EventsLogJournalApi.EventDto) => (
            <EventDetails data={data} />
          )
        }}
        onRequestSort={onSort}
        rowKey="id"
        stickyHeader
      />
    </div>
  );
};

export default React.memo(EventsTable);
