import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateBinRangeDialogProps
  extends Omit<DialogProps, "onClose"> {
  binRange?: BinRangesApi.BinRangeDto;
  onClose?: (binRange?: BinRangesApi.BinRangeDto) => void;
}

type FromState = BinRangesApi.CreateBinRange | BinRangesApi.UpdateBinRange;

const defaultValues: FromState = {
  deviceBin: "",
  cardProgramName: "",
};

/**
 * @memberof BinRanges
 * @component
 * @desc Dialog for creating or editing a BinRange.
 * @property {BinRangesApi.BinRangeDto} contract a BinRange data
 * @property {Function} onClose - passes BinRange data if the BinRange was created/updated successfully. (result?: BinRangesApi.BinRangeDto) => void;
 */

const CreateOrUpdateBinRangeDialog = ({
  binRange,
  open,
  onClose,
}: CreateOrUpdateBinRangeDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    deviceBin: yup.number().required(),
    cardProgramName: yup.string().required(),
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      setLoading(true);
      const formData = {
        ...values,
      };
      try {
        if (isEditMode && binRange) {
          await api.binRanges.update(
            binRange.id,
            formData as BinRangesApi.UpdateBinRange
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.binRanges.create(formData as BinRangesApi.CreateBinRange);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as BinRangesApi.BinRangeDto);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, binRange, onClose, t]
  );

  useEffect(() => {
    if (binRange) {
      const { deviceBin, cardProgramName } = binRange;
      setInitialValues({
        deviceBin,
        cardProgramName
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [binRange]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editBinRange") : t("title.addBinRange")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
          validateOnChange: false,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel 
                 label={t("label.deviceBin")}
                >
                  <OutlinedInput name="deviceBin" formikControll />
                </FormControlLabel>
                <FormControlLabel  label={t("label.cardProgramName")}>
                  <OutlinedInput name="cardProgramName"  formikControll/>
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateBinRangeDialog;
