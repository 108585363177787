import {
  Box,
  MUIFormControlLabel,
  Checkbox as BaseCheckbox,
} from "~/components/UI";
import { Field, FieldProps } from "formik";

interface CheckboxProps {
  label?: string;
  name: string;
  value?: string;
}

const Checkbox = ({ label, name, value }: CheckboxProps) => {
  return (
    <Box py={2}>
      <MUIFormControlLabel
        label={label}
        control={
          <Field name={name}>
            {({ field, form }: FieldProps<string[]>) => (
              <BaseCheckbox
                {...field}
                id={name}
                checked={
                  value && field.value ? field.value.includes(value) : false
                }
                onChange={() => {
                  if (!value) return;
                  const set = new Set(field.value);
                  if (set.has(value)) {
                    set.delete(value);
                  } else {
                    set.add(value);
                  }
                  form.setFieldValue(field.name, Array.from(set));
                  form.setFieldTouched(field.name, true);
                }}
              />
            )}
          </Field>
        }
      />
    </Box>
  );
};

export default Checkbox;
