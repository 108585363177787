import { Box, Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { USER_ACTIVITY_TYPE_I18n } from "~/api/userActivityJournal/enums";

interface UserActivityTypeProps {
  type: UserActivityJournalApi.eUserActivityType;
}

/**
 * @memberof UserActivity
 * @component
 * @desc Render type of UserActivity.
 * @property {UserActivityJournalApi.eUserActivityType} type - UserActivity status;
 */

const UserActivityType = ({ type }: UserActivityTypeProps) => {
  const { t } = useTranslation(["enums"]);

  return (
    <Box color="warning.main">
      <Typography variant="body1" color="inherit">
        {t(USER_ACTIVITY_TYPE_I18n[type])}
      </Typography>
    </Box>
  );
};

export default UserActivityType;
