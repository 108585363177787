import Table, { TableProps } from "~/components/UI/Table/Table";
import IncidentDetails from "./IncidentDetails";
import IncidentsStatus from "./IncidentsStatus";
import IncidentOptions from "./IncidentOptions";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import { SOURCE_TYPE_I18n } from "~/api/common";
import EnumValue from "~/components/EnumValue";

const defaultColumns = [
  // {
  //   key: "incidentId",
  //   title: "ID",
  //   cellOptions: {
  //     width: 300,
  //   },
  // },
  {
    key: "createdAt",
    i18nKey: "created",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "sourceType",
    i18nKey: "sourceType",
    render: (sourceType: string) => (
      <EnumValue value={sourceType} enumI18n={SOURCE_TYPE_I18n} />
    )
  },
  {
    key: "sourceId",
    i18nKey: "sourceId"
  },
  {
    key: "closedAt",
    i18nKey: "closed",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "status",
    i18nKey: "status",
    render: (status: IncidentsApi.eIncidentStatus) => (
      <IncidentsStatus status={status} />
    ),
    cellOptions: {
      width: 85
    }
  },
  {
    key: "subject",
    i18nKey: "subject"
  },
  {
    key: "createdBy",
    i18nKey: "createdBy"
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

interface IncidentsTableProps
  extends Omit<
    TableProps<IncidentsApi.IncidentDto>,
    "columns" | "rowSelection"
  > {}

/**
 * @memberof Incidents
 * @component
 * @desc Render table of Incidents.
 * @property {IncidentsApi.IncidentDto[]} dataSource - Incidents data
 */

const IncidentsTable = ({ ...props }: IncidentsTableProps) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);

    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, incident: IncidentsApi.IncidentDto) => (
            <IncidentOptions {...{ incident }} />
          )
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      columns={columns}
      rowKey="incidentId"
      expandable={{
        highlightRow: true,
        expandedRowRender: (incident: IncidentsApi.IncidentDto) => (
          <IncidentDetails incident={incident} />
        )
      }}
      stickyHeader
    />
  );
};

export default IncidentsTable;
