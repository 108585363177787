import { useContext, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import AcquirersContext from "./acquirersContext";
import AcquirerOptions from "./AcquirerOptions";
import AcquirerDetails from "./AcquirerDetails";

const defaultColumns = [
  {
    key: "acquirerId",
    title: "ID",
    sort: true,
    copy: true,
    keyForSort: "id",
    cellOptions: {
      width: 120,
    },
  },
  {
    key: "acquirerName",
    i18nKey: "acquirerName",
    sort: true,
  },
  {
    key: "mfo",
    title: "MFO",
    cellOptions: {
      width: 100,
    },
  },
  {
    key: "enabled",
    sort: true,
    i18nKey: "enabled",
  },
  {
    key: "endpointCount",
    sort: true,
    i18nKey: "endpoints",
  },
  {
    key: "outboundVirtualTerminalCount",
    sort: true,
    i18nKey: "outboundVirtualTerminals",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

const AcquirersTable = (
  props: Omit<TableProps<AcquirersApi.AcquirerDto>, "columns">,
  ref: any
) => {
  const { onUpdate } = useContext(AcquirersContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, acquirer: AcquirersApi.AcquirerDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_ACQUIRER)}
              onConfirm={() =>
                onUpdate && onUpdate(acquirer.acquirerId, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_ACQUIRER)}
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, acquirer: AcquirersApi.AcquirerDto) => (
            <AcquirerOptions acquirer={acquirer} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      ref={ref}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (acquirer) => (
          <AcquirerDetails acquirer={acquirer} />
        ),
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<TableProps<AcquirersApi.AcquirerDto>, "columns">
>(AcquirersTable);
