import React from "react";
import MUIAutocomplete, {
  AutocompleteProps as MUIAutocompleteProps,
} from "@material-ui/lab/Autocomplete";
import Icon from "./Icon";
import { withStyles, createStyles } from "@material-ui/core/styles";

export interface AutocompleteProps
  extends MUIAutocompleteProps<any, boolean, boolean, boolean> {}

const Autocomplete = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    inputRoot: {
      "&&": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    endAdornment: {
      color: theme.palette.text.primary,
      verticalAlign: "middle",
      lineHeight: "10px",
      top: "auto",
    },
    popupIndicator: {
      color: theme.palette.text.primary,
      fontSize: 10,
    },
    clearIndicator: {
      color: theme.palette.text.primary,
      fontSize: 10,
      padding: 2,
      marginRight: theme.spacing(2),
    },
    option: {
      color: theme.palette.text.primary,
      fontSize: 14,
      padding: "8px 15px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
      "&[data-focus=true]": {
        backgroundColor: theme.palette.primary.light,
      },
      "&[aria-selected=true]": {
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
      },
    },
  })
)((props: AutocompleteProps) => (
  <MUIAutocomplete
    {...props}
    popupIcon={<Icon name="ArrowDown" />}
    closeIcon={<Icon name="Close" />}
    renderInput={(params) => {
      return (
        <div ref={params.InputProps.ref}>
          {props.renderInput && props.renderInput(params)}
        </div>
      );
    }}
  />
));

export default Autocomplete;
