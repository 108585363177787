import React from "react";
import MUIMenu, { MenuProps } from "@material-ui/core/Menu";
import { withStyles, createStyles } from "@material-ui/core/styles";

const Menu = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    list: {
      padding: 0,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      minWidth: 130,
    },
  })
)(
  React.forwardRef((props: MenuProps, ref) => <MUIMenu ref={ref} {...props} />)
);

export default Menu;
