import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class ContractsApi {
  rootPath: string = "/contracts";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.contracts')
  }

  create = (data: ContractsApi.CreateContract) =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: ContractsApi.GetContractsQuery
  ): Promise<Api.GetAll<ContractsApi.ContractDto>> => {
    if (query && !query.sort) {
      query =  {...query, sort: "id"} 
    }
   return this.instance.get(this.rootPath, {
    params: {
      limit,
      skip: (page - 1) * limit,
      ...deleteEmptyFields(query || {}),
    },
  })};
  getContract = (contractId: string) =>
    this.instance.get(`${this.rootPath}/${contractId}`);
  getMerchants = (
    contractId: string,
    limit: number,
    page: number,
    query?: ContractsApi.GetMerchantsQuery
  ): Promise<Api.GetAll<MerchantsApi.MerchantDto>> =>
    this.instance.get(`${this.rootPath}/${contractId}/merchants`, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    });
  update = (contractId: string, data: ContractsApi.UpdateContract) =>
    this.instance.put(`${this.rootPath}/${contractId}`, data);
  deleteContracts = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (contractId: string) =>
    this.instance.delete(`${this.rootPath}/${contractId}`);
}
