import MUIPagination, { PaginationProps } from "@material-ui/lab/Pagination";
import MUIPaginationItem from "@material-ui/lab/PaginationItem";
import Box from "@material-ui/core/Box";
import { withStyles, createStyles, styled } from "@material-ui/core/styles";

interface CustomPaginationProps extends Omit<PaginationProps, "renderItem"> {
  prefContent?: React.ReactNode;
}

const Container = styled(Box)(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    display: "flex",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    justifyContent: "center",
    padding: 20,
    top: 0,
    bottom: 0,
  })
);

const PrefConteiner = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  alignContent: "center",
}));

const PaginationItem = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    page: {
      fontFamily: '"Plumb", sans-serif',
      color: theme.palette.text.secondary,
      minWidth: 30,
      height: 30,
    },
    selected: {
      color: theme.palette.primary.main,
      fontFamily: '"PlumbBoldC", sans-serif',
      fontWeight: "bold",
    },
  })
)(MUIPaginationItem);

const Pagination = withStyles(() =>
  createStyles({
    root: {
      // display: "flex",
    },
  })
)(({ prefContent, ...props }: CustomPaginationProps) => (
  <Container>
    <PrefConteiner>{prefContent}</PrefConteiner>
    <MUIPagination
      {...props}
      renderItem={(itemProps) => <PaginationItem {...itemProps} />}
    />
  </Container>
));

export default Pagination;
