import React from "react";
import Card, { CardProps } from "@material-ui/core/Card";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export type Props = {
  closeIcon?: boolean;
  onClose?: () => void;
  extra?: () => React.ReactNode;
} & CardProps;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      padding: 20,
    },
  })
);

const CustomCard = (props: Props) => {
  const classes = useStyles();
  return <Card classes={{ ...classes }} {...props} elevation={0} />;
};

export default CustomCard;
