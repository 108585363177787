import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import FinancialTransactions from "./Components/FinancialTransactions";
import { useTranslation } from "react-i18next";

/**
 * @namespace FinancialTransactions
 */

const FinancialTransactionsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.journals") },
      { label: t("title.financialTransactions") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <FinancialTransactions />
    </>
  );
};

export default FinancialTransactionsPage;
