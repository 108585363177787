import { createContext } from 'react';
import { ListContext } from '~/types/list';

export interface IDPrefixesContextProps extends ListContext<IdPrefixApi.IdPrefixDto, IdPrefixApi.CreateIdPrefix, {}> {
  switchActivity?: (item: IdPrefixApi.IdPrefixDto) => Promise<void>;
}

const IDPrefixesContext = createContext<IDPrefixesContextProps>({});


export default IDPrefixesContext;
