import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import _ from "lodash";
import api from "~/api";

interface FromikSelectAcquirerProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (
    options: AcquirersApi.AcquirerDto[],
    state: any
  ) => AcquirersApi.AcquirerDto[];
}

interface SelectAcquirerProps {
  inputValue?: string;
  name?: string;
  onChange?: (acquirer?: AcquirersApi.AcquirerDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (
    options: AcquirersApi.AcquirerDto[],
    state: any
  ) => AcquirersApi.AcquirerDto[];
}

const requestSearchAcquirer = (search: string) =>
  api.acquirers.getAll(20, 1, { search });

export const SelectAcquirer = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue
  }: SelectAcquirerProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<
      AcquirersApi.AcquirerDto[]
    >([]);
    const [acquirer, setAcquirer] =
      useState<Partial<AcquirersApi.AcquirerDto> | null>(null);

    const searchAcquirers = useMemo(
      () =>
        _.debounce((acquirerId: string) => {
          setLoading(true);
          requestSearchAcquirer(acquirerId)
            .then(({ data }) => {
              setSearchResults(data.data);
            })
            .finally(() => {
              setLoading(false);
            });
        }, 500),
      []
    );

    const onChangeInputValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
      searchAcquirers(e.target.value || "");
    };

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.name);
          setAcquirer(newValue);
        } else {
          setValue("");
          setAcquirer(null);
        }
        onChange && onChange(newValue);
        onChangeValue && onChangeValue(newValue);
      },
      [onChange, onChangeValue]
    );

    const getOptionSelected = useCallback(
      (o: AcquirersApi.AcquirerDto, value: AcquirersApi.AcquirerDto) =>
        o.acquirerId === value.acquirerId,
      []
    );

    const getOptionLabel = useCallback(
      (o: AcquirersApi.AcquirerDto) => o.acquirerName || "",
      []
    );

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setAcquirer(null);
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchAcquirers("");
    }, [searchAcquirers]);

    return (
      <Autocomplete
        value={acquirer}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText="No Acquirers"
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        loading={loading}
        disableClearable
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            fullWidth
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FromikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FromikSelectAcquirerProps & SelectAcquirerProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChange = useCallback(
    (acquirer?: AcquirersApi.AcquirerDto) => {
      field.onChange({
        target: {
          name,
          value: acquirer ? acquirer.acquirerId : ""
        }
      });
    },
    [field, name]
  );

  return (
    <SelectAcquirer
      {...field}
      {...other}
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default React.memo(FromikContainer);
