import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "~/components/UI";

export enum TimeInterval {
  MINUTE,
  LAST_MINUTE,
  LAST_HOUR,
  LAST_24_HOURS,
}

export interface TimeMeta {
  label: string;
  value: TimeInterval;
  refreshTime: number;
}

interface TimeIntervalSelectorProps {
  defaultValue: TimeInterval;
  timeIntervalsConfig: TimeInterval[];
  onChange?: (value: TimeInterval, meta: TimeMeta) => void;
}

export const eTimeInterval = TimeInterval;

export const RefreshTimeForInterval = {
  [eTimeInterval.MINUTE]: 5 * 1000,
  [eTimeInterval.LAST_MINUTE]: 5 * 1000,
  [eTimeInterval.LAST_HOUR]: 5 * 60 * 1000,
  [eTimeInterval.LAST_24_HOURS]: 60 * 60 * 1000,
};

const TimeIntervalSelector = ({
  timeIntervalsConfig = [],
  defaultValue = eTimeInterval.MINUTE,
  onChange,
}: TimeIntervalSelectorProps) => {
  const { t } = useTranslation();
  const [timeIntervals] = useState({
    [eTimeInterval.MINUTE]: {
      label: t("label.minute"),
      value: eTimeInterval.MINUTE,
    },
    [eTimeInterval.LAST_MINUTE]: {
      label: t("label.lastMinute"),
      value: eTimeInterval.LAST_MINUTE,
    },
    [eTimeInterval.LAST_HOUR]: {
      label: t("label.lastHour"),
      value: eTimeInterval.LAST_HOUR,
    },
    [eTimeInterval.LAST_24_HOURS]: {
      label: t("label.last24Hours"),
      value: eTimeInterval.LAST_24_HOURS,
    },
  });

  return (
    <Tabs
      defaultValue={defaultValue}
      tabs={timeIntervalsConfig.map(i => timeIntervals[i])}
      onChange={(value: TimeInterval) =>
        onChange &&
        onChange(value, {
          ...timeIntervals[value],
          refreshTime: RefreshTimeForInterval[value],
        })
      }
    />
  );
};

export default TimeIntervalSelector;
