import { Grid } from "~/components/UI";
import BaseCard from "../BaseCard";
import PaymentDuration from "../Charts/PaymentDuration";
import { eTimeInterval } from "../TimeIntervalSelector";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";

interface ClientPaymentDurationProps {
  title?: string;
  onUpdate: (
    queryParams: MetricsAPI.GetMetricQuery
  ) => Promise<MetricsAPI.ClientPaymentDurationResponceDto | undefined>;
}

const ClientPaymentDuration = ({
  title,
  onUpdate
}: ClientPaymentDurationProps) => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  if (!hasAll(PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS)) return null;

  return (
    <Grid item md={12} lg={6}>
      <BaseCard
        title={title || "Client Payment Duration"}
        timeIntervals={[eTimeInterval.LAST_HOUR, eTimeInterval.LAST_24_HOURS]}
        onUpdate={onUpdate}
      >
        {(data) =>
          data ? (
            <PaymentDuration
              period={data.period}
              list={data.list}
              yAxis={t("title.clientPaymentDuration")}
            />
          ) : null
        }
      </BaseCard>
    </Grid>
  );
};

export default ClientPaymentDuration;
