import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class InboundVirtualTerminalsApi {
  rootPath: string = "/inbound-virtual-terminals";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.inboundVirtualTerminals')
  }

  create = (data: InboundVirtualTerminalsApi.CreateInboundVirtualTerminal): Promise<Api.GetOne<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>> =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: InboundVirtualTerminalsApi.GetInboundVirtualTerminalsQuery
  ): Promise<Api.GetAll<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>> => {
    if (query && !query.sort) {
      query = { ...query, sort: "id" }
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    })
  };
  getTerminal = (terminalId: string) =>
    this.instance.get(`${this.rootPath}/${terminalId}`);
  getTerminalDetails = (contractId: string): Promise<Api.GetOne<InboundVirtualTerminalsApi.InboundVirtualTerminalDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${contractId}/details`);
  getTerminalBindings = (terminalId: string, limit: number = 10,
    page: number = 1,
    query?: InboundVirtualTerminalsApi.GetTerminalBindingsQuery): Promise<Api.GetAll<InboundVirtualTerminalsApi.InboundTerminalBindingDto>> =>
    this.instance.get(`${this.rootPath}/${terminalId}/outbound-terminal-bindings`, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      }
    });
  update = (terminalId: string, data: InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal): Promise<Api.GetOne<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>> =>
    this.instance.put(`${this.rootPath}/${terminalId}`, data);
  deleteTerminals = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (terminalId: string) =>
    this.instance.delete(`${this.rootPath}/${terminalId}`);
  deleteOutboundTerminal = (terminalId: string, outboundTerminalId: string) =>
    this.instance.delete(`${this.rootPath}/${terminalId}/outbound-terminal-bindings/${outboundTerminalId}`)
}
