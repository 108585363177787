import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { USER_STATUS } from "~/api/users/enums";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface UserOptionsProps {
  user: UsersApi.UserDto;
  onEdit?: (data: UsersApi.UserDto) => void;
  onUpdateItem?: (data: UsersApi.UserDto) => void;
  onUpdate?: (id: string, formData: UsersApi.UpdateUserStatus) => void;
  onDelete?: (data: UsersApi.UserDto) => Promise<void> | void;
}

/**
 * @memberof Users
 * @component
 * @desc User options.
 * @property {RolesApi.RoleDto} user - User data;
 * @property {Function} onEdit - callback for open the Edit User dialog. (data: UsersApi.UserDto) => void
 * @property {Function} onUpdate - callback for update a user. (id: string, formData: UsersApi.UpdateUserStatus) => void
 * @property {Function} onDelete - callback for the delete a user. (data: UsersApi.UserDto) => Promise<undefined> | void
 */

const UserOptions = ({
  user,
  onEdit,
  onUpdateItem,
  onUpdate,
  onDelete,
}: UserOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.USER);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (user.incidents) {
        user.incidents.count++;
        user.incidents.incidentIds.push(incident.incidentId);
      } else {
        user.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(user);
    },
    [user, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_USER,
      PERMISSION.DELETE_USER,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.rolesManagement"),
      hidden: !hasAll(PERMISSION.EDIT_USER),
      onClick: () => onEdit && onEdit(user),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_USER),
      onClick: () => onDelete && onUpdateHandler(onDelete(user)),
    },
    {
      label:
        user.status === USER_STATUS.ACTIVE
          ? t("option.block")
          : t("option.active"),
      hidden: !hasAll(PERMISSION.EDIT_USER),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(
            onUpdate(user.userId, {
              status:
                user.status === USER_STATUS.ACTIVE
                  ? USER_STATUS.BLOCKED
                  : USER_STATUS.ACTIVE,
            })
          ),
      },
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(user.userId, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default UserOptions;
