import { useState, useEffect } from "react";
import {
  Box,
  DialogContent,
  Section,
  Tag,
  TagsContainer,
  LinearProgress,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import EntitiesSection from "./EntitiesSection";
import ProcessesSection from "./ProcessesSection";
import { useTranslation } from "react-i18next";
import { getContrastYIQ } from "~/utils/helpers";
import _ from "lodash";
import api from "~/api";

interface PermissionsDialogProps extends Omit<DialogProps, "role"> {
  roles?: UsersApi.UserRoleDto[];
  groups?: UsersApi.UserGroupDto[];
  role?: UsersApi.UserRoleDto;
  allPermissions?: PermissionsApi.eBasePermissions[];
}

const Roles = ({
  roles,
  groups,
}: {
  roles?: UsersApi.UserRoleDto[];
  groups?: UsersApi.UserGroupDto[];
}) => {
  const groupsObj = groups ? _.groupBy(groups, "groupId") : {};
  const { t } = useTranslation();
  if (!roles) return null;
  return (
    <Section title={t("label.myRoles")}>
      <TagsContainer>
        {roles.map((r, i) => {
          const color = groupsObj[r.groupId] && groupsObj[r.groupId][0].color;
          return (
            <Tag
              key={`${i}-role`}
              bgcolor={color}
              color={color ? getContrastYIQ(color) : "inherit"}
            >
              {r.name}
            </Tag>
          );
        })}
      </TagsContainer>
    </Section>
  );
};

const PermissionsDialog = ({
  role,
  roles,
  groups,
  allPermissions,
  ...others
}: PermissionsDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] =
    useState<PermissionsApi.eBasePermissions[]>();
  const { t } = useTranslation();
  const getRoleDetails = async (id: string) => {
    try {
      setLoading(true);
      const {
        data: { data: role },
      } = await api.roles.getRole(id);
      const permissions = _.flattenDepth(_.values(role.permissions), 1).map(
        (p) => p.scope
      );
      setPermissions(permissions);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!role) return;
    getRoleDetails(role.roleId);
  }, [role]);

  return (
    <Dialog
      {...others}
      title={
        role
          ? `${t("title.permissionsForRole")}: ${role.name}`
          : t("title.allPermissions")
      }
      closable
    >
      <DialogContent>
        <LinearProgress hidden={!loading} />
        {!role && <Roles roles={roles} groups={groups} />}
        <Box hidden={loading}>
          <EntitiesSection
            permissions={role && permissions ? permissions : allPermissions}
          />
          <ProcessesSection
            permissions={role && permissions ? permissions : allPermissions}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PermissionsDialog;
