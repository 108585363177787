import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import AcquirersContext from "./acquirersContext";
import CreateOrUpdateAcquirerDialog from "./CreateOrUpdateAcquirerDialog";
import { toast } from "react-toastify";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useQueryParams, StringParam } from "use-query-params";
import Filter from "./Filter";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const Acquirers = () => {
  const [editAcquirer, setEditAcquirer] = useState<AcquirersApi.AcquirerDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [acquirers, setAcquirers] = useState<AcquirersApi.AcquirerDto[]>();
  const [selectedAcquirers, setSelectedAcquirers] = useState<string[]>([]);
  const [query, setQuery] = useState<AcquirersApi.GetAcquirersQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();
  const [queryParams] = useQueryParams({
    search: StringParam,
    acquirerId: StringParam
  });
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_ACQUIRER)
      ? {
          hideSelectAll: false,
          onChange: setSelectedAcquirers
        }
      : undefined
  );

  const getAcquirers = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const { data } = await api.acquirers.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setAcquirers(data.data);
        pagination.current = data.meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateAcquirer = () => {
    setEditAcquirer(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (acquirer?: AcquirersApi.AcquirerDto) => {
    setShowEditDialog(false);
    setEditAcquirer(undefined);
    acquirer && getAcquirers();
  };

  const onUpdateItem = useCallback((acquirer: AcquirersApi.AcquirerDto) => {
    setAcquirers((acquirers) =>
      acquirers ? updateArrayItem(acquirers, acquirer, "acquirerId") : []
    );
  }, []);

  const onEdit = (acquirer: AcquirersApi.AcquirerDto) => {
    setEditAcquirer(acquirer);
    setShowEditDialog(true);
  };

  const onUpdate = useCallback(
    async (id: string, formData: AcquirersApi.UpdateAcquirer) => {
      try {
        const {
          data: { data: acquirer }
        } = await api.acquirers.update(id, formData);
        onUpdateItem(acquirer);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (acquirer: AcquirersApi.AcquirerDto) => {
      await deleteConfirm(acquirer?.acquirerName);
      try {
        await api.acquirers.delete(acquirer.acquirerId);
        getAcquirers();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getAcquirers, deleteConfirm]
  );

  const onDeleteAcquirers = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedAcquirers?.length }));
    try {
      await api.acquirers.deleteAcquirers(selectedAcquirers);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getAcquirers();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedAcquirers]);

  const onChangePagination = (page: number) => {
    getAcquirers(page);
  };

  const onChangeFilters = (filters: AcquirersApi.GetAcquirersQuery) => {
    pagination.current = undefined;
    setAcquirers([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem]
  );

  useEffect(() => {
    setQuery(queryParams as AcquirersApi.GetAcquirersQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getAcquirers();
  }, [query, getAcquirers, limit]);

  return (
    <AcquirersContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.acquirers")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_ACQUIRER}>
                <Button
                  hidden={!selectedAcquirers.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteAcquirers}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_ACQUIRER}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateAcquirer}
                >
                  {t("button.addAcquirer")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={acquirers}
          onRequestSort={onSort}
          rowKey="acquirerId"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateAcquirerDialog
        open={showEditDialog}
        acquirer={editAcquirer}
        onClose={onCloseEditDialog}
      />
    </AcquirersContext.Provider>
  );
};

export default Acquirers;
