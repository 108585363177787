import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import Icon from "../Icon";
import { withStyles, createStyles } from "@material-ui/core/styles";

const AccordionSummary = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: 0,
      boxSizing: "border-box",
      overflow: "visible",
    },
    content: {
      margin: "0 !important",
    },
    expandIcon: {
      color: theme.palette.text.primary,
      fontSize: 10,
    },
  })
)(({ expandIcon, ...props }: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={expandIcon || <Icon name="ArrowDown" />}
  />
));

export default AccordionSummary;
