import { enumToI18nKey } from '~/utils/helpers';

export const CONNECTOR_TYPE = {
  SOAP_UPC: 'SOAP UPC',
  REST_AVAL: 'REST AVAL',
  MASTERPASS: 'MASTERPASS TASLINK',
  OKKO_ONLINE: 'OKKO ONLINE',
  FISHKA_LOYALTY: 'FISHKA LOYALTY',
} as { [key: string]: AcquirersApi.eConnectorType };

export const CONNECTOR_TYPE_I18n = enumToI18nKey(CONNECTOR_TYPE, "CONNECTOR_TYPE");
