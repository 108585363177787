import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class SystemLogJournalApi {
  rootPath: string = "/journal-system-logs";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.systemLog')
  }

  getSystemLogs = (limit: number = 10, query?: SystemLogJournalApi.GetSystemLogsQuery): Promise<Api.GetAll<SystemLogJournalApi.SystemLogDto>> =>
    this.instance.get(this.rootPath, { params: { limit, ...deleteEmptyFields(query || {}) } });
  getSystemLog = (id: string): Promise<Api.GetOne<SystemLogJournalApi.SystemLogDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${id}`);
}
