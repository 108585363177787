import { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Divider,
  Section,
  Pagination,
  Typography,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { getSortStr } from "~/utils/helpers";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

/**
 * @memberof UsersActivities
 * @component
 * @desc UsersActivities Controller.
 */

const UsersActivities = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] =
    useState<UserActivityJournalApi.GetUserActivityQuery>({
      sort: "-createdAt"
    });
  const [limit, setLimit] = useState<number>();
  const [data, setData] = useState<UserActivityJournalApi.UserActivityDto[]>();
  const pagination = useRef<Api.PaginationSchema>();
  const { t } = useTranslation();

  const getUsersActivities = useCallback(
    async (page?: number) => {
      setLoading(true);
      const currentPage = pagination.current ? pagination.current.page : 1;
      try {
        const {
          data: { data, meta }
        } = await api.userActivityJournal.getUsersActivities(
          limit,
          page || currentPage,
          { ...query, sort: query.sort || "-createdAt" }
        );
        data && setData(data);
        pagination.current = meta.pagination;
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [query, pagination, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = useCallback(
    (page: number) => {
      getUsersActivities(page);
    },
    [getUsersActivities]
  );

  const onChangeFilters = useCallback((filters) => {
    pagination.current = undefined;
    setQuery(filters);
  }, []);

  useEffect(() => {
    getUsersActivities();
  }, [query, getUsersActivities, limit]);

  return (
    <>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.userActivityLog")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination.current && pagination.current.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={<Filter onChangeFilter={onChangeFilters} />}
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2}>
        <Table dataSource={data} onSort={onSort} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default UsersActivities;
