import { useCallback, useEffect } from "react";
import { Select } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "~/api/dashboardSettings/enums";
import { useSelector, useDispatch } from "react-redux";
import { updateDashboardSettings } from "~/store/settings/actions";
import { getI18nLanguage } from '~/utils/helpers';

const options = [
  { label: "Українська", value: LANGUAGE.UA },
  { label: "English", value: LANGUAGE.ENG },
  { label: "Русский", value: LANGUAGE.RU },
];

const SelectLanguage = () => {
  const { i18n } = useTranslation();
  const settings = useSelector(
    (state: ReduxStore.State) => state.settings.dashboardSettings
  );
  const dispatch = useDispatch();

  const onChange = useCallback(
    (event: React.ChangeEvent<{ value: any }>) => {
      i18n.changeLanguage(getI18nLanguage(event.target.value));
      dispatch(
        updateDashboardSettings({
          language: event.target.value,
        })
      );
    },
    [i18n, dispatch]
  );

  useEffect(() => {
    if (settings?.language) {
      i18n.changeLanguage(getI18nLanguage(settings.language));
    }
  }, [settings, i18n]);

  return (
    <Select
      value={settings?.language as string}
      options={options}
      onChange={onChange}
      variant="standard"
    />
  );
};

export default SelectLanguage;
