import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import history from "~/packages/history";
import alerts from "./alerts/reducer";
import auth from './auth/reducer';
import settings from "./settings/reducer";
import profile from "./profile/reducer";
import search from './search/reducer';
import storage from 'redux-persist/lib/storage'

const Middleware = applyMiddleware(thunk, routerMiddleware(history));

const rootReducer = combineReducers({
  router: connectRouter(history),
  alerts,
  auth,
  settings,
  profile,
  search
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: []
};

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  process.env.NODE_ENV === "production"
    ? compose(Middleware)
    : composeWithDevTools(Middleware)
);

export const persistor = persistStore(store);
