import { Box, Typography, Icon } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { useConfirm } from "~/components/UI/ConfirmDialog";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    icon: {
      "&&": {
        fontSize: 24,
        marginBottom: theme.spacing(4),
      },
    },
  })
);

const DeleteConfirmationContent = ({ message }: { message?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Icon className={classes.icon} name="Delete" />
      <Typography variant="h4">{t("option.delete")}</Typography>
      {message && <Box style={{ maxWidth: "100%"}} m={1} textAlign="center"><Typography style={{wordWrap: "break-word",  maxWidth: "100%"}} variant="h4" color="error">{message}</Typography></Box>}
      <Typography variant="body1">{t("text.confirmationRequest")}</Typography>
    </>
  );
};

const ActionConfirmationContent = ({ message }: { message?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Icon className={classes.icon} color="warning" name="AlertError" />
      {
        message
          ? <Box m={1}><Typography variant="h4">{message}</Typography></Box>
          : <Typography variant="body1">{t("text.confirmationRequest")}</Typography>
      }
    </>
  );
};

type TypeDialog = "delete" | "action";

const useConfirmDialog = (type?: TypeDialog) => {
  const { t } = useTranslation();
  const confirm = useConfirm();

  switch (type) {
    case "delete":
      return (message?: string) =>
        confirm({
          content: <DeleteConfirmationContent message={message} />,
          confirmationText: t("button.delete"),
          cancellationText: t("button.cancel"),
        });
    case "action":
      return (message?: string) =>
        confirm({
          content: <ActionConfirmationContent message={message} />,
        });
    default:
      return confirm;
  }
};

export default useConfirmDialog;
