import React, { useState } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { Collapse, Icon, IconButton } from ".";

import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface SubSectionProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  collapsed?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "block",
    },
    header: {
      paddingTop: theme.spacing(2),
      position: "relative",
      color: theme.palette.text.primary,
      cursor: "pointer",
    },
    extra: {},
    hidden: {
      visibility: "visible",
    },
    container: {
      marginLeft: theme.spacing(7),
    },
    hideBtn: {
      "&&": {
        fontSize: 10,
        color: theme.palette.text.primary,
      },
    },
  })
);

const SubSection = ({
  title,
  children,
  collapsed,
  className,
}: SubSectionProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid
        container
        alignItems="center"
        className={classes.header}
        onClick={() => setOpen(!open)}
      >
        <Grid item>
          {collapsed && (
            <IconButton className={classes.hideBtn}>
              {open ? <Icon name="Minus" /> : <Icon name="Plus" />}
            </IconButton>
          )}
        </Grid>
        <Grid item>
          {typeof title === "string" ? (
            <Typography variant="subtitle2">{title}</Typography>
          ) : (
            title
          )}
        </Grid>
      </Grid>
      {collapsed ? (
        <Collapse
          in={open}
          classes={{
            wrapper: classes.container,
            hidden: classes.hidden,
          }}
        >
          {children}
        </Collapse>
      ) : (
        children
      )}
    </div>
  );
};

export default SubSection;
