import Constants from "./constants";

const { ACTIONS } = Constants;

const initialState: SearchStore.State = {
  loading: false
};

const Reducer = (
  state = initialState,
  action: SearchStore.Actions
): SearchStore.State => {
  switch (action.type) {
    case ACTIONS.GLOBAL_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.SET_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        data: action.payload.result,
        searchQuery: action.payload.searchQuery,
      };
    case ACTIONS.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchQuery: '',
        data: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

export default Reducer;
