export const ENTITIES = {
  CREATE_CONTRACT: 'create-contract',
  READ_CONTRACT: 'read-contract',
  EDIT_CONTRACT: 'edit-contract',
  DELETE_CONTRACT: 'delete-contract',

  CREATE_MERCHANT: 'create-merchant',
  READ_MERCHANT: 'read-merchant',
  EDIT_MERCHANT: 'edit-merchant',
  DELETE_MERCHANT: 'delete-merchant',

  CREATE_MDES_MERCHANT: 'create-mdes-merchant',
  READ_MDES_MERCHANT: 'read-mdes-merchant',
  EDIT_MDES_MERCHANT: 'edit-mdes-merchant',
  DELETE_MDES_MERCHANT: 'delete-mdes-merchant',

  CREATE_INBOUND_VIRTUAL_TERMINAL: 'create-inbound-virtual-terminal',
  READ_INBOUND_VIRTUAL_TERMINAL: 'read-inbound-virtual-terminal',
  EDIT_INBOUND_VIRTUAL_TERMINAL: 'edit-inbound-virtual-terminal',
  DELETE_INBOUND_VIRTUAL_TERMINAL: 'delete-inbound-virtual-terminal',

  CREATE_ACQUIRER: 'create-acquirer',
  READ_ACQUIRER: 'read-acquirer',
  EDIT_ACQUIRER: 'edit-acquirer',
  DELETE_ACQUIRER: 'delete-acquirer',

  CREATE_OUTBOUND_VIRTUAL_TERMINAL: 'create-outbound-virtual-terminal',
  READ_OUTBOUND_VIRTUAL_TERMINAL: 'read-outbound-virtual-terminal',
  EDIT_OUTBOUND_VIRTUAL_TERMINAL: 'edit-outbound-virtual-terminal',
  DELETE_OUTBOUND_VIRTUAL_TERMINAL: 'delete-outbound-virtual-terminal',

  CREATE_KEY_MANAGEMENT: 'create-key-management',
  READ_KEY_MANAGEMENT: 'read-key-management',
  EDIT_KEY_MANAGEMENT: 'edit-key-management',
  DELETE_KEY_MANAGEMENT: 'delete-key-management',

  CREATE_WALLET_CREDENTIALS: 'create-wallet-credentials',
  READ_WALLET_CREDENTIALS: 'read-wallet-credentials',
  EDIT_WALLET_CREDENTIALS: 'edit-wallet-credentials',
  DELETE_WALLET_CREDENTIALS: 'delete-wallet-credentials',

  CREATE_CARD_ON_FILE: 'create-card-on-file',
  READ_CARD_ON_FILE: 'read-card-on-file',
  EDIT_CARD_ON_FILE: 'edit-card-on-file',
  DELETE_CARD_ON_FILE: 'delete-card-on-file',

  CREATE_ROLE: 'create-role',
  READ_ROLE: 'read-role',
  EDIT_ROLE: 'edit-role',
  DELETE_ROLE: 'delete-role',

  CREATE_USER: 'create-user',
  READ_USER: 'read-user',
  EDIT_USER: 'edit-user',
  DELETE_USER: 'delete-user',

  CREATE_GROUP: 'create-group',
  READ_GROUP: 'read-group',
  EDIT_GROUP: 'edit-group',
  DELETE_GROUP: 'delete-group',

  READ_JOURNAL_FINANCIAL_TRANSACTIONS: 'read-journal-financial-transactions',

  READ_EVENT_LOG: 'read-event-log',

  CREATE_JOURNAL_INCIDENTS: 'create-journal-incidents',
  READ_JOURNAL_INCIDENTS: 'read-journal-incidents',
  EDIT_JOURNAL_INCIDENTS: 'edit-journal-incidents',

  READ_JOURNAL_SYSTEM_LOG: 'read-journal-log-system',

  READ_JOURNAL_OF_USERS_ACTIONS: 'read-journal-of-users-actions',

  READ_AUDIT_LOG_KEY_MANAGEMENT: 'read-audit-log-key-management',

  READ_FINANCIAL_TRANSACTION_COUNTS_METRICS: 'read-financial-transaction-counts-metrics',

  READ_CLIENT_PAYMENT_DURATION_METRICS: 'read-client-payment-duration-metrics',

  READ_PSP_PAYMENT_DURATION_METRICS: 'read-psp-payment-duration-metrics',

  CREATE_SYSTEM_SETTINGS: 'create-system-settings',
  READ_SYSTEM_SETTINGS: 'read-system-settings',
  EDIT_SYSTEM_SETTINGS: 'edit-system-settings',
  DELETE_SYSTEM_SETTINGS: 'delete-system-settings',

  CREATE_ID_PREFIX: 'create-id-prefix',
  READ_ID_PREFIX: 'read-id-prefix',
  EDIT_ID_PREFIX: 'edit-id-prefix',
  DELETE_ID_PREFIX: 'delete-id-prefix',

  CREATE_BIN_RANGE: 'create-bin-range',
  READ_BIN_RANGE: 'read-bin-range',
  EDIT_BIN_RANGE: 'edit-bin-range',
  DELETE_BIN_RANGE: 'delete-bin-range',

} as { [key: string]: PermissionsApi.eBasePermissions }

export const PROCESSES = {
  FINANCIAL_TRANSACTION_INCIDENTS: 'financial-transaction-incidents',
  REFUND_FOR_FINANCIAL_TRANSACTIONS: 'refund-for-financial-transactions',
  REVERSAL_FOR_FINANCIAL_TRANSACTIONS: 'reversal-for-financial-transactions',
} as { [key: string]: PermissionsApi.eBasePermissions }

export const PERMISSION = Object.assign({},PROCESSES, ENTITIES) as { [key: string]: PermissionsApi.eBasePermissions }

export const METRICS = [
  PERMISSION.READ_FINANCIAL_TRANSACTION_COUNTS_METRICS,
  PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS,
  PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS,
]

export const PERMISSION_TYPE = {
  CREATE: 'create',
  READ: 'read',
  EDIT: 'edit',
  DELETE: 'delete',
} as { [key: string]: PermissionsApi.ePermissionType }
