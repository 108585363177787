import MUIMenuItem from "@material-ui/core/MenuItem";
import { withStyles, createStyles } from "@material-ui/core/styles";

const MenuItem = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      fontSize: 14,
      padding: "8px 15px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  })
)(MUIMenuItem);

export default MenuItem;
