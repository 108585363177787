import { useState, useEffect } from "react";
import { Box, Typography, makeStyles, createStyles } from "~/components/UI";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ANIMATION_DURATION, numeralFormatter, dateFormatter } from "./Common";

const colors = [
  "#414E5A",
  "#56C75B",
  "#56A5C7",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "auto",
      fontSize: 12,
      display: "flex",
      alignContent: "stretch",
      flexDirection: "column",
    },
    graphicContainer: {
      flex: 1,
    },
  })
);

interface BusinessTransactionCountProps {
  period?: MetricsAPI.eTimePeriod;
  list?: any[];
}

const TooltipContent = ({
  time,
  payload,
}: {
  time: string;
  payload: { name: string; fill: string; value: number }[];
}) => {
  const { t } = useTranslation();

  return (
    <Box border={1} borderColor="divider" p={3} bgcolor="background.paper">
      <Typography gutterBottom>
        <strong>{t("label.time")}:</strong> {time}
      </Typography>
      <Typography>
        {payload && payload.length
          ? payload.map((i) => (
              <Typography gutterBottom>
                <strong style={{ color: i.fill }}>{i.name}:</strong> {i.value}
              </Typography>
            ))
          : null}
      </Typography>
    </Box>
  );
};

const BusinessTransactionCount = ({
  period,
  list,
}: BusinessTransactionCountProps) => {
  const [barKeys, setBarKeys] = useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (list && list.length) {
      const keys = Object.keys(list[0]).filter((key) => key !== "createdAt");
      setBarKeys(keys);
    } else {
      setBarKeys([]);
    }
  }, [list]);

  if (!list) return null;

  return (
    <div className={classes.root}>
      <Box p={4}></Box>
      <Box className={classes.graphicContainer}>
        <ResponsiveContainer width="100%" height="98%">
          <BarChart data={list as any}>
            <CartesianGrid vertical={false} strokeDasharray="2" />
            <XAxis
              dataKey="createdAt"
              domain={["auto", "auto"]}
              tickCount={0}
              tickLine={false}
              axisLine={false}
              tickFormatter={dateFormatter(period)}
            />
            <YAxis
              type="number"
              allowDecimals={false}
              domain={[0, "auto"]}
              tickLine={false}
              axisLine={false}
              width={25}
              tickFormatter={numeralFormatter}
            />
            <Tooltip
              content={({ active, payload, label }: any) => {
                return active && payload ? (
                  <TooltipContent
                    time={dateFormatter(period)(label)}
                    payload={payload}
                  />
                ) : null;
              }}
            />
            {barKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                fill={colors[index]}
                animationDuration={ANIMATION_DURATION}
              />
            ))}
            <Legend
              verticalAlign="bottom"
              align="left"
              iconType="square"
              iconSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </div>
  );
};

export default BusinessTransactionCount;
