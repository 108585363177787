import React from "react";
import { Box, Input, Icon, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { LOG_TYPE, LOG_TYPE_I18n } from "~/api/systemLogJournal/enums";
import { useTranslation } from "react-i18next";
import SelectMerchant from "~/components/CommonFormControls/SelectMerchant";
import SelectOutboundVirtualTerminal from "~/components/CommonFormControls/SelectOutboundVT";
import SelectInboundVirtualTerminal from "~/components/CommonFormControls/SelectInboundVT";
import moment from "moment";
interface FilterProps {
  onChangeFilter: (
    data: UserActivityJournalApi.GetUserActivityQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.merchant")} collapsed defaultOpen={true}>
          <SelectMerchant name="merchantId" />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.inboundVirtualTerminal")}
          collapsed
          defaultOpen={true}
        >
          <SelectInboundVirtualTerminal name="inboundVtId" />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.outboundVirtualTerminal")}
          collapsed
          defaultOpen={true}
        >
          <SelectOutboundVirtualTerminal name="outboundVtId" />
        </SidebarGroup>
        <SidebarGroup label={t("label.severity")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="logLevel"
            enumValue={LOG_TYPE}
            enumI18n={LOG_TYPE_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
