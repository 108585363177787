import { useTranslation } from "react-i18next";
import Checkbox from "./Checkbox";
import _ from 'lodash';

interface EnumCheckboxGroupProps {
  fieldName: string;
  enumValue: { [key: string]: string };
  enumI18n: { [key: string]: string };
}

const EnumCheckboxGroup = ({
  fieldName,
  enumValue,
  enumI18n,
}: EnumCheckboxGroupProps) => {
  const { t } = useTranslation(["enums"]);
  const keys = Object.keys(enumValue);

  return (
    <>
      {keys
        ? _.sortBy(keys).map((key) => (
            <Checkbox
              key={key}
              label={enumI18n ? t(enumI18n[enumValue[key]]) : enumValue[key]}
              name={fieldName}
              value={enumValue[key]}
            />
          ))
        : null}
    </>
  );
};

export default EnumCheckboxGroup;
