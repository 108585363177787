import { Box, Grid, InputLabel } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @memberof MerchantsPage
 * @component
 * @desc Render more information about Merchant.
 * @property {MerchantsApi.MerchantDto} merchant Merchant data
 */

interface AdditionalInformationProps {
  merchant: MerchantsApi.MerchantDto;
}

const MoreInformation = ({ merchant }: AdditionalInformationProps) => {
  const { t } = useTranslation();

  return (
    <Box p={4}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item lg={3}>
          <InputLabel>{t("label.linkForSuccessfulPayment")}</InputLabel>
        </Grid>
        <Grid item>{merchant.linkSuccessfulPayment}</Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item lg={3}>
          <InputLabel>{t("label.linkForUnsuccessfulPayment")}</InputLabel>
        </Grid>
        <Grid item>{merchant.linkUnsuccessfulPayment}</Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item lg={3}>
          <InputLabel>{t("label.description")}</InputLabel>
        </Grid>
        <Grid item>{merchant.description}</Grid>
      </Grid>
    </Box>
  );
};

export default MoreInformation;
