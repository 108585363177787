import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateOrUpdateMDESMerchantDialog from "./CreateOrUpdateMDESMerchantDialog";
import CreateMDESMerchantKeys from "./CreateMDESMerchantKeys";
import MDESMerchantsContext from "./MDESMerchantsContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { useQueryParams, StringParam } from "use-query-params";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const MDESMerchants = () => {
  const [editMDESMerchant, setEditMDESMerchant] =
    useState<MDESMerchantsApi.mdesMerchantDto>();
  const [isOpenAddKeysDialog, showAddKeysDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([]);
  const [mdesMerchants, setMDESMerchants] =
    useState<MDESMerchantsApi.mdesMerchantDto[]>();
  const [query, setQuery] = useState<MDESMerchantsApi.GetMDESMerchantsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [queryParams] = useQueryParams({
    search: StringParam,
    mdesMerchantId: StringParam
  });
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_MDES_MERCHANT)
      ? {
          hideSelectAll: false,
          onChange: setSelectedMerchants
        }
      : undefined
  );

  const getMDESMerchants = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.MDESMerchants.getAll(limit, page || currentPage, query);
        data && setMDESMerchants(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateMerchant = () => {
    setEditMDESMerchant(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (
    mdesMerchant?: MDESMerchantsApi.mdesMerchantDto
  ) => {
    setShowEditDialog(false);
    setEditMDESMerchant(undefined);
    mdesMerchant && getMDESMerchants();
  };

  const onUpdateItem = useCallback(
    (mdesMerchant: MDESMerchantsApi.mdesMerchantDto) => {
      setMDESMerchants((mdesMerchants) =>
        mdesMerchants
          ? updateArrayItem(mdesMerchants, mdesMerchant, "mdesMerchantId")
          : []
      );
    },
    []
  );

  const onEdit = useCallback(
    (mdesMerchant: MDESMerchantsApi.mdesMerchantDto) => {
      setEditMDESMerchant(mdesMerchant);
      setShowEditDialog(true);
    },
    []
  );

  const onUpdate = useCallback(
    async (id: string, formData: MDESMerchantsApi.UpdateMerchant) => {
      try {
        const {
          data: { data: merchant }
        } = await api.MDESMerchants.update(id, formData);
        onUpdateItem(merchant);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (merchant: MDESMerchantsApi.mdesMerchantDto) => {
      await deleteConfirm(merchant?.mdesMerchantName);
      try {
        await api.MDESMerchants.delete(merchant.mdesMerchantId);
        getMDESMerchants();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getMDESMerchants, deleteConfirm]
  );

  const onDeleteMerchants = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedMerchants?.length }));
    try {
      await api.MDESMerchants.deleteMerchants(selectedMerchants);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getMDESMerchants();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedMerchants]);

  const onChangePagination = (page: number) => {
    getMDESMerchants(page);
  };

  const onChangeFilters = (filters: MDESMerchantsApi.GetMDESMerchantsQuery) => {
    pagination.current = undefined;
    setMDESMerchants([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(queryParams as MDESMerchantsApi.GetMDESMerchantsQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getMDESMerchants();
  }, [query, getMDESMerchants, limit]);

  return (
    <MDESMerchantsContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.mdesMerchants")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.CREATE_KEY_MANAGEMENT}>
                <Button
                  prefixIcon={<Icon name="Key" />}
                  variant="contained"
                  onClick={() => showAddKeysDialog(true)}
                >
                  {t("button.mdesKeys")}
                </Button>
              </CanIUse>
              <CanIUse permissions={PERMISSION.DELETE_MDES_MERCHANT}>
                <Button
                  hidden={!selectedMerchants.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteMerchants}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_MDES_MERCHANT}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateMerchant}
                >
                  {t("button.addMDESMerchant")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={mdesMerchants}
          onRequestSort={onSort}
          rowKey="mdesMerchantId"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateMDESMerchantDialog
        open={showEditDialog}
        mdesMerchant={editMDESMerchant}
        onClose={onCloseEditDialog}
      />
      <CreateMDESMerchantKeys
        open={isOpenAddKeysDialog}
        onClose={() => showAddKeysDialog(false)}
      />
    </MDESMerchantsContext.Provider>
  );
};

export default MDESMerchants;
