import { useTranslation } from "react-i18next";
import { Select } from "~/components/UI";

interface SelectEnumProps {
  fieldName: string;
  enumValue: { [key: string]: string };
  enumI18n: { [key: string]: string };
}

const SelectEnum = ({ fieldName, enumValue, enumI18n }: SelectEnumProps) => {
  const { t } = useTranslation(["enums"]);
  const keys = Object.keys(enumValue);

  const getOptions = () => {
    return keys
      ? keys.map((key) => ({
          label: enumI18n ? t(enumI18n[enumValue[key]]) : enumValue[key],
          value: enumValue[key],
        }))
      : [];
  };

  return (
    <Select
      name={fieldName}
      options={getOptions()}
      variant="outlined"
      formikControll
      fullWidth
    />
  );
};

export default SelectEnum;
