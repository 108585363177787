import isUndefined from 'lodash/isUndefined';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import pickBy from 'lodash/pickBy';
import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

export const enumToI18nKey = (obj: { [key: string]: string }, pefixKey: string) =>
  mapValues(invert(obj), (value) => `${pefixKey ? pefixKey + '.' : ''}${value}`);

export const isEmptyValue = (value: unknown) =>
  isUndefined(value) ||
  isNaN(value) ||
  isNull(value) ||
  (isObject(value) && Object.keys(value).length === 0) ||
  (isString(value) && value.trim() === "");

/**
 * Remove all empty keys from the query object
 * @function deleteEmptyFields
 * @param {object} obj - Query object.
 * @return {object}
 */

export const deleteEmptyFields = (obj: object) =>
  pickBy(obj, (val) => !isEmptyValue(val));

/**
 * Detect better contrast color (black or white)
 * @function getContrastYIQ
 * @param {string} hexcolor - HEX color.
 * @return {'black' | 'white'}
 */

export const getContrastYIQ = (hexcolor: string) => {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}

/**
 * Сreates a new array of objects with updated elements by key;
 * @function updateArrayItem
 * @param {string} array - Array<Object>.
 * @param {string} obj - Object.
 * @param {string} key - Key for compare.
 * @return {Array<Object>}
 */

export const updateArrayItem = <T>(array?: T, item?: {[key: string]: unknown} | any, key?: string) => {
  if(!Array.isArray(array) || !item || !key) return array;
  return array.map(i => i[key] === item[key] ? ({ ...i, ...item }) : i)
} 

/**
 * Move item in the array. Mutation the current array.
 * @function arrayMoveMutate
 * @param {string} value - Phone number.
 * @return {string}
 */

export const arrayMoveMutate = <T>(array: T[], from: number, to: number) => {
	const startIndex = from < 0 ? array.length + from : from;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = to < 0 ? array.length + to : to;

		const [item] = array.splice(from, 1);
		array.splice(endIndex, 0, item);
	}
};

/**
 * Move item in the array. Creating a new array.
 * @function arrayMove
 * @param {string} value - Phone number.
 * @return {string}
 */

export const arrayMove = <T>(array: T[], from: number, to: number) => {
	array = [...array];
	arrayMoveMutate(array, from, to);
	return array;
};

/**
 * Create a query string: Example: ID, order: "desc" = > " - ID"
 * @function getSortStr
 * @param {string} field - field.
 * @param {"desc" | "asc"} order - order.
 * @return {string}
 */

export const getSortStr = (field: string, order: "desc" | "asc") => `${order === "desc" ? '-': ''}${field}`;

export const parseSortStr = (string: string): { field: string, order: "desc" | "asc" } => ({
  field: string.charAt(0) === '-' ? string.substring(1) : string,
  order: string.charAt(0) === '-' ? "desc" : "asc"
})

/**
 * Conver object to FormData
 * @function getFormData
 * @param {string} object -  { [key: string]: any }
 * @return {FormData}
 */

export const getFormData = (object: { [key: string]: any }) => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key]);
  return formData;
}, new FormData());

export const getLogoUrl = (fileUrl?: string): string => {
  const d = new Date();
  if (!fileUrl) return '';
  return `${fileUrl}?t=${d.getDate()}_${d.getHours()}:${Math.floor(d.getMinutes()/10)}`;
}

export const getI18nLanguage = (language?: string) =>
  language === 'ua' ? 'uk': language

export const sortByField = (field: string, order: "desc" | "asc") => {
  return function(a: any, b: any) {
    const stringA = a[field].toLowerCase();
    const stringB = b[field].toLowerCase();
    let result = 0;
    
    if (stringA < stringB) {
      result = -1;
    } else if (stringA > stringB) {
      result = 1;
    }

    if (order === "desc") {
      result *= -1;
    }

    return result;
  }
}

export const getCyrillicSorterByField = (field: string, order: "desc" | "asc", local: string) => {
  const collator = new Intl.Collator(local, { sensitivity: 'base' });

  return function(a: any, b: any) {
    const stringA = a[field];
    const stringB = b[field];
    const result = collator.compare(stringA, stringB);

    return order === 'desc' ? -result : result;
  }
}

export const saveFile = (data: string, filename: string): void => {
  const a = document.createElement('a');
  
  a.href = data;
  a.download = filename;
  a.click();
  a.remove();
}