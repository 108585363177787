import { Switch } from "react-router";
import ROUTES from "~/constants/routes";
import Acquirers from "./Acquirers";
import BinRange from "./BinRange";
import Contracts from "./Contracts";
import Merchants from "./Merchants";
import MDESMerchants from "./MDESMerchants";
import KeyManagement from "./KeyManagement";
import InboundVirtualTerminals from "./InboundVirtualTerminals";
import OutboundVirtualTerminals from "./OutboundVirtualTerminals";
import WalletsCredentials from "./WalletsCredentials";
import CardOnFile from "./CardOnFile";
import UserRoles from "./UserRoles";
import Users from "./Users";
import Groups from "./Groups";
import PrivateRoute from "~/components/PrivateRoute";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const CatalogsRoutes = () => {
  const { hasAll } = usePermissions();

  return (
    <Switch>
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_CONTRACT)}
        path={ROUTES.CONTRACTS}
        component={Contracts}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_MERCHANT)}
        path={ROUTES.MERCHANTS}
        component={Merchants}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_MDES_MERCHANT)}
        path={ROUTES.MDES_MERCHANTS}
        component={MDESMerchants}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_INBOUND_VIRTUAL_TERMINAL)}
        path={ROUTES.INBOUND_VIRTUAL_TERMINALS}
        component={InboundVirtualTerminals}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_ACQUIRER)}
        path={ROUTES.ACQUIRERS}
        component={Acquirers}
      />
      <PrivateRoute
      // TODO change READ_ACQUIRER
        canIUse={hasAll(PERMISSION.READ_ACQUIRER)}
        path={ROUTES.BIN_RANGE}
        component={BinRange}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_CARD_ON_FILE)}
        path={ROUTES.CARD_ON_FILE}
        component={CardOnFile}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_KEY_MANAGEMENT)}
        path={ROUTES.KEY_MANAGEMENT}
        component={KeyManagement}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_OUTBOUND_VIRTUAL_TERMINAL)}
        path={ROUTES.OUTBOUND_VIRTUAL_TERMINALS}
        component={OutboundVirtualTerminals}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_WALLET_CREDENTIALS)}
        path={ROUTES.WALLET_CREDENTIALS}
        component={WalletsCredentials}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_ROLE)}
        path={ROUTES.USER_ROLES}
        component={UserRoles}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_USER)}
        path={ROUTES.USERS}
        component={Users}
      />
      <PrivateRoute
        canIUse={hasAll(PERMISSION.READ_GROUP)}
        path={ROUTES.GROUPS}
        component={Groups}
      />
    </Switch>
  );
};

export default CatalogsRoutes;
