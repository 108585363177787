import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import InboundVirtualTerminalsContext from "./InboundVirtualsTerminalsContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface InboundVirtualTerminalOptionsProps {
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
}

/**
 * @memberof InboundVirtualTerminals
 * @component
 * @desc InboundVirtualTerminal options. Using a InboundVirtualTerminalsContext.
 * @property {InboundVirtualTerminalsApi.InboundVirtualTerminalDto} inboundVirtualTerminal - InboundVirtualTerminal data;
 * @requires InboundVirtualTerminalsContext
 */

const InboundVirtualTerminalOptions = ({
  inboundVirtualTerminal,
}: InboundVirtualTerminalOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdate, onUpdateItem, onDelete } = useContext(
    InboundVirtualTerminalsContext
  );
  const [createIncident] = useCreateIncidentDialog(
    SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL
  );
  const { enabled } = inboundVirtualTerminal;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (inboundVirtualTerminal.incidents) {
        inboundVirtualTerminal.incidents.count++;
        inboundVirtualTerminal.incidents.incidentIds.push(incident.incidentId);
      } else {
        inboundVirtualTerminal.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(inboundVirtualTerminal);
    },
    [inboundVirtualTerminal, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL,
      PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(
            onUpdate(inboundVirtualTerminal.id, { enabled: !enabled })
          ),
      },
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL),
      onClick: () => onEdit && onEdit(inboundVirtualTerminal),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL),
      onClick: () =>
        onDelete && onUpdateHandler(onDelete(inboundVirtualTerminal)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () =>
        createIncident(inboundVirtualTerminal.id, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default InboundVirtualTerminalOptions;
