import { useState, useEffect } from "react";
import {
  Label,
  Tag,
  Box,
  Typography,
  Grid,
  LinearProgress,
  Divider,
} from "~/components/UI";
import CollapsibleTagsGroup from "~/components/CollapsibleTagsGroup";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { phoneNumber } from "~/utils/format";
import { getContrastYIQ } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface UserDetailsProps {
  user: UsersApi.UserDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      maxHeight: 300,
      overflow: "auto",
    },
  })
)(Grid);

interface ColumnProps {
  title?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const Column = ({ title, children }: ColumnProps) => (
  <Grid item md={4} sm={12}>
    <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
    <Box p={4} flexGrow={1}>
      {children}
    </Box>
  </Grid>
);

type GroupsMeta = { [ket: string]: UsersApi.UserGroupDto };

function getGroupsMeta(value: UsersApi.UserGroupDto[]): GroupsMeta {
  const groups: GroupsMeta = {};

  if (value) {
    value.forEach((v) => {
      groups[v.groupId] = v;
    });
  }

  return groups;
}

const Groups = ({ groups }: { groups?: GroupsMeta }) => {
  const [list, setList] = useState<UsersApi.UserGroupDto[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const gs = [];
    for (let key in groups) {
      gs.push(groups[key]);
    }
    setList(gs);
  }, [groups]);

  if (!groups) return null;

  return (
    <CollapsibleTagsGroup
      displayByDefault={4}
      showAllBtnText={t("button.showAll")}
    >
      {list &&
        list.map((g, i) => (
          <Tag
            key={`${i}-group`}
            bgcolor={g.color}
            color={g.color ? getContrastYIQ(g.color) : "inherit"}
          >
            {g.name}
          </Tag>
        ))}
    </CollapsibleTagsGroup>
  );
};

const Roles = ({
  roles,
  groups,
}: {
  roles?: UsersApi.UserRoleDto[];
  groups?: GroupsMeta;
}) => {
  const { t } = useTranslation();
  if (!roles) return null;
  return (
    <CollapsibleTagsGroup
      displayByDefault={4}
      showAllBtnText={t("button.showAll")}
    >
      {roles &&
        roles.map((r, i) => {
          const color = groups && groups[r.groupId] && groups[r.groupId].color;
          return (
            <Tag
              key={`${i}-role`}
              bgcolor={color}
              color={color ? getContrastYIQ(color) : "inherit"}
            >
              {r.name}
            </Tag>
          );
        })}
    </CollapsibleTagsGroup>
  );
};

/**
 * @memberof Users
 * @component
 * @desc Render detailed information about a user.
 * @property {UsersApi.UserDto} user - Group data;
 */

const UserDetails = ({ user }: UserDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UsersApi.UserDetailsDto>();
  const [groups, setGroups] = useState<GroupsMeta>();
  const { t } = useTranslation();

  async function getUser(id: string) {
    setLoading(true);
    try {
      const { data } = await api.users.getUser(id);
      setUserDetails(data.data);
      setGroups(getGroupsMeta(data.data.groups));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser(user.userId);
  }, [user]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (userDetails) {
    return (
      <>
        <Container container alignItems="stretch">
          <Column title={t("title.additionalInfromation")}>
            <Label text={t("label.businessPhones")} verticalAlign="flex-start">
              {userDetails.businessPhones &&
                userDetails.businessPhones.map((p, i) => (
                  <Typography key={i} variant="body1">
                    {phoneNumber(p)}
                  </Typography>
                ))}
            </Label>
            <Label text={t("label.userPrincipalName")}>
              {userDetails.userPrincipalName}
            </Label>
          </Column>
          <Column title={t("title.securityGroups")}>
            <Groups groups={groups} />
          </Column>
          <Column title={t("title.roles")}>
            <Roles roles={userDetails.roles} groups={groups} />
          </Column>
        </Container>
        <Divider />
      </>
    );
  } else {
    return null;
  }
};

export default UserDetails;
