import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Collapse, Icon } from "~/components/UI";
import MenuItem, { MenuItemProps } from "./MenuItem";
import { SidebarContext } from "./Menu";
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface MenuGroupProps extends MenuItemProps {
  children?: React.ReactChild | React.ReactChild[];
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    menuItem: {
      pointerEvents: "auto",
    },
    menuItemCollapsed: {
      pointerEvents: "none",
    },
    popper: {
      zIndex: theme.zIndex.appBar,
      flexGrow: 1
    },
    popperContainer: {
      pointerEvents: "auto",
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      minWidth: 205,
      boxShadow: theme.shadows[3],
      marginLeft: -1,
      overflow: "auto"
    },
  })
);

const MenuGroup = ({
  children,
  active,
  onClick,
  ...itemProps
}: MenuGroupProps) => {
  const { open } = useContext(SidebarContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const itemEl = useRef(null);
  const collapsed = Boolean(!anchorEl);
  const classes = useStyles();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      onClick && onClick(event);
    },
    [anchorEl, onClick]
  );

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (open) return;
      setAnchorEl(event.currentTarget);
    },
    [open]
  );

  const handlePopoverClose = useCallback(() => {
    if (open) return;
    setAnchorEl(null);
  }, [open]);

  useEffect(() => {
    if (open && active) {
      setAnchorEl(itemEl.current);
    } else if (!open) {
      setAnchorEl(null);
    }
  }, [open, active, itemEl]);

  return (
    <div
      ref={itemEl}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <MenuItem
        className={open ? classes.menuItem : classes.menuItemCollapsed}
        {...itemProps}
        active={active}
        onClick={handleClick}
        extra={() =>
          collapsed ? (
            <Icon name="ArrowDown" size="small" />
          ) : (
            <Icon name="ArrowTop" size="small" />
          )
        }
      />
      {open ? (
        <Collapse in={!collapsed}>{children}</Collapse>
      ) : (
        <Popper
          id="side-menu-group"
          className={classes.popper}
          open={!collapsed}
          anchorEl={anchorEl}
          disablePortal={false}
          placement="right-start"
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport",
            },
          }}
          keepMounted={true}
        >
          <div className={classes.popperContainer}>{children}</div>
        </Popper>
      )}
    </div>
  );
};

export default MenuGroup;
