import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class PermissionApi {
  rootPath: string = "/profiles";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.personalProfile');
  }

  getMyProfile = (): Promise<Api.GetOne<ProfileApi.MyProfileDto>> =>
    this.instance.get(`${this.rootPath}/me`)

  getPermissions = (): Promise<Api.GetOne<ProfileApi.PermissionsDto>> =>
    this.instance.get(`${this.rootPath}/permissions`)
}
