import { useContext, forwardRef } from "react";
import { Switch, Typography } from "~/components/UI";
import { Link } from "react-router-dom";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import MerchantOptions from "./MerchantOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import MerchantsContext from "./merchantsContext";
import MerchantDetails from "./MerchantDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";
import Routes from "~/constants/routes";

const defaultColumns = [
  {
    key: "id",
    i18nKey: "merchantID",
    copy: true,
    sort: true,
    cellOptions: {
      width: 120,
    }
  },
  {
    key: "merchantName",
    sort: true,
    i18nKey: "merchantName",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    sort: true,
    cellOptions: {
      width: 50
    }
  },
  {
    key: "inboundVirtualTerminals",
    i18nKey: "inboundVirtualTerminals",
    render: (inboundVirtualTerminals: MerchantsApi.InboundVirtualTerminals) => (
      <Link
        to={`${Routes.INBOUND_VIRTUAL_TERMINALS}?search=${
          inboundVirtualTerminals &&
          inboundVirtualTerminals.inboundVirtualTerminalIds.join(",")
        }`}
      >
        <Typography style={{ textDecoration: 'underline' }}>
          {inboundVirtualTerminals ? inboundVirtualTerminals.count : 0}
        </Typography>
      </Link>
    )
  },
  {
    key: "contractName",
    sort: true,
    i18nKey: "contractName",
    render: (contractName: string, merchant: MerchantsApi.MerchantDto) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.CONTRACT}
        sourceId={merchant.contractId}
      >
        {contractName}
      </LinkToObject>
    )
  },
  {
    key: "description",
    i18nKey: "description"
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

const MerchantsTable = (
  props: Omit<TableProps<MerchantsApi.MerchantDto>, "columns">,
  ref: any
) => {
  const { onUpdate } = useContext(MerchantsContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, merchant: MerchantsApi.MerchantDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_MERCHANT)}
              onConfirm={() =>
                onUpdate && onUpdate(merchant.id, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_MERCHANT)}
              />
            </ConfirmDialog>
          )
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, merchant: MerchantsApi.MerchantDto) => (
            <MerchantOptions merchant={merchant} />
          )
        };
      default:
        return column;
    }
  });

  return (
    <Table
      ref={ref}
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (merchant) => <MerchantDetails merchant={merchant} />
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<TableProps<MerchantsApi.MerchantDto>, "columns">
>(MerchantsTable);
