import { createContext } from 'react';

export interface  KeysContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (key: KeyManagementApi.KeyDto) => void;
  onEdit?: (key: KeyManagementApi.KeyDto) => void;
  onUpdate?: (id: string, formData:  KeyManagementApi.UpdateKey) => Promise<void>;
  onDelete?: (key:  KeyManagementApi.KeyDto) => Promise<void>;
}

/**
 * @memberof KeyManagementPage
 * @component
 * @desc React context for providing methods of Key to child components.
 * @property {Function} onEdit - callback for open the Edit Key dialog. (key: KeyManagementApi.KeyDto) => void;
 * @property {Function} onUpdate - callback for update a Key. (id: string, formData:  KeyManagementApi.UpdateKey) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a Key. (key:  KeyManagementApi.KeyDto) => Promise<void>;
 */

const KeysContext = createContext<KeysContextProps>({});


export default KeysContext;