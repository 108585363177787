import { useMemo, useState, useCallback, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  Typography,
  LinearProgress,
} from "~/components/UI";
import Table from "./Table";
import { toast } from "react-toastify";
import IDPrefixesContext from "./IDPrefixesContext";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { useTranslation } from "react-i18next";
import { updateArrayItem, getSortStr, getCyrillicSorterByField, parseSortStr } from "~/utils/helpers";
import { useQueryParams, StringParam } from "use-query-params";
import Filter from "./Filter";
import useConfirmDialog from "~/hook/useConfirmDialog";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";
import CreateIDPrefixDialog from "./CreateIDPrefixDialog";
import { ID_PREFIX_ENTITY_I18n } from "~/api/id-prefix/enums";

/**
 * @memberof IDPrefixes
 * @component
 * @desc IDPrefixes controller.
 * @requires IDPrefixesContext
 */

const IDPrefixes = () => {
  const [prefixes, setPrefixes] = useState<IdPrefixApi.IdPrefixDto[]>();
  const [query, setQuery] = useState<IdPrefixApi.GetIdPrefixQuery>();
  const [limit, setLimit] = useState<number>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
  });
  const { t, i18n } = useTranslation(["translation", "enums"]);
  const [selectedIds, setSelectedIds] = useState<string[]>()
  const tableRef = useRef<{ resetSelectedRows: () => {} }>();
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean | undefined>();

  const getPrefixes = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      const sort = query?.sort || "entity"

      setLoading(true);
      try {
        const { data } = await api.idPrefix.getAll(
          limit,
          page || currentPage,
          { ...query, sort }
        );

        if (data.data) {

          const list = data.data?.map(({ entity, ...other }) => ({
              entity: entity ? t("enums:" + ID_PREFIX_ENTITY_I18n[entity]): entity,
              ...other
            }) || []
          )

          if (sort && /^\S?entity$/.test(sort)) {
            const sortParams = parseSortStr(sort);

            setPrefixes(list.sort(getCyrillicSorterByField(sortParams.field, sortParams.order, i18n.language)));
          } else {
            setPrefixes(list);
          }
        }

        data.meta && setPagination(data.meta.pagination);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onCloseCreatingPrefixDialog  = useCallback((prefix?: IdPrefixApi.IdPrefixDto) => {
    setOpenCreateDialog(false);
    prefix && getPrefixes();
  }, [query]);

  const onDeletePrefixes = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedIds?.length }));
    try {
      selectedIds && await api.idPrefix.deleteIDPrefixes(selectedIds);
      getPrefixes();
      toast.success(t("text.recordsSuccessfullyDeleted"));
      setSelectedIds([]);
      tableRef && tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [t, selectedIds, tableRef]);

  const onDelete = useCallback(
    async (prefix: IdPrefixApi.IdPrefixDto) => {
      await deleteConfirm(prefix?.prefix);
      try {
        prefix.id && await api.idPrefix.deleteIDPrefix(prefix.id);
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      } finally {
        getPrefixes();
      }
    },
    [t]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onUpdateItem = useCallback((prefix: IdPrefixApi.IdPrefixDto) => {
    setPrefixes((prefixes) => (prefixes ? updateArrayItem(prefixes, prefix, "id") : []));
  }, []);

  const switchActivity = useCallback(async (prefix: IdPrefixApi.IdPrefixDto) => {
    if (!prefix.id) return;

    try {
      const { data: { data } } = await api.idPrefix.switchActivityIDPrefix(prefix.id);
      onUpdateItem(data);
      toast.success(t("text.recordSuccessfullyUpdated"));
    } catch (e) {
      console.error(e);
    }
  }, [])

  const contextValue = useMemo(
    () => ({
      onSort,
      onSelect: setSelectedIds,
      onDelete,
      switchActivity,
    }),
    [prefixes]
  );

  const onChangeFilter = useCallback((filters) => {
    setPagination(undefined);
    setQuery(filters);
  }, []);

  const onChangePagination = (page: number) => {
    getPrefixes(page);
  };

  useEffect(() => {
    setQuery(queryParams as IdPrefixApi.GetIdPrefixQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getPrefixes();
  }, [query, limit]);

  return (
    <IDPrefixesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.prefixes")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_ID_PREFIX}>
                <Button
                  hidden={!selectedIds || !selectedIds.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeletePrefixes}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilter} />
              <CanIUse permissions={PERMISSION.CREATE_ID_PREFIX}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={() => setOpenCreateDialog(true)}
                >
                  {t("button.createPrefix")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table ref={tableRef} dataSource={prefixes} />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateIDPrefixDialog open={openCreateDialog} onClose={onCloseCreatingPrefixDialog} />
    </IDPrefixesContext.Provider>
  );
};

export default IDPrefixes;
