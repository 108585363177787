import React from "react";
import { Box, Input, SidebarGroup, Icon } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { PRIORITY, PRIORITY_I18n } from "~/api/eventsLogJournal/enums";
import { useTranslation } from "react-i18next";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import SelectMerchant from "~/components/CommonFormControls/SelectMerchant";
import SelectOutboundVirtualTerminal from "~/components/CommonFormControls/SelectOutboundVT";
import SelectInboundVirtualTerminal from "~/components/CommonFormControls/SelectInboundVT";
import api from "~/api";
import { FormikValues } from "formik";
import moment from "moment";

interface FilterProps {
  initialValues?: FormikValues;
  onChangeFilter: (
    data: UserActivityJournalApi.GetUserActivityQuery
  ) => void | Promise<void>;
}

const Filter = ({ initialValues, onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer
      openByDefault={typeof initialValues === 'object' && !!Object.keys(initialValues).length}
      initialValues={initialValues}
      onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.priority")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="priority"
            enumValue={PRIORITY}
            enumI18n={PRIORITY_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.eventType")} collapsed defaultOpen={true}>
          <SelectValue
            name="eventType"
            loadOptions={() =>
              api.eventsLogJournal
                .getFilterEventTypes()
                .then(({ data }) =>
                  [...data.data].sort().map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.merchant")} collapsed defaultOpen={true}>
          <SelectMerchant name="merchantId" />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.inboundVirtualTerminal")}
          collapsed
          defaultOpen={true}
        >
          <SelectInboundVirtualTerminal name="inboundVtId" />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.outboundVirtualTerminal")}
          collapsed
          defaultOpen={true}
        >
          <SelectOutboundVirtualTerminal name="outboundVtId" />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
