import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Label,
  styled,
} from "~/components/UI";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface WalletDetailsProps {
  wallet: WalletsApi.WalletDto;
}

const Container = styled("div")(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof WalletsCredentialsPage
 * @component
 * @desc Render detailed information about a Wallet.
 * @property {WalletsApi.WalletDto} wallet - Wallet data;
 */

const WalletDetails = ({ wallet }: WalletDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [walletDetails, setWalletDetails] =
    useState<WalletsApi.WalletDetailsDto>();
  const { t } = useTranslation();

  const getWalletDetails = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await api.wallets.getWalletDetails(wallet.walletId);
      setWalletDetails(data);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getWalletDetails();
  }, []);

  return (
    <Container>
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">
          {t("title.additionalInformation")}
        </Typography>
      </Box>
      <LinearProgress hidden={!loading} />
      <Box my={4} pl={4}>
        <Label text={t("label.walletMerchantId")}>{walletDetails?.walletMerchantId}</Label>
        <Label text={t("label.merchantId")}>{walletDetails?.merchantId}</Label>
        <Label text={t("label.privateKeyID")}>
          {walletDetails?.privateKeyId}
        </Label>
        <Label text={t("label.publicKeyID")}>
          {walletDetails?.publicKeyId}
        </Label>
        <Label text={t("label.description")}>
          {walletDetails?.description}
        </Label>
      </Box>
    </Container>
  );
};

export default WalletDetails;
