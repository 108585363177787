import React, { useCallback } from "react";
import IconButton from "./IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon, { IconName } from "./Icon";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MUIListItemIcon from "@material-ui/core/ListItemIcon";
import MUIListItemText from "@material-ui/core/ListItemText";
import Fade from "@material-ui/core/Fade";
import Popconfirm, { PopconfirmProps } from "./Popconfirm";
import { withStyles, createStyles } from "@material-ui/core/styles";

interface OptionProps {
  label?: string;
  icon?: IconName | React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  confirmation?: Omit<PopconfirmProps, "children">;
}

interface DropdownProps {
  options?: OptionProps[];
  loading?: boolean;
  icon?: IconName | React.ReactNode;
}

const ListItemIcon = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: "inherit",
      minWidth: 35,
      fontSize: "1em",
    },
  })
)(MUIListItemIcon);

const ListItemText = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: "inherit",
      fontSize: "1em",
    },
  })
)(MUIListItemText);

const Option = React.forwardRef(
  (
    {
      icon,
      label,
      onClick,
      hidden,
      disabled,
      confirmation = { disabled: true },
    }: OptionProps,
    ref: any
  ) => {
    const handleConfirm = useCallback(() => {
      onClick && onClick();
      confirmation.onConfirm && confirmation.onConfirm();
    }, [confirmation, onClick]);

    if (hidden) return null;

    return (
      <Popconfirm {...confirmation} onConfirm={handleConfirm}>
        <MenuItem
          ref={ref}
          alignItems="center"
          disabled={disabled}
          onClick={(e) => {
            if (disabled) return;
            if (confirmation.disabled) {
              e.stopPropagation();
              onClick && onClick();
            }
          }}
        >
          {icon ? (
            <ListItemIcon>
              {typeof icon === "string" ? (
                <Icon name={icon as IconName} />
              ) : (
                icon
              )}
            </ListItemIcon>
          ) : null}
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      </Popconfirm>
    );
  }
);

const Dropdown = ({ icon, options, loading }: DropdownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(
    (event?: React.MouseEvent<HTMLElement> | any) => {
      event && event.stopPropagation && event.stopPropagation();
      setAnchorEl(null);
    },
    []
  );

  const renderIcon = useCallback(() => {
    return icon ? (
      typeof icon === "string" ? (
        <Icon name={icon as IconName} />
      ) : (
        icon
      )
    ) : (
      <Icon name="Options" />
    );
  }, [icon]);

  return (
    <div>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {loading ? <CircularProgress size="1em" /> : renderIcon()}
      </IconButton>
      {open !== null && (
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(open)}
          onClose={(e) => handleClose(e)}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          {options && options.length
            ? options.map((o, index) => (
                <Option
                  key={index}
                  {...o}
                  onClick={() => {
                    handleClose();
                    o.onClick && o.onClick();
                  }}
                />
              ))
            : null}
        </Menu>
      )}
    </div>
  );
};

export default React.memo(Dropdown);
