import { createContext } from 'react';

export interface  WalletsCredentialsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (wallet: WalletsApi.WalletDto) => void;
  onEdit?: (wallet: WalletsApi.WalletDto) => void;
  onUpdate?: (id: string, formData:  WalletsApi.UpdateWallet) => Promise<void>;
  onDelete?: (wallet:  WalletsApi.WalletDto) => Promise<void>;
}

/**
 * @memberof WalletsCredentialsPage
 * @component
 * @desc React context for providing methods of Wallet to child components.
 * @property {Function} onEdit - callback for open the Edit Wallet dialog. (wallet: WalletsApi.WalletDto) => void;;
 * @property {Function} onUpdate - callback for update a Wallet. (id: string, formData:  WalletsApi.UpdateWallet) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a Wallet. (wallet:  WalletsApi.WalletDto) => Promise<void>;
 */

const WalletsCredentialsContext = createContext<WalletsCredentialsContextProps>({});


export default WalletsCredentialsContext;