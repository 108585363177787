import { createContext } from 'react';

export interface IncidentsContextProps {
  onClose?: (incident: IncidentsApi.IncidentDto) => void;
}

/**
 * @memberof Incidents
 * @component
 * @desc React context for providing methods of Incidents to child components.
 * @property {Function} onClose - callback for the closing an Incident.
 */

const IncidentsContext = createContext<IncidentsContextProps>({});


export default IncidentsContext;
