import IconButton from "@material-ui/core/IconButton";
import { withStyles, createStyles } from "@material-ui/core/styles";

const IButton = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.text.icon,
      fontSize: theme.typography.fontSize + 2,
    },
    colorInherit: {
      color: "inherit",
    },
    sizeSmall: {
      fontSize: theme.typography.fontSize - 4,
      padding: 5,
    },
  })
)(IconButton);

export default IButton;
