import Popconfirm, { PopconfirmProps } from "~/components/UI/Popconfirm";
import { useTranslation } from "react-i18next";

const BaseConfirmDialog = (props: PopconfirmProps) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      cancelText={t("button.no")}
      okText={t("button.yes")}
      title={t("text.confirmationRequest")}
      {...props}
    />
  );
};

export default BaseConfirmDialog;
