import React from "react";
import MUICheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import clsx from "clsx";
import { useField } from "formik";
import FieldError from "./Form/FieldError";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "inline-block",
      padding: 0,
      fontSize: 20,
      "&:hover": {
        backgroundColor: "transparent",
      },
      width: "1em",
      height: "1em",
    },
    small: {
      fontSize: 16,
    },
    icon: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      display: "inline-block",
      width: "1em",
      height: "1em",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      "$root.Mui-focusVisible &": {
        outline: `1px auto ${theme.palette.primary.main}`,
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.action.hover,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.divider,
      },
    },
    indeterminate: {},
    checkedIcon: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        position: "absolute",
        top: "0.2em",
        left: 0,
        display: "inline-block",
        width: "1em",
        height: "1em",
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8394 0.621916C10.6246 0.407115 10.2764 0.407115 10.0616 0.621916L3.47224 7.21132L0.939409 4.67849C0.72463 4.46369 0.376411 4.46371 0.161589 4.67849C-0.0532119 4.89327 -0.0532119 5.24149 0.161589 5.45629L3.08334 8.37799C3.29805 8.59277 3.64653 8.59262 3.86116 8.37799L10.8394 1.39974C11.0542 1.18496 11.0542 0.836717 10.8394 0.621916Z' fill='white'/%3E%3C/svg%3E%0A")`,
        content: '""',
        backgroundPosition: "top",
        backgroundSize: "0.7em 0.5em",
        backgroundRepeat: "no-repeat",
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    indeterminateIcon: {
      backgroundColor: theme.palette.primary.main,
      "&:before": {
        position: "absolute",
        top: "50%",
        left: "25%",
        display: "inline-block",
        width: "50%",
        height: 1,
        content: '" "',
        backgroundColor: theme.palette.primary.contrastText,
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

const BaseCheckbox = React.memo(({ size, ...props }: CheckboxProps) => {
  const classes = useStyles();

  return (
    <MUICheckbox
      {...props}
      className={clsx(classes.root, {
        [classes.small]: size === "small",
      })}
      disableRipple
      color="default"
      indeterminateIcon={
        <span className={clsx(classes.icon, classes.indeterminateIcon)} />
      }
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
    />
  );
});

const FormikCheckbox = ({
  name,
  ...other
}: CheckboxProps & { name: string }) => {
  const [field, meta] = useField({ name, type: "checkbox" });
  const { error, touched } = meta;

  return (
    <>
      <BaseCheckbox checked={!!field.value} {...field} {...other} />
      {touched && error && <FieldError>{error}</FieldError>}
    </>
  );
};

const Checkbox = ({
  name,
  formikControll,
  ...checkboxProps
}: CheckboxProps & { formikControll?: boolean }) =>
  formikControll && name ? (
    <FormikCheckbox name={name} {...checkboxProps} />
  ) : (
    <BaseCheckbox {...checkboxProps} />
  );

export default Checkbox;
