import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import BinRangeContext from "./binRangeContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface BinRangeOptionsProps {
  binRange: BinRangesApi.BinRangeDto;
}

/**
 * @memberof BinRanges
 * @component
 * @desc BinRange options. Using a BinRangeContext.
 * @property {BinRangesApi.BinRangeDto} acquirer - BinRange data;
 * @requires BinRangesContext
 */

const BinRangeOptions = ({ binRange }: BinRangeOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdateItem, onDelete } =
    useContext(BinRangeContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.BIN_RANGE);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (binRange.incidents) {
        binRange.incidents.count++;
        binRange.incidents.incidentIds.push(incident.incidentId);
      } else {
        binRange.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(binRange);
    },
    [binRange, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_BIN_RANGE,
      PERMISSION.DELETE_BIN_RANGE,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_BIN_RANGE),
      onClick: () => onEdit && onEdit(binRange),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_BIN_RANGE),
      onClick: () => onDelete && onUpdateHandler(onDelete(binRange)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(binRange.id, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default BinRangeOptions;
