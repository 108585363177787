import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateMDESMerchantDialogProps
  extends Omit<DialogProps, "onClose"> {
  mdesMerchant?: MDESMerchantsApi.mdesMerchantDto;
  onClose?: (mdesMerchant?: MDESMerchantsApi.mdesMerchantDto) => void;
}

interface FromState extends MDESMerchantsApi.CreateMerchant {
  mdesMerchantId?: string;
}

/**
 * @memberof MDESMerchants
 * @component
 * @desc Dialog for creating or editing a MDESMerchant.
 * @property {MDESMerchantsApi.mdesMerchantDto} merchant a MDESMerchant data
 * @property {Function} onClose - passes merchant data if the merchant was created/updated successfully. (mdesMerchant?: MDESMerchantsApi.mdesMerchantDto) => void;
 */

const CreateOrUpdateMDESMerchantDialog = ({
  mdesMerchant,
  open,
  onClose,
}: CreateOrUpdateMDESMerchantDialogProps) => {
  const [initialValues, setInitialValues] = useState<FromState>({
    mdesMerchantName: "",
  });
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    mdesMerchantName: yup.string().required(),
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      setLoading(true);
      const formData = {
        ...values,
      };
      try {
        if (isEditMode && mdesMerchant) {
          await api.MDESMerchants.update(
            mdesMerchant.mdesMerchantId,
            formData as MDESMerchantsApi.UpdateMerchant
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.MDESMerchants.create(
            formData as MDESMerchantsApi.CreateMerchant
          );
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as MDESMerchantsApi.mdesMerchantDto);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, mdesMerchant, onClose, t]
  );

  useEffect(() => {
    if (mdesMerchant) {
      const { mdesMerchantId, mdesMerchantName } = mdesMerchant;
      setInitialValues({
        mdesMerchantId,
        mdesMerchantName,
      });
      setEditMode(true);
    } else {
      setInitialValues({ mdesMerchantName: "" });
      setEditMode(false);
    }
  }, [mdesMerchant]);

  return (
    <Dialog
      open={open}
      title={
        isEditMode ? t("title.editMDESMerchant") : t("title.addMDESMerchant")
      }
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {mdesMerchant && (
                  <FormControlLabel label="ID">
                    {mdesMerchant.mdesMerchantId}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.name")}>
                  <OutlinedInput name="mdesMerchantName" formikControll />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateMDESMerchantDialog;
