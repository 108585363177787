import { useState } from "react";
import { useTheme, styled } from "@material-ui/styles";
import {
  BarChart as BaseBarChart,
  Bar,
  BarProps as BaseBarProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  CartesianGrid,
  CartesianGridProps,
  Cell,
} from "recharts";
import { Tooltip, TooltipProps } from "./Common";

interface BarProps extends BaseBarProps {
  color?: "primary" | "secondary" | "warning" | "info" | "success" | "error";
}

interface Props {
  width?: number;
  height?: number;
  xAxis?: XAxisProps;
  yAxis?: YAxisProps;
  grid?: CartesianGridProps;
  tooltip?: TooltipProps;
  data: { [ket: string]: string | Array<number> | Object }[];
  bars?: BarProps[];
}

const Container = styled(BaseBarChart)(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    overflow: "auto",
    fontFamily: theme.typography.charts.fontFamily,
    fontSize: theme.typography.charts.fontSize,
  })
);

const BarChart = ({
  width,
  height,
  xAxis,
  yAxis,
  grid,
  data,
  bars,
  tooltip,
}: Props) => {
  const theme: CustomTheme.RootObject = useTheme();
  const [focusBar, setFocusBar] = useState(null);
  return (
    <Container
      {...{
        width,
        height: height && height - 10,
        data,
      }}
      onMouseMove={(state: any) => {
        if (state.isTooltipActive) {
          setFocusBar(state.activeTooltipIndex);
        } else {
          setFocusBar(null);
        }
      }}
    >
      {tooltip && <Tooltip {...tooltip} />}
      {grid && (
        <CartesianGrid
          {...(grid as any)}
          strokeDasharray="5 5"
          stroke={theme.palette.charts.grid}
        />
      )}
      {xAxis && <XAxis {...xAxis} stroke={theme.palette.charts.axis} />}
      {yAxis && <YAxis {...yAxis} stroke={theme.palette.charts.axis} />}
      {bars &&
        bars.length &&
        bars.map(({ color, ...other }, index) => (
          <Bar
            key={index}
            {...(other as any)}
            fill={color && theme.palette[color].light}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  color
                    ? focusBar === index
                      ? theme.palette[color].main
                      : theme.palette[color].light
                    : theme.palette.gray["200"]
                }
              />
            ))}
          </Bar>
        ))}
    </Container>
  );
};

export default BarChart;
