import { useState, useEffect } from "react";
import { Grid, Select } from "~/components/UI";
import BaseCard from "../BaseCard";
import FinancialTransactionCount from "../Charts/FinancialTransactionCount";
import { eTimeInterval } from "../TimeIntervalSelector";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { PERMISSION } from "~/api/permissions/enums";
import {
  TRANSACTION_COUNT_FILTER,
  TRANSACTION_COUNT_FILTER_I18n
} from "~/api/metrics/enums";

interface FinancialTransactionCountsCardProps {
  title?: string;
  onUpdate: (
    queryParams: MetricsAPI.GetMetricQuery
  ) => Promise<MetricsAPI.FinancialTransactionCountDto | undefined>;
}

const FinancialTransactionCountsCard = ({
  title,
  onUpdate
}: FinancialTransactionCountsCardProps) => {
  const { t } = useTranslation(["enums"]);
  const { hasAll } = usePermissions();
  const [type, setType] = useState(TRANSACTION_COUNT_FILTER.SUCCESS);
  const [filterOptions, setFilterOptions] = useState<
    { label: string; value: any }[]
  >([]);

  useEffect(() => {
    setFilterOptions([
      {
        label: t(
          TRANSACTION_COUNT_FILTER_I18n[TRANSACTION_COUNT_FILTER.SUCCESS]
        ),
        value: TRANSACTION_COUNT_FILTER.SUCCESS
      },
      {
        label: t(
          TRANSACTION_COUNT_FILTER_I18n[
            TRANSACTION_COUNT_FILTER.FAILED_BY_CLIENT
          ]
        ),
        value: TRANSACTION_COUNT_FILTER.FAILED_BY_CLIENT
      },
      {
        label: t(
          TRANSACTION_COUNT_FILTER_I18n[
            TRANSACTION_COUNT_FILTER.FAILED_BY_INTERNAL
          ]
        ),
        value: TRANSACTION_COUNT_FILTER.FAILED_BY_INTERNAL
      }
    ]);
  }, [t]);

  if (!hasAll(PERMISSION.READ_FINANCIAL_TRANSACTION_COUNTS_METRICS))
    return null;

  return (
    <Grid item md={12} lg={6}>
      <BaseCard
        title={title || "Financial Transaction Counts"}
        timeIntervals={[
          eTimeInterval.LAST_MINUTE,
          eTimeInterval.LAST_HOUR,
          eTimeInterval.LAST_24_HOURS
        ]}
        onUpdate={onUpdate}
        extraParams={{ type }}
        extra={
          <Select
            variant="outlined"
            value={type}
            options={filterOptions}
            onChange={(e) =>
              setType(e.target.value as MetricsAPI.eTransactionCountFilter)
            }
          />
        }
      >
        {(data) =>
          data ? (
            <FinancialTransactionCount period={data.period} list={data.list} />
          ) : null
        }
      </BaseCard>
    </Grid>
  );
};

export default FinancialTransactionCountsCard;
