import { Box, Input, Icon } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import { useTranslation } from "react-i18next";

interface FilterProps {
  onChangeFilter: (data: WalletsApi.GetWalletsQuery) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
