import MUIBadge from "@material-ui/core/Badge";
import { withStyles, createStyles } from "@material-ui/core/styles";

const Badge = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    badge: {
      minWidth: 3,
      height: 15,
      fontSize: 11,
      fontWeight: "bold",
      boxSizing: "content-box",
      padding: "0 5px",
      top: 2,
      right: 2,
      border: `2px solid ${theme.palette.background.paper}`,
    },
    colorError: {
      color: theme.palette.error.default,
      backgroundColor: theme.palette.error.text,
    },
    colorPrimary: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.primary,
    },
  })
)(MUIBadge);

export default Badge;
