import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import SelectEnum from "~/components/CommonFormControls/SelectEnum";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import { ID_PREFIX_ENTITY, ID_PREFIX_ENTITY_I18n } from '~/api/id-prefix/enums'
import useConfirmDialog from "~/hook/useConfirmDialog";
import api from "~/api";

interface CreateIDPrefixDialogProps extends Omit<DialogProps, "onClose"> {
  onClose?: (key?: IdPrefixApi.IdPrefixDto) => void;
}

const defaultValues: IdPrefixApi.CreateIdPrefix = {
  enabled: false
};

/**
 * @memberof IdPrefixPage
 * @component
 * @desc Dialog for creating a prefix.
 * @property {Function} onClose - (key?: IdPrefixApi.IdPrefixDto) => void;
 */

const CreateIDPrefixDialog = ({ open, onClose }: CreateIDPrefixDialogProps) => {
  const [initialValues, setInitialValues] = useState<IdPrefixApi.CreateIdPrefix>(defaultValues);
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [alreadyUsedPrefix, setAlreadyUsedPrefix] = useState<string>();
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    entity: yup.string().required(),
    prefix:	yup.string().idPrefix().required(),
    enabled: yup.boolean().required(),
  });
  const confirmDialog = useConfirmDialog("action");

  const findActivePrefixes = useCallback(async (entity: string) => {
    try {
      setDisableSubmit(true);
      const { data: { data } } = await api.idPrefix.getAll(1, 1, { enabled: true, entity })

      if (data?.length) {
        setAlreadyUsedPrefix(data[0].prefix);
      }
    } catch (e) {
      console.warn('findActivePrefixes', e)
    } finally {
      setDisableSubmit(false);
    }


  }, [])

  const onSubmit = useCallback(
    async (formData) => {

      if (formData.enabled && alreadyUsedPrefix) {
        await confirmDialog(
          `${t("text.entityAlreadyHasPrefixWarning", { prefix: alreadyUsedPrefix })} \n
            ${t("button.save")}?
          `
        )
      }

      try {
        setLoading(true);
        const { data } = await api.idPrefix.createIDPrefix({...formData, prefix: formData?.prefix?.toUpperCase()});
        onClose && onClose(data.data);
      } finally {
        setLoading(false);
      }
    },
    [alreadyUsedPrefix, onClose]
  );

  useEffect(() => {
    if (open) setInitialValues(defaultValues);
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t("title.createPrefix")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                <FormControlLabel label={t("label.entity")}>
                  <SelectEnum
                    value={values["entity"]}
                    enumValue={ID_PREFIX_ENTITY}
                    enumI18n={ID_PREFIX_ENTITY_I18n}
                    onChange={(e) => {
                      setFieldValue("entity", e.target.value)
                      findActivePrefixes(e.target.value as string)
                    }}
                    formikControll={false}
                    fullWidth
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.prefix")}>
                  <OutlinedInput
                    name="prefix"
                    formikControll
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                disabled={disableSubmit}
                onClick={() => handleSubmit()}
              >
                {t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateIDPrefixDialog;
