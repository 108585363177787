import MUITableBody from "@material-ui/core/TableBody";
import { withStyles, createStyles } from "@material-ui/core/styles";

const TableBody = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
)(MUITableBody);

export default TableBody;
