import { useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";

interface FinancialTransactionOptionsProps {
  transaction: FinancialTransactionsApi.FinancialTransactionDto;
  onUpdateItem?: (
    transaction: FinancialTransactionsApi.FinancialTransactionDto
  ) => void;
}

/**
 * @memberof Incidents
 * @component
 * @desc Incidents options.
 * @property {IncidentsApi.IncidentDto} incident - Incident data;
 */

const FinancialTransactionOptions = ({
  transaction,
  onUpdateItem
}: FinancialTransactionOptionsProps) => {
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.TRANSACTION);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (transaction.incidents) {
        transaction.incidents.count++;
        transaction.incidents.incidentIds.push(incident.incidentId);
      } else {
        transaction.incidents = {
          count: 1,
          incidentIds: [incident.incidentId]
        };
      }
      onUpdateItem && onUpdateItem(transaction);
    },
    [transaction, onUpdateItem]
  );

  const options = [
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(transaction.id, onCreatedIncident)
    }
  ];

  return <Dropdown options={options} />;
};

export default FinancialTransactionOptions;
