import { styled } from "@material-ui/core/styles";

const ButtonGroup = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    display: "inline-block",
    margin: `-${theme.spacing(1)}px`,
    "& > *": {
      margin: `${theme.spacing(1)}px`,
    },
  })
);

export default ButtonGroup;
