import { Box, Grid, InputLabel } from "~/components/UI";
import BaseDatePicker, { DatePickerProps } from "~/components/UI/DatePicker";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

interface Props extends DatePickerProps {
  label?: string;
  name: string;
  maxDateFormikKey?: string;
  minDateFormikKey?: string;
  maxDate?: string | Date;
  minDate?: string | Date;
}

const baseOptions = {
  enableTime: true,
  dateFormat: "M d, Y, H:i",
  position: "bottom left",
  time_24hr: true
};

const DatePicker = ({
  label,
  name,
  minDateFormikKey,
  maxDateFormikKey,
  minDate,
  maxDate,
  options,
  ...other
}: Props) => {
  const { values } = useFormikContext<{ [key: string]: any }>();
  const [{ value }, , helper] = useField(name);
  const { i18n } = useTranslation();

  return (
    <Box py={2}>
      <Grid container alignItems="center">
        <Box width={50} mr={1}>
          <InputLabel>{label}</InputLabel>
        </Box>
        <BaseDatePicker
          {...other}
          id={name}
          value={value}
          name={name}
          clear={true}
          onChange={helper.setValue}
          onClose={() => helper.setTouched(true)}
          options={
            {
              ...baseOptions,
              ...options,
              locale: i18n.language,
              minDate:
                minDateFormikKey && values[minDateFormikKey]
                  ? typeof values[minDateFormikKey] === "object" && values
                    ? values[minDateFormikKey]
                    : null
                  : minDate,
              maxDate:
                maxDateFormikKey && values[maxDateFormikKey]
                  ? typeof values[maxDateFormikKey] === "object" && values
                    ? values[maxDateFormikKey]
                    : null
                  : maxDate
            } as any
          }
        />
      </Grid>
    </Box>
  );
};

export default DatePicker;
