import { useState, useCallback, useEffect } from "react";
import {
  Section,
  Box,
  LinearProgress,
  CardActionArea,
  Typography,
} from "~/components/UI";
import { useHistory } from "react-router-dom";

interface CommonTileProps {
  title?: string;
  showAllText?: string;
  showAllLink?: string;
  children?: (data: any) => React.ReactElement;
  onUpdate?: () => Promise<any>;
}

const CommonTile = ({
  title,
  showAllText,
  showAllLink,
  children,
  onUpdate,
}: CommonTileProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const history = useHistory();

  const showAll = useCallback(() => {
    if (!showAllLink) return;
    history.push(showAllLink);
  }, [history, showAllLink]);

  const refreshData = useCallback(async () => {
    if (onUpdate) {
      setLoading(true);
      try {
        const d = await onUpdate();
        setData(d);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [onUpdate]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <Section title={title}>
      <LinearProgress hidden={!loading} />
      {children && children(data)}
      <Box>
        {showAllText && (
          <CardActionArea onClick={showAll}>
            <Box textAlign="center" p={4} bgcolor="background.paper">
              <Typography variant="subtitle1" color="textSecondary">
                {showAllText}
              </Typography>
            </Box>
          </CardActionArea>
        )}
      </Box>
    </Section>
  );
};

export default CommonTile;
