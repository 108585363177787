import { enumToI18nKey } from '~/utils/helpers';

export const LANGUAGE = {
  UA: "ua",
  ENG: "en",
  RU: "ru"
} as { [key: string]: DashboardSettingsApi.eLanguage }

export const METRIC_KEY = {
  PSP_PAYMENT_DURATION_BY_WALLET: 'PspPaymentDurationMetricsByWallet',
  PSP_PAYMENT_DURATION_BY_ACQUIRER: 'PspPaymentDurationMetricsByAcquirer',
  CLIENT_PAYMENT_DURATION_BY_ACQUIRER: 'ClientPaymentDurationMetricsByAcquire',
  CLIENT_PAYMENT_DURATION_BY_WALLET: 'ClientPaymentDurationMetricsByWallet',
  FINANCIAL_TRANSACTION_COUNTS_BY_PAYMENT_SYSTEM: "TransactionCountByPaymentSystem",
  FINANCIAL_TRANSACTION_COUNTS_BY_WALLET: "TransactionCountByWallet",
  FINANCIAL_TRANSACTION_COUNTS_BY_ACQUIRER: "TransactionCountByAcquirer",
} as { [key: string]: DashboardSettingsApi.eMetricKey }

export const METRIC_KEY_I18n = enumToI18nKey(METRIC_KEY, 'METRIC_KEY');

export const WIDGET_ENTITY = {
  JOURNAL_INCIDENTS: "JournalIncident"
} as { [key: string]: DashboardSettingsApi.eWidgetEntity }
export const JournalEntitys = [ WIDGET_ENTITY.JOURNAL_INCIDENTS ];

export const WIDGET_ENTITY_I18n = enumToI18nKey(WIDGET_ENTITY, 'WIDGET_ENTITY');