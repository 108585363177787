import { useState, useCallback, useEffect } from "react";
import { Box, Button, DialogActions, DialogContent } from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import GetUserFromAD from "./GetUserFromAD";
import RolesManagement from "~/components/RolesManagement";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";

interface AddUserProps extends Omit<DialogProps, "onClose" | "role"> {
  onClose?: (result?: boolean) => void;
}

interface FromData extends UsersApi.AddUser {}

const defaultValues = {
  principleName: "",
  roles: [],
};

/**
 * @memberof Users
 * @component
 * @desc Add User Dialog.
 * @property {Function} onClose - passes true if the user was added successfully. (result?: boolean) => void;
 */

const AddUser = ({ open, onClose }: AddUserProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [roles, setRoles] = useState<UsersApi.UserRolesDto[]>([]);
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    principleName: yup.string().required(),
    roles: yup.array(),
  });
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      setLoading(true);
      try {
        await api.users.addUser(formData);
        toast.success(t("text.recordWasSuccessfullyCreated"));
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t]
  );

  const getRoles = async () => {
    try {
      const {
        data: { data: roles },
      } = await api.roles.getAll(100);
      setRoles(
        roles.map(({ roleId, name }) => ({ roleId, name, status: false }))
      );
    } catch (e) {
      console.error(e);
      setRoles([]);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    setInitialValues(defaultValues);
    setRoles((roles) => roles.map((role) => ({ ...role, status: false })));
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t("title.addUser")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit, values, setFieldValue, isValid, dirty }) => (
            <>
              <DialogContent>
                <GetUserFromAD
                  onChange={(user) => {
                    setFieldValue("principleName", user.userPrincipalName);
                  }}
                />
                {values.principleName && (
                  <Box mt={4}>
                    <RolesManagement
                      roles={roles}
                      onChange={(roles) => {
                        setFieldValue("roles", roles);
                      }}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!dirty || !isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.add")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default AddUser;
