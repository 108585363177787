import { Switch, Route } from "react-router-dom";

import { useSelector } from "react-redux";

import PrivateRoute from "~/components/PrivateRoute";
import ROUTES from "~/constants/routes";
import Login from "./Login";
import Logout from "./Logout";
import Forbidden from "./Forbidden";
import Main from "./Main";

const RouterComponent = () => {
  const { isAuthenticated } = useSelector(
    (state: ReduxStore.State) => state.auth
  );
  return (
    <Switch>
      <Route exact={true} path={ROUTES.LOGIN} component={Login} />
      <Route exact={true} path={ROUTES.LOGOUT} component={Logout} />
      <Route exact={true} path={ROUTES.FORBIDDEN} component={Forbidden} />
      <PrivateRoute
        path="/"
        component={Main}
        isAuthenticated={isAuthenticated}
        redirect={ROUTES.LOGIN}
      />
    </Switch>
  );
};

export default RouterComponent;
