import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import {
  AUDIT_KEY_ACTION_TYPE,
  AUDIT_KEY_ACTION_TYPE_I18n,
  AUDIT_KEY_STATUS,
  AUDIT_KEY_STATUS_I18n
} from "~/api/auditKeyManagementJournal/enums";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface FilterProps {
  onChangeFilter: (
    data: AuditKeyManagementJournalApi.GetAuditKeyManagementQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.dateTime")} collapsed defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.actionType")}
          collapsed
          defaultOpen={true}
        >
          <EnumCheckboxGroup
            fieldName="actionType"
            enumValue={AUDIT_KEY_ACTION_TYPE}
            enumI18n={AUDIT_KEY_ACTION_TYPE_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={AUDIT_KEY_STATUS}
            enumI18n={AUDIT_KEY_STATUS_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
