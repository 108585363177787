import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  ButtonGroup,
  Button,
  Divider,
  Label,
  withStyles,
  createStyles
} from "~/components/UI";
import LinkToObject from "~/components/LinkToObject";
import { useTranslation } from "react-i18next";
import { formatDateWithTime } from "~/utils/format";
import { SOURCE_TYPE } from "~/api/common";
import Routes from "~/constants/routes";
import api from "~/api";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { currencyString } from '~/utils/format';
import { PAYMENT_TYPE } from '~/api/financialTransactions/enums'


interface FinancialTransactionsDetailsProps {
  financialTransaction: FinancialTransactionsApi.FinancialTransactionDto;
  onRefundTransaction: (
    transaction: FinancialTransactionsApi.FinancialTransactionDto &
      FinancialTransactionsApi.FinancialTransactionDetailsDto
  ) => void;
  onReversalTransaction: (
    transaction: FinancialTransactionsApi.FinancialTransactionDto &
      FinancialTransactionsApi.FinancialTransactionDetailsDto
  ) => void;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      maxHeight: 540,
      overflow: "auto"
    }
  })
)(Grid);

/**
 * @memberof
 * @component
 * @desc Render detailed information about an Financial Transaction.
 * @property {FinancialTransactionsApi.FinancialTransactionDto} data - Financial Transaction data;
 */

const FinancialTransactionsDetails = ({
  financialTransaction,
  onRefundTransaction,
  onReversalTransaction
}: FinancialTransactionsDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [financialTransactionDetails, setFinancialTransactionDetails] =
    useState<FinancialTransactionsApi.FinancialTransactionDetailsDto>();
  const { t } = useTranslation();

  async function getFinancialTransactionDetails(id: string) {
    setLoading(true);
    try {
      const {
        data: { data }
      } = await api.financialTransactions.getTransactionDetails(id);
      setFinancialTransactionDetails(data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFinancialTransactionDetails(financialTransaction.id);
  }, [financialTransaction]);

  const goToEvensLog = useCallback(() => {
    const win = window.open(`${Routes.EVENTS}?search=${financialTransaction.id}`, "_blank");
    win?.focus();
  }, [financialTransaction]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (financialTransactionDetails) {
    return (
      <Container
        container
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
          <Typography variant="subtitle1">
            {t("title.additionalInformation")}
          </Typography>
        </Box>
        <Box p={4} flexGrow={1} overflow="auto">
          <Grid container>
            <Grid item lg={4} md={12}>
              <Label text="ID">{financialTransaction.id}</Label>
              <Label text={t("label.transactionStartTime")}>
                {formatDateWithTime(
                  financialTransactionDetails.transactionStartTime
                )}
              </Label>
              <Label text={t("label.cardType")}>
                {financialTransactionDetails.cardType}
              </Label>
              <Label text={t("label.expiryDate")}>
                {financialTransactionDetails.expiryDate}
              </Label>
              <Label text="PAN">{financialTransactionDetails.pan}</Label>
              <Label text="TUR">{financialTransactionDetails.tur}</Label>
              <Label text="RRN">{financialTransactionDetails.rrn}</Label>
              <Label text={t("label.approvalCode")}>
                {financialTransactionDetails.approvalCode}
              </Label>
              <Label text={t("label.responseCode")}>
                {financialTransactionDetails.responseCode}
              </Label>
            </Grid>
            <Grid item lg={4} md={12}>
              {/* <Label text={t("label.merchant")}>
                <LinkToObject
                  sourceType={SOURCE_TYPE.MERCHANT}
                  sourceId={financialTransactionDetails.merchantId}
                />
              </Label> */}
              <Label text={t("label.inboundVirtualTerminal")}>
                <LinkToObject
                  sourceType={SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL}
                  sourceId={financialTransactionDetails.inboundVtId}
                />
              </Label>
              <Label text={t("label.outboundVirtualTerminal")}>
                <LinkToObject
                  sourceType={SOURCE_TYPE.OUTBOUND_VIRTUAL_TERMINAL}
                  sourceId={financialTransactionDetails.outboundVtId}
                />
              </Label>
              <Label text={t("label.acquirerEndpoint")}>
                {financialTransactionDetails.acquirerEndpoint}
              </Label>
              <Label text={t("label.transactionCode")}>
                {financialTransactionDetails.transactionCode}
              </Label>
              <Label text={t("label.reason")}>
                {financialTransactionDetails.comment}
              </Label>
              <Label text={t("label.originalAmount")}>
                {currencyString(financialTransactionDetails.originalAmount)}
              </Label>
              <Label text={t("label.refundSum")}>
                {currencyString(financialTransactionDetails.refundAmount)}
              </Label>
              <Label text={t("label.refundReason")}>
                {financialTransactionDetails.refundReason}
              </Label>              
            </Grid>
            <Grid item lg={4} md={12}>
              <Label text={t("label.cardProgramName")}>
                {financialTransactionDetails.cardProgramName}
              </Label>
              <Label text={t("label.refundRrn")}>
                {financialTransactionDetails.refundRrn}
              </Label>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={4}>
          <ButtonGroup>
            <Button variant="outlined" onClick={goToEvensLog}>
              {t("button.eventLog")}
            </Button>
            <CanIUse
              permissions={PERMISSION.REVERSAL_FOR_FINANCIAL_TRANSACTIONS}
            >
              <Button
                variant="outlined"
                hidden={!financialTransaction.amount}
                onClick={() =>
                  onReversalTransaction({
                    ...financialTransaction,
                    ...financialTransactionDetails
                  })
                }
              >
                {t("button.reversal")}
              </Button>
            </CanIUse>
            { financialTransactionDetails.cardType !== PAYMENT_TYPE.OKKO_ONLINE && (
              <CanIUse permissions={PERMISSION.REFUND_FOR_FINANCIAL_TRANSACTIONS}>
                <Button
                  variant="outlined"
                  hidden={!financialTransaction.amount}
                  onClick={() =>
                    onRefundTransaction({
                      ...financialTransaction,
                      ...financialTransactionDetails
                    })
                  }
                >
                  {t("button.refund")}
                </Button>
              </CanIUse>
            )}
          </ButtonGroup>
        </Box>
      </Container>
    );
  } else {
    return null;
  }
};

export default FinancialTransactionsDetails;
