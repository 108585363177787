import { useMemo, useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  Typography,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import IncidentsContext from "./IncidentsContext";
import CloseIncident from "./CloseIncident";
import Filter from "./Filter";
import { updateArrayItem } from "~/utils/helpers";
import { useQueryParams, StringParam, ArrayParam } from "use-query-params";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { getSortStr } from "~/utils/helpers";
import api from "~/api";
import RowsPerPage from "~/components/RowsPerPage";

/**
 * @memberof Incidents
 * @component
 * @desc Incidents Controller.
 */

type QueryParams = {
  incidentId?: string | string[];
  sourceType?: Api.eSourceType;
  status?: IncidentsApi.eIncidentStatus;
};

const getFiltersFromQueryString = (
  queryParams: QueryParams
): IncidentsApi.GetIncidentsQuery => {
  let filtersState: IncidentsApi.GetIncidentsQuery = {};

  if (queryParams.status) {
    filtersState.status = [queryParams.status];
  }

  if (queryParams.sourceType) {
    filtersState.sourceType = queryParams.sourceType;
  }

  if (queryParams.incidentId) {
    filtersState.incidentId = queryParams.incidentId;
  }

  return filtersState;
};

const Incidents = () => {
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState<IncidentsApi.IncidentDto[]>();
  const [pagination, setPagination] = useState<Api.PaginationSchema>();
  const [closeIncident, setCloseIncident] =
    useState<IncidentsApi.IncidentDto>();
  const [queryParams] = useQueryParams({
    incidentId: ArrayParam,
    sourceType: StringParam,
    status: StringParam
  });
  const [sort, setSort] = useState("-createdAt");
  const [filters, setFilters] = useState<IncidentsApi.GetIncidentsQuery>(
    getFiltersFromQueryString(queryParams as QueryParams)
  );
  const [query, setQuery] = useState<IncidentsApi.GetIncidentsQuery>();
  const [limit, setLimit] = useState<number>();
  const [createIncident] = useCreateIncidentDialog();
  const { t } = useTranslation();

  const getIncidents = useCallback(
    async (page?: number) => {
      const currentPage = pagination ? pagination.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.incidents.getIncidents(limit, page || currentPage, {
          ...query,
          sort: query?.sort || "-createdAt"
        });
        data && setIncidents(data);
        meta && setPagination(meta.pagination);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const openIncidentDialog = useCallback(() => {
    createIncident("", () => getIncidents());
  }, [getIncidents]);

  const onShowCloseIncidentDialog = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      setCloseIncident(incident);
    },
    []
  );

  const onCloseIncident = useCallback(
    (incident?: IncidentsApi.IncidentDto) => {
      if (incident) {
        incidents &&
          setIncidents(updateArrayItem(incidents, incident, "incidentId"));
      }
      setCloseIncident(undefined);
    },
    [incidents]
  );

  const contextValue = useMemo(
    () => ({
      onClose: onShowCloseIncidentDialog
    }),
    [incidents]
  );

  const onChangePagination = useCallback(
    (page: number) => {
      getIncidents(page);
    },
    [query, limit]
  );

  const onSort = (field: string, order: "desc" | "asc") => {
    setSort(getSortStr(field, order));
  };

  const onChangeFilters = (filters: IncidentsApi.GetIncidentsQuery) => {
    setPagination(undefined);
    setFilters(filters);
  };

  useEffect(() => {
    if (Array.isArray(incidents)) {
      setFilters(getFiltersFromQueryString(queryParams as QueryParams));
    }
  }, [queryParams]);

  useEffect(() => {
    setQuery({ sort, ...filters });
  }, [sort, filters]);

  useEffect(() => {
    query && getIncidents();
  }, [query, limit]);

  return (
    <IncidentsContext.Provider value={contextValue}>
      <Box>
        <Section
          title={
            <>
              <Typography variant="h4">
                {t("title.incidents")}{" "}
                <Typography variant="h4" component="span" color="textSecondary">
                  {pagination && pagination.totalCount}
                </Typography>
              </Typography>
            </>
          }
          extra={
            <ButtonGroup>
              <Filter
                initialValues={filters}
                onChangeFilter={onChangeFilters}
              />
              <CanIUse permissions={PERMISSION.CREATE_JOURNAL_INCIDENTS}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={openIncidentDialog}
                >
                  {t("button.createIncident")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto">
        <Table dataSource={incidents} onRequestSort={onSort} />
      </Box>
      <Box>
        <Divider />
        {pagination && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.totalPages}
            page={pagination.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CloseIncident
        open={Boolean(closeIncident)}
        incident={closeIncident}
        onClose={onCloseIncident}
      />
    </IncidentsContext.Provider>
  );
};

export default Incidents;
