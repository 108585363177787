import { useCallback } from "react";
import { Section, Box, Table } from "~/components/UI";
import { Processes } from "~/constants/Permissions";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "i18nKey",
    i18nKey: "name",
  },
  {
    key: "permissions",
    i18nKey: "permissions",
    cellOptions: {
      align: "right",
      width: 70,
    },
    render: () => "Execute",
  },
];

interface ProcessesSectionProps {
  permissions?: PermissionsApi.eBasePermissions[];
}

const ProcessesSection = ({ permissions }: ProcessesSectionProps) => {
  const data = permissions
    ? Processes.filter((p) => permissions.includes(p.execute))
    : [];
  const { t } = useTranslation();
  const getColumns = useCallback(
    () =>
      defaultColumns.map((c) => {
        const column = i18nTableColumn(c, t);
        switch (column.key) {
          case "i18nKey":
            return { ...column, render: t };
          default:
            return column;
        }
      }),
    [t]
  );

  return (
    <Section title={t("label.processes")} collapsed openByDefault={true}>
      <Box my={4} border={1} borderColor="divider">
        <Table columns={getColumns()} dataSource={data} stickyHeader />
      </Box>
    </Section>
  );
};

export default ProcessesSection;
