import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { USER_STATUS, USER_STATUS_I18n } from "~/api/users/enums";

interface UserStatusProps {
  status: UsersApi.eUserStatus;
}

/**
 * @memberof Users
 * @component
 * @desc Render status of a User.
 * @property {UsersApi.eUserStatus} status - User status;
 */

const UserStatus = ({ status }: UserStatusProps) => {
  const { t } = useTranslation("enums");
  let color = "primary";

  switch (status) {
    case USER_STATUS.ACTIVE:
      color = "primary";
      break;
    case USER_STATUS.BLOCKED:
    case USER_STATUS.DELETED:
      color = "error";
      break;
    default:
      return null;
  }

  return (
    <Typography variant="body1" color={color as "primary"}>
      {t(USER_STATUS_I18n[status])}
    </Typography>
  );
};

export default UserStatus;
