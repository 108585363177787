import Table, { TableProps } from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import KeyStatus from "./KeyStatus";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";
import { AUDIT_KEY_ACTION_TYPE_I18n } from '~/api/auditKeyManagementJournal/enums';
import EnumValue from '~/components/EnumValue';

interface AuditKeyManagementTableProps
  extends Omit<
    TableProps<AuditKeyManagementJournalApi.AuditKeyManagementDto>,
    "columns" | "rowSelection"
  > {
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  {
    key: "auditId",
    title: "ID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "createdAt",
    i18nKey: "dateTime",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "keyName",
    i18nKey: "keyName",
  },
  {
    key: "actionType",
    i18nKey: "actionType",
    render: (actionType: string) => <EnumValue value={actionType} enumI18n={AUDIT_KEY_ACTION_TYPE_I18n} />,
    cellOptions: {
      width: 180,
    },
  },
  {
    key: "azureUserId",
    i18nKey: "userID",
    render: (id: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.USER} sourceId={id}>
        {id}
      </LinkToObject>
    ),
    cellOptions: {
      width: 280,
    },
  },
  {
    key: "status",
    i18nKey: "status",
    render: (status: AuditKeyManagementJournalApi.eStatus) => (
      <KeyStatus status={status} />
    ),
  },
  {
    key: "errorMessage",
    i18nKey: "errorMessage"
  }
];

/**
 * @memberof AuditKeyManagement
 * @component
 * @desc Render table of Audit Key Management Journal.
 * @property {AuditKeyManagementJournalApi.AuditKeyManagementDto[]} dataSource - Audit Key data
 */

const AuditKeyManagementTable = ({
  onSort,
  ...props
}: AuditKeyManagementTableProps) => {
  const { t } = useTranslation();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return (
    <Table
      {...props}
      columns={columns}
      onRequestSort={onSort}
      rowKey="activityId"
      stickyHeader
    />
  );
};

export default AuditKeyManagementTable;
