import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { INCIDENT_STATUS } from "~/api/incidents/enums";

interface IncidentsStatusProps {
  status: IncidentsApi.eIncidentStatus;
}

/**
 * @memberof Incidents
 * @component
 * @desc Render status of an Incidents.
 * @property {UsersApi.eUserStatus} status - Incidents status;
 */

const IncidentsStatus = ({ status }: IncidentsStatusProps) => {
  const { t } = useTranslation(["enums"]);

  switch (status) {
    case INCIDENT_STATUS.OPENED:
      return <Box color="error.main">{t("INCIDENT_STATUS.OPENED")}</Box>;
    case INCIDENT_STATUS.CLOSED:
      return <Box color="info.main">{t("INCIDENT_STATUS.CLOSED")}</Box>;
    default:
      return null;
  }
};

export default IncidentsStatus;
