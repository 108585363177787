import { Box, Tabs, Grid, styled } from "~/components/UI";
import { useTranslation } from "react-i18next";
import AcquirerEndpoint from "./AcquirerEndpoint";
import AdditionalInformation from "./AdditionalInformation";
import InboundTerminalsBinding from './InboundTerminalsBinding';

interface OutboundVirtualTerminalProps {
  outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto;
}

const Container = styled(Grid)(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof OutboundVirtualTerminals
 * @component
 * @desc Render detailed information of outboundVirtualTerminal.
 * @property { OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto} outboundVirtualTerminal - outboundVirtualTerminal data;
 */

const OutboundVirtualTerminalDetails = ({
  outboundVirtualTerminal,
}: OutboundVirtualTerminalProps) => {
  const { t } = useTranslation();

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box flexGrow={1}>
        <Tabs
          tabs={[
            {
              label: t("title.acquirerEndpoint"),
              content: (
                <AcquirerEndpoint
                  outboundVirtualTerminal={outboundVirtualTerminal}
                />
              ),
            },
            {
              label: t("title.additionalInformation"),
              content: (
                <AdditionalInformation
                  outboundVirtualTerminal={outboundVirtualTerminal}
                />
              ),
            },
            {
              label: t("title.inboundTerminalsBinding"),
              content: (
                <InboundTerminalsBinding
                  outboundVirtualTerminal={outboundVirtualTerminal}
                />
              )
            }
          ]}
        />
      </Box>
    </Container>
  );
};

export default OutboundVirtualTerminalDetails;
