import { useEffect } from "react";
import { msalAuth } from "~/packages/msalInstance";

const Logout = () => {
  useEffect(() => {
    msalAuth.logout();
  }, []);
  return null;
};

export default Logout;
