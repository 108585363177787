import RJSONPretty from "react-json-pretty";
import { styled } from "@material-ui/core/styles";

const replacer = function (_key: string, value: any) {
  if (typeof value === "string") {
    let tempValue = value

    tempValue = tempValue.replace(/&|<|>|"|'+/g, (match) => {
      switch (match) {
        case '&':
          return  '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#x27;';
        default:
          return match
      }
    })

    try {
      return JSON.parse(value);
    } catch (e) {
      return tempValue;
    }
  }

  return value;
};

const JSONPretty = styled((props: any) => (
  <RJSONPretty {...props} replacer={replacer} />
))(({ theme }: { theme: CustomTheme.RootObject }) => ({
  "&&": {
    lineHeight: 1,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    overflow: "auto",
    "& pre": {
      display: "block",
      width: 1
    },
    "& .__json-key__": {
      color: theme.palette.error.main
    },

    "& .__json-value__": {
      color: theme.palette.warning.main
    },
    "& .__json-string__": {
      color: theme.palette.primary.main
    },
    "& .__json-boolean__": {
      color: theme.palette.info.main
    },
    "& .__json-pretty-error__": {
      lineHeight: 1.3,
      color: theme.palette.error.main,
      background: theme.palette.background.level1,
      overflow: "auto"
    }
  }
}));

export default JSONPretty;
