import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import {
  INCIDENT_TYPE,
  INCIDENT_TYPE_I18n,
  INCIDENT_STATUS,
  INCIDENT_STATUS_I18n
} from "~/api/incidents/enums";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import SelectIncidentSourceType from "~/components/CommonFormControls/SelectIncidentSourceType";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface FilterProps {
  initialValues?: IncidentsApi.GetIncidentsQuery;
  onChangeFilter: (
    data: IncidentsApi.GetIncidentsQuery
  ) => void | Promise<void>;
}

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer {...props}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup
          label={t("label.dateTimeCreated")}
          collapsed
          defaultOpen={true}
        >
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.dateTimeClosed")}
          collapsed
          defaultOpen={true}
        >
          <DatePicker
            label={t("label.from")}
            name="closedFrom"
            maxDateFormikKey="closedTo"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
          <DatePicker
            label={t("label.to")}
            name="closedTo"
            minDateFormikKey="closedFrom"
            maxDate={moment().endOf("day").toDate()}
            clear
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.source")} collapsed defaultOpen={true}>
          <SelectIncidentSourceType
            name="sourceType"
            fullWidth
            formikControll
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={INCIDENT_STATUS}
            enumI18n={INCIDENT_STATUS_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.type")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="type"
            enumValue={INCIDENT_TYPE}
            enumI18n={INCIDENT_TYPE_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
