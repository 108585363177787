import React, { useState } from "react";
import clsx from "clsx";
import { Grid, Typography, Button } from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Collapse, Icon } from "../";

export interface SidebarGroupProps {
  label?: string | React.ReactNode;
  children?: any;
  collapsed?: boolean;
  className?: string;
  defaultOpen?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {},
    header: {
      position: "relative",
      margin: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
    icon: {
      fontSize: 10,
    },
    container: {
      padding: 0,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  })
);

const SidebarGroup = ({
  label,
  children,
  className,
  collapsed,
  defaultOpen,
}: SidebarGroupProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean | undefined>(defaultOpen);

  return (
    <div className={clsx(classes.root, className)}>
      {!collapsed ? (
        <div className={classes.header}>
          <Typography variant="subtitle1">{label}</Typography>
        </div>
      ) : (
        <Button
          className={classes.header}
          variant="text"
          fullWidth
          onClick={() => setOpen(!open)}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">{label}</Typography>
            {open ? (
              <Icon className={classes.icon} name="ArrowTop" />
            ) : (
              <Icon className={classes.icon} name="ArrowDown" />
            )}
          </Grid>
        </Button>
      )}
      <Collapse
        in={open}
        classes={{
          wrapperInner: classes.container,
        }}
      >
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default SidebarGroup;
