import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  AUDIT_KEY_STATUS,
  AUDIT_KEY_STATUS_I18n,
} from "~/api/auditKeyManagementJournal/enums";

interface KeyStatusProps {
  status: AuditKeyManagementJournalApi.eStatus;
}

/**
 * @memberof AuditKeyManagement
 * @component
 * @desc Render status of a Key.
 */

const KeyStatus = ({ status }: KeyStatusProps) => {
  const { t } = useTranslation(["enums"]);

  switch (status) {
    case AUDIT_KEY_STATUS.SUCCESS:
      return (
        <Box color="success.main">
          {t(AUDIT_KEY_STATUS_I18n[AUDIT_KEY_STATUS.SUCCESS])}
        </Box>
      );
    case AUDIT_KEY_STATUS.FAILED:
      return (
        <Box color="error.main">
          {t(AUDIT_KEY_STATUS_I18n[AUDIT_KEY_STATUS.FAILED])}
        </Box>
      );
    default:
      return null;
  }
};

export default KeyStatus;
