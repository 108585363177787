import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Section, Grid } from "~/components/UI";
import { useHistory } from "react-router-dom";
import Routes from "~/constants/routes";

import FinancialTransactionsByAcquirers from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByAcquirers";
import FinancialTransactionsByWallets from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByWallets";
import FinancialTransactionsByPaymentSystem from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByPaymentSystem";

import ClientPaymentDurationByAcquirers from "~/components/Metrics/ClientPaymentDuration/ClientPaymentDurationByAcquirers";
import ClientPaymentDurationByWallets from "~/components/Metrics/ClientPaymentDuration/ClientPaymentDurationByWallets";

import PSPPaymentDurationByAcquirers from "~/components/Metrics/PSPPaymentDuration/PSPPaymentDurationByAcquirers";
import PSPPaymentDurationByWallets from "~/components/Metrics/PSPPaymentDuration/PSPPaymentDurationByWallets";

import { METRIC_KEY } from "~/api/dashboardSettings/enums";

const getMetricComponent = (
  setting: DashboardSettingsApi.Metric
): (() => JSX.Element | null) => {
  switch (setting.key) {
    case METRIC_KEY.FINANCIAL_TRANSACTION_COUNTS_BY_WALLET:
      return FinancialTransactionsByWallets;
    case METRIC_KEY.FINANCIAL_TRANSACTION_COUNTS_BY_PAYMENT_SYSTEM:
      return FinancialTransactionsByPaymentSystem;
    case METRIC_KEY.FINANCIAL_TRANSACTION_COUNTS_BY_ACQUIRER:
      return FinancialTransactionsByAcquirers;
    case METRIC_KEY.CLIENT_PAYMENT_DURATION_BY_ACQUIRER:
      return ClientPaymentDurationByAcquirers;
    case METRIC_KEY.CLIENT_PAYMENT_DURATION_BY_WALLET:
      return ClientPaymentDurationByWallets;
    case METRIC_KEY.PSP_PAYMENT_DURATION_BY_WALLET:
      return PSPPaymentDurationByWallets;
    case METRIC_KEY.PSP_PAYMENT_DURATION_BY_ACQUIRER:
      return PSPPaymentDurationByAcquirers;
    default:
      return () => null;
  }
};

interface MetricsProps {
  settings?: DashboardSettingsApi.Metric[];
}

const Metrics = ({ settings }: MetricsProps) => {
  const [components, setComponents] = useState<Array<() => JSX.Element | null>>(
    []
  );
  const { t } = useTranslation();
  const history = useHistory();

  const goToMetrics = useCallback(() => {
    history.push(Routes.METRICS);
  }, [history]);

  useEffect(() => {
    if (settings) {
      const components = settings
        .filter((s) => s.enabled)
        .map((s) => getMetricComponent(s));

      setComponents(components);
    }
  }, [settings]);

  if (!settings || !settings.length) return null;

  return (
    <Section
      title={t("title.metrics")}
      extra={
        <Button variant="text" size="small" onClick={goToMetrics}>
          {t("button.showAll")}
        </Button>
      }
    >
      <Box mt={4}>
        <Grid container spacing={4} wrap="wrap">
          {components &&
            components.map((MetricComponent, index) => <MetricComponent />)}
        </Grid>
      </Box>
    </Section>
  );
};

export default Metrics;
