import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class DashboardSettingsApi {
  rootPath: string = "/dashboard-settings";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.dashboardSettings')
  }

  getSettings = (): Promise<Api.GetOne<DashboardSettingsApi.Settings>> =>
    this.instance.get(this.rootPath)

  updateSettings = (data: DashboardSettingsApi.UpdateDashboardSettings): Promise<Api.GetOne<DashboardSettingsApi.Settings>> =>
    this.instance.put(this.rootPath, data)
}
