import { useContext } from "react";
import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import IDPrefixesContext from "./IDPrefixesContext";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface OptionsProps {
  prefix: IdPrefixApi.IdPrefixDto;
}

/**
 * @memberof IdPrefixes
 * @component
 * @desc Render options for a prefix.
 * @property {IdPrefixesApi.IdPrefixDto} prefix - prefix data
 * @requires IdPrefixesContext
 */

const Options = ({ prefix }: OptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onDelete, switchActivity } = useContext(IDPrefixesContext);

  const { enabled } = prefix;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<void>) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);


  if (
    !hasAny([
      PERMISSION.READ_ID_PREFIX,
      PERMISSION.EDIT_ID_PREFIX,
      PERMISSION.DELETE_ID_PREFIX,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_ID_PREFIX),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () => switchActivity && onUpdateHandler(switchActivity(prefix))
      },
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_ID_PREFIX),
      onClick: () => onDelete && onUpdateHandler(onDelete(prefix)),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default Options;
