import { useEffect, useState } from "react";
import { LinearProgress } from "~/components/UI";
import StatusBadge from '~/components/StatusBadge'
import Table from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import api from "~/api";

const defaultColumns = [
  {
    key: "objectName",
    i18nKey: "objectName",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "objectId",
    i18nKey: "objectId",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "status",
    i18nKey: "status",
    render: StatusBadge
  }
];

interface BindiedOdjectsProps {
  keyData: KeyManagementApi.KeyDto;
}

const BindiedOdjects = ({
  keyData
}: BindiedOdjectsProps) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] =
    useState<KeyManagementApi.BindiedOdjectDto[]>();
  const { t } = useTranslation();

  const getBindiedOdjects= async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await api.keyManagement.getBindiedOdjects(keyData.keyId);
      setDataSource(data);
    } finally {
      setLoading(false);
    }
  };


  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);

    return column;
  });

  useEffect(() => {
    getBindiedOdjects();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Table
        size="small"
        columns={columns}
        dataSource={dataSource}
        stickyHeader
      />
    </>
  );
};

export default BindiedOdjects;
