import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Section,
  LinearProgress,
  IconButton,
  Icon,
  OutlinedInput,
  Switch,
} from "~/components/UI";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import AddAcquirerEndpoint from "./AddAcquirerEndpoint";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import { FieldArray, useField } from "formik";
import { useTranslation } from "react-i18next";
import { CONNECTOR_TYPE_I18n } from "~/api/acquirers/enums";
import api from "~/api";

interface EndpointProps {
  index: number;
  endpoint: AcquirersApi.EndpointDto;
  onDelete: (index: number) => void;
}

const Endpoint = ({ index, endpoint, onDelete }: EndpointProps) => {
  const { t } = useTranslation(["translation", "enums"]);

  return (
    <Section
      title={`#Endpoint ${index + 1}`}
      collapsed
      extra={
        <Box display="inline">
          <ConfirmDialog placement="top" onConfirm={() => onDelete(index)}>
            <IconButton>
              <Icon name="Delete" />
            </IconButton>
          </ConfirmDialog>
        </Box>
      }
    >
      <Divider />
      <Box py={2}>
        {endpoint.endpointId && (
          <FormControlLabel label="ID">{endpoint.endpointId}</FormControlLabel>
        )}
        <FormControlLabel label={t("label.enabled")}>
          <Switch name={`endpoints[${index}].enabled`} formikControll />
        </FormControlLabel>
        <FormControlLabel label="URL">
          <OutlinedInput name={`endpoints[${index}].url`} formikControll />
        </FormControlLabel>
        <FormControlLabel  label="requestTimeout">
          <OutlinedInput type="number" name={`endpoints[${index}].requestTimeout`} formikControll />
        </FormControlLabel>
        <FormControlLabel label={t("label.connectorType")}>
          <SelectValue
              name={`endpoints[${index}].connectorType`}
              loadOptions={() =>
                api.acquirers
                  .getAcquirerEndpointTypes()
                  .then(({ data }) =>
                    [...data.data].sort().map((o) => ({
                      label: t(`enums:${CONNECTOR_TYPE_I18n[o]}`),
                      value: o
                    }))
                  )
              }
              formikControll
              fullWidth
            />
        </FormControlLabel>
      </Box>
    </Section>
  );
};

interface AcquirerEndpointsProps {
  acquirerId?: string;
}

const getEndpointsFormData = (data: AcquirersApi.EndpointDto[]) =>
  data
    ? data.map(({ endpointId, url, enabled, connectorType, requestTimeout }) => ({
        endpointId,
        url,
        requestTimeout,
        enabled,
        connectorType,
      }))
    : [];

const AcquirerEndpoints = ({ acquirerId }: AcquirerEndpointsProps) => {
  const [loading, setLoading] = useState(false);
  const [field, , helpers] = useField<AcquirersApi.EndpointDto[]>("endpoints");
  const { t } = useTranslation();

  const getDetails = useCallback(async (acquirerId: string) => {
    try {
      setLoading(true);
      const { data } = await api.acquirers.getAcquirerEndpoints(
        acquirerId,
        100,
        1
      );
      helpers.setValue(getEndpointsFormData(data.data));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (acquirerId) {
      getDetails(acquirerId);
    }
  }, [acquirerId]);

  return (
    <Section title={t("title.acquirerEndpoints")}>
      <Box pt={3}>
        <FieldArray
          name="endpoints"
          render={(arrayHelpers) => (
            <>
              <Divider />
              <AddAcquirerEndpoint onAddEndpoint={arrayHelpers.push} />
              <LinearProgress hidden={!loading} />
              {Array.isArray(field.value)
                ? field.value.map((e, i) => (
                    <Endpoint
                      key={i}
                      index={i}
                      endpoint={e}
                      onDelete={arrayHelpers.remove}
                    />
                  ))
                : null}
            </>
          )}
        />
      </Box>
    </Section>
  );
};

export default AcquirerEndpoints;
