import { useCallback } from "react";
import { useSelector } from "react-redux";

function usePermissions() {
  const user = useSelector((store: ReduxStore.State) => store.auth.user);

  const hasAll = useCallback(
    (
      permissions:
        | PermissionsApi.eBasePermissions
        | PermissionsApi.eBasePermissions[]
    ): boolean => {
      if (!user) return false;

      if (!user.permissions || !user.permissions.length) return false;

      if (typeof permissions === "string") {
        return user.permissions.includes(permissions);
      }

      const includedPermissions: string[] = [];

      for (const permission of permissions) {
        if (user.permissions.includes(permission)) {
          includedPermissions.push(permission);
        }
      }

      return includedPermissions.length === user.permissions.length;
    },
    [user]
  );

  const hasAny = useCallback(
    (
      permissions:
        | PermissionsApi.eBasePermissions
        | PermissionsApi.eBasePermissions[]
    ): boolean => {
      if (!user) return false;

      if (!user.permissions || !user.permissions.length) return false;

      if (typeof permissions === "string") {
        return user.permissions.includes(permissions);
      }

      for (const permission of permissions) {
        if (user.permissions.includes(permission)) {
          return true;
        }
      }

      return false;
    },
    [user]
  );

  return { hasAll, hasAny };
}

export default usePermissions;
