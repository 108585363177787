import { useContext, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import ContractOptions from "./ContractOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import ContractsContext from "./contractsContext";
import ContractDetails from "./ContractDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "contractId",
    title: "ID",
    copy: true,
    sort: true,
    keyForSort: "id",
    cellOptions: {
      width: 120,
    }
  },
  {
    key: "contractName",
    sort: true,
    i18nKey: "contractName",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "contractNumber",
    sort: true,
    i18nKey: "contractNumber",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "merchantCount",
    i18nKey: "merchants",
    cellOptions: {
      width: 50
    },
    render: (merchantCount: string) => merchantCount || "0"
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    sort: true,
    cellOptions: {
      width: 50
    }
  },
  {
    key: "description",
    i18nKey: "description"
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

const ContractsTable = (
  props: Omit<TableProps<ContractsApi.ContractDto>, "columns">,
  ref: any
) => {
  const { onUpdate } = useContext(ContractsContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, contract: ContractsApi.ContractDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_CONTRACT)}
              onConfirm={() =>
                onUpdate && onUpdate(contract.contractId, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_CONTRACT)}
              />
            </ConfirmDialog>
          )
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, contract: ContractsApi.ContractDto) => (
            <ContractOptions contract={contract} />
          )
        };
      default:
        return column;
    }
  });

  return (
    <Table
      ref={ref}
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (contract) => <ContractDetails contract={contract} />
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<TableProps<ContractsApi.ContractDto>, "columns">
>(ContractsTable);
