import { enumToI18nKey } from '~/utils/helpers';

export const AUDIT_KEY_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed'
} as { [key: string]: AuditKeyManagementJournalApi.eStatus }

export const AUDIT_KEY_STATUS_I18n = enumToI18nKey(AUDIT_KEY_STATUS,"AUDIT_KEY_STATUS");

export const AUDIT_KEY_ACTION_TYPE = {
  READ_AUDIT: 'Read Audit',
  READ_KEY_MANAGEMENT: 'Read Key Management',
  // READ: 'Read',
  CREATE: 'Create',
  // UPDATE: 'Update',
  DELETE: 'Delete'
} as { [key: string]: AuditKeyManagementJournalApi.eActionType }

export const AUDIT_KEY_ACTION_TYPE_I18n = enumToI18nKey(AUDIT_KEY_ACTION_TYPE,"AUDIT_KEY_ACTION_TYPE");