import { useContext, useState, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps } from "~/components/UI/Table/Table";
import Options from "./Options";
import IDPrefixesContext from "./IDPrefixesContext";
import { formatDateWithTime } from "~/utils/format";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "entity",
    sort: true,
    i18nKey: "entityName",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "prefix",
    sort: true,
    i18nKey: "prefix",
    cellOptions: {
      width: 50,
    },
  },
  {
    key: "index",
    sort: true,
    i18nKey: "lastUsedNumber",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "enabled",
    sort: true,
    i18nKey: "enabled",
    cellOptions: {
      width: 100,
    },
  },
  {
    key: "updatedAt",
    sort: true,
    i18nKey: "dateUpdate",
    render: formatDateWithTime,
    cellOptions: {
      width: 100,
    },
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

/**
 * @memberof IDPrefixes
 * @component
 * @desc Render Prefixes table.
 * @property {IdPrefixApi.IdPrefixDto[]} dataSource - Prefixes data
 * @requires IDPrefixesContext
 */

const IDPrefixesTable = forwardRef((props: Omit<TableProps<IdPrefixApi.IdPrefixDto>, "columns" | "rowSelection">, ref) => {
  const { onSelect, onDelete, onSort, switchActivity } = useContext(IDPrefixesContext);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_ID_PREFIX)
      ? {
          onChange: onSelect,
        }
      : undefined
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (
            enabled: boolean,
            prefix: IdPrefixApi.IdPrefixDto
          ) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_ID_PREFIX)}
              onConfirm={() => switchActivity && switchActivity(prefix)}
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_ID_PREFIX)}
              />
            </ConfirmDialog>
          ),
        };
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            prefix: IdPrefixApi.IdPrefixDto
          ) => <Options {...{ prefix, onDelete }} />,
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      ref={ref}
      onRequestSort={onSort}
      columns={columns}
      stickyHeader
      rowKey="id"
      rowSelection={rowSelection}
    />
  );
});

export default IDPrefixesTable;
