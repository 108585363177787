import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  OutlinedInput,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";

interface CloseIncidentProps extends Omit<DialogProps, "onClose" | "role"> {
  incident?: IncidentsApi.IncidentDto;
  onClose?: (incident?: IncidentsApi.IncidentDto) => void;
}

interface FromData extends IncidentsApi.CloseIncident {}

const defaultValues = {
  reason: "",
};

/**
 * @memberof Incidents
 * @component
 * @desc Closing an Incident.
 * @property {IncidentsApi.IncidentDto} incident - Incident data;
 * @property {Function} onClose - (incident?: IncidentsApi.IncidentDto) => void;
 */

const CloseIncidentDialog = ({
  incident,
  open,
  onClose,
}: CloseIncidentProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!incident) return;
      setLoading(true);
      try {
        const {
          data: { data },
        } = await api.incidents.closeIncident(incident.incidentId, formData);
        toast.success(t("text.recordSuccessfullyUpdated"));
        onClose && onClose(data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t, incident]
  );

  const validationSchema = yup.object().shape({
    reason: yup.string().required(),
  });

  useEffect(() => {
    setInitialValues(defaultValues);
  }, [open]);

  return (
    <Dialog
      open={open}
      title={`${t("title.closingTheIncident")} (ID: ${
        incident ? incident.incidentId : ""
      })`}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit }) => (
            <>
              <DialogContent>
                <Typography>{t("text.closingAnIncidentReason")}</Typography>
                <Box pt={4} maxWidth={400}>
                  <OutlinedInput
                    name="reason"
                    multiline
                    rows={8}
                    formikControll
                    fullWidth
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.closeIncident")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default CloseIncidentDialog;
