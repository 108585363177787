import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import InboundVirtualTerminalsContext from "../InboundVirtualsTerminalsContext";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface TerminalOptionsProps {
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
  onDelete: () => Promise<unknown>;
}

const TerminalOptions = ({
  inboundVirtualTerminal,
  onDelete,
}: TerminalOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit } = useContext(InboundVirtualTerminalsContext);

  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  if (
    !hasAny([
      PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL,
      PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL),
      onClick: () => onEdit && onEdit(inboundVirtualTerminal),
    },
    {
      label: t("option.delete"),
      hidden: true && !hasAll(PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL),
      confirmation: {
        ancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () => onDelete && onUpdateHandler(onDelete()),
      },
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default TerminalOptions;
