import { useState, useEffect } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { useTheme, makeStyles, createStyles } from "@material-ui/styles";

export interface Props {
  title?: string;
  value?: number;
  maxValue?: number;
  color?: "primary" | "secondary" | "warning" | "info" | "success" | "error";
  className?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    conatiner: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 100,
      minHeight: 100,
      width: 100,
      height: 100,
      "& > svg": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        transform: "rotate(-90deg)",
      },
    },
    level: {
      fontSize: 18,
    },
  })
);

const R = 47;
const C = Math.PI * (R * 2);

const Container = ({
  title,
  className,
  value = 0,
  maxValue = 100,
  color,
}: Props) => {
  const theme: CustomTheme.RootObject = useTheme();
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [endCoordinates, setEndCoordinates] = useState({
    x: 25,
    y: 50,
  });
  const classes = useStyles();

  useEffect(() => {
    let val = (value / maxValue) * 100;
    let ang = (value / maxValue) * 360 - 90;

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }
    setStrokeDashoffset((val / 100) * C);
    setEndCoordinates({
      x: R * -Math.sin((ang * Math.PI) / 180) + 50,
      y: R * Math.cos((ang * Math.PI) / 180) + 50,
    });
  }, [value, maxValue]);

  return (
    <div className={clsx(classes.conatiner, className)}>
      <div>
        <Typography
          className={classes.level}
          variant="body1"
          color="textSecondary"
        >
          {Math.round((value / maxValue) * 100)}%
        </Typography>
      </div>
      <div>
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
      </div>
      <svg
        width="100%"
        viewBox="-10 -10 120 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50"
          cy="50"
          r={R}
          style={{
            strokeDashoffset: 0,
            strokeDasharray: 0,
            strokeWidth: 5,
            stroke: color
              ? theme.palette[color].light
              : theme.palette.gray["50"],
            fill: "transparent",
          }}
        ></circle>
        <circle
          cx="50"
          cy="50"
          r={R}
          style={{
            strokeDashoffset: 310 - strokeDashoffset,
            strokeDasharray: 310,
            strokeWidth: 5,
            stroke: color
              ? theme.palette[color].main
              : theme.palette.gray["500"],
            fill: "transparent",
            strokeLinecap: "round",
          }}
        ></circle>
        <circle
          cx={endCoordinates.x}
          cy={endCoordinates.y}
          r="5"
          style={{
            strokeDashoffset: 0,
            strokeDasharray: 0,
            strokeWidth: 5,
            stroke: color
              ? theme.palette[color].main
              : theme.palette.gray["50"],
            fill: theme.palette.background.level1,
          }}
        ></circle>
      </svg>
    </div>
  );
};

export default Container;
