import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Section,
  LinearProgress,
  IconButton,
  Icon,
  FieldError
} from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import AddOutboundTerminal from "./AddOutboundTerminal";
import SelectOutboundVT from "~/components/CommonFormControls/SelectOutboundVT";
import { FieldArray, useField } from "formik";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface OutboundTerminalProps {
  index: number;
  name: string;
  terminal: InboundVirtualTerminalsApi.OutboundTerminalsDto;
  onDelete: (index: number) => void;
}

const OutboundTerminal = ({
  index,
  name,
  terminal,
  onDelete
}: OutboundTerminalProps) => {
  const [, , helpers] =
    useField<InboundVirtualTerminalsApi.OutboundTerminalsDto[]>(name + '.accountNumber');
  const { t } = useTranslation();
  return (
    <Section
      title={`ID: ${terminal.outboundTerminalId}`}
      collapsed
      extra={
        <Box display="inline">
          <ConfirmDialog placement="top" onConfirm={() => onDelete(index)}>
            <IconButton>
              <Icon name="Delete" />
            </IconButton>
          </ConfirmDialog>
        </Box>
      }
    >
      <Divider />
      <Box py={2}>
        <FormControlLabel label={t("label.outboundTerminalID")}>
          <SelectOutboundVT
            name={`${name}.outboundTerminalId`}
            onChange={(o: any) => helpers.setValue(o?.accountNumber)}
          />
        </FormControlLabel>
        <FormControlLabel label={t("label.accountNumber")}>
          <Box pt={1}>
          {terminal?.accountNumber}
          </Box>
        </FormControlLabel>
      </Box>
    </Section>
  );
};

interface OutboundTerminalBindingsProps {
  terminalId?: string;
  error?: string;
}

const getOutboundTerminalsFormData = (
  data: InboundVirtualTerminalsApi.InboundTerminalBindingDto[]
) =>
  data
    ? data.map(
        ({ outboundTerminalId, accountNumber, priority }) =>
          ({
            outboundTerminalId,
            accountNumber,
            priority
          } as InboundVirtualTerminalsApi.OutboundTerminalsDto)
      )
    : [];

const OutboundTerminalsBinding = ({
  terminalId,
  error
}: OutboundTerminalBindingsProps) => {
  const [loading, setLoading] = useState(false);
  const [field, , helpers] =
    useField<InboundVirtualTerminalsApi.OutboundTerminalsDto[]>(
      "outboundTerminals"
    );
  const { t } = useTranslation();

  const getDetails = useCallback(async (terminalId: string) => {
    try {
      setLoading(true);
      const { data } = await api.inboundVirtualTerminals.getTerminalBindings(
        terminalId,
        100,
        1
      );
      helpers.setValue(getOutboundTerminalsFormData(data.data));
    } finally {
      setLoading(false);
    }
  }, []);

  const filterOptions = useCallback(
    (options: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[]) =>
      options.filter(
        (o) =>
          !field.value ||
          !field.value.find((t) => t.outboundTerminalId === o.id)
      ),
    [field.value]
  );

  useEffect(() => {
    if (terminalId) {
      getDetails(terminalId);
    }
  }, [terminalId]);

  return (
    <Section title={t("title.outboundTerminalBindings")}>
      <Box pt={3}>
        <FieldArray
          name="outboundTerminals"
          render={(arrayHelpers) => (
            <>
              <Divider />
              <AddOutboundTerminal
                filter={filterOptions}
                onAddTerminal={arrayHelpers.push}
              />
              <Box ml={30}>
                <FieldError>{error}</FieldError>
              </Box>
              <LinearProgress hidden={!loading} />
              {Array.isArray(field.value)
                ? field.value.map((t, i) => (
                    <OutboundTerminal
                      key={i}
                      index={i}
                      name={`outboundTerminals[${i}]`}
                      terminal={t}
                      onDelete={arrayHelpers.remove}
                    />
                  ))
                : null}
            </>
          )}
        />
      </Box>
    </Section>
  );
};

export default OutboundTerminalsBinding;
