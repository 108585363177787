import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import RolesManagement, {
  Role,
  getActivatedRoles,
} from "~/components/RolesManagement";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";

interface UpdateRoleProps extends Omit<DialogProps, "onClose" | "role"> {
  user?: UsersApi.UserDto;
  onClose?: (result?: boolean) => void;
}

interface FromData extends UsersApi.UpdateUserRoles {}

const defaultValues = {
  roles: [],
};

/**
 * @memberof Users
 * @component
 * @desc Edit Roles of User Dialog.
 * @property {UsersApi.UserDto} user - User data;
 * @property {Function} onClose - (result?: boolean) => void;
 */

const RolesManagementDialog = ({ user, open, onClose }: UpdateRoleProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [roles, setRoles] = useState<Role[]>();
  const [loading, setLoading] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      if (!user) return;
      setLoading(true);
      try {
        await api.users.updateUserRoles(user.userId, formData);
        toast.success("New configuration has been saved!");
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, user]
  );

  const validationSchema = yup.object().shape({});

  const getUserDetails = useCallback(async (userId: string) => {
    setLoadingRoles(true);
    try {
      const {
        data: { data: roles },
      } = await api.users.getUserRoles(userId);
      setRoles(roles);
      setInitialValues({ roles: getActivatedRoles(roles) });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingRoles(false);
    }
  }, []);

  useEffect(() => {
    setRoles([]);
    if (user) {
      getUserDetails(user.userId);
    } else {
      setInitialValues({ roles: [] });
    }
  }, [user]);

  return (
    <Dialog
      open={open}
      title={`${t("title.rolesManagementForUser")}: ${user && user.username}`}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Box mx={4}>
          <LinearProgress hidden={!loadingRoles} />
        </Box>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <DialogContent>
                {roles && (
                  <RolesManagement
                    roles={roles}
                    onChange={(roles) => {
                      setFieldValue("roles", roles);
                    }}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.save")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default RolesManagementDialog;
