import { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Divider,
  Section,
  Typography,
  LinearProgress,
  Pagination,
  Button,
  Grid
} from "~/components/UI";
import Table from "./Table";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

/**
 * @memberof FinancialTransactions
 * @component
 * @desc FinancialTransactions Controller.
 */

const FinancialTransactions = () => {
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState<number>();
  const [query, setQuery] =
    useState<FinancialTransactionsApi.GetFinancialTransactionsQuery>({
      sort: "-createdAt"
    });
  const [dataSource, setDataSource] =
    useState<FinancialTransactionsApi.FinancialTransactionDto[]>();
  const pagination = useRef<Api.PaginationSchema>();
  const { t } = useTranslation();

  const getFinancialTransactions = useCallback(
    async (page?: number) => {
      setLoading(true);
      const currentPage = pagination.current ? pagination.current.page : 1;
      try {
        const {
          data: { data, meta }
        } = await api.financialTransactions.getFinancialTransactions(
          limit,
          page || currentPage,
          { ...query, sort: query.sort || "-createdAt" }
        );
        setDataSource(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [query, pagination, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onUpdateItem = useCallback(
    (transaction: FinancialTransactionsApi.FinancialTransactionDto) => {
      setDataSource((transactions) =>
        transactions ? updateArrayItem(transactions, transaction, "id") : []
      );
    },
    []
  );

  const onChangePagination = useCallback(
    (page: number) => {
      getFinancialTransactions(page);
    },
    [getFinancialTransactions]
  );

  const onChangeFilters = useCallback((filters) => {
    pagination.current = undefined;
    setQuery(filters);
  }, []);

  useEffect(() => {
    query && getFinancialTransactions();
  }, [query, getFinancialTransactions]);

  return (
    <>
      <Section
        title={
          <Typography variant="h4">
            {t("title.financialTransactions")}{" "}
            <Typography variant="h4" component="span" color="textSecondary">
              {pagination.current && pagination.current.totalCount}
            </Typography>
          </Typography>
        }
        extra={<Grid container>
          <Button
            color="default"
            variant="contained"
            style={{marginRight: "10px"}}
            onClick={() => {
              query && getFinancialTransactions();
            }}
          >
            {t("button.refresh")}
          </Button>
          <Filter onChangeFilter={onChangeFilters} />
        </Grid>}
      />
      <LinearProgress hidden={!loading} />
      <Box flexGrow={1} overflow="auto">
        <Table
          dataSource={dataSource}
          onRequestSort={onSort}
          onUpdateItem={onUpdateItem}
          onRefresh={getFinancialTransactions}
        />
      </Box>
      <Box>
        <Divider />
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default FinancialTransactions;
