import { useState, useCallback } from "react";
import {
  Box,
  Grid,
  Label,
  Avatar,
  Section,
  Divider,
  Typography,
  Tag,
  Link,
} from "~/components/UI";
import CollapsibleTagsGroup from "~/components/CollapsibleTagsGroup";
import PermissionsDialog from "./PermissionsDialog";
import { phoneNumber } from "~/utils/format";
import { getContrastYIQ } from "~/utils/helpers";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface ProfileDetailsProps {
  profile?: ProfileApi.MyProfileDto;
}

/**
 * @memberof MyProfile
 * @component
 * @desc Render the user's personal information.
 * @property {ProfileApi.MyProfileDto} profile - User data
 */

const ProfileDetails = ({ profile }: ProfileDetailsProps) => {
  const [showPermissions, setShowPermissions] = useState(false);
  const [role, setRole] = useState<UsersApi.UserRoleDto>();
  const { user } = useSelector((store: ReduxStore.State) => store.auth);
  const groupsObj =
    profile && profile.groups ? _.groupBy(profile.groups, "groupId") : {};
  const { t } = useTranslation();

  const onSelectRole = useCallback((role: UsersApi.UserRoleDto) => {
    setRole(role);
    setShowPermissions(true);
  }, []);

  const onShowAllPermissions = useCallback(() => {
    setRole(undefined);
    setShowPermissions(true);
  }, []);

  if (!profile) return null;

  return (
    <>
      <Section title={t("title.personalProfile")}>
        <Divider />
        <Box maxWidth={500}>
          <Box my={4}>
            <Box my={4}>
              <Grid container alignItems="center">
                <Grid item>
                  <Avatar src={profile.photo || ""} size="large" />
                </Grid>
                <Grid item>
                  <Box ml={4}>
                    <Typography variant="h3">{profile.name}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Label text={t("label.profileID")}>{profile.id}</Label>
            <Label text={t("label.userPrincipalName")}>
              {profile.userPrincipalName}
            </Label>
            <Label text="Email">{profile.email}</Label>
            <Label text={t("label.businessPhones")}>
              {profile.businessPhones
                ? profile.businessPhones.map((p, i) => (
                    <Typography key={i} variant="body1">
                      {phoneNumber(p)}
                    </Typography>
                  ))
                : null}
            </Label>
            <Label text={t("label.phone")}>{profile.phone}</Label>
          </Box>
          <Divider />
          <Box my={4}>
            <Label text={t("label.position")}>{profile.position}</Label>
            <Label text={t("title.securityGroups")} verticalAlign="flex-start">
              <Box width={350}>
                <CollapsibleTagsGroup
                  displayByDefault={4}
                  showAllBtnText={t("button.showAll")}
                >
                  {profile.groups &&
                    profile.groups.map((g, i) => (
                      <Tag
                        key={`${i}-group`}
                        bgcolor={g.color}
                        color={g.color ? getContrastYIQ(g.color) : "inherit"}
                      >
                        {g.name}
                      </Tag>
                    ))}
                </CollapsibleTagsGroup>
              </Box>
            </Label>
            <Label text={t("label.roles")} verticalAlign="flex-start">
              <Box maxWidth={350}>
                {profile.roles ? (
                  <CollapsibleTagsGroup
                    displayByDefault={4}
                    showAllBtnText={t("button.showAll")}
                  >
                    {profile.roles &&
                      profile.roles.map((r, i) => {
                        const color =
                          groupsObj[r.groupId] && groupsObj[r.groupId][0].color;
                        return (
                          <Tag
                            key={`${i}-role`}
                            bgcolor={color}
                            color={color ? getContrastYIQ(color) : "inherit"}
                            onClick={() => onSelectRole(r)}
                          >
                            {r.name}
                          </Tag>
                        );
                      })}
                  </CollapsibleTagsGroup>
                ) : null}
              </Box>
            </Label>
            <Label>
              <Link
                underline="always"
                color="textPrimary"
                onClick={() => onShowAllPermissions()}
              >
                {t("button.myPermissions")}
              </Link>
            </Label>
          </Box>
        </Box>
      </Section>
      <PermissionsDialog
        open={showPermissions}
        allPermissions={user ? user.permissions : []}
        role={role}
        roles={profile.roles}
        groups={profile.groups}
        onClose={() => setShowPermissions(false)}
      />
    </>
  );
};

export default ProfileDetails;
