import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import BinRange from "./Components/BinRange";
import { useTranslation } from "react-i18next";

/**
 * @namespace BinRange
 */

const BinRangePage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.binRange") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <BinRange />
    </>
  );
};

export default BinRangePage;
