import Routes from "~/constants/routes";
import CommonTile from "./CommonTile";
import Table from "~/pages/Journals/Incidents/Components/Table";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import { INCIDENT_STATUS } from '~/api/incidents/enums';
import usePermissions from "~/hook/usePermissions";
import api from "~/api";

const IncidentsWidget = () => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  if (!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)) return null;

  const getData = () =>
    api.incidents
      .getIncidents(5, 1, { sort: "-createdAt", status: INCIDENT_STATUS.OPENED })
      .then((res) => res.data.data);

  return (
    <CommonTile
      title={t("title.incidents")}
      showAllText={t("button.showAll")}
      onUpdate={getData}
      showAllLink={Routes.INCIDENTS}
    >
      {(data) => (
        <Table
          dataSource={data || []}
          hideSorting={true}
          hideColumns={["options"]}
        />
      )}
    </CommonTile>
  );
};

export default IncidentsWidget;
