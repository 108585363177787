import { createContext } from 'react';

export interface  OutboundVirtualsTerminalsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (outboundVirtualsTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => void;
  onEdit?: (outboundVirtualsTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => void;
  onUpdate?: (id: string, formData:  OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal) => Promise<void>;
  onDelete?: (outboundVirtualsTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => Promise<void>;
}

/**
 * @memberof OutboundVirtualsTerminals
 * @component
 * @desc React context for providing methods of OutboundVirtualsTerminal to child components.
 * @property {Function} onEdit - callback for open the Edit OutboundVirtualsTerminal dialog.  (outboundVirtualsTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => void;
 * @property {Function} onUpdate - callback for update a OutboundVirtualsTerminal. (id: string, formData:  OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a OutboundVirtualsTerminal. (outboundVirtualsTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => Promise<void>;
 */

const OutboundVirtualsTerminalsContext = createContext<OutboundVirtualsTerminalsContextProps>({});


export default OutboundVirtualsTerminalsContext;