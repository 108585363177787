import FinancialTransactionCountsCard from "./FinancialTransactionCountsCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const FinancialTransactionsByAcquirers = () => {
  const { t } = useTranslation();
  const getBusinessTransactionsMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } = await api.metrics.getTransactionCountByAcquirers(params);
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FinancialTransactionCountsCard
      title={`${t("title.financialTransactionCountsBy")} ${t(
        "title.metricOfAcquirers"
      )}`}
      onUpdate={getBusinessTransactionsMetrics}
    />
  );
};

export default FinancialTransactionsByAcquirers;
