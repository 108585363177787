import MUIDialogContent from "@material-ui/core/DialogContent";

import { withStyles, createStyles } from "@material-ui/core/styles";

const DialogContent = withStyles((theme: CustomTheme.RootObject) => 
  createStyles({
    root: {
      padding: theme.spacing(4),
    }
  })
)(MUIDialogContent)

export default DialogContent;