import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Icon,
  Grid,
  ButtonGroup,
  IconButton,
} from "~/components/UI";
import Search from "~/components/Search";
import { styled } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Members from "./Members";
import api from "~/api";

interface GroupDetailsProps {
  group: GroupsApi.GroupDto;
}

const Container = styled("div")(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof Groups
 * @component
 * @desc Render detailed information about a group.
 * @property {GroupsApi.GroupDto} group - Group data;
 */

const GroupDetails = ({ group }: GroupDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [skipTokens, setSkipTokens] = useState<string[]>([]);
  const [members, setMembers] = useState<GroupsApi.GroupMemberDto[]>([]);
  const { t } = useTranslation();

  const getMembers = useCallback(
    async (index: number, search?: string) => {
      setLoading(true);
      try {
        const {
          data: {
            data,
            meta: { pagination },
          },
        } = await api.groups.getGroupMembers(group.groupId, {
          limit: 20,
          search,
          skipToken: skipTokens[index],
        });

        if (pagination && pagination.skipToken && index === skipTokens.length) {
          setSkipTokens([...skipTokens, pagination.skipToken]);
        }

        setMembers(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [skipTokens, group]
  );

  const onSearch = useCallback(
    (search: string) => {
      getMembers(index, search);
    },
    [index]
  );

  const onBack = useCallback(() => {
    if (index > 0) {
      getMembers(index);
      setIndex(index - 1);
    }
  }, [index]);

  const onNext = useCallback(() => {
    getMembers(index);
    setIndex(index + 1);
  }, [index]);

  useEffect(() => {
    getMembers(index);
  }, [group]);

  return (
    <Container>
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">{t("title.members")}</Typography>
      </Box>
      <LinearProgress hidden={!loading} />
      <Box my={4} pl={4}>
        <Search placeholder={t("text.searchByMembers")} onSearch={onSearch} />
        <Members dataSource={members} />
        <Grid container justifyContent="center">
          <ButtonGroup>
            <IconButton disabled={!index} onClick={onBack}>
              <Icon name="ArrowLeft" size="small" />
            </IconButton>
            <IconButton disabled={!skipTokens[index]} onClick={onNext}>
              <Icon name="ArrowRight" size="small" />
            </IconButton>
          </ButtonGroup>
        </Grid>
      </Box>
    </Container>
  );
};

export default GroupDetails;
