import { forwardRef } from "react";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import BinRangeOptions from "./BinRangeOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";

const defaultColumns = [
  {
    key: "id",
    title: "ID",
    sort: true,
  },
  {
    key: "deviceBin",
    i18nKey: "deviceBin",
    cellOptions: {
      align: "center",
    },
  },
  {
    key: "cardProgramName",
    title: "Card Program Name",
    cellOptions: {
      align: "center",
    },
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

const BinRangeTable = (
  props: Omit<TableProps<BinRangesApi.BinRangeDto>, "columns">,
  ref: any
) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (_value: unknown, binRange: BinRangesApi.BinRangeDto) => (
            <BinRangeOptions binRange={binRange} />
          ),
        };
      default:
        return column;
    }
  });

  return (
    <Table
      {...props}
      ref={ref}
      columns={columns}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<TableProps<BinRangesApi.BinRangeDto>, "columns">
>(BinRangeTable);
