import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateOrUpdateMerchantDialog from "./CreateOrUpdateMerchantDialog";
import MerchantsContext from "./merchantsContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryParams, StringParam } from "use-query-params";
import usePermissions from "~/hook/usePermissions";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const Merchants = () => {
  const [editMerchant, setEditMerchant] = useState<MerchantsApi.MerchantDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([]);
  const [merchants, setMerchants] = useState<MerchantsApi.MerchantDto[]>();
  const [query, setQuery] = useState<MerchantsApi.GetMerchantsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [queryParams] = useQueryParams({
    search: StringParam,
    id: StringParam
  });
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_MERCHANT)
      ? {
          hideSelectAll: false,
          onChange: setSelectedMerchants
        }
      : undefined
  );

  const getMerchants = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.merchants.getAll(20, page || currentPage, query);
        data && setMerchants(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateMerchant = () => {
    setEditMerchant(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (merchant?: MerchantsApi.MerchantDto) => {
    setShowEditDialog(false);
    setEditMerchant(undefined);
    merchant && getMerchants();
  };

  const onUpdateItem = useCallback((merchant: MerchantsApi.MerchantDto) => {
    setMerchants((merchants) =>
      merchants ? updateArrayItem(merchants, merchant, "id") : []
    );
  }, []);

  const onEdit = useCallback((merchant: MerchantsApi.MerchantDto) => {
    setEditMerchant(merchant);
    setShowEditDialog(true);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: MerchantsApi.UpdateMerchant) => {
      try {
        const {
          data: { data: merchant }
        } = await api.merchants.update(id, formData);
        onUpdateItem(merchant);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (merchant: MerchantsApi.MerchantDto) => {
      await deleteConfirm(merchant?.merchantName);
      try {
        await api.merchants.delete(merchant.id);
        getMerchants();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getMerchants, deleteConfirm]
  );

  const onDeleteMerchants = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedMerchants?.length }));
    try {
      await api.merchants.deleteMerchant(selectedMerchants);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getMerchants();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedMerchants]);

  const onChangePagination = (page: number) => {
    getMerchants(page);
  };

  const onChangeFilters = (filters: MerchantsApi.GetMerchantsQuery) => {
    pagination.current = undefined;
    setMerchants([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(queryParams as MerchantsApi.GetMerchantsQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getMerchants();
  }, [query, getMerchants, limit]);

  return (
    <MerchantsContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.merchants")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_MERCHANT}>
                <Button
                  hidden={!selectedMerchants.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteMerchants}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_MERCHANT}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateMerchant}
                >
                  {t("button.addMerchant")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={merchants}
          onRequestSort={onSort}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateMerchantDialog
        open={showEditDialog}
        merchant={editMerchant}
        onClose={onCloseEditDialog}
      />
    </MerchantsContext.Provider>
  );
};

export default Merchants;
