import { enumToI18nKey } from '~/utils/helpers';

export const USER_ACTIVITY_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
} as { [key: string]: UserActivityJournalApi.eUserActivityStatus }

export const USER_ACTIVITY_STATUS_I18n = enumToI18nKey(USER_ACTIVITY_STATUS, 'USER_ACTIVITY_STATUS');

export const USER_ACTIVITY_TYPE = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  CREATE: 'Create',
  READ: "Read",
  RESTORE: 'Restore',
  UPDATE: 'Update',
  DELETE: 'Delete',
  REVERSAL: 'Void',
} as { [key: string]: UserActivityJournalApi.eUserActivityType }

export const USER_ACTIVITY_TYPE_I18n = enumToI18nKey(USER_ACTIVITY_TYPE, 'USER_ACTIVITY_TYPE');