import { Box, Input, Icon, SidebarGroup } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import { useTranslation } from "react-i18next";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import api from "~/api";

interface FilterProps {
  onChangeFilter: (data: KeyManagementApi.GetKeyQuery) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup
          label={t("label.keyPurpose")}
          collapsed
          defaultOpen={true}
        >
          <SelectValue
            name="purpose"
            loadOptions={() =>
              api.keyManagement
                .getKeyPurposes()
                .then(({ data }) =>
                  [...data.data].sort().map((o) => ({ label: o, value: o }))
                )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <IncludeFieldGroup
          label={t("title.openedIcidents")}
          fieldName="hasOpenedIncidents"
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
