import Constants from "./constants";
import { eThemeType } from '~/themes';

const { ACTIONS } = Constants;

const initialState: SettingsStore.State = {
  theme: eThemeType.LIGHT,
  dashboardSettings: undefined,
  isLoaded: false
};

const Reducer = (
  state = initialState,
  action: SettingsStore.Actions
): SettingsStore.State => {
  switch (action.type) {
    case ACTIONS.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case ACTIONS.SET_DASHBOARD_SETTINGS:
      return {
        ...state,
        dashboardSettings: action.payload,
      };
    case ACTIONS.ALL_SETTINGS_LOADED: 
      return {
        ...state,
        isLoaded: true
      }
    default:
      return state;
  }
};

export default Reducer;
