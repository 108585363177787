import MUIDialogActions from "@material-ui/core/DialogActions";
import { withStyles, createStyles } from "@material-ui/core/styles";

const DialogActions = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(4),
      padding: 0,
      margin: theme.spacing(4),
      marginTop: 0,
      borderTop: `1px solid ${theme.palette.divider}`
    },
    spacing: {
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(3)
      }
    }
  })
)(MUIDialogActions)

export default DialogActions;