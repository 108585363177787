import { forwardRef } from "react";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import MDESMerchantOptions from "./MDESMerchantOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "mdesMerchantId",
    i18nKey: "MDESMerchantID",
    copy: true,
    sort: true,
    keyForSort: "id",
    cellOptions: {
      width: 120,
    },
  },
  {
    key: "mdesMerchantName",
    sort: true,
    i18nKey: "name",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "merchantCount",
    sort: true,
    i18nKey: "merchants",
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50,
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    ),
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70,
    },
  },
];

const MDESMerchantsTable = (
  props: Omit<TableProps<MDESMerchantsApi.mdesMerchantDto>, "columns">,
  ref: any
) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            mdesMerchant: MDESMerchantsApi.mdesMerchantDto
          ) => <MDESMerchantOptions mdesMerchant={mdesMerchant} />,
        };
      default:
        return column;
    }
  });

  return <Table ref={ref} {...props} columns={columns} stickyHeader />;
};

export default forwardRef<
  TableRef,
  Omit<TableProps<MDESMerchantsApi.mdesMerchantDto>, "columns">
>(MDESMerchantsTable);
