import React, { useContext } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import {
  Box,
  SidebarItem,
  SidebarItemIcon,
  SidebarItemText,
} from "~/components/UI";
import Icon, { IconName } from "~/components/UI/Icon";
import { SidebarContext } from "./Menu";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface MenuItemProps {
  className?: string;
  active?: boolean;
  icon?: IconName;
  label?: string;
  depth?: number;
  hidden?: boolean;
  extra?: () => React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "block",
      boxSizing: "border-box",
      maxWidth: "100%",
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      margin: 0,
      fontSize: 14,
      "@media (max-width:600px)": {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      "&.active": {
        "&:after": {
          position: "absolute",
          content: '""',
          top: 0,
          bottom: 0,
          right: 0,
          borderRight: `3px solid ${theme.palette.primary.main}`,
        },
      },
    },
    subItem: {
      "&.active": {
        "&:after": {
          borderRight: "none",
        },
      },
    },
    hidden: {
      display: "none",
    },
  })
);

const MenuItem = ({
  className,
  depth = 0,
  icon,
  label,
  active,
  hidden,
  extra,
  onClick,
}: MenuItemProps) => {
  const { open } = useContext(SidebarContext);
  const classes = useStyles();

  return (
    <SidebarItem
      button
      hidden={true}
      className={clsx(classes.root, className, {
        active,
        [classes.subItem]: depth,
        [classes.hidden]: Boolean(hidden),
      })}
      onClick={(e) => {
        onClick && onClick(e);
      }}>
      <Box pl={open ? 10 * depth : 0}>
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center">
          {icon && (
            <SidebarItemIcon className={clsx({ active })}>
              <Icon name={icon} />
            </SidebarItemIcon>
          )}
          <Box flexGrow={1}>
            <SidebarItemText
              className={clsx({ active })}
              primary={label}
              disableTypography>
              {label}
            </SidebarItemText>
          </Box>
          <Grid item>{extra && extra()}</Grid>
        </Grid>
      </Box>
    </SidebarItem>
  );
};

export default React.memo(MenuItem);
