import ApplePayWallet, {
  getValidationSchema as ApplePayValidationSchema
} from "./ApplePayWallet";
import GooglePayWallet, {
  getValidationSchema as GooglePayValidationSchema
} from "./GooglePayWallet";
import MasterPassWallet, {
  getValidationSchema as MasterPassValidationSchema
} from "./MasterPassWallet";
import { WALLET_TYPE } from "~/api/wallets/enums";
import yup from "~/packages/yup";

export const getValidationRules = (walletType?: WalletsApi.eWalletType) => {
  switch (walletType) {
    case WALLET_TYPE.APPLE_PAY:
      return ApplePayValidationSchema();
    case WALLET_TYPE.GOOGLE_PAY:
      return GooglePayValidationSchema();
    case WALLET_TYPE.MASTER_PASS:
      return MasterPassValidationSchema();
    default:
      return yup.object({});
  }
};

const FieldsForWallet = ({
  walletType,
  wallet
}: {
  walletType?: WalletsApi.eWalletType;
  wallet?: WalletsApi.WalletDto;
}) => {
  switch (walletType) {
    case WALLET_TYPE.APPLE_PAY:
      return <ApplePayWallet wallet={wallet} />;
    case WALLET_TYPE.GOOGLE_PAY:
      return <GooglePayWallet wallet={wallet} />;
    case WALLET_TYPE.MASTER_PASS:
      return <MasterPassWallet wallet={wallet} />;
    default:
      return null;
  }
};

export default FieldsForWallet;
