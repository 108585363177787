import { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Box, Input, Icon, IconButton, Button } from "~/components/UI";
import { useSelector, useDispatch } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { globalSearch, clearSearchResults } from "~/store/search/actions";
import { useTranslation } from "react-i18next";
import { useQueryParam, StringParam } from "use-query-params";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";

const GlobalSearch = () => {
  const [searchValue, setSearchValue] = useState<string | null>("");
  const location = useLocation();
  const { loading } = useSelector((state: ReduxStore.State) => state.search);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchQueryParam, setSearchQueryParam] = useQueryParam(
    "search",
    StringParam
  );

  const onSearch = useCallback(() => {
    history.push(ROUTES.SEARCH_RESULTS);
    setSearchQueryParam(searchValue);
    setTimeout(() => {
      searchValue && dispatch(globalSearch(searchValue));
    }, 0);
  }, [searchValue, dispatch]);

  const onClear = useCallback(() => {
    setSearchValue("");
  }, []);

  const onKeyPress = (keyCode: number) => {
    if (keyCode === 13) {
      onSearch();
    }
  };

  useEffect(() => {
    if (
      matchPath(location.pathname, { path: ROUTES.SEARCH_RESULTS }) &&
      searchQueryParam
    ) {
      setSearchValue(searchQueryParam);
      onSearch();
    }
  }, []);

  useEffect(() => {
    if (!matchPath(location.pathname, { path: ROUTES.SEARCH_RESULTS })) {
      dispatch(clearSearchResults());
      onClear();
    }
  }, [dispatch, location, onClear]);

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Input
          placeholder={t("text.globalSearchplaceholder")}
          startAdornment={<Icon name="Lens" />}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={(e) => {
            onKeyPress(e.nativeEvent.keyCode);
          }}
          fullWidth
        />
      </Grid>
      <Box width={20}>
        <div hidden={!searchValue}>
          <IconButton size="small" onClick={onClear}>
            <Icon name="Close" />
          </IconButton>
        </div>
      </Box>
      <Box width={30}>
        <div hidden={!searchValue}>
          <Button
            variant="text"
            color="primary"
            disabled={loading}
            onClick={onSearch}
          >
            {t("button.search")}
          </Button>
        </div>
      </Box>
    </Grid>
  );
};

export default GlobalSearch;
