import io, { Socket } from 'socket.io-client';
import ENV from "~/constants";
import { CLIENT_ACTION } from './enums'; 
import { store } from "~/store";

export default class AlertsApi {
  instance: Socket = {} as Socket;

  constructor() {
    const appState: ReduxStore.State = store.getState();
    const { authData } = appState.auth;

    if (authData) {
      this.instance = io(ENV.REACT_APP_API_URL, {
        auth: {
          token: authData.accessToken
        },
        transports: ["websocket"],
        extraHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${authData.accessToken}`
        }
      });
    } else {
      console.error('authData is undefined');
    }
  }

  connect = () => this.instance.connect()

  getAlerts = () => this.instance.emit(CLIENT_ACTION.GET_ALERTS)

  handleAlerts = (cb: (data: any) => void) => {
    this.instance.on(CLIENT_ACTION.GET_ALERTS, cb);
  }

  handleNewAlert = (cb: (data: any) => void) => {
    this.instance.on(CLIENT_ACTION.NEW_ALERT, cb);
  }

  skipAlert = (alertId: string) => this.instance.emit(CLIENT_ACTION.SKIP_ALERT, { alertId })

  handleSkipAlert = (cb: (data: any) => void) => {
    this.instance.on(CLIENT_ACTION.SKIP_ALERT, cb);
  }

  disconnect = () => this.instance.disconnect()
}
