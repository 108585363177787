import { createContext } from 'react';

export interface MerchantsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (merchant: MerchantsApi.MerchantDto) => void;
  onEdit?: (merchant: MerchantsApi.MerchantDto) => void;
  onUpdate?: (id: string, formData:  MerchantsApi.UpdateMerchant) => Promise<void>;
  onDelete?: (merchant:  MerchantsApi.MerchantDto) => Promise<void>;
}

/**
 * @memberof Merchants
 * @component
 * @desc React context for providing methods of Merchants to child components.
 * @property {Function} onEdit - callback for open the Edit Merchant dialog. (merchant: MerchantsApi.MerchantDto) => void;
 * @property {Function} onUpdate - callback for update a Merchant. (id: string, formData:  MerchantsApi.UpdateMerchant) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a Merchant. (merchant:  MerchantsApi.MerchantDto) => Promise<void>;
 */

const MerchantsContext = createContext<MerchantsContextProps>({});


export default MerchantsContext;