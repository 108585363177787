import { useCallback } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface InboundVirtualTerminalsResultProps {
  data?: Api.SearchResult<InboundVirtualTerminalsApi.InboundVirtualTerminalSearchResult>;
  searchQuery: string;
}

const InboundVirtualTerminal = ({
  inboundVirtualTerminal,
  searchQuery,
}: {
  searchQuery: string;
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalSearchResult;
}) => {
  const openGroup = useCallback(() => {
    history.push(
      `${ROUTES.INBOUND_VIRTUAL_TERMINALS}?id=${inboundVirtualTerminal.id}`
    );
  }, [inboundVirtualTerminal.id]);

  return (
    <ListItem button onClick={openGroup}>
      <Typography variant="body2" color="textSecondary">
        <Typography variant="body2" component="span" color="primary">
          ID
        </Typography>
        :{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={inboundVirtualTerminal.id}
        />
      </Typography>
      <Divider />
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the InboundVirtualTerminals catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<Api.InboundVirtualTerminalSearchResult>} data - api response
 */

const InboundVirtualTerminals = ({
  data,
  searchQuery,
}: InboundVirtualTerminalsResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.INBOUND_VIRTUAL_TERMINALS}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogInboundVirtualTerminals")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((u, index) => (
            <InboundVirtualTerminal
              key={index}
              searchQuery={searchQuery}
              inboundVirtualTerminal={u}
            />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default InboundVirtualTerminals;
