import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class OutboundVirtualTerminalsApi {
  rootPath: string = "/outbound-virtual-terminals";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.outboundVirtualTerminals')
  }

  create = (data: OutboundVirtualTerminalsApi.CreateOutboundVirtualTerminal) =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: OutboundVirtualTerminalsApi.GetOutboundVirtualTerminalsQuery
  ): Promise<Api.GetAll<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto>> => {
    if (query && !query.sort) {
      query = { ...query, sort: "id" }
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    })
  };
  getTerminal = (terminalId: string) =>
    this.instance.get(`${this.rootPath}/${terminalId}`);
  getTerminalDetails = (terminalId: string): Promise<Api.GetOne<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${terminalId}/details`);
  update = (terminalId: string, data: OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal) =>
    this.instance.put(`${this.rootPath}/${terminalId}`, data);
  deleteTerminals = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (terminalId: string) =>
    this.instance.delete(`${this.rootPath}/${terminalId}`);
  getInboundTerminals = (terminalId: string, query?: OutboundVirtualTerminalsApi.GetOutboundVirtualTerminalsQuery): Promise<Api.GetAll<OutboundVirtualTerminalsApi.InboundTerminalsBindingDto>> =>
    this.instance.get(`${this.rootPath}/${terminalId}/inboundVT`, {
      params: {
        ...deleteEmptyFields(query || {}),
      }
    });
}
