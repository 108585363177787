import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateOrUpdateOutboundVirtualTerminalDialog from "./CreateOrUpdateOutboundVirtualTerminalDialog";
import OutboundVirtualTerminalsContext from "./OutboundVirtualsTerminalsContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { useQueryParams, StringParam } from "use-query-params";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const OutboundVirtualTerminals = () => {
  const [editOutboundVirtualTerminal, setEditOutboundVirtualTerminal] =
    useState<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedTerminals, setSelectedTerminals] = useState<string[]>([]);
  const [terminals, setOutboundVirtualTerminals] =
    useState<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[]>();
  const [query, setQuery] =
    useState<OutboundVirtualTerminalsApi.GetOutboundVirtualTerminalsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [queryParams] = useQueryParams({
    search: StringParam,
    id: StringParam
  });
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_OUTBOUND_VIRTUAL_TERMINAL)
      ? {
          hideSelectAll: false,
          onChange: setSelectedTerminals
        }
      : undefined
  );

  const getOutboundVirtualTerminals = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.outboundVirtualTerminals.getAll(
          limit,
          page || currentPage,
          query
        );
        data && setOutboundVirtualTerminals(data);
        pagination.current = meta.pagination;
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateOutboundVirtualTerminal = () => {
    setEditOutboundVirtualTerminal(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (
    contract?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
  ) => {
    setShowEditDialog(false);
    setEditOutboundVirtualTerminal(undefined);
    contract && getOutboundVirtualTerminals();
  };

  const onUpdateItem = useCallback(
    (contract: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => {
      setOutboundVirtualTerminals((contracts) =>
        contract ? updateArrayItem(contracts, contract, "id") : []
      );
    },
    []
  );

  const onEdit = useCallback(
    (contract: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => {
      setEditOutboundVirtualTerminal(contract);
      setShowEditDialog(true);
    },
    []
  );

  const onUpdate = useCallback(
    async (
      id: string,
      formData: OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal
    ) => {
      try {
        const {
          data: { data: contract }
        } = await api.outboundVirtualTerminals.update(id, formData);
        onUpdateItem(contract);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (
      outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
    ) => {
      await deleteConfirm(outboundVirtualTerminal?.id);
      try {
        await api.outboundVirtualTerminals.delete(outboundVirtualTerminal.id);
        getOutboundVirtualTerminals();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getOutboundVirtualTerminals, deleteConfirm]
  );

  const onDeleteTerminals = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedTerminals?.length }));
    try {
      await api.outboundVirtualTerminals.deleteTerminals(selectedTerminals);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getOutboundVirtualTerminals();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedTerminals]);

  const onChangePagination = (page: number) => {
    getOutboundVirtualTerminals(page);
  };

  const onChangeFilters = (
    filters: OutboundVirtualTerminalsApi.GetOutboundVirtualTerminalsQuery
  ) => {
    pagination.current = undefined;
    setOutboundVirtualTerminals([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(
      queryParams as OutboundVirtualTerminalsApi.GetOutboundVirtualTerminalsQuery
    );
  }, [queryParams]);

  useEffect(() => {
    query && getOutboundVirtualTerminals();
  }, [query, getOutboundVirtualTerminals, limit]);

  return (
    <OutboundVirtualTerminalsContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.outboundVirtualTerminals")}
          extra={
            <ButtonGroup>
              <CanIUse
                permissions={PERMISSION.DELETE_OUTBOUND_VIRTUAL_TERMINAL}
              >
                <Button
                  hidden={!selectedTerminals.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteTerminals}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse
                permissions={PERMISSION.CREATE_OUTBOUND_VIRTUAL_TERMINAL}
              >
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateOutboundVirtualTerminal}
                >
                  {t("button.addTerminal")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={terminals}
          onRequestSort={onSort}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateOutboundVirtualTerminalDialog
        open={showEditDialog}
        outboundVirtualTerminal={editOutboundVirtualTerminal}
        onClose={onCloseEditDialog}
      />
    </OutboundVirtualTerminalsContext.Provider>
  );
};

export default OutboundVirtualTerminals;
