import { useState, useCallback, useEffect } from "react";
import api from "~/api";
import {
  Box,
  Grid,
  Button,
  Label,
  FormControlLabel,
  OutlinedInput,
  FieldError,
} from "~/components/UI";
import { useTranslation } from "react-i18next";

interface GroupDetailsProps {
  group?: GroupsApi.AzureGroupDto;
}

const GroupDetails = ({ group }: GroupDetailsProps) => {
  const { t } = useTranslation();
  if (!group) return null;

  return (
    <>
      <Label text="ID">{group.id}</Label>
      <Label text={t("label.description")}>{group.description}</Label>
    </>
  );
};

interface GetGroupFromADProps {
  onChange?: (group: GroupsApi.AzureGroupDto) => void;
}

/**
 * @memberof Groups
 * @component
 * @desc Form for finding a group in Azure AD.
 * @property {Function} onChange - call cb function when we found a group. (group: GroupsApi.AzureGroupDto) => void;
 */

const GetGroupFromAD = ({ onChange }: GetGroupFromADProps) => {
  const [groupName, setGroupName] = useState<string>("");
  const [group, setGroup] = useState<GroupsApi.AzureGroupDto>();
  const [error, setError] = useState<string>();
  const [loadingAzureGroup, setLoadingAzureGroup] = useState(false);
  const { t } = useTranslation();

  const getGroupFromAD = useCallback(async () => {
    if (!groupName || !groupName.length) return;
    try {
      setLoadingAzureGroup(true);
      const {
        data: { data },
      } = await api.groups.getAzureGroup(groupName);
      setGroup(data);
      onChange && onChange(data);
    } catch (e: any) {
      if (e.errors) setError(e.errors[0].message);
    } finally {
      setLoadingAzureGroup(false);
    }
  }, [groupName]);

  useEffect(() => {
    if (error) setError("");
  }, [groupName]);

  return (
    <>
      <FormControlLabel label={t("label.groupName")}>
        <Grid container alignItems="flex-start">
          <Box display="inline-block">
            <OutlinedInput
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            {error && <FieldError>{error}</FieldError>}
          </Box>
          <Box display="inline" ml={4}>
            <Button
              loading={loadingAzureGroup}
              variant="contained"
              color="secondary"
              onClick={() => getGroupFromAD()}
            >
              {t("button.getFromAD")}
            </Button>
          </Box>
        </Grid>
      </FormControlLabel>
      <GroupDetails group={group} />
    </>
  );
};

export default GetGroupFromAD;
