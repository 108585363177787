import { enumToI18nKey } from '~/utils/helpers';
import { SOURCE_TYPE } from '../common';
import _ from 'lodash';

export const INCIDENT_STATUS = {
  OPENED: 'Opened',
  CLOSED: 'Closed',
} as { [key: string]: IncidentsApi.eIncidentStatus }

export const INCIDENT_STATUS_I18n = enumToI18nKey(INCIDENT_STATUS,"INCIDENT_STATUS");

export const INCIDENT_TYPE = {
  EXTERNAL: 'External',
  INTERNAL: 'Internal',
} as { [key: string]: IncidentsApi.eIncidentType }

export const INCIDENT_TYPE_I18n = enumToI18nKey(INCIDENT_TYPE, 'INCIDENT_TYPE');

export const INCIDENT_SOURCE_TYPE = _.pick(SOURCE_TYPE,
  ['USER',
  'GROUP',
  'CONTRACT',
  'MERCHANT',
  'MDES_MERCHANT',
  'ACQUIRER',
  'ACQUIRER_ENDPOINT',
  'OUTBOUND_VIRTUAL_TERMINAL',
  'INBOUND_VIRTUAL_TERMINAL',
  'TRANSACTION',
  'KEY_MANAGEMENT',
  'WALLETS',
  'BIN_RANGE']
);