import { useCallback } from "react";
import SystemSettings from "./Components/SystemSettings";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @namespace SystemSettings
 */

const SettingsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.settings") }, { label: t("title.companyProfile") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <SystemSettings />;
    </>
  )
}

export default SettingsPage;
