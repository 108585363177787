import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import SelectContract from "~/components/CommonFormControls/SelectContract";
import SelectMDESMerchant from "~/components/CommonFormControls/SelectMDESMerchant";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateMerchantDialogProps
  extends Omit<DialogProps, "onClose"> {
  merchant?: MerchantsApi.MerchantDto;
  onClose?: (merchant?: MerchantsApi.MerchantDto) => void;
}

interface FromState extends Omit<MerchantsApi.CreateMerchant, "contractId"> {
  contractId?: string | ContractsApi.ContractDto;
}

const defaultValues: FromState = {
  contractId: "",
  merchantName: "",
  mdesMerchantId: "",
  linkSuccessfulPayment: "",
  linkUnsuccessfulPayment: "",
  enabled: false,
  description: ""
};

/**
 * @memberof Merchants
 * @component
 * @desc Dialog for creating or editing a Merchant.
 * @property {MerchantsApi.MerchantDto} merchant a Merchant data
 * @property {Function} onClose - passes merchant data if the merchant was created/updated successfully. (merchant?: MerchantsApi.MerchantDto) => void;
 */

const CreateOrUpdateMerchantDialog = ({
  merchant,
  open,
  onClose
}: CreateOrUpdateMerchantDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    contractId: yup.string().required(),
    merchantName: yup.string().required(),
    edrpou: yup.string().edrpou(),
    description: yup.string()
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      let contractId;

      if (values.contractId) {
        if (typeof values.contractId === "string") {
          contractId = values.contractId;
        } else {
          contractId = values.contractId.contractId;
        }
      }

      setLoading(true);
      const formData = {
        ...values,
        contractId
      };
      try {
        if (isEditMode && merchant) {
          await api.merchants.update(
            merchant.id,
            formData as MerchantsApi.UpdateMerchant
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.merchants.create(formData as MerchantsApi.CreateMerchant);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as MerchantsApi.MerchantDto);
      } catch (e: any) {
        toast.error(e.improvments);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, merchant, onClose, t]
  );

  useEffect(() => {
    if (merchant) {
      const {
        contractId,
        merchantName,
        mdesMerchantId,
        enabled,
        edrpou,
        linkSuccessfulPayment,
        linkUnsuccessfulPayment,
        description
      } = merchant;
      setInitialValues({
        contractId,
        merchantName,
        mdesMerchantId,
        enabled,
        edrpou,
        linkSuccessfulPayment,
        linkUnsuccessfulPayment,
        description
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [merchant]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editMerchant") : t("title.addMerchant")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {merchant && (
                  <FormControlLabel label="ID">{merchant.id}</FormControlLabel>
                )}
                <FormControlLabel label={t("label.contractName")}>
                  <SelectContract
                    defaultInputValue={merchant ? merchant.contractName : ""}
                    name="contractId"
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.merchantName")} >
                  <OutlinedInput name="merchantName" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.mdesMerchantId")}>
                  <SelectMDESMerchant
                    defaultInputValue=""
                    name="mdesMerchantId"
                  />
                </FormControlLabel>
                <FormControlLabel label="EDRPOU">
                  <OutlinedInput name="edrpou" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.linkForSuccessfulPayment")}>
                  <OutlinedInput name="linkSuccessfulPayment" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.linkForUnsuccessfulPayment")}>
                  <OutlinedInput
                    name="linkUnsuccessfulPayment"
                    formikControll
                    fullWidth
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateMerchantDialog;
