const constants = {
  ACTIONS: {
    OPEN_SIDEBAR: "OPEN_ALERS_SIDEBAR",
    CLOSE_SIDEBAR: "CLOSE_ALERTS_SIDEBAR",
    ADD_ALERT: 'ADD_ALERT',
    SKIP_ALERT: 'SKIP_ALERT',
    SET_ALERTS: 'SET_ALERTS'
  } as { [key: string]: AlertsStore.eActionType },
};

export default constants;
