import usePermissions from "~/hook/usePermissions";

interface CanIUseProps {
  permissions?:
    | PermissionsApi.eBasePermissions
    | PermissionsApi.eBasePermissions[];
  children: JSX.Element;
}

const CanIUse = ({ permissions, children }: CanIUseProps) => {
  const { hasAny } = usePermissions();

  if (permissions && !hasAny(permissions)) {
    return null;
  }
  return children;
};

export default CanIUse;
