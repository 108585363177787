import i18n from "i18next";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { enumToI18nKey } from '~/utils/helpers';

export const SOURCE_TYPE = {
  USER: 'User',
  GROUP: 'Group',
  ROLE: 'Role',
  INCIDENT: 'JournalIncident',
  CONTRACT: 'Contract',
  MERCHANT: 'Merchant',
  MDES_MERCHANT: 'MdesMerchant',
  ACQUIRER: 'Acquirer',
  BIN_RANGE: 'BinRange',
  ACQUIRER_ENDPOINT: 'AcquirerEndpoint',
  OUTBOUND_VIRTUAL_TERMINAL: 'OutboundVirtualTerminal',
  INBOUND_VIRTUAL_TERMINAL: 'InboundVirtualTerminal',
  TRANSACTION: 'Transaction',
  KEY_MANAGEMENT: 'KeyManagement',
  WALLETS: 'WalletDecryptionKey',
  DASHBOARD_SETTINGS: 'DashboardSettings',
  USER_ACTIVITY: 'JournalUserActivity',
  EVENT_LOG: 'JournalEventLog',
  SYSTEM_LOG: 'JournalSystemLog',
  CARD_ON_FILE: 'TokenizedCard',
  USER_ROLE: "User Role",
} as { [key: string]: Api.eSourceType }

export const SOURCE_TYPE_I18n = enumToI18nKey(SOURCE_TYPE, "SOURCE_TYPE");

export const PRIORITY = {
  URGENT: "URGENT",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  DEFAULT: "DEFAULT"
} as { [key: string]: Api.ePriority }

export const PRIORITY_I18n = enumToI18nKey(PRIORITY, "PRIORITY");

class HttpError {
  private sourceTranslations: { [key: string]: string}

  constructor() {
    this.sourceTranslations = {};
  }

  findSourceName = (path?: string): string | undefined => {
    if (path) {
      const key = Object.keys(this.sourceTranslations).find(key => path.startsWith(key))

      if (key) {
        return this.sourceTranslations[key];
      }
    }

    return undefined;
  }

  private handle403 = (response: AxiosResponse<Api.ErrorResponse>) => {
    let message = ''
    const sourceNameTKey = this.findSourceName(response.config.url)

    if (sourceNameTKey) {
      message = `${i18n.t(sourceNameTKey)}: `
    }

    switch(response.config.method) {
      case 'get':
        message += i18n.t("httpErrors.get403")
        break;
      case 'post':
        message += i18n.t("httpErrors.post403")
        break;
      case 'put':
        message += i18n.t("httpErrors.put403")
        break;
      case 'delete':
        message += i18n.t("httpErrors.delete403")
        break;
      default:
        message += i18n.t("httpErrors.403")
    }

    return message
  }

  handleHttpError = (response: AxiosResponse<Api.ErrorResponse>) => {
    let message = "something went wrong!"

    switch (response.status) {
      case 403:
        message = this.handle403(response);
        break;
      default:
        if (response.data) {
          if (Array.isArray(response.data.errors)) {
            message = response.data.errors.map((e) => e.message || "").join(". ");
          } else if (typeof response.data.message === 'string' ) {
            message = response.data.message
          }
        }
    }

    toast.error(message);

    return Promise.reject(response);
  }

  addSource = (path: string, tKey: string) => {
    this.sourceTranslations[path] = tKey;
  }
}

export const httpError = new HttpError()