import { useEffect, useCallback } from "react";
import history from "~/packages/history";
import { useSelector } from "react-redux";
import { msalAuth } from "~/packages/msalInstance";

const Login = () => {
  const { error } = useSelector((state: ReduxStore.State) => state.auth);

  const redirect = useCallback(() => {
    if (error) return;

    if (!msalAuth.getAccount()) {
      msalAuth.loginRedirect();
    } else {
      history.push("/");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      redirect();
    }, 500);
  }, [redirect]);

  return null;
};

export default Login;
