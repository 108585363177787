import { useState, useEffect } from "react";
import { LinearProgress } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { initialize as yupInitialize } from "./packages/yup";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "~/store/settings/actions";
import { getProfile } from "~/store/profile/actions";
import { getUserData } from "~/store/auth/actions";

interface InitializationAppProps {
  children: JSX.Element;
}

const InitializationApp = ({ children }: InitializationAppProps) => {
  const [loading, setLoading] = useState(false);
  const loadedAllData = useSelector(
    (store: ReduxStore.State) => store.profile.isLoaded
  );
  const [isInitialized, setInitialized] = useState(false);
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const init = async () => {
    setLoading(true)
    try {
      await dispatch(getSettings());
      await dispatch(getProfile());
      await dispatch(getUserData());
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (i18n.isInitialized) {
      yupInitialize(t);
    }
  }, [i18n.isInitialized, t]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setInitialized(loadedAllData && i18n.isInitialized);
  }, [loadedAllData, i18n.isInitialized]);

  return isInitialized && !loading ? children : <LinearProgress />;
};

export default InitializationApp;
