import { useState } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

/**
 * @memberof Roles
 * @component
 * @desc Role options.
 * @property {RolesApi.RoleDto} role - Role data;
 * @property {Function} onEdit - callback for open the Edit Role dialog. (data: RolesApi.RoleDto) => void
 * @property {Function} onDelete - callback for the delete a role. (data: RolesApi.RoleDto) => Promise<unknown> | void
 */

interface RoleOptionsProps {
  role: RolesApi.RoleDto;
  onEdit?: (data: RolesApi.RoleDto) => void;
  onDelete?: (data: RolesApi.RoleDto) => Promise<unknown> | void;
}

const RoleOptions = ({ role, onEdit, onDelete }: RoleOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const deleteHandler = () => {
    if (onDelete) {
      const res = onDelete(role);
      if (res) {
        setLoading(true);
        res.finally(() => {
          setLoading(false);
        });
      }
    }
  };

  if (!hasAny([PERMISSION.EDIT_ROLE, PERMISSION.DELETE_ROLE])) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_ROLE),
      onClick: () => onEdit && onEdit(role),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_ROLE),
      onClick: deleteHandler,
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default RoleOptions;
