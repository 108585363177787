import { enumToI18nKey } from '~/utils/helpers';

export const PRIORITY = {
  URGENT: "URGENT",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  DEFAULT: "DEFAULT"
} as { [key: string]: EventsLogJournalApi.eEventPriority }

export const PRIORITY_I18n = enumToI18nKey(PRIORITY, "PRIORITY");

export const API = {
  LEGACY: "LEGACY",
  NEW: "NEW"
} as { [key: string]: EventsLogJournalApi.eApi }

export const API_I18n = enumToI18nKey(API, "API");