import { enumToI18nKey } from "~/utils/helpers";

export const TRANSACTION_TYPE = {
  PURCHASE: "PURCHASE",
  REVERSAL: "VOID",
  REFUND: "REFUND"
} as { [key: string]: FinancialTransactionsApi.eTransactionType };

export const TRANSACTION_TYPE_I18n = enumToI18nKey(
  TRANSACTION_TYPE,
  "TRANSACTION_TYPE"
);

export const PAYMENT_TYPE = {
  CARD: "Card",
  GOOGLE_PAY: "GooglePay",
  APPLE_PAY: "ApplePay",
  MASTER_PASS: "MasterPass",
  OKKO_ONLINE: "OKKOOnline",
  FISHKA_WALLET: "FishkaWallet"
} as { [key: string]: FinancialTransactionsApi.ePaymentType };

export const PAYMENT_TYPE_I18n = enumToI18nKey(PAYMENT_TYPE, "PAYMENT_TYPE");

export const TRANSACTION_STATUS = {
  CREATED: "created",
  PROCESSING: "processing",
  COMPLETED: "completed",
  APPROVED: "approved",
  CANCELED: "canceled",
  REFUNDED: "refunded",
  REFUNDING_PROGRESS: "refundinprogress",
  REFUNDED_ERROR: "refunderror"
} as { [key: string]: FinancialTransactionsApi.eStatus };

export const TRANSACTION_STATUS_I18n = enumToI18nKey(
  TRANSACTION_STATUS,
  "TRANSACTION_STATUS"
);
