import { useCallback, useEffect, useState, useContext } from "react";
import { Switch, LinearProgress } from "~/components/UI";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table from "~/components/UI/Table/Table";
import EnumValue from '~/components/EnumValue';
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import EndpointOptions from "./EndpointOptions";
import AcquirersContext from "../acquirersContext";
import { updateArrayItem } from "~/utils/helpers";
import api from "~/api";
import { CONNECTOR_TYPE_I18n } from "~/api/acquirers/enums";

const defaultColumns = [
  {
    key: "endpointId",
    title: "ID",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "url",
    title: "URL",
    cellOptions: {
      width: 300
    },
    render: (link: string) => (
      <a style={{ textDecoration: "underline"}} target="_blank" rel="noreferrer" href={link}>
        {link}
      </a>
    )
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    cellOptions: {
      width: 50
    }
  },
  {
    key: "connectorType",
    i18nKey: "connectorType",
    render: (connectorType: string) => <EnumValue value={connectorType} enumI18n={CONNECTOR_TYPE_I18n} />
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} size="small" />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

interface AcquirerEndpointsProps {
  acquirer: AcquirersApi.AcquirerDto;
}

const AcquirerEndpoints = ({ acquirer }: AcquirerEndpointsProps) => {
  const [loading, setLoading] = useState(false);
  const [endpoints, setEndpoints] = useState<AcquirersApi.EndpointDto[]>();
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const { onEdit: onEditAcquirer } = useContext(AcquirersContext);

  const getAcquirerEndpoints = async () => {
    try {
      setLoading(true);
      const {
        data: { data: endpoints }
      } = await api.acquirers.getAcquirerEndpoints(acquirer.acquirerId);
      setEndpoints(endpoints);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = useCallback(
    async (endpoint: AcquirersApi.EndpointDto) => {
      const {
        data: { data }
      } = await api.acquirers.updateAcquirerEndpoint(
        acquirer.acquirerId,
        endpoint.endpointId,
        endpoint
      );
      onUpdateItem(data);
    },
    [acquirer]
  );

  const onEdit = useCallback(
    () => onEditAcquirer && onEditAcquirer(acquirer),
    [acquirer]
  );

  const onUpdateItem = useCallback((endpoint: AcquirersApi.EndpointDto) => {
    setEndpoints((endpoints) =>
      endpoints ? updateArrayItem(endpoints, endpoint, "endpointId") : []
    );
  }, []);

  const onDelete = useCallback(
    async (endpoint: AcquirersApi.EndpointDto) => {
      await api.acquirers.deleteAcquirerEndpoint(
        acquirer.acquirerId,
        endpoint.endpointId
      );
      setEndpoints((endpoints) =>
        endpoints
          ? endpoints.filter((e) => e.endpointId !== endpoint.endpointId)
          : []
      );
    },
    [acquirer]
  );

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, endpoint: AcquirersApi.EndpointDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_ACQUIRER)}
              onConfirm={() =>
                onUpdate({ ...endpoint, enabled: !endpoint.enabled })
              }
            >
              <Switch
                checked={enabled}
                size="small"
                disabled={!hasAll(PERMISSION.EDIT_ACQUIRER)}
              />
            </ConfirmDialog>
          )
        };
      case "options":
        return {
          ...column,
          render: (_e: unknown, endpoint: AcquirersApi.EndpointDto) => (
            <EndpointOptions
              endpoint={endpoint}
              onEdit={onEdit}
              onUpdate={onUpdate}
              onUpdateItem={onUpdateItem}
              onDelete={onDelete}
            />
          )
        };
      default:
        return column;
    }
  });

  useEffect(() => {
    getAcquirerEndpoints();
  }, []);

  useEffect(() => {
    getAcquirerEndpoints();
  }, [acquirer]);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Table
        size="small"
        columns={columns}
        dataSource={endpoints}
        stickyHeader
      />
    </>
  );
};

export default AcquirerEndpoints;
