import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles, createStyles } from "@material-ui/core/styles";

const Accordion = withStyles(() =>
  createStyles({
    root: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:before": {
        display: "none",
      },
    },
    expanded: {
      margin: "0 !important",
    },
  })
)(MuiAccordion);

export default Accordion;
