import { useTheme, styled } from "@material-ui/styles";
import {
  AreaChart,
  Area,
  AreaProps as BaseAreaProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  CartesianGrid,
  CartesianGridProps,
} from "recharts";
import { Tooltip, TooltipProps } from "./Common";

interface AreaProps extends BaseAreaProps {
  color?: "primary" | "secondary" | "warning" | "info" | "success" | "error";
  gradient?: boolean;
}

interface Props {
  width?: number;
  height?: number;
  xAxis?: XAxisProps;
  yAxis?: YAxisProps;
  grid?: CartesianGridProps;
  tooltip?: TooltipProps;
  data: { [ket: string]: string | Array<number> | Object }[];
  areas?: AreaProps[];
}

const Container = styled(AreaChart)(
  ({ theme }: { theme: CustomTheme.RootObject }) => {
    return {
      overflow: "auto",
      fontFamily: theme.typography.charts.fontFamily,
      fontSize: theme.typography.charts.fontSize,
    };
  }
);

const SimpleAreaChart = ({
  width,
  height,
  xAxis,
  yAxis,
  data,
  areas,
  grid,
  tooltip,
}: Props) => {
  const theme: CustomTheme.RootObject = useTheme();
  return (
    <Container
      {...{
        width,
        height: height && height - 10,
        data,
      }}
    >
      <defs>
        {areas &&
          areas.length &&
          areas.map(({ color, gradient, dataKey }, index) =>
            gradient ? (
              <linearGradient
                key={"linearGradient" + index}
                id={dataKey as string}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={color && theme.palette[color].light}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={color && theme.palette[color].light}
                  stopOpacity={0}
                />
              </linearGradient>
            ) : null
          )}
      </defs>
      {tooltip && <Tooltip {...tooltip} />}
      {grid && (
        <CartesianGrid
          {...(grid as any)}
          strokeDasharray="5 5"
          stroke={theme.palette.charts.grid}
        />
      )}
      {xAxis && <XAxis {...xAxis} stroke={theme.palette.charts.axis} />}
      {yAxis && <YAxis {...yAxis} stroke={theme.palette.charts.axis} />}
      {areas &&
        areas.length &&
        areas.map(({ color, gradient, ...other }, index) => (
          <Area
            key={index}
            {...(other as any)}
            stroke={color && theme.palette[color].light}
            strokeWidth={2}
            fill={gradient ? `url(#${other.dataKey})` : "transparent"}
            type="monotone"
          />
        ))}
    </Container>
  );
};

export default SimpleAreaChart;
