import { useState, useCallback, useRef, useEffect } from "react";
import flatpickr from "flatpickr";
import FlatDatePicker, { DateTimePickerProps } from "react-flatpickr";
import { OutlinedInput, IconButton, Icon } from "./";
import { withStyles, createStyles } from "@material-ui/core/styles";
import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/l10n/ru";
import "flatpickr/dist/l10n/uk";

export interface DatePickerProps extends Omit<DateTimePickerProps, "onChange"> {
  onChange?: (value?: Date) => void;
  error?: boolean;
  clear?: boolean;
  classes?: { root: string };
}

const DatePicker = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      "&&": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        "& .flatpickr-time": {
          borderRadius: theme.shape.borderRadius,
        },
        "& .numInputWrapper span": {
          borderColor: theme.palette.primary.contrastText,
          "&.arrowUp:after": {
            borderBottomColor: theme.palette.primary.contrastText,
          },
          "&.arrowDown:after": {
            borderTopColor: theme.palette.primary.contrastText,
          },
        },
        "& .flatpickr-months": {
          "& .flatpickr-month": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
          },
          "& .flatpickr-prev-month": {
            color: theme.palette.primary.contrastText,
            fill: theme.palette.primary.contrastText,
            "&:hover svg": {
              color: theme.palette.primary.contrastText,
              fill: theme.palette.primary.contrastText,
            },
          },
          "& .flatpickr-next-month": {
            color: theme.palette.primary.contrastText,
            fill: theme.palette.primary.contrastText,
            "&:hover svg": {
              color: theme.palette.primary.contrastText,
              fill: theme.palette.primary.contrastText,
            },
          },
          "& .flatpickr-monthDropdown-months": {
            backgroundColor: "transparent",
          },
        },
        "&&:after": {
          borderBottomColor: theme.palette.primary.main,
        },
        "& .flatpickr-day": {
          "&:hover": {
            backgroundColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
          },
          "&.today": {
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
          },
          "&.selected": {
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  })
)(({ classes, onChange, clear, error, ...props }: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const elRef = useRef<HTMLDivElement & { flatpickr: flatpickr.Instance }>();
  const onChangeHandler = useCallback(
    (dates: Date[]) => {
      const value = dates.length ? dates[0] : undefined;
      onChange && onChange(value);
    },
    [onChange]
  );

  const handleClick = useCallback(
    (e) => {

      if (elRef.current) {
        // @ts-ignore
        if (!open && elRef.current.flatpickr.isOpen && elRef.current.node.parent === e.target.parent) {
          setOpen(true);
          return;
        }
        if (elRef.current.flatpickr.isOpen &&
          (
            elRef.current.flatpickr.currentYearElement.parentNode !== e.target.parentNode
          ) &&
          (
            elRef.current.flatpickr.timeContainer !== e.target.parentNode.parentNode
          ) &&
          (
            e?.target?.className &&
            typeof e?.target?.className === "string" &&
            !e?.target?.className?.includes("flatpickr")
          )
        ) {
          elRef.current.flatpickr.close();
          setOpen(false);
        }
      }
    },
    [elRef.current, open]
  );

  useEffect(() => {
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <FlatDatePicker
      ref={elRef as any}
      {...props}
      render={({ defaultValue, value, ...props }, ref) => (
        <>
          <OutlinedInput
            inputProps={props as any}
            defaultValue={defaultValue}
            error={error}
            inputRef={ref}
          />
          {clear && value && (
            <IconButton
              size="small"
              onClick={() => {
                onChangeHandler([]);
              }}
            >
              <Icon name="Close" />
            </IconButton>
          )}
        </>
      )}
      onChange={onChangeHandler}
      onReady={(_, __, flatpickr) => {
        classes && flatpickr?.calendarContainer?.classList?.add(classes.root);
      }}
    />
  );
});

export default DatePicker;
