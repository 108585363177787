import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import AcquirerEndpoints from "./AcquirerEndpoints";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateAcquirerDialogProps
  extends Omit<DialogProps, "onClose"> {
  acquirer?: AcquirersApi.AcquirerDto;
  onClose?: (acquirer?: AcquirersApi.AcquirerDto) => void;
}

type FromState = AcquirersApi.CreateAcquirer | AcquirersApi.UpdateAcquirer;

const defaultValues: FromState = {
  acquirerName: "",
  mfo: undefined,
  enabled: false,
  description: "",
  endpoints: [],
};

/**
 * @memberof Acquirers
 * @component
 * @desc Dialog for creating or editing a Acquirer.
 * @property {AcquirersApi.AcquirerDto} contract a Acquirer data
 * @property {Function} onClose - passes Acquirer data if the Acquirer was created/updated successfully. (result?: AcquirersApi.AcquirerDto) => void;
 */

const CreateOrUpdateAcquirerDialog = ({
  acquirer,
  open,
  onClose,
}: CreateOrUpdateAcquirerDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    acquirerName: yup.string().required(),
    mfo: yup.number().mfo(),
    description: yup.string(),
  });

  const onSubmit = useCallback(
    async (values: FromState) => {
      setLoading(true);
      
      const formData = {
        ...values,
        mfo:  values.mfo === "" ? null: values.mfo
      };

      try {
        if (isEditMode && acquirer) {
          await api.acquirers.update(
            acquirer.acquirerId,
            formData as AcquirersApi.UpdateAcquirer
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.acquirers.create(formData as AcquirersApi.CreateAcquirer);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as AcquirersApi.AcquirerDto);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, acquirer, onClose, t]
  );

  useEffect(() => {
    if (acquirer) {
      const { acquirerName, mfo, enabled, description } = acquirer;
      setInitialValues({
        acquirerName,
        mfo,
        enabled,
        description,
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [acquirer]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editAcquirer") : t("title.addAcquirer")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true,
          validateOnChange: false,
        }}
      >
        {({ handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {acquirer && (
                  <FormControlLabel label="ID">
                    {acquirer.acquirerId}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.name")}>
                  <OutlinedInput name="acquirerName" formikControll />
                </FormControlLabel>
                <FormControlLabel label="MFO">
                  <OutlinedInput name="mfo" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
                <AcquirerEndpoints
                  acquirerId={acquirer && acquirer.acquirerId}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateAcquirerDialog;
