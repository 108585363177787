import { useState, useCallback, useEffect } from "react";
import { Icon, IconButton } from "~/components/UI";
import { SketchPicker } from "react-color";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface ColorPickerProps {
  defaultValue?: string;
  onChange?: (color: string) => void;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    color: {
      width: 16,
      height: 16,
      borderRadius: theme.shape.borderRadius,
    },
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    popover: {
      position: "absolute",
      zIndex: 1,
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  })
);

const ColorPicker = ({ defaultValue, onChange }: ColorPickerProps) => {
  const [color, setColor] = useState(defaultValue || "#ccc");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(!!anchorEl);
  const classes = useStyles();

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  useEffect(() => {
    setColor(defaultValue || "#ccc");
  }, [defaultValue]);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    onChange && onChange(color);
  }, [color, onChange]);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.color} style={{ backgroundColor: color }} />
        <IconButton onClick={handlePopoverOpen}>
          <Icon name={open ? "ArrowTop" : "ArrowDown"} size="small" />
        </IconButton>
      </div>

      {open ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handlePopoverClose} />
          <SketchPicker
            color={color}
            presetColors={[
              "#EB9694",
              "#FAD0C3",
              "#FEF3BD",
              "#C1E1C5",
              "#BEDADC",
              "#C4DEF6",
              "#BED3F3",
              "#D4C4FB",
            ]}
            onChange={(color) => setColor(color.hex)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
