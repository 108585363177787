import { useContext, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import OutboundVirtualTerminalOptions from "./OutboundVirtualsTerminalOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import OutboundVirtualTerminalsContext from "./OutboundVirtualsTerminalsContext";
import OutboundVirtualTerminalDetails from "./OutboundVirtualTerminalDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "id",
    title: "ID",
    copy: true,
    sort: true,
    cellOptions: {
      width: 120,
    }
  },
  {
    key: "externalTerminalId",
    i18nKey: "externalTerminalId"
  },
  {
    key: "externalMerchantId",
    i18nKey: "externalMerchantId"
  },
  {
    key: "internalMerchantName",
    i18nKey: "merchant",
  },
  {
    key: "acquirerId",
    i18nKey: "acquirerID"
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    sort: true,
    cellOptions: {
      width: 50
    }
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "description",
    i18nKey: "description",
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

const OutboundVirtualTerminalsTable = (
  props: Omit<
    TableProps<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto>,
    "columns"
  >,
  ref: any
) => {
  const { onUpdate } = useContext(OutboundVirtualTerminalsContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (
            enabled: boolean,
            outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
          ) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_CONTRACT)}
              onConfirm={() =>
                onUpdate &&
                onUpdate(outboundVirtualTerminal.id, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_OUTBOUND_VIRTUAL_TERMINAL)}
              />
            </ConfirmDialog>
          )
        };
      case "options":
        return {
          ...column,
          render: (
            _value: unknown,
            outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
          ) => (
            <OutboundVirtualTerminalOptions
              outboundVirtualTerminal={outboundVirtualTerminal}
            />
          )
        };
      default:
        return column;
    }
  });

  return (
    <Table
      ref={ref}
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (outboundVirtualTerminal) => (
          <OutboundVirtualTerminalDetails
            outboundVirtualTerminal={outboundVirtualTerminal}
          />
        )
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<
    TableProps<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto>,
    "columns"
  >
>(OutboundVirtualTerminalsTable);
