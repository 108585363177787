import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateOrUpdateInboundVirtualTerminalDialog from "./CreateOrUpdateInboundVirtualTerminalDialog";
import InboundVirtualTerminalsContext from "./InboundVirtualsTerminalsContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import usePermissions from "~/hook/usePermissions";
import { useQueryParams, StringParam } from "use-query-params";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const InboundVirtualTerminals = () => {
  const [editInboundVirtualTerminal, setEditInboundVirtualTerminal] =
    useState<InboundVirtualTerminalsApi.InboundVirtualTerminalDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedTerminals, setSelectedTerminals] = useState<string[]>([]);
  const [inboundVirtualTerminals, setInboundVirtualTerminals] =
    useState<InboundVirtualTerminalsApi.InboundVirtualTerminalDto[]>();
  const [query, setQuery] =
    useState<InboundVirtualTerminalsApi.GetInboundVirtualTerminalsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [queryParams] = useQueryParams({
    search: StringParam,
    id: StringParam
  });
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL)
      ? {
          hideSelectAll: false,
          onChange: setSelectedTerminals
        }
      : undefined
  );

  const getInboundVirtualTerminals = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.inboundVirtualTerminals.getAll(
          limit,
          page || currentPage,
          query
        );
        data && setInboundVirtualTerminals(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateInboundVirtualTerminal = () => {
    setEditInboundVirtualTerminal(undefined);
    setShowEditDialog(true);
  };

  const onUpdateItem = useCallback(
    (contract: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => {
      setInboundVirtualTerminals((contracts) =>
        contract ? updateArrayItem(contracts, contract, "id") : []
      );
    },
    []
  );

  const onCloseEditDialog = (
    contract?: InboundVirtualTerminalsApi.InboundVirtualTerminalDto
  ) => {
    setShowEditDialog(false);
    setEditInboundVirtualTerminal(undefined);
    contract && onUpdateItem({
                              id: contract.id,
                              updatedAt: new Date().toString()
                            } as InboundVirtualTerminalsApi.InboundVirtualTerminalDto
                          );
    contract && getInboundVirtualTerminals();
  };

  const onEdit = useCallback(
    (contract: InboundVirtualTerminalsApi.InboundVirtualTerminalDto) => {
      setEditInboundVirtualTerminal(contract);
      setShowEditDialog(true);
    },
    []
  );

  const onUpdate = useCallback(
    async (
      id: string,
      formData: InboundVirtualTerminalsApi.UpdateInboundVirtualTerminal
    ) => {
      try {
        const {
          data: { data: contract }
        } = await api.inboundVirtualTerminals.update(id, formData);
        onUpdateItem(contract);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (
      inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto
    ) => {
      await deleteConfirm(inboundVirtualTerminal?.id);
      try {
        await api.inboundVirtualTerminals.delete(inboundVirtualTerminal.id);
        getInboundVirtualTerminals();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getInboundVirtualTerminals, deleteConfirm]
  );

  const onDeleteInboundVirtualTerminals = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedTerminals?.length }));
    try {
      await api.inboundVirtualTerminals.deleteTerminals(selectedTerminals);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getInboundVirtualTerminals();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedTerminals]);

  const onChangePagination = (page: number) => {
    getInboundVirtualTerminals(page);
  };

  const onChangeFilters = (
    filters: InboundVirtualTerminalsApi.GetInboundVirtualTerminalsQuery
  ) => {
    pagination.current = undefined;
    setInboundVirtualTerminals([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {

    if (queryParams.search) {
      setQuery(
        { ...queryParams, search: queryParams.search.split(',') } as InboundVirtualTerminalsApi.GetInboundVirtualTerminalsQuery
      );
    } else {
      setQuery(
        queryParams as InboundVirtualTerminalsApi.GetInboundVirtualTerminalsQuery
      );
    }
  }, [queryParams]);

  useEffect(() => {
    query && getInboundVirtualTerminals();
  }, [query, getInboundVirtualTerminals, limit]);

  return (
    <InboundVirtualTerminalsContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.inboundVirtualTerminals")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL}>
                <Button
                  hidden={!selectedTerminals.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteInboundVirtualTerminals}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_INBOUND_VIRTUAL_TERMINAL}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateInboundVirtualTerminal}
                >
                  {t("button.addTerminal")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={inboundVirtualTerminals}
          onRequestSort={onSort}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateInboundVirtualTerminalDialog
        open={showEditDialog}
        inboundVirtualTerminal={editInboundVirtualTerminal}
        onClose={onCloseEditDialog}
      />
    </InboundVirtualTerminalsContext.Provider>
  );
};

export default InboundVirtualTerminals;
