import { ThunkAction } from 'redux-thunk';
import Constants from "./constants";
import api from '~/api';
import { openSidebar, closeSidebar } from '../alerts/actions';
import i18n from '~/packages/i18n';
import { getI18nLanguage } from '~/utils/helpers'

const { ACTIONS } = Constants;

export const setTheme = (
  payload: CustomTheme.eThemeType
): SettingsStore.SetTheme => ({
  type: ACTIONS.SET_THEME,
  payload,
});

export const getSettings = (): ThunkAction<void, ReduxStore.State, SearchStore.eActionType, any> => async (dispatch, getState) => {
  const appState: ReduxStore.State = getState();
  const loadingDashboardSettings = api.dashboardSettings.getSettings()
    .then(({ data: { data } }) => {
      if(data.alertPanelIsOpenedByDefault && !appState.settings.isLoaded) {
        dispatch(openSidebar());
      } else {
        dispatch(closeSidebar());
      }
      dispatch(setDashboardSettings(data));
      i18n.changeLanguage(getI18nLanguage(data?.language));
    });
  

  return Promise.all([loadingDashboardSettings])
  .then(() => {
    dispatch({
      type: ACTIONS.ALL_SETTINGS_LOADED
    })
  })
};


export const updateDashboardSettings = (
  payload: DashboardSettingsApi.UpdateDashboardSettings
): ThunkAction<void, ReduxStore.State, SettingsStore.eActionType, any> => async (dispatch) => {
  try {
    const { data } = await api.dashboardSettings.updateSettings(payload)
    dispatch(setDashboardSettings(data.data))
    return data;
  } catch (e) {
    throw e;
  }
}

export const setDashboardSettings = (
  payload: DashboardSettingsApi.Settings
): SettingsStore.SetDashboardSettings => ({
  type: ACTIONS.SET_DASHBOARD_SETTINGS,
  payload,
});
