import { useTheme } from "~/components/UI";
import EnumValue from "~/components/EnumValue";
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_I18n
} from "~/api/financialTransactions/enums";

const TransactionStatus = (status: FinancialTransactionsApi.eStatus) => {
  const theme = useTheme();
  let color;
  switch (status) {
    case TRANSACTION_STATUS.REFUNDED:
    case TRANSACTION_STATUS.COMPLETED:
    case TRANSACTION_STATUS.CREATED:
      color = theme.palette.info.main;
      break;
    case TRANSACTION_STATUS.PROCESSING:
    case TRANSACTION_STATUS.REFUNDING_PROGRESS:
      color = theme.palette.warning.main;
      break;
    case TRANSACTION_STATUS.APPROVED:
      color = theme.palette.success.main;
      break;
    case TRANSACTION_STATUS.CANCELED:
    case TRANSACTION_STATUS.REFUNDED_ERROR:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.text.primary;
  }
  return (
    <span style={{ color }}>
      <EnumValue value={status} enumI18n={TRANSACTION_STATUS_I18n} />
    </span>
  );
};

export default TransactionStatus;
