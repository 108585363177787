import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import _ from "lodash";
import api from "~/api";

interface FromikSelectOutboundVirtualTerminalProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (
    options: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[],
    state: any
  ) => OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[];
}

interface SelectOutboundVirtualTerminalProps {
  inputValue?: string;
  name?: string;
  onChange?: (
    outboundVirtualTerminal?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
  ) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  filterOptions?: (
    options: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[],
    state: any
  ) => OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[];
}

const requestSearchOutboundVirtualTerminal = (search: string) =>
  api.outboundVirtualTerminals.getAll(20, 1, { search });

export const SelectOutboundVirtualTerminal = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue,
  }: SelectOutboundVirtualTerminalProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<
      OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto[]
    >([]);
    const [outboundVirtualTerminal, setOutboundVirtualTerminal] =
      useState<Partial<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto> | null>(
        null
      );

    const searchOutboundVirtualTerminals = useMemo(
      () =>
        _.debounce((outboundVirtualTerminalId: string) => {
          setLoading(true);
          requestSearchOutboundVirtualTerminal(outboundVirtualTerminalId)
            .then(({ data }) => {
              setSearchResults(data.data);
            })
            .finally(() => {
              setLoading(false);
            });
        }, 500),
      []
    );

    const onChangeInputValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
      searchOutboundVirtualTerminals(e.target.value || "");
    };

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue.name);
          setOutboundVirtualTerminal(newValue);
        } else {
          setValue("");
          setOutboundVirtualTerminal(null);
        }
        onChange && onChange(newValue);
      },
      [onChange]
    );

    const getOptionSelected = useCallback(
      (
        o: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto,
        value: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
      ) => o.id === value.id,
      []
    );

    const getOptionLabel = useCallback(
      (o: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => o.id || "",
      []
    );

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setOutboundVirtualTerminal(null);
        searchOutboundVirtualTerminals("");
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchOutboundVirtualTerminals("");
    }, [searchOutboundVirtualTerminals]);

    return (
      <Autocomplete
        value={outboundVirtualTerminal}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText="No OutboundVirtualTerminals"
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        loading={loading}
        disableClearable
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            fullWidth
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FromikContainer = ({
  name,
  defaultInputValue,
  onChange,
  ...other
}: FromikSelectOutboundVirtualTerminalProps &
  SelectOutboundVirtualTerminalProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChangeHandler = useCallback(
    (
      outboundVirtualTerminal?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
    ) => {
      field.onChange({
        target: {
          name,
          value: outboundVirtualTerminal ? outboundVirtualTerminal.id : "",
        },
      });
      onChange && onChange(outboundVirtualTerminal)
    },
    [field, name]
  );

  return (
    <SelectOutboundVirtualTerminal
      {...field}
      {...other}
      onChange={onChangeHandler}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default React.memo(FromikContainer);
