import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  ColorPicker,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import GetGroupFromAD from "./GetGroupFromAD";
import RolesManagement from "~/components/RolesManagement";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";

interface AddGroupProps extends Omit<DialogProps, "onClose" | "role"> {
  onClose?: (result?: boolean) => void;
}

interface FromData extends GroupsApi.AddGroup {}

const defaultValues = {
  name: "",
  color: "#ccc",
  roles: [],
};

/**
 * @memberof Groups
 * @component
 * @desc Add Group Dialog.
 * @property {Function} onClose - passes true if the group was created successfully. (result?: boolean) => void;
 */

const AddGroup = ({ open, onClose }: AddGroupProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [roles, setRoles] = useState<GroupsApi.GroupRolesDto[]>([]);
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    roles: yup.array(),
  });
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      setLoading(true);

      try {
        await api.groups.addGroup(formData);
        toast.success(t("text.recordWasSuccessfullyCreated"));
        onClose && onClose(true);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t]
  );

  const getRoles = async () => {
    try {
      const {
        data: { data: roles },
      } = await api.roles.getAll(100);
      setRoles(
        roles.map(({ roleId, name }) => ({ roleId, name, status: false }))
      );
    } catch (e) {
      console.error(e);
      setRoles([]);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    setInitialValues(defaultValues);
    setRoles((roles) => roles.map((role) => ({ ...role, status: false })));
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t("title.addGroup")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true,
          }}
        >
          {({ handleSubmit, values, setFieldValue, isValid, dirty }) => (
            <>
              <DialogContent>
                <GetGroupFromAD
                  onChange={(group) => {
                    setFieldValue("name", group.name);
                  }}
                />
                {values.name && (
                  <FormControlLabel label={t("label.colorGroup")}>
                    <ColorPicker
                      defaultValue={values.color}
                      onChange={(color) => setFieldValue("color", color)}
                    />
                  </FormControlLabel>
                )}
                {values.name && (
                  <Box mt={4}>
                    <RolesManagement
                      roles={roles}
                      onChange={(roles) => {
                        setFieldValue("roles", roles);
                      }}
                    />
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!dirty || !isValid}
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.add")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default AddGroup;
