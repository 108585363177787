import { useState, useEffect, useCallback } from "react";
import { Box, LinearProgress } from "~/components/UI";
import Search from "~/components/Search";
import { useTranslation } from "react-i18next";
import Table from "./WalletsCredentialsTable";
import api from "~/api";

interface MerchantDetailsProps {
  merchant: MerchantsApi.MerchantDto;
}

/**
 * @memberof Merchants
 * @component
 * @desc Render detailed information about a Merchant.
 * @property {MerchantsApi.MerchantDto} merchant - Merchant data;
 */

const MerchantDetails = ({ merchant }: MerchantDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [wallets, setWallets] = useState<MerchantsApi.WalletDto[]>();
  const [searchResult, setSearchResult] = useState<MerchantsApi.WalletDto[]>();
  const { t } = useTranslation();

  const getMerchantDetails = useCallback(async () => {
    setLoading(true);
    try {
      await api.merchants.getMerchant(merchant.id);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [merchant]);

  const getWallets = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.merchants.getMerchantWallets(merchant.id);
      setWallets(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [merchant]);

  const onSearch = useCallback(
    (search: string) => {
      if (search && wallets) {
        setSearchResult(
          wallets.filter(({ walletMerchantId }) =>
            walletMerchantId
              ? walletMerchantId.toLowerCase().includes(search)
              : false
          )
        );
      } else {
        setSearchResult(undefined);
      }
    },
    [wallets]
  );

  useEffect(() => {
    getWallets();
    getMerchantDetails();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Box ml={4} width={375}>
        <Search placeholder={t("text.searchByID")} onSearch={onSearch} />
      </Box>
      <Table dataSource={searchResult || wallets} />
    </>
  );
};

export default MerchantDetails;
