import { useCallback } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface WalletsResultProps {
  data?: Api.SearchResult<WalletsApi.WalletSearchResult>;
  searchQuery: string;
}

const Wallet = ({
  wallet,
  searchQuery,
}: {
  searchQuery: string;
  wallet: WalletsApi.WalletSearchResult;
}) => {
  const openGroup = useCallback(() => {
    history.push(`${ROUTES.WALLET_CREDENTIALS}?id=${wallet.id}`);
  }, [wallet.id]);

  return (
    <ListItem button onClick={openGroup}>
      <Typography variant="body2" color="textSecondary">
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={wallet.walletType || ""}
        />{" "}
        [
        <Typography variant="body2" component="span" color="primary">
          ID
        </Typography>
        :{" "}
        <Highlighter searchWords={[searchQuery]} textToHighlight={wallet.id} />]
      </Typography>
      <Divider />
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the Wallets catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<WalletsApi.WalletSearchResult>} data - api response
 */

const Wallets = ({ data, searchQuery }: WalletsResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.WALLET_CREDENTIALS}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogWallets")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((u, index) => (
            <Wallet key={index} searchQuery={searchQuery} wallet={u} />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default Wallets;
