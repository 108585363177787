import React from "react";
import clsx from "clsx";
import MUIDialog, {
  DialogProps as MUIDialogProps
} from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { Divider, Icon } from "../";
import IconButton from "../IconButton";
import { styled, withStyles, createStyles } from "@material-ui/core/styles";

export interface DialogProps extends Omit<MUIDialogProps, "open" | "title"> {
  title?: string | React.ReactNode;
  open?: boolean;
  children?: React.ReactChild | React.ReactChild[];
  closable?: boolean;
  size?: "small" | "default";
}

export const HeaderContainer = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    padding: theme.spacing(4),
    paddingBottom: 0
  })
);

export const Title = withStyles(
  createStyles({
    root: {
      padding: 0,
      marginTop: 15,
      marginBottom: 20,
      "&::first-letter": {
        textTransform: "uppercase"
      }
    }
  })
)((props) => <Typography {...props} variant="h3" />);

const CloseIcon = withStyles(
  createStyles({
    root: {
      position: "absolute",
      top: 20,
      right: 20,
      fontSize: 10
    }
  })
)(IconButton);

const Dialog = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(4px)"
      },
      "&.small div[role='dialog']": {
        width: 265,
      }
    },
    paper: {
      position: "relative",
      width: "100%"
    }
  })
)(
  ({
    title,
    open,
    children,
    classes,
    closable,
    size = "default",
    maxWidth = "sm",
    onClose,
    ...other
  }: DialogProps) => {
    return typeof open === "boolean" ? (
      <MUIDialog
        open={open}
        {...other}
        maxWidth={maxWidth}
        className={clsx({ [size]: true })}
        transitionDuration={0}
        classes={classes}
        onClose={onClose}
      >
        {title && (
          <HeaderContainer>
            {typeof title === "string" ? <Title>{title}</Title> : title}
            {closable && (
              <CloseIcon
                onClick={() =>
                  onClose && onClose({}, "backdropClick" as "backdropClick")
                }
              >
                <Icon name="Close" />
              </CloseIcon>
            )}
            <Divider />
          </HeaderContainer>
        )}
        {children}
      </MUIDialog>
    ) : null;
  }
);

export default Dialog;
