import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table, { TableProps } from "./Table";
import Icon from "../Icon";
import Box from "@material-ui/core/Box";

interface TableTreeProps<T> extends TableProps<T> {
  depth?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      "& tbody td": {
        paddingRight: 0,
      },
      "& table tbody td:first-child": {
        padding: 0,
      },
    },
  })
);

const TableTree = ({
  depth = 0,
  columns,
  ...props
}: TableTreeProps<unknown>) => {
  const classes = useStyles();
  return (
    <Table
      className={classes.table}
      columns={columns}
      {...props}
      expandable={{
        expandIcon: (expanded) => <Icon name={expanded ? "Minus" : "Plus"} />,
        rowExpandable: (row) => row.items && row.items.length,
        expandedRowRender: (row) => (
          <Box ml={12}>
            <Table
              columns={columns}
              dataSource={row.items}
              hideHeader
              expandable={{
                rowExpandable: (row) => row.items && row.items.length,
                expandIcon: (expanded) => (
                  <Icon name={expanded ? "Minus" : "Plus"} />
                ),
                expandedRowRender: (row) => (
                  <Box ml={8}>
                    <Table
                      columns={columns}
                      dataSource={row.items}
                      hideHeader
                      expandable={{
                        rowExpandable: (row) => row.items && row.items.length,
                        expandIcon: (expanded) => (
                          <Icon name={expanded ? "Minus" : "Plus"} />
                        ),
                        expandedRowRender: (row) => (
                          <Box ml={8}>
                            <Table
                              columns={columns}
                              dataSource={row.items}
                              hideHeader
                            />
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                ),
              }}
            />
          </Box>
        ),
      }}
    />
  );
};

export default TableTree;
