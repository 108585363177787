import { Box, Typography } from "~/components/UI";

interface ColumnProps {
  title?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const Column = ({ title, children }: ColumnProps) => (
  <>
    <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
    <Box p={4}>{children}</Box>
  </>
);

export default Column;
