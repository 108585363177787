import { useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import Drawer from "~/components/UI/Drawer";
import { Box, makeStyles, createStyles, Theme } from "~/components/UI";
import SideBarFilterContext from "./SideBarFilterContext";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const drawerWidth = 380;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      height: "100%",
    },
    drawer: {
      width: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      boxSizing: "border-box",
    },
    closeBtn: {
      cursor: "pointer",
    },
    app: {
      boxSizing: "border-box",
      height: "100%",
    },
    appShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  })
);

interface SideBarFilterProviderProps {
  children: React.ReactChildren | React.ReactNode;
}

const SideBarFilterProvider = ({
  children,
  ...other
}: SideBarFilterProviderProps) => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const inOpenAlertPanel = useSelector(
    (store: ReduxStore.State) => store.alerts.isOpenSideBar
  );
  const classes = useStyles();

  const contextValue = useMemo(
    () => ({
      isOpen,
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    [isOpen]
  );

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <SideBarFilterContext.Provider value={contextValue}>
      <div className={classes.container}>
        <div
          className={clsx(classes.app, {
            [classes.appShift]: !inOpenAlertPanel && isOpen,
          })}
        >
          {children}
        </div>
        <Drawer
          {...other}
          open={isOpen}
          variant="persistent"
          anchor="right"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box id="right-side-filter-container" height="100%"></Box>
        </Drawer>
      </div>
    </SideBarFilterContext.Provider>
  );
};

export default SideBarFilterProvider;
