import { useContext, forwardRef } from "react";
import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps, TableRef } from "~/components/UI/Table/Table";
import WalletOptions from "./WalletOptions";
import IncidentsIcon from "~/components/Incidents/IncidentsIcon";
import WalletsCredentialsContext from "./walletsCredentialsContext";
import WalletDetails from "./WalletDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import EnumValue from "~/components/EnumValue";
import { WALLET_TYPE_I18n } from "~/api/wallets/enums";

const defaultColumns = [
  {
    key: "walletId",
    title: "ID",
    sort: true,
    keyForSort: "id",
    copy: true,
    cellOptions: {
      width: 120,
    }
  },
  {
    key: "walletType",
    sort: true,
    i18nKey: "walletType",
    cellOptions: {
      width: 100
    },
    render: (walletType: WalletsApi.eWalletType) => (
      <EnumValue value={walletType} enumI18n={WALLET_TYPE_I18n} />
    )
  },
  {
    key: "merchantId",
    i18nKey: "merchantId",
    // sort: true,
    cellOptions: {
      width: 300
    }
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    // sort: true,
    cellOptions: {
      width: 50
    }
  },
  {
    key: "incidents",
    cellOptions: {
      align: "center",
      padding: "none",
      width: 50
    },
    render: (incidents: IncidentsApi.IncidentsCount) => (
      <IncidentsIcon incidents={incidents} />
    )
  },
  {
    key: "options",
    cellOptions: {
      align: "right",
      padding: "none",
      width: 70
    }
  }
];

const WalletsTable = (
  props: Omit<TableProps<WalletsApi.WalletDto>, "columns">,
  ref: any
) => {
  const { onUpdate } = useContext(WalletsCredentialsContext);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, wallet: WalletsApi.WalletDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_WALLET_CREDENTIALS)}
              onConfirm={() =>
                onUpdate &&
                onUpdate(wallet.walletId, {
                  enabled: !enabled
                } as WalletsApi.UpdateWallet)
              }
            >
              <Switch
                checked={enabled}
                disabled={!hasAll(PERMISSION.EDIT_WALLET_CREDENTIALS)}
              />
            </ConfirmDialog>
          )
        };
      case "options":
        return {
          ...column,
          render: (_value: unknown, wallet: WalletsApi.WalletDto) => (
            <WalletOptions wallet={wallet} />
          )
        };
      default:
        return column;
    }
  });

  return (
    <Table
      ref={ref}
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (wallet) => <WalletDetails wallet={wallet} />
      }}
      stickyHeader
    />
  );
};

export default forwardRef<
  TableRef,
  Omit<TableProps<WalletsApi.WalletDto>, "columns">
>(WalletsTable);
