import Select, { SelectEnumProps } from "./SelectEnum";
import { INCIDENT_TYPE, INCIDENT_TYPE_I18n } from "~/api/incidents/enums";

const SelectIncidentType = (
  props: Omit<SelectEnumProps, "enumValue" | "enumI18n">
) => {
  return (
    <Select
      {...props}
      sortOptions="asc"
      enumValue={INCIDENT_TYPE}
      enumI18n={INCIDENT_TYPE_I18n}
    />
  );
};

export default SelectIncidentType;
