import { createContext } from "react";

export interface SideBarContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const SideBarContext = createContext<SideBarContextType>({
  isOpen: false,
  open: function () {},
  close: function () {}, 
});

export default SideBarContext;