import MUILinearProgress from "@material-ui/core/LinearProgress";
import { withStyles, createStyles } from "@material-ui/core/styles";

const LinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 3,
      "&[hidden]": {
        display: "block",
        opacity: 0,
      },
    },
  })
)(MUILinearProgress);

export default LinearProgress;
