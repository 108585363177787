import { useState, useCallback } from 'react';

const useSelection = (rows: any[], key?: string) => {
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const setSelectedRow = useCallback((id: string, value: boolean) => {
    if (selectedRows.includes(id)) {
      !value && setSelectedRows(selectedRows.filter((i) => i !== id));
    } else {
      value && setSelectedRows([...selectedRows, id]);
    }
  }, [selectedRows]);

  const setSelectAll = useCallback((value: boolean) => {
    setSelectedAll(value);
    value && rows
      ? setSelectedRows(selected => [...selected, ...rows.map((r, i) => key ? r[key] : i.toString())])
      : setSelectedRows([]);

  }, [rows, key]);

  const resetSelectedRows = () => {
    setSelectAll(false);
    setSelectedRows([]);
  }

  const indeterminateAllCb = Boolean(selectedRows?.length)
                              && Boolean(rows?.length)
                              && !rows?.every((row, index) => selectedRows.includes(row[key||index]));

  return { selectedRows, selectedAll, indeterminateAllCb, setSelectedRow, setSelectAll, resetSelectedRows };
}

export default useSelection;