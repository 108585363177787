import React from "react";
import clsa from "clsx";
import Box, { BoxProps } from "@material-ui/core/Box";
import { withStyles, createStyles } from "@material-ui/core/styles";

export interface TagProps extends BoxProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[] | string;
  classes: { [key: string]: string };
  onClick?: () => void;
  hidden?: boolean;
}

const Tag = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      position: "relative",
      display: "inline-block",
      borderRadius: theme.shape.borderRadius,
      fontFamily: '"Plumb", sans-serif',
      fontWeight: "bold",
      fontSize: theme.typography.fontSize,
      lineHeight: 1.5,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px `,
      maxWidth: 200,
      border: `1px solid ${theme.palette.divider}`,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    button: {
      cursor: "pointer",
    },
    hidden: {
      display: "none",
    },
  })
)(({ classes, className, hidden, ...props }: TagProps) => (
  <Box
    className={clsa(classes.root, className, {
      [classes.button]: !!props.onClick,
      [classes.hidden]: hidden,
    })}
    {...props}
  />
));

export default Tag;
