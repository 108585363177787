import ClientPaymentDurationCard from "./ClientPaymentDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const ClientPaymentDurationByAcquirers = () => {
  const { t } = useTranslation();
  const getClientPaymentDurationMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } = await api.metrics.getClientPaymentDurationByAcquirers(
        params
      );
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ClientPaymentDurationCard
      title={`${t("title.clientPaymentDurationBy")} ${t(
        "title.metricOfAcquirers"
      )}`}
      onUpdate={getClientPaymentDurationMetrics}
    />
  );
};

export default ClientPaymentDurationByAcquirers;
