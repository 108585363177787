import { useContext } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import IncidentsContext from "./IncidentsContext";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface IncidentOptionsProps {
  incident: IncidentsApi.IncidentDto;
}

/**
 * @memberof Incidents
 * @component
 * @desc Incidents options.
 * @property {IncidentsApi.IncidentDto} incident - Incident data;
 */

const IncidentOptions = ({ incident }: IncidentOptionsProps) => {
  const { onClose } = useContext(IncidentsContext);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  if (incident.closedAt || !hasAny(PERMISSION.EDIT_JOURNAL_INCIDENTS))
    return null;

  const options = [
    {
      label: t("option.closeIncident"),
      hidden: !hasAll(PERMISSION.EDIT_JOURNAL_INCIDENTS),
      onClick: () => onClose && onClose(incident),
    },
  ];

  return <Dropdown options={options} />;
};

export default IncidentOptions;
