import React, { useState, useEffect } from "react";
import clsx from "clsx";
import MUITabs, { TabsProps as MUITabsProps } from "@material-ui/core/Tabs";
import MUITab, { TabProps as MUITabProps } from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";

export type TabProps = MUITabProps & { content?: React.ReactNode; value?: any };
export interface TabsProps {
  tabs?: TabProps[];
  hidden?: boolean;
  onChange?: (value: any) => void;
  tabPanelClass?: string;
  className?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  active: boolean;
  className?: string;
}

const Tabs = withStyles(() =>
  createStyles({
    root: {
      minHeight: 32,
    },
    indicator: {
      backgroundColor: "transparent",
    },
  })
)(MUITabs);

const Tab = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      padding: 0,
      minHeight: 32,
      minWidth: 184,
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  })
)(MUITab);

const useStyles = makeStyles((theme: CustomTheme.RootObject) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    height: "100%",
    overflow: "auto",
  },
}));

const TabPanel = React.memo(
  ({ children, index, active, ...other }: TabPanelProps) => {
    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
      if (active) {
        setFirstRender(true);
      }
    }, [active]);

    return firstRender ? (
      <div role="tabpanel" hidden={!active} {...other}>
        {children}
      </div>
    ) : null;
  }
);

const TabsController = ({
  tabs,
  defaultValue,
  onChange,
  tabPanelClass,
  className,
  ...other
}: TabsProps & Omit<MUITabsProps, "onChange">) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue || 0);
  const [filteredTabs, setFilteredTabs] = useState<TabProps[]>([]);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  useEffect(() => {
    if (tabs && tabs.length) {
      setFilteredTabs(tabs.filter((t) => !t.hidden));
    } else {
      setFilteredTabs([]);
    }
  }, [tabs]);

  return (
    <div className={clsx(classes.root, className)}>
      <Tabs value={value} {...other} onChange={handleChange}>
        {filteredTabs.map(({ content, hidden, ...tabProps }, index) => (
          <Tab key={`tab-${index}`} {...tabProps} />
        ))}
      </Tabs>
      <Divider />
      {filteredTabs.map((t, index) => (
        <TabPanel
          key={`panel-${index}`}
          active={value === t.value || value === index}
          index={t.value || index}
          className={classes.tabPanel}
        >
          {t.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default TabsController;
