import {
  Box,
  FormControlLabel,
  OutlinedInput,
  Switch,
  Checkbox
} from "~/components/UI";
import SelectMerchant from "~/components/CommonFormControls/SelectMerchant";
import SelectPurposeKey from "~/components/CommonFormControls/SelectPurposeKey";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";

export const getValidationSchema = () => yup.object({
  walletMerchantId: yup.string().required(),
  merchantId: yup.string().required(),
  enabled: yup.boolean().required(),
  publicKeyId: yup.string().required(),
  privateKeyId: yup.string().required(),
  generate: yup.boolean()
});

const MasterPassWallet = ({ wallet }: { wallet?: WalletsApi.WalletDto }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControlLabel label={t("label.walletMerchantId")}>
        <OutlinedInput name="walletMerchantId" formikControll fullWidth />
      </FormControlLabel>
      <FormControlLabel label={t("label.enabled")}>
        <Switch name="enabled" formikControll />
      </FormControlLabel>
      <FormControlLabel label={t("label.merchant")}>
        <SelectMerchant
          defaultInputValue={wallet?.merchantName}
          name="merchantId"
        />
      </FormControlLabel>
      <FormControlLabel label={t("label.macKeyId")}>
        <SelectPurposeKey
          defaultInputValue={wallet?.privateKeyName}
          name="privateKeyId"
          keyPurpose={KEY_PURPOSE.MASTER_PASS_MAC_KEY}
        />
      </FormControlLabel>
      <FormControlLabel label={t("label.encryptionKeyId")}>
        <SelectPurposeKey
          defaultInputValue={wallet?.publicKeyName}
          name="publicKeyId"
          keyPurpose={KEY_PURPOSE.MASTER_PASS_ENCRYPTION_KEY}
        />
      </FormControlLabel>
      {wallet && (
        <>
          <FormControlLabel label={t("label.expirationDate")}>
            {wallet?.expirationDate}
          </FormControlLabel>
          <FormControlLabel label={t("label.generate")}>
            <Checkbox name="generate" size="medium" formikControll />
          </FormControlLabel>
          <FormControlLabel label={t("label.rrn")}>
            <Box mt={1}>{wallet?.rrn}</Box>
          </FormControlLabel>
        </>
      )}
    </>
  );
};

export default MasterPassWallet;
