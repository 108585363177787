import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import WalletsCredentials from "./Components/WalletsCredentials";
import { useTranslation } from "react-i18next";

/**
 * @namespace WalletsCredentialsPage
 */

const WalletsCredentialsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.walletCredentials") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <WalletsCredentials />
    </>
  );
};

export default WalletsCredentialsPage;
