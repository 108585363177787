import React, { useState, useEffect, useMemo } from "react";
import { Box, Checkbox, TableTree } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { useFormikContext } from "formik";
import { Entities, DependenciesForPermissions } from "~/constants/Permissions";
import { FromData } from "./index";

const PermissionCtrl = ({ action }: { action?: string }) => {
  const { values, setFieldValue } = useFormikContext<FromData>();
  const [fieldName] = useState(`permissions.${action}`);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (action && values && DependenciesForPermissions[action]) {
      const key = DependenciesForPermissions[action];
      const depVal = values.permissions[key];
      const value = values.permissions[action];
  
      if (!depVal && value) {
        setFieldValue(fieldName, false);
      }
  
      setDisabled(!Boolean(depVal));
    }
  }, [values])

  return typeof action !== "undefined" ? (
    <Checkbox
      name={fieldName}
      disabled={!action || disabled}
      formikControll
    />
  ) : null;
};

const defaultColumns = [
  {
    key: "i18nKey",
    i18nKey: "name"
  },
  {
    key: "read",
    i18nKey: "read",
    render: (action?: string) => <PermissionCtrl action={action} />,
    cellOptions: {
      width: 130
    }
  },
  {
    key: "create",
    i18nKey: "create",
    render: (action?: string) => <PermissionCtrl action={action} />,
    cellOptions: {
      width: 130
    }
  },
  {
    key: "edit",
    i18nKey: "edit",
    render: (action?: string) => <PermissionCtrl action={action} />,
    cellOptions: {
      width: 130
    }
  },
  {
    key: "delete",
    i18nKey: "delete",
    render: (action?: string) => <PermissionCtrl action={action} />,
    cellOptions: {
      width: 130
    }
  }
];

const EntitiesForm = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      defaultColumns.map((c) => {
        const column = i18nTableColumn(c, t);
        switch (column.key) {
          case "i18nKey":
            return { ...column, render: t };
          default:
            return column;
        }
      }),
    [t]
  );

  return (
    <Box my={4}>
      <Box border={1} color="divider" maxHeight={300} overflow="auto">
        <TableTree columns={columns} dataSource={Entities} stickyHeader />
      </Box>
    </Box>
  );
};

export default React.memo(EntitiesForm);
