import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class PaymentApi {
  rootPath: string = "/payment";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.paymentSystems');
  }

  refund = (
    formData: PaymentApi.Refund
  ): Promise<Api.GetAll<PaymentApi.RefundDto>> =>
    this.instance.post(`${this.rootPath}/refund`, formData);

  reversal = (
    formData: PaymentApi.Reversal
  ): Promise<Api.GetAll<PaymentApi.ReversalDto>> =>
    this.instance.post(`${this.rootPath}/void`, formData);
}
