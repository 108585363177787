import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  InputLabel
} from "~/components/UI";
import JSONPretty from "~/components/JSONPretty";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface EventDetailsProps {
  data: EventsLogJournalApi.EventDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      maxHeight: 300,
      overflow: "auto",
      flexWrap: "nowrap"
    }
  })
)(Grid);

/**
 * @memberof EventsLog
 * @component
 * @desc Render detailed information about an Event.
 * @property {EventsLogJournalApi.EventDto} data - Event data;
 */

const EventDetailsDetails = ({ data }: EventDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [eventDetails, setEventDetails] =
    useState<EventsLogJournalApi.EventDetailsDto>();
  const { t } = useTranslation();

  async function getEventDetailes(id: string) {
    setLoading(true);
    try {
      const {
        data: { data }
      } = await api.eventsLogJournal.getEvent(id);
      setEventDetails(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEventDetailes(data.id);
  }, [data]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (eventDetails) {
    return (
      <Container container direction="column" alignItems="stretch">
        <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
          <Typography variant="subtitle1">{t("title.context")}</Typography>
        </Box>
        <Box p={4} flexGrow={1} overflow="auto">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InputLabel>ID</InputLabel>
            </Grid>
            <Grid item>{data.id}</Grid>
          </Grid>
          <JSONPretty data={eventDetails.contextData}></JSONPretty>
        </Box>
      </Container>
    );
  } else {
    return null;
  }
};

export default EventDetailsDetails;
