import { Box, Input, SidebarGroup, Icon } from "~/components/UI";
import DatePicker from "~/components/SideBarFilter/DatePicker";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import Slider from "~/components/SideBarFilter/Slider";
import SelectEnum from "~/components/SideBarFilter/SelectEnum";
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_I18n,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_I18n,
  PAYMENT_TYPE,
  PAYMENT_TYPE_I18n,
} from "~/api/financialTransactions/enums";
import { ENV } from "~/constants";
import numeral from "numeral";
// import { currencyString } from "~/utils/format";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SelectValue from "~/components/SideBarFilter/SelectValue";
import api from "~/api";

interface FilterProps {
  onChangeFilter: (
    data: FinancialTransactionsApi.GetFinancialTransactionsQuery
  ) => void | Promise<void>;
}

const renderAmountValue = (amount: number) =>
  `${ENV.REACT_APP_CURRENCY_SYMBOL} ${numeral(amount / 100).format("0.00a")}`;

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup
          label={t("label.dateTimeCreated")}
          collapsed
          defaultOpen={true}>
          <DatePicker
            label={t("label.from")}
            name="createdFrom"
            maxDateFormikKey="createdTo"
            maxDate={moment().endOf("day").toDate()}
          />
          <DatePicker
            label={t("label.to")}
            name="createdTo"
            minDateFormikKey="createdFrom"
            maxDate={moment().endOf("day").toDate()}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={TRANSACTION_STATUS}
            enumI18n={TRANSACTION_STATUS_I18n}
          />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.transactionType")}
          collapsed
          defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="transactionType"
            enumValue={TRANSACTION_TYPE}
            enumI18n={TRANSACTION_TYPE_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.amount")} collapsed defaultOpen={true}>
          <Slider
            min={0}
            max={2000000}
            maxFieldName="amountTo"
            minFieldName="amountFrom"
            minFieldLabel={renderAmountValue}
            maxFieldLabel={renderAmountValue}
          />
        </SidebarGroup>
        <SidebarGroup
          label={t("label.paymentType")}
          collapsed
          defaultOpen={true}>
          <SelectEnum
            fieldName="paymentType"
            enumValue={PAYMENT_TYPE}
            enumI18n={PAYMENT_TYPE_I18n}
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.merchant")} collapsed defaultOpen={true}>
          <SelectValue
            name="merchantId"
            loadOptions={() =>
              api.merchants.getAll().then(({ data }) =>
                [...data.data].sort().map((o) => ({
                  label: o.merchantName,
                  value: o.id,
                }))
              )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <SidebarGroup label={t("label.contractName")} collapsed defaultOpen={true}>
          <SelectValue
            name="contractId"
            loadOptions={() =>
              api.contracts.getAll().then(({ data }) =>
                [...data.data].sort().map((contract) => ({
                  label: contract.contractName,
                  value: contract.contractId,
                }))
              )
            }
            formikControll
            fullWidth
          />
        </SidebarGroup>
        <IncludeFieldGroup
          label={t("title.openedIcidents")}
          fieldName="hasOpenedIncidents"
        />
      </Box>
    </SideBarFilterContainer>
  );
};

export default Filter;
