import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface TilesProps<T> {
  list?: T[];
  renderTaile: (item: T, key: string) => React.ReactNode;
  customHeight?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "grid",
      gridGap: theme.spacing(4),
      margin: 1,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      [theme.breakpoints.up("xl")]: {
        gridTemplateColumns: "repeat(3, 1fr)",
      },
      [theme.breakpoints.up("xxl")]: {
        gridTemplateColumns: "repeat(4, 1fr)",
      },
    },
    itemContainer: {
      gridRowEnd: "span 1",
    },
  })
);

const Tiles = ({ renderTaile, list, customHeight }: TilesProps<any>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {list &&
        list.map((item, index) =>
          customHeight ? (
            renderTaile(item, `tile-${index}`)
          ) : (
            <div key={`tile-${index}`} className={classes.itemContainer}>
              {renderTaile(item, `tile-${index}`)}
            </div>
          )
        )}
    </div>
  );
};

export default Tiles;
