import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import BinRangesContext from "./binRangeContext";
import CreateOrUpdateBinRangeDialog from "./CreateOrUpdateBinRangeDialog";
import { toast } from "react-toastify";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { useQueryParams, StringParam } from "use-query-params";
import Filter from "./Filter";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const BinRange = () => {
  const [editBinRange, setEditBinRange] = useState<BinRangesApi.BinRangeDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [binRanges, setBinRanges] = useState<BinRangesApi.BinRangeDto[]>();
  const [selectedBinRanges, setSelectedBinRanges] = useState<string[]>([]);
  const [query, setQuery] = useState<BinRangesApi.GetBinRangesQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const tableRef = useRef<TableRef>(null);
  const { hasAll } = usePermissions();
  const { t } = useTranslation();
  const [queryParams] = useQueryParams({
    search: StringParam,
    acquirerId: StringParam
  });
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_BIN_RANGE)
      ? {
          hideSelectAll: false,
          onChange: setSelectedBinRanges
        }
      : undefined
  );

  const getBinRanges = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const { data } = await api.binRanges.getAll(
          limit,
          page || currentPage,
          query
        );
        data.data && setBinRanges(data.data);
        pagination.current = data.meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );
  

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateBinRange = () => {
    setEditBinRange(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (acquirer?: BinRangesApi.BinRangeDto) => {
    setShowEditDialog(false);
    setEditBinRange(undefined);
    acquirer && getBinRanges();
  };

  const onUpdateItem = useCallback((binRange: BinRangesApi.BinRangeDto) => {
    setBinRanges((binRanges) =>
    binRanges ? updateArrayItem(binRanges, binRange, "id") : []
    );
  }, []);

  const onEdit = (binRange: BinRangesApi.BinRangeDto) => {
    setEditBinRange(binRange);
    setShowEditDialog(true);
  };

  const onUpdate = useCallback(
    async (id: string, formData: BinRangesApi.UpdateBinRange) => {
      try {
        const {
          data: { data: acquirer }
        } = await api.binRanges.update(id, formData);
        onUpdateItem(acquirer);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (binRange: BinRangesApi.BinRangeDto) => {
      await deleteConfirm(binRange?.id);
      try {
        await api.binRanges.delete(binRange.id);
        getBinRanges();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getBinRanges, deleteConfirm]
  );

  const onDeleteBinRanges = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedBinRanges?.length }));
    try {
      await api.binRanges.deleteBinRanges(selectedBinRanges);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getBinRanges();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedBinRanges]);

  const onChangePagination = (page: number) => {
    getBinRanges(page);
  };

  const onChangeFilters = (filters: BinRangesApi.GetBinRangesQuery) => {
    pagination.current = undefined;
    setBinRanges([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem]
  );

  useEffect(() => {
    setQuery(queryParams as BinRangesApi.GetBinRangesQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getBinRanges();
  }, [query, getBinRanges, limit]);
 

  return (
    <BinRangesContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.binRanges")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_BIN_RANGE}>
                <Button
                  hidden={!selectedBinRanges.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteBinRanges}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_BIN_RANGE}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateBinRange}
                >
                  {t("title.addBinRange")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          ref={tableRef}
          dataSource={binRanges}
          onRequestSort={onSort}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateBinRangeDialog
        open={showEditDialog}
        binRange={editBinRange}
        onClose={onCloseEditDialog}
      />
    </BinRangesContext.Provider>
  );
};

export default BinRange;
