import { useState, useEffect, useCallback } from "react";
import { Box, Typography, LinearProgress, styled } from "~/components/UI";
import { updateArrayItem } from "~/utils/helpers";
import Search from "~/components/Search";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import { toast } from "react-toastify";
import api from "~/api";

interface ContractDetailsProps {
  contract: ContractsApi.ContractDto;
}

const Container = styled("div")(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto"
}));

/**
 * @memberof Contracts
 * @component
 * @desc Render detailed information about a Contract.
 * @property {ContractsApi.ContractDto} contract - Contract data;
 */

const ContractDetails = ({ contract }: ContractDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState<MerchantsApi.MerchantDto[]>();
  const { t } = useTranslation();

  const getMerchants = useCallback(async (search?: string) => {
    setLoading(true);
    try {
      const {
        data: { data }
      } = await api.contracts.getMerchants(
        contract.contractId,
        contract.merchantCount || 0,
        1,
        { search }
      );
      setMerchants(data);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSearch = useCallback((search: string) => {
    getMerchants(search);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: MerchantsApi.UpdateMerchant) => {
      try {
        const {
          data: { data: merchant }
        } = await api.merchants.update(id, formData);
        setMerchants((merchants) => updateArrayItem(merchants, merchant, "id"));
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t]
  );

  useEffect(() => {
    getMerchants();
  }, []);

  return (
    <Container>
      <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
        <Typography variant="subtitle1">{t("title.merchants")}</Typography>
      </Box>
      <LinearProgress hidden={!loading} />
      <Box my={4} pl={4}>
        <Box maxWidth={500}>
          <Search placeholder={t("text.searchByID")} onSearch={onSearch} />
        </Box>
        <Table dataSource={merchants} onUpdate={onUpdate} />
      </Box>
    </Container>
  );
};

export default ContractDetails;
