import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Autocomplete, OutlinedInput } from "~/components/UI";
import { useField, FormikHandlers } from "formik";
import _ from "lodash";
import api from "~/api";

interface FromikSelectMerchantProps {
  name: string;
  defaultInputValue?: string;
  filterOptions?: (
    options: MerchantsApi.MerchantDto[],
    state: any
  ) => MerchantsApi.MerchantDto[];
}

interface SelectMerchantProps {
  inputValue?: string;
  name?: string;
  onChange?: (merchant?: MerchantsApi.MerchantDto) => void;
  onChangeValue?: (value?: string) => void;
  onBlur?: FormikHandlers["handleBlur"];
  error?: string | undefined;
  touched?: boolean;
  autoClear?: boolean;
  disableClearable?: boolean;
  filterOptions?: (
    options: MerchantsApi.MerchantDto[],
    state: any
  ) => MerchantsApi.MerchantDto[];
  showKey?: string;
}

const requestSearchMerchant = (search: string) =>
  api.merchants.getAll(20, 1, { search });

export const SelectMerchant = React.memo(
  ({
    name = "",
    onChange,
    onChangeValue,
    onBlur = () => {},
    touched,
    error,
    filterOptions,
    inputValue,
    disableClearable = false,
    showKey = "merchantName"
  }: SelectMerchantProps) => {
    const [value, setValue] = useState<string | undefined>(inputValue || "");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<
      MerchantsApi.MerchantDto[]
    >([]);
    const [merchant, setMerchant] =
      useState<Partial<MerchantsApi.MerchantDto> | null>(null);

    const searchMerchants = useMemo(
      () =>
        _.debounce((merchantId: string) => {
          setLoading(true);
          requestSearchMerchant(merchantId)
            .then(({ data }) => {
              setSearchResults(data.data);
            })
            .finally(() => {
              setLoading(false);
            });
        }, 500),
      []
    );

    const onChangeInputValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValue(e.target.value);
      onChangeValue && onChangeValue(e.target.value);
      searchMerchants(e.target.value || "");
    };

    const onChangeAutocomplete = useCallback(
      (_e: React.ChangeEvent<{}>, newValue: any) => {
        if (newValue) {
          setValue(newValue[showKey]);
          setMerchant(newValue);
        } else {
          setValue("");
          setMerchant(null);
          searchMerchants("");
        }
        onChange && onChange(newValue);
      },
      [onChange, showKey]
    );

    const getOptionSelected = useCallback(
      (o: MerchantsApi.MerchantDto, value: MerchantsApi.MerchantDto) =>
        o.id === value.id,
      []
    );

    const getOptionLabel = useCallback(
      (o) => o[showKey] || "",
      [showKey]
    );

    useEffect(() => {
      if (name && !inputValue) {
        setValue("");
        setMerchant(null);
        searchMerchants("");
      }
    }, [name, inputValue]);

    useEffect(() => {
      searchMerchants("");
    }, [searchMerchants]);

    return (
      <Autocomplete
        value={merchant}
        onChange={onChangeAutocomplete}
        getOptionSelected={getOptionSelected}
        onBlur={onBlur}
        noOptionsText="No Merchants"
        options={searchResults}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        filterSelectedOptions={true}
        loading={loading}
        disableClearable={disableClearable}
        renderInput={(params: any) => (
          <OutlinedInput
            className={params.InputProps.className}
            endAdornment={params.InputProps.endAdornment}
            inputProps={_.omit(params.inputProps, "value")}
            value={params.inputProps.value || value}
            touched={touched}
            error={error}
            placeholder="All"
            fullWidth
            onChange={onChangeInputValue}
          />
        )}
      />
    );
  }
);

const FromikContainer = ({
  name,
  defaultInputValue,
  ...other
}: FromikSelectMerchantProps & SelectMerchantProps) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  const onChange = useCallback(
    (merchant?: MerchantsApi.MerchantDto) => {
      field.onChange({
        target: {
          name,
          value: merchant ? merchant.id : ""
        }
      });
    },
    [field, name]
  );

  return (
    <SelectMerchant
      {...field}
      {...other}
      onChange={onChange}
      touched={touched}
      error={error}
      inputValue={defaultInputValue || field.value}
    />
  );
};

export default React.memo(FromikContainer);
