import IncidentsBadge, {
  IncidentsBadgeProps,
} from "~/components/UI/IncidentsBadge";
import ROUTES from "~/constants/routes";
import { useHistory } from "react-router";
import { stringify } from "query-string";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface IncidentsIconProps extends IncidentsBadgeProps {
  incidents?: IncidentsApi.IncidentsCount;
}

const IncidentsIcon = ({
  incidents,
  ...other
}: Omit<IncidentsIconProps, "count">) => {
  const { hasAll } = usePermissions();
  let history = useHistory();

  if (!hasAll(PERMISSION.READ_JOURNAL_INCIDENTS)) {
    return null;
  }

  return incidents ? (
    <IncidentsBadge
      {...other}
      count={incidents.count}
      onClick={() =>
        history.push(
          `${ROUTES.INCIDENTS}?${stringify({
            incidentId: incidents.incidentIds,
          })}`
        )
      }
    />
  ) : null;
};

export default IncidentsIcon;
