import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class UserApi {
  rootPath: string = "/groups";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.groups')
  }

  addGroup = (data: GroupsApi.AddGroup): Promise<Api.GetOne<GroupsApi.GroupDto>> =>
    this.instance.post(this.rootPath, data);

  updateGroup = (groupId: string, data: GroupsApi.UpdateGroup): Promise<Api.GetOne<GroupsApi.GroupDto>> =>
    this.instance.put(`${this.rootPath}/${groupId}`, data);

  getGroups = (limit: number = 10, page: number = 1, query?: GroupsApi.GetGroupsQuery): Promise<Api.GetAll<GroupsApi.GroupDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });

  updateGroups = (data: GroupsApi.UpdateGroups): Promise<Api.GetOne<GroupsApi.GroupDto>> =>
    this.instance.put(this.rootPath, data);

  getGroupRoles = (id: string): Promise<Api.GetOne<GroupsApi.GroupDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${id}`);

  getGroupMembers = (id: string, query?: GroupsApi.GetMembersQuery): Promise<Api.GetAll<GroupsApi.GroupMemberDto>> =>
    this.instance.get(`${this.rootPath}/${id}/members`, 
      { params: deleteEmptyFields(query || {}) }
    );

  updateGroupRoles = (id: string, data: GroupsApi.UpdateGroupRoles): Promise<Api.GetOne<GroupsApi.GroupDto>> =>
    this.instance.put(`${this.rootPath}/${id}`, data);

  deleteGroups = (ids: string[]): Promise<Api.GetOne<{}>> =>
    this.instance.delete(this.rootPath,  { params: { ids } });

  deleteGroup = (id: string): Promise<{}> =>
    this.instance.delete(`${this.rootPath}/${id}`);

  getAzureGroup = (groupName: string): Promise<Api.GetOne<GroupsApi.AzureGroupDto>> =>
    this.instance.get(`${this.rootPath}/${groupName}/azure`);
}
