import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles, createStyles } from "@material-ui/core/styles";

const AccordionDetails = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      display: "block",
      borderBottom: "none",
      padding: `${theme.spacing(4)}px 0`,
      "&$expanded": {
        margin: 0,
      },
    },
  })
)(MuiAccordionDetails);

export default AccordionDetails;
