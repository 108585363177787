import { useState, useEffect } from "react";
import { Label, Box, Typography, Grid, LinearProgress } from "~/components/UI";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
// import { SOURCE_TYPE_I18n } from "~/api/common";
import api from "~/api";

interface IncidentDetailsProps {
  incident: IncidentsApi.IncidentDto;
}

const Container = withStyles(() =>
  createStyles({
    container: {
      maxHeight: 320,
      overflow: "auto"
    }
  })
)(Grid);

/**
 * @memberof Incidents
 * @component
 * @desc Render detailed information about an incident.
 * @property {IncidentsApi.IncidentDto} incident - Incident data;
 */

const IncidentDetails = ({ incident }: IncidentDetailsProps) => {
  const [loadeing, setLoading] = useState(false);
  const [incidentDetails, setIncidentDetails] =
    useState<IncidentsApi.IncidentDetailsDto>();
  const { t } = useTranslation(["translation", "enums"]);

  async function getIncident(id: string) {
    setLoading(true);
    try {
      const {
        data: { data: details }
      } = await api.incidents.getIncident(id);
      setIncidentDetails(details);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getIncident(incident.incidentId);
  }, [incident]);

  if (loadeing) {
    return <LinearProgress />;
  } else if (incidentDetails) {
    return (
      <Container
        container
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Box px={4} py={1} bgcolor="primary.main" color="primary.contrastText">
          <Typography variant="subtitle1">{t("title.general")}</Typography>
        </Box>
        <Box p={4} flexGrow={1}>
          <Grid container>
            <Grid item sm={4}>
              <Label text="ID">{incident.incidentId}</Label>
              <Label text="SmartCloud ID">{incidentDetails.smartCloudId}</Label>
              <Label text={`SmartCloud ${t("label.status")}`}>
                {incidentDetails.smartCloudStatus}
              </Label>
              {/* <Label text={t("label.sourceType")}>
                <span>
                  {t(`enums:${SOURCE_TYPE_I18n[incidentDetails.sourceType]}`)}
                </span>
              </Label>
              <Label text={t("label.sourceId")}>
                {incidentDetails.sourceId}
              </Label> */}
              <Label text={t("label.closedBy")}>
                {incidentDetails.closedBy}
              </Label>
            </Grid>
            <Grid item sm={8}>
              <Label text={t("label.incidentType")}>
                {incidentDetails.type}
              </Label>
              <Label text={t("label.description")}>
                {incidentDetails.description}
              </Label>
              <Label text={t("label.reasonForClosing")}>
                {incidentDetails.reason}
              </Label>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  } else {
    return null;
  }
};

export default IncidentDetails;
