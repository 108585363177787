import { createContext } from 'react';

export interface  BinRangesContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (id: BinRangesApi.BinRangeDto) => void;
  onEdit?: (binRange: BinRangesApi.BinRangeDto) => void;
  onUpdate?: (id: string, formData:  BinRangesApi.UpdateBinRange) => Promise<void>;
  onDelete?: (binRange:  BinRangesApi.BinRangeDto) => Promise<void>;
}

/**
 * @memberof BinRanges
 * @component
 * @desc React context for providing methods of BinRanges to child components.
 * @property {Function} onEdit - callback for open the Edit BinRange dialog. (acquirer: BinRangesApi.BinRangeDto) => void
 * @property {Function} onUpdate - callback for update a BinRange. (id: string, formData: BinRangesApi.UpdateBinRange) => Promise<void>
 * @property {Function} onDelete - callback for the delete a BinRange. (acquirer: BinRangesApi.BinRangeDto) => Promise<void>
 */

const BinRangesContext = createContext<BinRangesContextProps>({});


export default BinRangesContext;