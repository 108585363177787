import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class CardOnFileApi {
  rootPath: string = "/card-on-files";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.cardOnFile')
  }

  getAll = (
    limit: number = 10,
    skipToken?: string,
    query?: CardOnFileApi.GetCardOnFileQuery
  ): Promise<Api.GetAll<CardOnFileApi.CardOnFileDto>> =>
    this.instance.get(this.rootPath, {
      params: {
        limit,
        skipToken,
        ...deleteEmptyFields(query || {}),
      },
    });
}
