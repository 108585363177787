import { enumToI18nKey } from '~/utils/helpers';

export const ID_PREFIX_ENTITY = {
  ACQUIRERS: "Acquirers",
  CONTRACTS: "Contracts",
  MERCHANTS: "Merchants",
  MDES_MERCHANTS: "MDES",
  USER: "User",
  GROUP: "Group",
  ROLE: "Role",
  INBOUND_VIRTUAL_TERMINAL: "Inbound Virtual Terminal",
  OUTBOUND_VIRTUAL_TERMINAL: "Outbound Virtual Terminal",
  WALLETS: "Wallet Credentials",
  KEY_MANAGEMENT: "Key Management",
  BIN_RANGE: "BIN ranges"
} as { [key: string]: IdPrefixApi.eEntity }

export const ID_PREFIX_ENTITY_I18n = enumToI18nKey(ID_PREFIX_ENTITY, 'ID_PREFIX_ENTITY');