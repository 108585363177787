const constants = {
  ACTIONS: {
    LOGGED_IN: "LOGGED_IN",
    LOGIN_ERROR: 'LOGIN_ERROR',
    UPDATE_TOKEN: "UPDATE_TOKEN",
    SET_LOGO: "SET_LOGO",
    USER_AUTHORIZED: "USER_AUTHORIZED",
    LOGGED_OUT: "LOGGED_OUT",
  } as { [key: string]: AuthStore.eActionType },
};

export default constants;
