import { Box, Tabs, Grid, styled } from "~/components/UI";
import { useTranslation } from "react-i18next";
import BindiedOdjects from './BindiedOdjects';

interface KeyDetailsProps {
  keyData: KeyManagementApi.KeyDto;
}

const Container = styled(Grid)(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof Keys
 * @component
 * @desc Render detailed information of Key.
 * @property { KeysApi.KeyDto} Key - Key data;
 */

const KeyDetails = ({
  keyData,
}: KeyDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box flexGrow={1}>
        <Tabs
          tabs={[
            {
              label: t("title.bindiedOdjects"),
              content: <BindiedOdjects keyData={keyData} />
            }
          ]}
        />
      </Box>
    </Container>
  );
};

export default KeyDetails;
