import Table, { TableProps } from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "walletMerchantId",
    i18nKey: "walletMerchantID",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "keyVaultId",
    i18nKey: "keyVaultID",
    cellOptions: {
      width: 300
    }
  },
  {
    key: "description",
    i18nKey: "description"
  }
];

interface MerchantsTableProps extends Omit<TableProps<any>, "columns"> {}

const WalletsTable = (props: MerchantsTableProps) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return <Table {...props} size="small" columns={columns} stickyHeader />;
};

export default WalletsTable;
