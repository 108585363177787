import React from "react";
import { makeStyles, createStyles } from "~/components/UI";
import Table, { TableProps } from "~/components/UI/Table/Table";
import SystemLogDetails from "./SystemLogDetails";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import LinkToObject from "~/components/LinkToObject";
import EnumValue from "~/components/EnumValue";
import { SOURCE_TYPE } from "~/api/common";
import {
  // SERVICE_I18n,
  LOG_TYPE_I18n
  // SEVERITY_I18n,
} from "~/api/systemLogJournal/enums";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flexGrow: 1
    }
  })
);

interface SystemLogsTableProps
  extends Omit<
    TableProps<SystemLogJournalApi.SystemLogDto>,
    "columns" | "rowSelection"
  > {
  onNext?: () => void;
  hasMore?: boolean;
  dataLength?: number;
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  // {
  //   key: "id",
  //   title: "ID",
  //   cellOptions: {
  //     width: 300
  //   }
  // },
  {
    key: "timestamp",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "merchantId",
    i18nKey: "merchant",
    sort: true,
    render: (merchantId: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.MERCHANT} sourceId={merchantId} />
    )
  },
  {
    key: "inboundVtId",
    i18nKey: "inboundVT",
    sort: true,
    render: (inboundId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.INBOUND_VIRTUAL_TERMINAL}
        sourceId={inboundId}
      />
    )
  },
  {
    key: "outboundVtId",
    i18nKey: "outboundVT",
    sort: true,
    render: (outboundId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.OUTBOUND_VIRTUAL_TERMINAL}
        sourceId={outboundId}
      />
    )
  },
  {
    key: "key",
    i18nKey: "type"
    // render: (type: SystemLogJournalApi.eLogType) => (
    //   <EnumValue value={type} enumI18n={LOG_TYPE_I18n} />
    // ),
  },
  {
    key: "logLevel",
    i18nKey: "severity",
    render: (logLevel: SystemLogJournalApi.eLogType) => (
      <EnumValue value={logLevel} enumI18n={LOG_TYPE_I18n} />
    )
  },
  {
    key: "service",
    i18nKey: "source"
  }
];

/**
 * @memberof SystemLogs
 * @component
 * @desc Render table of System Logs.
 * @property {SystemLogJournalApi.SystemLogDto[]} dataSource - System log data ???
 */

const SystemLogsTable = ({
  onSort,
  onNext,
  hasMore,
  dataLength,
  ...props
}: SystemLogsTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return (
    <div className={classes.wrapper}>
      <Table
        {...props}
        columns={columns}
        expandable={{
          highlightRow: true,
          expandedRowRender: (data: SystemLogJournalApi.SystemLogDto) => (
            <SystemLogDetails data={data} />
          )
        }}
        onRequestSort={onSort}
        rowKey="id"
        stickyHeader
      />
    </div>
  );
};

export default React.memo(SystemLogsTable);
