import InfiniteScroll, { Props } from "react-infinite-scroll-component";

export interface InfiniteScrollOptions
  extends Omit<Props, "children" | "loader"> {}

interface InfiniteScrollProps {
  infiniteScrollOptions?: InfiniteScrollOptions;
  children?: React.ReactChild | React.ReactChild[];
}

const InfiniteScrollWrapper = ({
  infiniteScrollOptions,
  children,
}: InfiniteScrollProps) => {
  const { next, hasMore, dataLength } = infiniteScrollOptions || {};

  if (!next || !hasMore || !dataLength) return <>{children}</>;

  return (
    <InfiniteScroll
      {...infiniteScrollOptions}
      {...{ next, hasMore, dataLength }}
      loader={null}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollWrapper;
