import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class WalletsApi {
  rootPath: string = "/wallets";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.catalogWallets')
  }

  createWallet = (data: WalletsApi.CreateWallet): Promise<Api.GetOne<WalletsApi.WalletDto>> =>
    this.instance.post(this.rootPath, data);
  getAll = (limit: number = 10,
    page: number = 1,
    query?: WalletsApi.GetWalletsQuery): Promise<Api.GetAll<WalletsApi.WalletDto>> => {
    if (query && !query.sort) {
      query = { ...query, sort: "id" }
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    })
  };
  getWallet = (walletId: string): Promise<Api.GetOne<WalletsApi.WalletDto>> =>
    this.instance.get(`${this.rootPath}/${walletId}`);
  getWalletDetails = (walletId: string): Promise<Api.GetOne<WalletsApi.WalletDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${walletId}`);
  updateWallet = (walletId: string, data: WalletsApi.UpdateWallet): Promise<Api.GetOne<WalletsApi.WalletDto>> =>
    this.instance.put(`${this.rootPath}/${walletId}`, data);
  deleteWallets = (ids: string[]): Promise<unknown> =>
    this.instance.delete(this.rootPath, { params: { ids } });
  deleteWallet = (walletId: string): Promise<unknown> =>
    this.instance.delete(`${this.rootPath}/${walletId}`);
}
