import { Section, Grid } from "~/components/UI";

interface MetricsGroupProps {
  title: string;
  children?: React.ReactChildren | React.ReactNode;
}

const MetricsGroup = ({ title, children }: MetricsGroupProps) => {
  return (
    <Section title={title} collapsed>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Section>
  );
};

export default MetricsGroup;
