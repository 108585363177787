import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";
import CardOnFiles from "./Components/CardOnFiles";

/**
 * @namespace CardOnFilePage
 */

const CardOnFilePage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.cardOnFile") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <CardOnFiles />
    </>
  );
};

export default CardOnFilePage;
