import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class EventsLogJournalApi {
  rootPath: string = "/journal-events";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.eventsLog')
  }

  getEvents = (
    limit: number = 10,
    query?: EventsLogJournalApi.GetEventsLogsQuery
  ): Promise<Api.GetAll<EventsLogJournalApi.EventDto>> =>
    this.instance.get(this.rootPath, {
      params: { limit, ...deleteEmptyFields(query || {}) }
    });
  getEvent = (
    id: string
  ): Promise<Api.GetOne<EventsLogJournalApi.EventDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${id}`);
  getFilterEventTypes = (): Promise<Api.GetOne<string[]>> =>
    this.instance.get(`${this.rootPath}/eventTypes`);
}
