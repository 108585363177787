import { createContext } from 'react';

export interface MDESMerchantsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (mdesMerchant: MDESMerchantsApi.mdesMerchantDto) => void;
  onEdit?: (mdesMerchant: MDESMerchantsApi.mdesMerchantDto) => void;
  onUpdate?: (id: string, formData:  MDESMerchantsApi.UpdateMerchant) => Promise<void>;
  onDelete?: (mdesMerchant:  MDESMerchantsApi.mdesMerchantDto) => Promise<void>;
}

/**
 * @memberof MDESMerchants
 * @component
 * @desc React context for providing methods of MDESMerchants to child components.
 * @property {Function} onEdit - callback for open the Edit Merchant dialog. (mdesMerchant: MDESMerchantsApi.mdesMerchantDto) => void;
 * @property {Function} onUpdate - callback for update a Merchant. (id: string, formData:  MDESMerchantsApi.UpdateMerchant) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a Merchant. (mdesMerchant:  MDESMerchantsApi.mdesMerchantDto) => Promise<void>;
 */

const MDESMerchantsContext = createContext<MDESMerchantsContextProps>({});


export default MDESMerchantsContext;