import Table, { TableProps } from "~/components/UI/Table/Table";
import { phoneNumber } from "~/utils/format";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";

const defaultColumns = [
  {
    key: "username",
    i18nKey: "userName",
  },
  {
    key: "userPrincipalName",
    i18nKey: "userPrincipalName",
  },
  {
    key: "email",
    title: "Email",
  },
  {
    key: "phone",
    i18nKey: "phone",
    render: phoneNumber,
  },
];

/**
 * @memberof Groups
 * @component
 * @desc Render members of a Group.
 */

const Members = (
  props: Omit<TableProps<GroupsApi.GroupMemberDto>, "columns" | "rowSelection">
) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));
  return <Table {...props} columns={columns} stickyHeader />;
};

export default Members;
