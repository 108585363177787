import { enumToI18nKey } from "~/utils/helpers";

export const SERVICE = {
  API_CLIENT: "API client",
  POS: "POS",
  GATEWAY: "Gateway"
} as { [key: string]: SystemLogJournalApi.eService };

export const SERVICE_I18n = enumToI18nKey(SERVICE, "SERVICE");

export const LOG_TYPE = {
  ERROR: "error",
  LOG: "info"
} as { [key: string]: SystemLogJournalApi.eLogType };

export const LOG_TYPE_I18n = enumToI18nKey(LOG_TYPE, "LOG_TYPE");

export const SEVERITY = {
  EMERGENCY: "Emergency",
  ALERT: "Alert",
  CRITICAL: "Critical"
} as { [key: string]: SystemLogJournalApi.eSeverity };

export const SEVERITY_I18n = enumToI18nKey(SEVERITY, "SEVERITY");
