import React, { createContext, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Box, Icon, IconButton } from "~/components/UI";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface SidebarContextProps {
  open: boolean;
}

export const SidebarContext = createContext<SidebarContextProps>({
  open: true,
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
    },
    list: {
      paddingTop: 37,
      overflowX: "hidden",
    },
    close: {
      padding: 17,
      "& > *": {
        fontSize: 20,
      },
    },
  })
);

export interface MenuProps {
  children?: React.ReactChild | React.ReactChild[];
  open?: boolean;
  width?: number;
  onChangeState?: () => void;
}

interface CloseButtonProps {
  open?: boolean;
  onClick?: () => void;
}

const CloseButton = React.memo(({ open, onClick }: CloseButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      {open ? <Icon name="MenuArrowLeft" /> : <Icon name="MenuArrowRight" />}
    </IconButton>
  );
});

const MenuContainer = ({ children, open, width, onChangeState }: MenuProps) => {
  const classes = useStyles();
  const [sidebarState, setSidebarState] = useState<SidebarContextProps>({
    open: true,
  });

  useEffect(() => {
    if (typeof open !== "undefined") {
      setSidebarState({
        open,
      });
    }
  }, [open]);

  return (
    <SidebarContext.Provider value={sidebarState}>
      <Grid
        className={classes.root}
        container
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Box
          flexGrow={1}
          className={classes.list}
          overflow="auto"
          style={{ width }}
        >
          {children}
        </Box>
        <Grid item className={classes.close}>
          <CloseButton open={open} onClick={onChangeState} />
        </Grid>
      </Grid>
    </SidebarContext.Provider>
  );
};

export default MenuContainer;
