import { TFunction } from "i18next";
import * as Yup from "yup";
import { isEmptyValue } from "~/utils/helpers";

let YupInstance = Yup;

export const initialize = (t: TFunction) => {

  /* String */
  YupInstance.addMethod(Yup.string, "required", function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });
  /* eslint-disable  no-useless-escape */
  YupInstance.addMethod(Yup.string, "website", function () {
    return this.test({
      name: "website",
      message: () => t("validation.website"),
      test: (value) => value ? new RegExp("((?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})").test(value) : true
    })
  });
  /* eslint-disable  no-useless-escape */

  /* Only characters  */
  YupInstance.addMethod(Yup.string, "onlyCharacters", function () {
    return this.test({
      name: "onlyCharacters",
      message: () => t("validation.onlyCharacters"),
      test: (value) => value ? /^[^\s][\w,\W][^\s]+$/.test(value) : true,
    })
  });

  /* no Cyrillic */
  YupInstance.addMethod(Yup.string, "noCyrillic", function () {
    return this.test({
      name: "noCyrillic",
      message: () => t("validation.noCyrillic"),
      test: (value) => value ? !/[а-яёА-ЯЁ]+/.test(value) : true,
    })
  });

  /* edrpou */
  YupInstance.addMethod(Yup.string, "edrpou", function () {
    return this.test({
      name: "edrpou",
      message: () => t("validation.edrpou"),
      test: (value) => value ? /^[\d]{8}$/.test(value) : true,
    })
  });

  /* URL  */
  YupInstance.addMethod(Yup.string, "url", function () {
    return this.test({
      name: "url",
      message: () => t("validation.url"),
      test: (value) => value ? /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/.test(value) : true,
    })
  });

  /* ID Prefix */
  YupInstance.addMethod(Yup.string, "idPrefix", function () {
    return this.test({
      name: "idPrefix",
      message: () => t("validation.idPrefix"),
      test: (value) => value ? /^[A-Z, a-z]{2}$/.test(value) : true
    })
  });

  /* Number */
  YupInstance.addMethod(Yup.number, "required",  function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  YupInstance.addMethod(Yup.number, "mfo",  function () {
    return this.test({
      name: "mfo",
      message: () => t("validation.mfo"),
      test: (value) => value ? /^[0-9]{6}$/.test(`${value}`) : true,
    })
  });

   /* price */
  YupInstance.addMethod(Yup.number, "price", function () {
    return this.test({
      name: "price",
      message: () => t("validation.price"),
      test: (value) => value ? /^[\d]+\.{0,1}[\d]{0,2}$/.test(String(value)) : true,
    })
  });

  /* Date */
  YupInstance.addMethod(Yup.date, "required",  function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Array */
  YupInstance.addMethod(Yup.array, "required",  function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  YupInstance.addMethod(Yup.array, "min",  function (minLength) {
    return this.test({
      name: "min",
      message: () => `${t("validation.min")} ${minLength}`,
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Object */
  YupInstance.addMethod(Yup.object, "required",  function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });

  /* Boolean */
  YupInstance.addMethod(Yup.boolean, "required",  function () {
    return this.test({
      name: "required",
      message: () => t("validation.required"),
      test: (value) => !isEmptyValue(value),
    })
  });
};

export default YupInstance as any;
