import light from "./light";
import dark from "./dark";

const themes: {
  [key: string]: CustomTheme.RootObject;
} = {
  light,
  dark,
};

export const eThemeType = {
  LIGHT: "light",
  DARK: "dark",
} as { [key: string]: CustomTheme.eThemeType };

export const getTheme = function (theme: CustomTheme.eThemeType) {
  return themes[theme];
};
