import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  Box,
  Paper,
  Fade,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface PopconfirmProps {
  cancelText?: string;
  disabled?: boolean;
  placement?: PopperPlacementType;
  okText?: string;
  okType?: string;
  title?: string | React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => Promise<unknown> | void;
  children?: React.ReactChild;
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.tooltip,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "0 100%",
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.71em",
        marginLeft: 4,
        marginRight: 4,
        "&::before": {
          transformOrigin: "100% 0",
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "100% 100%",
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.71em",
        height: "1em",
        width: "0.71em",
        marginTop: 4,
        marginBottom: 4,
        "&::before": {
          transformOrigin: "0 0",
        },
      },
    },
    paper: {
      minWidth: 180,
      boxSizing: "border-box",
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    },
    container: {
      display: "inherit",
      "&.disabled": {
        cursor: "not-allowed",
      },
    },
    arrow: {
      overflow: "hidden",
      position: "absolute",
      width: "1em",
      height: "0.71em",
      boxSizing: "border-box",
      color: theme.palette.background.paper,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: theme.shadows[1],
        backgroundColor: "currentColor",
        transform: "rotate(45deg)",
      },
    },
  })
);

const Popconfirm = (
  {
    children,
    cancelText = "No",
    disabled,
    okText = "Yes",
    title = "Are you sure?",
    placement = "left-start",
    onCancel,
    onConfirm,
  }: PopconfirmProps,
  ref: any
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (disabled) return;
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const onConfirmHandler = useCallback(() => {
    const res = onConfirm && onConfirm();

    if (res && res.finally) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
        setOpen(false);
      });
    } else {
      setOpen(false);
    }
  }, [onConfirm]);

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div
        className={clsx(classes.container, { disabled: disabled })}
        onClick={onClickHandler}
      >
        <Popper
          className={classes.root}
          open={open}
          anchorEl={anchorEl}
          ref={ref}
          placement={placement}
          disablePortal={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper}>
                <span className={classes.arrow} ref={setArrowRef} />
                <Box mb={2}>
                  <Typography>{title}</Typography>
                </Box>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    color="primary"
                    loading={loading}
                    onClick={onConfirmHandler}
                  >
                    {okText}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={loading}
                    onClick={() => {
                      onCancel && onCancel();
                      setOpen(false);
                    }}
                  >
                    {cancelText}
                  </Button>
                </ButtonGroup>
              </Paper>
            </Fade>
          )}
        </Popper>
        {children}
      </div>
    </ClickAwayListener>
  );
};

export default React.forwardRef<unknown, PopconfirmProps>(Popconfirm);
