import { useCallback, useState, useMemo } from "react";
import * as React from "react";
import { IncidentsContext, Options } from "./CreateIncidentContext";
import CreateIncidentDialog from "./CreateIncidentDialog";

export interface CreateIncidentDProps {
  children: React.ReactNode;
}

export const CreateIncidentProvider = ({ children }: CreateIncidentDProps) => {
  const [sourceId, setSourceId] = useState("");
  const [sourceType, setSourceType] = useState<Api.eSourceType>();
  const [isShown, setShown] = useState(false);
  const [options, setOptions] = useState<Options>();

  const createIncident = useCallback((sourceId, options) => {
    setSourceId(sourceId);
    setSourceType(options.sourceType);
    setOptions(options);
    setShown(true);
  }, []);

  const onClose = useCallback(
    (incident?: IncidentsApi.IncidentDto) => {
      setShown(false);
      if (incident && options) {
        options.onCreated && options.onCreated(incident);
      }
    },
    [options]
  );

  const contextValue = useMemo(() => ({ createIncident }), [createIncident]);

  return (
    <IncidentsContext.Provider value={contextValue}>
      <React.Fragment>{children}</React.Fragment>
      <CreateIncidentDialog
        sourceType={sourceType}
        sourceId={sourceId}
        open={isShown}
        onClose={onClose}
      />
    </IncidentsContext.Provider>
  );
};

export default CreateIncidentProvider;
