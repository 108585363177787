import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import Merchants from "./Components/Merchants";
import { useTranslation } from "react-i18next";

/**
 * @namespace MerchantsPage
 */

const MerchantsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.catalogs") }, { label: t("title.merchants") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <Merchants />
    </>
  );
};

export default MerchantsPage;
