import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import MDESMerchantsContext from "./MDESMerchantsContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface MDESMerchantOptionsProps {
  mdesMerchant: MDESMerchantsApi.mdesMerchantDto;
}

/**
 * @memberof MDESMerchants
 * @component
 * @desc MDESMerchant options. Using a MDESMerchantsContext.
 * @property {MDESMerchantsApi.MerchantDto} merchant - merchant data;
 * @requires MDESMerchantsContext
 */

const MDESMerchantOptions = ({ mdesMerchant }: MDESMerchantOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdateItem, onDelete } = useContext(MDESMerchantsContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.MDES_MERCHANT);
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (mdesMerchant.incidents) {
        mdesMerchant.incidents.count++;
        mdesMerchant.incidents.incidentIds.push(incident.incidentId);
      } else {
        mdesMerchant.incidents = {
          count: 1,
          incidentIds: [incident.incidentId]
        };
      }
      onUpdateItem && onUpdateItem(mdesMerchant);
    },
    [mdesMerchant, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_MDES_MERCHANT,
      PERMISSION.DELETE_MDES_MERCHANT,
      PERMISSION.CREATE_JOURNAL_INCIDENTS
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_MDES_MERCHANT),
      onClick: () => onEdit && onEdit(mdesMerchant)
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_MDES_MERCHANT),
      onClick: () => onDelete && onUpdateHandler(onDelete(mdesMerchant))
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () =>
        createIncident(mdesMerchant.mdesMerchantId, onCreatedIncident)
    }
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default MDESMerchantOptions;
