import { createContext } from 'react';

export interface  ContractsContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (contract: ContractsApi.ContractDto) => void;
  onEdit?: (contract: ContractsApi.ContractDto) => void;
  onUpdate?: (id: string, formData:  ContractsApi.UpdateContract) => Promise<void>;
  onDelete?: (contract:  ContractsApi.ContractDto) => Promise<void>;
}

/**
 * @memberof Contracts
 * @component
 * @desc React context for providing methods of Contract to child components.
 * @property {Function} onEdit - callback for open the Edit Contract dialog. (contract: ContractsApi.ContractDto) => void;
 * @property {Function} onUpdate - callback for update a Contract. (id: string, formData:  ContractsApi.UpdateContract) => Promise<void>;
 * @property {Function} onDelete - callback for the delete a Contract. (contract:  ContractsApi.ContractDto) => Promise<void>;
 */

const ContractsContext = createContext<ContractsContextProps>({});


export default ContractsContext;