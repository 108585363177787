import Constants from "./constants";

const { ACTIONS } = Constants;

const initialState: AuthStore.State = {
  isAuthenticated: false,
  isAuthorized: false,
};

const Reducer = (
  state = initialState,
  action: AuthStore.Actions
): AuthStore.State => {
  switch (action.type) {
    case ACTIONS.LOGGED_IN:
      return {
        ...state,
        isAuthenticated: true,
        authData: action.payload
      };
    case ACTIONS.LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isAuthorized: false,
        authData: undefined,
        error: action.error
      }
    case ACTIONS.UPDATE_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        authData: action.payload
      };
    case ACTIONS.USER_AUTHORIZED:
      return {
        ...state,
        isAuthorized: true,
        user: action.payload
      };
    case ACTIONS.SET_LOGO:
      return {
        ...state,
        isAuthorized: true,
        user: {
          ...state.user,
          companyLogo: action.payload
        } as AuthApi.UserDto
      };
    case ACTIONS.LOGGED_OUT:
      return {
        isAuthenticated: false,
        isAuthorized: false,
      };
    default:
      return state;
  }
};

export default Reducer;
