import { PERMISSION } from "~/api/permissions/enums";

type Entitie = {
  label?: string;
  i18nKey?: string;
  read?: string | null;
  create?: string | null;
  edit?: string | null;
  delete?: string | null;
  items?: Entitie[];
};

export const Entities: Entitie[] = [
  {
    i18nKey: "title.catalogs",
    items: [
      {
        i18nKey: "title.contracts",
        read: PERMISSION.READ_CONTRACT,
        create: PERMISSION.CREATE_CONTRACT,
        edit: PERMISSION.EDIT_CONTRACT,
        delete: PERMISSION.DELETE_CONTRACT,
      },
      {
        i18nKey: "title.binRanges",
        read: PERMISSION.CREATE_BIN_RANGE,
        create: PERMISSION.READ_BIN_RANGE,
        edit: PERMISSION.EDIT_BIN_RANGE,
        delete: PERMISSION.DELETE_BIN_RANGE,
      },
      {
        i18nKey: "title.merchants",
        read: PERMISSION.READ_MERCHANT,
        create: PERMISSION.CREATE_MERCHANT,
        edit: PERMISSION.EDIT_MERCHANT,
        delete: PERMISSION.DELETE_MERCHANT,
      },
      {
        i18nKey: "title.mdesMerchants",
        read: PERMISSION.READ_MDES_MERCHANT,
        create: PERMISSION.CREATE_MDES_MERCHANT,
        edit: PERMISSION.EDIT_MDES_MERCHANT,
        delete: PERMISSION.DELETE_MDES_MERCHANT,
      },
      {
        i18nKey: "title.inboundVirtualTerminals",
        read: PERMISSION.READ_INBOUND_VIRTUAL_TERMINAL,
        create: PERMISSION.CREATE_INBOUND_VIRTUAL_TERMINAL,
        edit: PERMISSION.EDIT_INBOUND_VIRTUAL_TERMINAL,
        delete: PERMISSION.DELETE_INBOUND_VIRTUAL_TERMINAL,
      },
      {
        i18nKey: "title.acquirers",
        read: PERMISSION.READ_ACQUIRER,
        create: PERMISSION.CREATE_ACQUIRER,
        edit: PERMISSION.EDIT_ACQUIRER,
        delete: PERMISSION.DELETE_ACQUIRER,
      },
      {
        i18nKey: "title.cardOnFile",
        read: PERMISSION.READ_CARD_ON_FILE,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.keyManagement",
        read: PERMISSION.READ_KEY_MANAGEMENT,
        create: PERMISSION.CREATE_KEY_MANAGEMENT,
        edit: null,
        delete: PERMISSION.DELETE_KEY_MANAGEMENT,
      },
      {
        i18nKey: "title.outboundVirtualTerminals",
        read: PERMISSION.READ_OUTBOUND_VIRTUAL_TERMINAL,
        create: PERMISSION.CREATE_OUTBOUND_VIRTUAL_TERMINAL,
        edit: PERMISSION.EDIT_OUTBOUND_VIRTUAL_TERMINAL,
        delete: PERMISSION.DELETE_OUTBOUND_VIRTUAL_TERMINAL,
      },
      {
        i18nKey: "title.walletCredentials",
        read: PERMISSION.READ_WALLET_CREDENTIALS,
        create: PERMISSION.CREATE_WALLET_CREDENTIALS,
        edit: PERMISSION.EDIT_WALLET_CREDENTIALS,
        delete: PERMISSION.DELETE_WALLET_CREDENTIALS,
      },
      {
        i18nKey: "title.roles",
        read: PERMISSION.READ_ROLE,
        create: PERMISSION.CREATE_ROLE,
        edit: PERMISSION.EDIT_ROLE,
        delete: PERMISSION.DELETE_ROLE,
      },
      {
        i18nKey: "title.users",
        read: PERMISSION.READ_USER,
        create: PERMISSION.CREATE_USER,
        edit: PERMISSION.EDIT_USER,
        delete: PERMISSION.DELETE_USER,
      },
      {
        i18nKey: "title.groups",
        read: PERMISSION.READ_GROUP,
        create: PERMISSION.CREATE_GROUP,
        edit: PERMISSION.EDIT_GROUP,
        delete: PERMISSION.DELETE_GROUP,
      },
    ],
  },
  {
    i18nKey: "title.journals",
    items: [
      {
        i18nKey: "title.financialTransactions",
        read: PERMISSION.READ_JOURNAL_FINANCIAL_TRANSACTIONS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.eventsLog",
        read: PERMISSION.READ_EVENT_LOG,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.incidents",
        read: PERMISSION.READ_JOURNAL_INCIDENTS,
        create: PERMISSION.CREATE_JOURNAL_INCIDENTS,
        edit: PERMISSION.EDIT_JOURNAL_INCIDENTS,
        delete: null,
      },
      {
        i18nKey: "title.systemLog",
        read: PERMISSION.READ_JOURNAL_SYSTEM_LOG,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.userActivityLog",
        read: PERMISSION.READ_JOURNAL_OF_USERS_ACTIONS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.auditKeyManagement",
        read: PERMISSION.READ_AUDIT_LOG_KEY_MANAGEMENT,
        create: null,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    i18nKey: "title.metrics",
    items: [
      {
        i18nKey: "title.financialTransactionCounts",
        read: PERMISSION.READ_FINANCIAL_TRANSACTION_COUNTS_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.clientPaymentDuration",
        read: PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
      {
        i18nKey: "title.PSPPaymentDuration",
        read: PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS,
        create: null,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    i18nKey: "title.systemSettings",
    items: [
      {
        i18nKey: "title.companyProfile",
        read: PERMISSION.READ_SYSTEM_SETTINGS,
        create: PERMISSION.CREATE_SYSTEM_SETTINGS,
        edit: PERMISSION.EDIT_SYSTEM_SETTINGS,
        delete: PERMISSION.DELETE_SYSTEM_SETTINGS,
      },
      {
        i18nKey: "title.prefixes",
        read: PERMISSION.READ_ID_PREFIX,
        create: PERMISSION.CREATE_ID_PREFIX,
        edit: PERMISSION.EDIT_ID_PREFIX,
        delete: PERMISSION.DELETE_ID_PREFIX,
      },
    ],
  },
];

function generateDependencies(entities: Entitie[]) {
  const res: { [key: string]: string } = {};

  function iterator(item: Entitie) {
    if (typeof item.read === "string") {
      if (item.create) res[item.create] = item.read;
      if (item.edit) res[item.edit] = item.read;
      if (item.delete) res[item.delete] = item.read;
    }

    if (item.items) {
      item.items.forEach(iterator);
    }
  }

  entities.forEach(iterator);

  return res;
}

export const DependenciesForPermissions = generateDependencies(Entities);

export const Processes = [
  {
    i18nKey: "option.financialTransactionIncidents",
    execute: PERMISSION.FINANCIAL_TRANSACTION_INCIDENTS,
  },
  {
    i18nKey: "option.refundForFinancialTransactions",
    execute: PERMISSION.REFUND_FOR_FINANCIAL_TRANSACTIONS,
  },
  {
    i18nKey: "option.reversalForFinancialTransactions",
    execute: PERMISSION.REVERSAL_FOR_FINANCIAL_TRANSACTIONS,
  },
];
