import { Typography, makeStyles, createStyles } from "~/components/UI";
import { useTranslation } from "react-i18next";

const getStyles = makeStyles(() =>
  createStyles({
    container: {
      "& > div + .message": {
        display: "none",
      },
    },
  })
);

const NoPermissionsForMetrics = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const classes = getStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {children}
      <Typography className="message" variant="body1" color="textSecondary">
        {t("text.noPermissionsForMetrics")}
      </Typography>
    </div>
  );
};

export default NoPermissionsForMetrics;
