import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class AcquirersApi {
  rootPath: string = "/acquirers";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.acquirers')
  }

  create = (data: AcquirersApi.CreateAcquirer) =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: AcquirersApi.GetAcquirersQuery
  ): Promise<Api.GetAll<AcquirersApi.AcquirerDto>> =>{
    if (query && !query.sort) {
      query =  {...query, sort: "id"}
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    })};
  getAcquirer = (acquirerId: string) =>
    this.instance.get(`${this.rootPath}/${acquirerId}`);
  getAcquirerEndpoints = (
    acquirerId: string,
    limit: number = 10,
    page: number = 1,
    query?: AcquirersApi.GetAcquirerDetailsQuery
  ): Promise<Api.GetAll<AcquirersApi.EndpointDto>> =>
    this.instance.get(`${this.rootPath}/${acquirerId}/acquirer-endpoints`, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    });
  getAcquirerEndpointTypes = (): Promise<Api.GetAll<string>> =>
    this.instance.get(`${this.rootPath}/acquirerEndpointTypes`);
  updateAcquirerEndpoint = (
    acquirerId: string,
    endpointId: string,
    formData: AcquirersApi.UpdateEndpoint
  ): Promise<Api.GetOne<AcquirersApi.EndpointDto>> =>
    this.instance.put(
      `${this.rootPath}/${acquirerId}/acquirer-endpoints/${endpointId}`,
      formData
    );
  deleteAcquirerEndpoint = (acquirerId: string, endpointId: string) =>
    this.instance.delete(
      `${this.rootPath}/${acquirerId}/acquirer-endpoints/${endpointId}`
    );
  update = (acquirerId: string, data: AcquirersApi.UpdateAcquirer) =>
    this.instance.put(`${this.rootPath}/${acquirerId}`, data);
  deleteAcquirers = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (acquirerId: string) =>
    this.instance.delete(`${this.rootPath}/${acquirerId}`);
}
