import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import EnumCheckboxGroup from "~/components/SideBarFilter/EnumCheckboxGroup";
import { USER_STATUS, USER_STATUS_I18n } from "~/api/users/enums";
import { useTranslation } from "react-i18next";

interface FilterProps {
  onChangeFilter: (
    data: IncidentsApi.GetIncidentsQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          fullWidth
          formikControll
        />
        <SidebarGroup label={t("label.status")} collapsed defaultOpen={true}>
          <EnumCheckboxGroup
            fieldName="status"
            enumValue={USER_STATUS}
            enumI18n={USER_STATUS_I18n}
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
