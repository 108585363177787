import { useState } from "react";
import { Box, Button, ButtonGroup, Grid } from "~/components/UI";
import ProfileDetails from "./Components/ProfileDetails";
import DashboardSettingsDialog from "./Components/DashboardSettingsDialog";
import SelectLanguage from "./SelectLanguage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ROUTES from "~/constants/routes";
import api from "~/api";

/**
 * @namespace MyProfile
 */

const MyProfile = () => {
  const [openDashboardSettings, setOpenDashboardSettings] = useState(false);
  const history = useHistory();
  const userDetails = useSelector(
    (store: ReduxStore.State) => store.profile.profile
  );
  const { t } = useTranslation();

  return (
    <>
      <Box flexGrow={1} p={6} bgcolor="background.paper" overflow="auto">
        <ProfileDetails profile={userDetails} />
      </Box>
      <Box mt={4} p={6} bgcolor="background.paper">
        <Grid container justifyContent="space-between">
          <Grid item>
            <ButtonGroup>
              <Button
                variant="outlined"
                onClick={() => setOpenDashboardSettings(true)}
              >
                {t("button.dashboardSettings")}
              </Button>
              {/* <Button prefixIcon={<Icon name="Moon" />} variant="outlined">
                {t("button.darkTheme")}
              </Button> */}
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonGroup>
              <SelectLanguage />
              <Button
                variant="contained"
                color="danger"
                onClick={() =>
                  api.auth.logout().then(() => history.push(ROUTES.LOGOUT))
                }
              >
                {t("button.logout")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <DashboardSettingsDialog
        open={openDashboardSettings}
        onClose={() => setOpenDashboardSettings(false)}
      />
    </>
  );
};

export default MyProfile;
