import { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Divider,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import UpdateRoleDialog from "./UpdateRole";
import { useQueryParams, StringParam } from "use-query-params";
import { getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import usePermissions from "~/hook/usePermissions";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";
import Search from "~/components/Search";

/**
 * @memberof Roles
 * @component
 * @desc Roles Controller.
 */

const RolesList = () => {
  const [roles, setRoles] = useState<RolesApi.RoleDto[]>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [query, setQuery] = useState<RolesApi.GetRolesQuery>();
  const [search, setSearch] = useState<string>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editRole, setEditRole] = useState<RolesApi.RoleDto>();
  const [queryParams] = useQueryParams({
    search: StringParam,
    roleId: StringParam
  });
  const deleteConfirm = useConfirmDialog("delete");
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_ROLE)
      ? {
          hideSelectAll: false,
          onChange: setSelectedRoles
        }
      : undefined
  );

  const getUserRoles = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.roles.getAll(limit, page || currentPage, {
          ...query,
          search
        });
        data && setRoles(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit, search]
  );

  const openCreateRoleDialog = () => {
    setEditRole(undefined);
    setShowCreateDialog(true);
  };

  const onEdit = useCallback((role: RolesApi.RoleDto) => {
    setEditRole(role);
    setShowCreateDialog(true);
  }, []);

  const onDelete = useCallback(
    async (role: RolesApi.RoleDto) => {
      await deleteConfirm(role?.name);
      try {
        await api.roles.deleteRole(role.roleId);
      } finally {
        getUserRoles();
      }
    },
    [deleteConfirm, getUserRoles]
  );

  const onDeleteRoles = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedRoles?.length }));
    try {
      await api.roles.deleteRoles(selectedRoles);
      getUserRoles();
      toast.success(t("text.recordsSuccessfullyDeleted"));
      setSelectedRoles([]);
    } catch (e) {
      console.error(e);
    }
  }, [t, selectedRoles, deleteConfirm, getUserRoles]);

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = (page: number) => {
    getUserRoles(page);
  };

  const onCloseEditRoleDialog = useCallback(
    (result?: boolean) => {
      setShowCreateDialog(false);
      setEditRole(undefined);
      result && getUserRoles();
    },
    [setShowCreateDialog, setEditRole, getUserRoles]
  );

  useEffect(() => {
    setQuery(queryParams as RolesApi.GetRolesQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getUserRoles();
  }, [query, getUserRoles, limit]);

  return (
    <>
      <Box pr={2}>
        <Section
          title={t("title.userRoles")}
          extra={
            <Grid container alignContent="center">
              <Box width={300} mt={-1}>
                <Search
                  placeholder={t("text.searchByRoleName")}
                  onSearch={setSearch}
                />
              </Box>
              <Grid item>
                <ButtonGroup>
                  <CanIUse permissions={PERMISSION.DELETE_ROLE}>
                    <Button
                      hidden={!selectedRoles.length}
                      prefixIcon={<Icon name="Delete" />}
                      variant="contained"
                      onClick={onDeleteRoles}
                    >
                      {t("button.delete")}
                    </Button>
                  </CanIUse>
                  <CanIUse permissions={PERMISSION.CREATE_ROLE}>
                    <Button
                      prefixIcon={<Icon name="Plus" />}
                      variant="contained"
                      onClick={openCreateRoleDialog}
                    >
                      {t("button.createNewRole")}
                    </Button>
                  </CanIUse>
                </ButtonGroup>
              </Grid>
            </Grid>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          dataSource={roles}
          onSort={onSort}
          onEdit={onEdit}
          onDelete={onDelete}
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        <Divider />
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <UpdateRoleDialog
        role={editRole}
        open={showCreateDialog}
        onClose={onCloseEditRoleDialog}
      />
    </>
  );
};

export default RolesList;
