import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import CreateOrUpdateContractDialog from "./CreateOrUpdateContractDialog";
import ContractsContext from "./contractsContext";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryParams, StringParam } from "use-query-params";
import usePermissions from "~/hook/usePermissions";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const Contracts = () => {
  const [editContract, setEditContract] = useState<ContractsApi.ContractDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [contracts, setContracts] = useState<ContractsApi.ContractDto[]>();
  const [query, setQuery] = useState<ContractsApi.GetContractsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    contractId: StringParam
  });
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_CONTRACT)
      ? {
          hideSelectAll: false,
          onChange: setSelectedContracts
        }
      : undefined
  );

  const getContracts = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.contracts.getAll(20, page || currentPage, query);
        data && setContracts(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateContract = () => {
    setEditContract(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (contract?: ContractsApi.ContractDto) => {
    setShowEditDialog(false);
    setEditContract(undefined);
    contract && getContracts();
  };

  const onUpdateItem = useCallback((contract: ContractsApi.ContractDto) => {
    setContracts((contracts) =>
      contract ? updateArrayItem(contracts, contract, "contractId") : []
    );
  }, []);

  const onEdit = useCallback((contract: ContractsApi.ContractDto) => {
    setEditContract(contract);
    setShowEditDialog(true);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: ContractsApi.UpdateContract) => {
      try {
        const {
          data: { data: contract }
        } = await api.contracts.update(id, formData);
        onUpdateItem(contract);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (contract: ContractsApi.ContractDto) => {
      await deleteConfirm(contract?.contractName);
      try {
        await api.contracts.delete(contract.contractId);
        getContracts();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getContracts, deleteConfirm]
  );

  const onDeleteContracts = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedContracts?.length }));
    try {
      await api.contracts.deleteContracts(selectedContracts);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getContracts();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedContracts]);

  const onChangePagination = (page: number) => {
    getContracts(page);
  };

  const onChangeFilters = (filters: ContractsApi.GetContractsQuery) => {
    pagination.current = undefined;
    setContracts([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(queryParams as ContractsApi.GetContractsQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getContracts();
  }, [query, getContracts, limit]);

  return (
    <ContractsContext.Provider value={contextValue}>
      <Box pr={2}>
        <Section
          title={t("title.contracts")}
          extra={
            <ButtonGroup>
              <CanIUse permissions={PERMISSION.DELETE_CONTRACT}>
                <Button
                  hidden={!selectedContracts.length}
                  prefixIcon={<Icon name="Delete" />}
                  variant="contained"
                  onClick={onDeleteContracts}
                >
                  {t("button.delete")}
                </Button>
              </CanIUse>
              <Filter onChangeFilter={onChangeFilters} />
              <CanIUse permissions={PERMISSION.CREATE_CONTRACT}>
                <Button
                  prefixIcon={<Icon name="Plus" />}
                  variant="contained"
                  onClick={onCreateContract}
                >
                  {t("button.addContract")}
                </Button>
              </CanIUse>
            </ButtonGroup>
          }
        />
        <LinearProgress hidden={!loading} />
      </Box>
      <Box flexGrow={1} overflow="auto" mr={2} bgcolor="background.paper">
        <Table
          dataSource={contracts}
          onRequestSort={onSort}
          rowKey="contractId"
          rowSelection={rowSelection}
        />
      </Box>
      <Box pr={2}>
        {pagination.current && (
          <Pagination
            prefContent={
              <RowsPerPage initialValue={limit} onChange={setLimit} />
            }
            count={pagination.current.totalPages}
            page={pagination.current.page}
            defaultPage={1}
            onChange={(_e, page) => {
              onChangePagination(page);
            }}
          />
        )}
      </Box>
      <CreateOrUpdateContractDialog
        open={showEditDialog}
        contract={editContract}
        onClose={onCloseEditDialog}
      />
    </ContractsContext.Provider>
  );
};

export default Contracts;
