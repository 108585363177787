import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class AuditKeyManagementJournalApi {
  rootPath: string = "/journal-audit-key-management";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.auditKeyManagement')
  }

  getAuditKeyManagement = (limit: number = 10, page: number = 1, query?: AuditKeyManagementJournalApi.GetAuditKeyManagementQuery): Promise<Api.GetAll<AuditKeyManagementJournalApi.AuditKeyManagementDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });
}
