import { useState } from "react";
import { MUIFormControlLabel, Select } from "~/components/UI";
import { ROWS_PER_PAGE_OPTIONS } from "~/constants";
import { useTranslation } from "react-i18next";

const options = ROWS_PER_PAGE_OPTIONS.map((v) => ({ label: `${v}`, value: v }));

interface RowsPerPageProps {
  initialValue?: number;
  onChange: (value: number) => void;
}

const RowsPerPage = ({ initialValue, onChange }: RowsPerPageProps) => {
  const [value, setValue] = useState(initialValue || options[0].value);
  const { t } = useTranslation();

  return (
    <MUIFormControlLabel
      labelPlacement="start"
      control={
        <Select
          value={value}
          options={options}
          onChange={(e) => {
            const value = e.target.value as number;
            onChange(value);
            setValue(value);
          }}
        />
      }
      label={t("label.showRows")}
    />
  );
};

export default RowsPerPage;
