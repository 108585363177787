import Table, { TableProps } from "~/components/UI/Table/Table";
import LinkToObject from "~/components/LinkToObject";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import { SOURCE_TYPE } from "~/api/common";

const defaultColumns = [
  {
    key: "createdAt",
    i18nKey: "created",
    sort: true,
    cellOptions: {
      width: 185
    },
    render: formatDateWithTime
  },
  {
    key: "updatedAt",
    i18nKey: "updated",
    sort: true,
    cellOptions: {
      width: 185
    },
    render: formatDateWithTime
  },
  {
    key: "userId",
    i18nKey: "userId",
    sort: true
    // render: (userId: string) => (
    //   <LinkToObject sourceType={SOURCE_TYPE.USER} sourceId={userId} />
    // )
  },
  // {
  //   key: "tur",
  //   title: "TUR"
  // },
  {
    key: "alias",
    i18nKey: "cardAlias",
    sort: true
  },
  {
    key: "merchantId",
    i18nKey: "merchantID",
    render: (merchantID: string) => (
      <LinkToObject sourceType={SOURCE_TYPE.MERCHANT} sourceId={merchantID} />
    )
  },
  {
    key: "mdesMerchantId",
    i18nKey: "mdesMerchantId",
    render: (mdesMerchantId: string) => (
      <LinkToObject
        sourceType={SOURCE_TYPE.MDES_MERCHANT}
        sourceId={mdesMerchantId}
      />
    )
  }
];

const CardOnFileTable = (
  props: Omit<TableProps<CardOnFileApi.CardOnFileDto>, "columns">
) => {
  const { t } = useTranslation();

  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return <Table {...props} columns={columns} stickyHeader />;
};

export default CardOnFileTable;
