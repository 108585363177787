import { createContext } from 'react';

export interface  AcquirersContextProps {
  onSelect?: (ids: string[]) => void;
  onSort?: (field: string, order: "desc" | "asc") => void;
  onUpdateItem?: (acquirer: AcquirersApi.AcquirerDto) => void;
  onEdit?: (acquirer: AcquirersApi.AcquirerDto) => void;
  onUpdate?: (id: string, formData:  AcquirersApi.UpdateAcquirer) => Promise<void>;
  onDelete?: (acquirer:  AcquirersApi.AcquirerDto) => Promise<void>;
}

/**
 * @memberof Acquirers
 * @component
 * @desc React context for providing methods of Acquirers to child components.
 * @property {Function} onEdit - callback for open the Edit Acquirer dialog. (acquirer: AcquirersApi.AcquirerDto) => void
 * @property {Function} onUpdate - callback for update a Acquirer. (id: string, formData: AcquirersApi.UpdateAcquirer) => Promise<void>
 * @property {Function} onDelete - callback for the delete a Acquirer. (acquirer: AcquirersApi.AcquirerDto) => Promise<void>
 */

const AcquirersContext = createContext<AcquirersContextProps>({});


export default AcquirersContext;