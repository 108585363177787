import React from "react";
import { Box, Icon, Input, SidebarGroup } from "~/components/UI";
import SideBarFilterContainer from "~/components/SideBarFilter/SideBarFilterContainer";
import IncludeFieldGroup from "~/components/SideBarFilter/IncludeFieldGroup";
import SelectEnum from "~/components/CommonFormControls/SelectEnum";
import { useTranslation } from "react-i18next";
import { ID_PREFIX_ENTITY, ID_PREFIX_ENTITY_I18n } from '~/api/id-prefix/enums'

interface FilterProps {
  onChangeFilter: (
    data: IdPrefixApi.GetIdPrefixQuery
  ) => void | Promise<void>;
}

const Filter = ({ onChangeFilter }: FilterProps) => {
  const { t } = useTranslation();

  return (
    <SideBarFilterContainer onChangeFilter={onChangeFilter}>
      <Box pb={6}>
        <Input
          name="search"
          startAdornment={<Icon name="Lens" />}
          placeholder={t("text.searchByID")}
          formikControll
          fullWidth
        />
        <IncludeFieldGroup label={t("table.enabled")} fieldName="enabled" />
        <SidebarGroup label={t("label.entity")} collapsed defaultOpen={true}>
          <SelectEnum
            name="entity"
            enumValue={ID_PREFIX_ENTITY}
            enumI18n={ID_PREFIX_ENTITY_I18n}
            formikControll
            fullWidth
          />
        </SidebarGroup>
      </Box>
    </SideBarFilterContainer>
  );
};

export default React.memo(Filter);
