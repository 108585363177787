import { useCallback } from "react";
import IDPrefixes from "./Components/IDPrefixes";
import { Breadcrumbs } from "~/components/UI";
import { useTranslation } from "react-i18next";

/**
 * @namespace IDPrefixes
 */

const IDPrefixesPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [{ label: t("title.settings") }, { label: t("title.prefixes") }];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <IDPrefixes />
    </>
  )
}

export default IDPrefixesPage;
