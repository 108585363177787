import { Box, Tabs, Grid, styled } from "~/components/UI";
import { useTranslation } from "react-i18next";
import AcquirerEndpoints from "./AcquirerEndpoints";
import AdditionalInformation from "./AdditionalInformation";

interface AcquirerDetailsProps {
  acquirer: AcquirersApi.AcquirerDto;
}

const Container = styled(Grid)(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof Acquirers
 * @component
 * @desc Render detailed information of Acquirer.
 * @property { AcquirersApi.AcquirerDto} acquirer - Acquirer data;
 */

const AcquirerDetails = ({ acquirer }: AcquirerDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box flexGrow={1}>
        <Tabs
          tabs={[
            {
              label: t("title.acquirerEndpoint"),
              content: <AcquirerEndpoints acquirer={acquirer} />,
            },
            {
              label: t("title.additionalInformation"),
              content: <AdditionalInformation acquirer={acquirer} />,
            },
          ]}
        />
      </Box>
    </Container>
  );
};

export default AcquirerDetails;
