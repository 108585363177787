import { useCallback } from "react";
import { Box, Button, ButtonGroup, Grid, Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useQueryParams, StringParam } from "use-query-params";
import ROUTES from "~/constants/routes";

const Forbidden = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [queryParams] = useQueryParams({
    redirectedFrom: StringParam
  });
  const { redirectedFrom } = queryParams;
  const goToHomePage = useCallback(() => {
    history.replace('/');
  }, [])
  const redirect = useCallback(() => {
    history.push(ROUTES.LOGOUT);
  }, [history]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Typography variant="h3">{t("text.insufficient_rights")}</Typography>
      </Grid>
      <Grid item>
        <Box mb={4}>
          <Typography variant="h3">
            {t("text.contact_your_system_administrator")}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <ButtonGroup>
          { redirectedFrom !== ROUTES.LOGIN &&
            <Button variant="contained" color="primary" onClick={goToHomePage}>
              {t("button.homePage")}
            </Button>
          }
          <Button variant="contained" color="danger" onClick={redirect}>
            {t("button.logout")}
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default Forbidden;
