import MUITableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { withStyles, createStyles } from "@material-ui/core/styles";

export type Props = TableCellProps;

const TableCell = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      position: "relative",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      "@media (max-width:600px)": {
        padding: 6,
        fontSize: 10,
      },
      "&:first-child": {
        paddingLeft: 16,
        "@media (max-width:600px)": {
          paddingLeft: 6,
        },
      },
      "&:last-child": {
        paddingRight: 16,
        "@media (max-width:600px)": {
          paddingRight: 6,
        },
      },
      boxSizing: "border-box"
    },
    body: {
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: 16,
      "@media (max-width:600px)": {
        padding: 6,
      },
      "&:hover div": {
        visibility: "visible",
        opacity: 1,
      },
    },
    head: {
      color: theme.palette.text.secondary,
      fontFamily: '"Plumb", sans-serif',
      backgroundColor: theme.palette.background.paper,
      lineHeight: 1,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    paddingNone: {
      padding: 0
    },
    sizeSmall: {
      padding: 10
    }
  })
)(MUITableCell);

export default TableCell;
