import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import SelectEnum from "~/components/CommonFormControls/SelectEnum";
import { Formik, Field, FieldProps } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { WALLET_TYPE, WALLET_TYPE_I18n } from "~/api/wallets/enums";
import FieldsForWallet, { getValidationRules } from "./FieldsForWallet";
import api from "~/api";
interface CreateOrUpdateWalletDialogProps extends Omit<DialogProps, "onClose"> {
  wallet?: WalletsApi.WalletDto;
  onClose?: (wallet?: WalletsApi.WalletDto) => void;
}

interface FromState extends WalletsApi.CreateWallet {}

const defaultValues: FromState = {
  enabled: false
};

/**
 * @memberof Wallets
 * @component
 * @desc Dialog for creating or editing a Wallet.
 * @property {WalletsApi.WalletDto} wallet a Wallet data
 * @property {Function} onClose - passes Wallet data if the Wallet was created/updated successfully. (result?: WalletsApi.WalletDto) => void;
 */

const CreateOrUpdateWalletDialog = ({
  wallet,
  open,
  onClose
}: CreateOrUpdateWalletDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [walletType, setWalletType] = useState<WalletsApi.eWalletType>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["translation", "enums"]);

  const onSubmit = useCallback(
    async (formData: FromState) => {
      setLoading(true);

      try {
        if (isEditMode && wallet) {
          await api.wallets.updateWallet(
            wallet.walletId,
            formData as WalletsApi.UpdateWallet
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.wallets.createWallet(formData as WalletsApi.CreateWallet);
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose && onClose({} as WalletsApi.WalletDto);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, wallet, onClose, t]
  );

  useEffect(() => {
    if (wallet) {
      const {
        walletType,
        walletMerchantId,
        enabled,
        publicKeyId,
        privateKeyId,
        description,
        merchantId
      } = wallet;

      setWalletType(walletType);

      setInitialValues({
        walletType,
        walletMerchantId,
        description,
        enabled,
        publicKeyId,
        privateKeyId,
        merchantId
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
  }, [wallet]);

  return (
    <Dialog
      open={open}
      title={isEditMode ? t("title.editWallet") : t("title.addWallet")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema: getValidationRules(walletType),
          enableReinitialize: true
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {wallet && (
                  <FormControlLabel label="ID">
                    {wallet.walletId}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.walletType")}>
                  {values.walletType ? (
                    <>{t(`enums:${WALLET_TYPE_I18n[values.walletType]}`)}</>
                  ) : (
                    <Field name="walletType">
                      {({ field, form }: FieldProps) => (
                        <SelectEnum
                          value={field.value}
                          enumValue={WALLET_TYPE}
                          enumI18n={WALLET_TYPE_I18n}
                          formikControll={false}
                          onChange={(e) => {
                            form.setFieldValue("walletType", e.target.value);
                            setWalletType(
                              e.target.value as WalletsApi.eWalletType
                            );
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormControlLabel>
                <FieldsForWallet
                  walletType={values.walletType}
                  wallet={wallet}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  onClose && onClose()
                }}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateWalletDialog;
