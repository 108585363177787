import { AxiosInstance } from "axios";
import { deleteEmptyFields } from "~/utils/helpers";
import { httpError } from "~/api/common";

export default class BinRangesApi {
  rootPath: string = "/bin-ranges";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'bin-range')
  }

  create = (data: BinRangesApi.CreateBinRange) =>
    this.instance.post(this.rootPath, data);
  getAll = (
    limit: number = 10,
    page: number = 1,
    query?: BinRangesApi.GetBinRangesQuery
  ): Promise<Api.GetAll<BinRangesApi.BinRangeDto>> => {
    if (query && !query.sort) {
      query =  {...query, sort: "id"}
    }
    return this.instance.get(this.rootPath, {
      params: {
        limit,
        skip: (page - 1) * limit,
        ...deleteEmptyFields(query || {}),
      },
    })};
  getBinRange = (id: string) =>
    this.instance.get(`${this.rootPath}/${id}`);
  update = (id: string, data: BinRangesApi.UpdateBinRange) =>
    this.instance.put(`${this.rootPath}/${id}`, data);
  deleteBinRanges = (ids: string[]) =>
    this.instance.delete(this.rootPath, { params: { ids } });
  delete = (id: string) =>
    this.instance.delete(`${this.rootPath}/${id}`);
}
