import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, MUIFormControlLabel } from "~/components/UI";
interface WidgetsProps {
  widgets: DashboardSettingsApi.Widget[];
  onChange: (index: number, value: DashboardSettingsApi.Widget) => void;
}

const Widgets = ({ widgets, onChange }: WidgetsProps) => {
  const { t } = useTranslation();  
  const handleChangeItem = useCallback(
    (widget: DashboardSettingsApi.Widget) => {
      const index = widgets.findIndex((w) => w.widgetId === widget.widgetId);
      if (index !== -1 && onChange) {
        onChange(index, widget);
      }
    },
    [widgets, onChange]
  );

  return (
    <>
      {widgets.map((w) => (
        <MUIFormControlLabel
          key={w.widgetId}
          label={t("serverText."+ w.name)}
          control={
            <Checkbox
              checked={w.enabled}
              onChange={(_e, enabled) => handleChangeItem({ ...w, enabled })}
            />
          }
        />
      ))}
    </>
  );
};

export default Widgets;
