import { useState, useCallback, useContext } from "react";
import { Dropdown } from "~/components/UI";
import ContractsContext from "./contractsContext";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface ContractOptionsProps {
  contract: ContractsApi.ContractDto;
}

/**
 * @memberof Contracts
 * @component
 * @desc Contract options. Using a ContractsContext.
 * @property {ContractsApi.ContractDto} contract - Contract data;
 * @requires ContractsContext
 */

const ContractOptions = ({ contract }: ContractOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const { onEdit, onUpdate, onUpdateItem, onDelete } =
    useContext(ContractsContext);
  const [createIncident] = useCreateIncidentDialog(SOURCE_TYPE.CONTRACT);
  const { enabled } = contract;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (contract.incidents) {
        contract.incidents.count++;
        contract.incidents.incidentIds.push(incident.incidentId);
      } else {
        contract.incidents = {
          count: 1,
          incidentIds: [incident.incidentId],
        };
      }
      onUpdateItem && onUpdateItem(contract);
    },
    [contract, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_CONTRACT,
      PERMISSION.DELETE_CONTRACT,
      PERMISSION.CREATE_JOURNAL_INCIDENTS,
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_CONTRACT),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(onUpdate(contract.contractId, { enabled: !enabled })),
      },
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_CONTRACT),
      onClick: () => onEdit && onEdit(contract),
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_CONTRACT),
      onClick: () => onDelete && onUpdateHandler(onDelete(contract)),
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(contract.contractId, onCreatedIncident),
    },
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default ContractOptions;
