import { Box, Label, Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";

interface AdditionalInformationProps {
  acquirer: AcquirersApi.AcquirerDto;
}

const AdditionalInformation = ({ acquirer }: AdditionalInformationProps) => {
  const { t } = useTranslation();
  return (
    <Box p={2}>
      <Label text={t("label.description")}>
        <Typography variant="body1">{acquirer.description}</Typography>
      </Label>
    </Box>
  );
};

export default AdditionalInformation;
