import Constants from "./constants";

const { ACTIONS } = Constants;

const initialState: ProfileStore.State = {
  profile: undefined,
  isLoaded: false
};

const Reducer = (
  state = initialState,
  action: ProfileStore.Actions
): ProfileStore.State => {
  switch (action.type) {
    case ACTIONS.SET_PROFILE_DATA:
      return {
        ...state,
        isLoaded: true,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
