import { AxiosInstance } from "axios";
import { httpError } from "~/api/common";

export default class PermissionApi {
  rootPath: string = "/permissions";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.allPermissions');
  }

  getAll = (): Promise<Api.GetAll<PermissionsApi.PermissionDto>> =>
    this.instance.get(this.rootPath)
}
