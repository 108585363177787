import { styled } from "@material-ui/core/styles";

const ControlsGroup = styled("div")(
  ({ theme }: { theme: CustomTheme.RootObject }) => ({
    display: "inline-block",
    margin: `-${theme.spacing(2)}px`,
    "& > *": {
      margin: `${theme.spacing(2)}px`,
    },
  })
);

export default ControlsGroup;
