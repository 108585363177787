import { useRef, useCallback, useEffect } from "react";
import { Box, Label, Grid, Button, ButtonGroup } from "~/components/UI";
import CanIUse from "~/components/CanIUse";
import BaseConfirmDialog from "~/components/BaseConfirmDialog";
import { useTranslation } from "react-i18next";
import { styled } from "@material-ui/core/styles";
import { PERMISSION } from "~/api/permissions/enums";

const Logo = styled("img")(() => ({
  display: "inline-block",
  width: "auto",
  height: 35,
  lineHeight: 0,
}));

interface UploadLogoProps {
  src?: string;
  file?: File | null;
  onChange?: (file?: File | null) => void;
}

const UploadLogo = ({ src, file, onChange }: UploadLogoProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const onUpload = useCallback(() => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }, [ref]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        onChange && onChange(e.target.files[0]);
      }
    },
    [onChange]
  );

  const handleDelete = useCallback(() => {
    onChange && onChange(null);
  }, [onChange]);

  useEffect(() => {
    if ((!file || file === null) && ref && ref.current) {
      ref.current.value = "";
    }
  }, [ref, file]);

  return (
    <Box py={4}>
      <Label text={t("label.logoPicture")} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        spacing={4}
      >
        <Grid item>
          {file !== null && (
            <Logo
              src={
                typeof file !== "undefined"
                  ? URL.createObjectURL(file || "")
                  : src
              }
            />
          )}
          <input
            ref={ref}
            accept=".jpg, .png"
            type="file"
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </Grid>
        <CanIUse permissions={PERMISSION.EDIT_SYSTEM_SETTINGS}>
          <Grid item>
            <ButtonGroup>
              <Button variant="contained" color="secondary" onClick={onUpload}>
                {t("button.uploadNewImage")}
              </Button>
              <BaseConfirmDialog onConfirm={handleDelete}>
                <Button variant="contained">{t("button.delete")}</Button>
              </BaseConfirmDialog>
            </ButtonGroup>
          </Grid>
        </CanIUse>
      </Grid>
    </Box>
  );
};

export default UploadLogo;
