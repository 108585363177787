import React from "react";
import clsx from "clsx";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

type Variant = "block" | "circle" | "circleOutline" | "blockOutline";
type Color = "default" | "primary" | "secondary" | "error" | "warning" | "info";

export interface IconContainerProps {
  variant?: Variant;
  color?: Color;
  className?: string;
  children?: React.ReactChildren | React.ReactNode;
  size?: "default" | "small";
}

const useStyles = ({ variant, color }: IconContainerProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 42,
        height: 42,
        borderRadius: variant === "circle" ? "50%" : theme.shape.borderRadius,
        color: (() => {
          switch (color) {
            case "primary":
              return theme.palette.primary.contrastText;
            case "secondary":
              return theme.palette.secondary.contrastText;
            case "error":
              return theme.palette.error.contrastText;
            case "warning":
              return theme.palette.warning.contrastText;
            case "info":
              return theme.palette.info.contrastText;
            default:
              return theme.palette.text.primary;
          }
        })(),
        backgroundColor: (() => {
          switch (color) {
            case "primary":
              return theme.palette.primary.main;
            case "secondary":
              return theme.palette.secondary.main;
            case "error":
              return theme.palette.error.main;
            case "warning":
              return theme.palette.warning.main;
            case "info":
              return theme.palette.info.main;
            default:
              return theme.palette.background.default;
          }
        })(),
      },
      small: {
        width: 13,
        height: 13,
        fontSize: 7,
      },
    })
  );

const IconContainer = ({
  children,
  className,
  size,
  ...other
}: IconContainerProps) => {
  const classes = useStyles(other)();
  return (
    <div
      className={clsx(classes.container, className, {
        [classes.small]: size === "small",
      })}
    >
      {children}
    </div>
  );
};

export default IconContainer;
