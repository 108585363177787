import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { loggedIn, setUserData } from "~/store/auth/actions";
import { LinearProgress } from "~/components/UI";
import { msalAuth, getToken } from "~/packages/msalInstance";
import { useHistory } from "react-router";
import api from "~/api";
import ROUTES from "~/constants/routes";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * @namespace Components
 */

/**
 * The component checks the session of the Microsoft Azure user. If the user is found, then we try to connect to the Admin Gateway server.
 * @memberOf Components
 * @name AuthProvider
 * @property {React.ReactChild | React.ReactChild[]} children
 * @return {React.Component}
 */

const AuthProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const account = msalAuth.getAccount();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const connect = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const {
        data: { data }
      } = await api.auth.connect(token);
      dispatch(loggedIn(data));
      const {
        data: { data: userData }
      } = await api.auth.getUserData(data.accessToken);
      dispatch(setUserData(userData));
    } catch (e: any) {
      console.error(e);
      if (e.code === "FORBIDDEN_RESOURCE") {
        history.push(`${ROUTES.FORBIDDEN}?redirectedFrom=${ROUTES.LOGIN}`);
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      connect();
    } else {
      setLoading(false);
    }
  }, [account, connect]);
  return loading ? <LinearProgress color="primary" /> : <>{children}</>;
};

export default AuthProvider;
