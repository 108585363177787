import { AxiosInstance } from "axios";

export default class SitesApi {
  rootPath: string = "/search";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  globalSearch = (search: string, take?: number): Promise<Api.Response<Api.GetResponseDto<Api.SearchResults>>> =>
    this.instance.get(this.rootPath, { params: { search, take }});
};
