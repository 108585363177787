import { FieldError } from "~/components/UI";
import DatePicker, { DatePickerProps } from "~/components/UI/DatePicker";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

interface FormikDatePickerProps extends DatePickerProps {
  name: string;
}

const defaultOptions = {
  dateFormat: "d M, Y",
  position: "below" as const,
  time_24hr: true
};

const FormikDatePicker = ({
  name,
  options,
  ...otherProps
}: FormikDatePickerProps) => {
  const [field, meta, helpers] = useField(name);
  const { error } = meta;
  const { i18n } = useTranslation();

  return (
    <>
      <DatePicker
        {...otherProps}
        value={field.value}
        error={!!error}
        options={{
          locale: i18n.language as any,
          ...defaultOptions,
          ...options,
        }}
        onChange={(date) =>
          helpers.setValue(date ? date.toISOString() : undefined)
        }
      />
      {error && <FieldError>{error}</FieldError>}
    </>
  );
};

export default FormikDatePicker;
