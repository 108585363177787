import { Button, DialogActions, DialogContent, Grid } from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { ConfirmOptions } from "./ConfirmProvider";

interface ConfirmationDialogProps extends DialogProps {
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}: ConfirmationDialogProps) => {
  const {
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
  } = options;

  return (
    <Dialog {...dialogProps} maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <Grid container alignItems="center" direction="column">
          {content}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          {...confirmationButtonProps}
          onClick={onConfirm}
          fullWidth
        >
          {confirmationText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          {...cancellationButtonProps}
          onClick={onCancel}
          fullWidth
        >
          {cancellationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
