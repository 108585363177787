import MUISwitch, {
  SwitchClassKey,
  SwitchProps,
} from "@material-ui/core/Switch";
import { useField } from "formik";
import FieldError from "./Form/FieldError";
import { withStyles, createStyles } from "@material-ui/core/styles";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const BaseSwitch = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      width: 44,
      height: 26,
      padding: 2,
      boxSizing: "border-box",
    },
    disabled: {
      opacity: 0.5,
    },
    sizeSmall: {
      width: 34,
      height: 24,
      "& .MuiSwitch-thumb": {
        width: 12,
        height: 12,
      },
      "& .MuiSwitch-switchBase": {
        padding: 6,
        "&.Mui-checked": {
          transform: "translateX(11px)",
        },
      },
    },
    switchBase: {
      color: theme.palette.controls.switch,
      padding: 5,
      "&.Mui-checked": {
        transform: "translateX(17px)",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          border: "none",
        },
        "& $thumb": {
          color: theme.palette.common.white,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.switch,
      opacity: 1,
      transition: "none",
    },
  })
)(({ classes, ...props }: Props) => {
  return <MUISwitch disableRipple classes={classes} {...props} />;
});

const FormikSwitch = ({ name, ...other }: SwitchProps & { name: string }) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <>
      <BaseSwitch checked={!!field.value} {...field} {...other} />
      {touched && error && <FieldError>{error}</FieldError>}
    </>
  );
};

const Switch = ({
  name,
  formikControll,
  ...checkboxProps
}: SwitchProps & { formikControll?: boolean }) =>
  formikControll && name ? (
    <FormikSwitch name={name} {...checkboxProps} />
  ) : (
    <BaseSwitch {...checkboxProps} />
  );

export default Switch;
