import { FormControlLabel, OutlinedInput, Switch } from "~/components/UI";
import SelectMerchant from "~/components/CommonFormControls/SelectMerchant";
import SelectPurposeKey from "~/components/CommonFormControls/SelectPurposeKey";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import { useTranslation } from "react-i18next";
import yup from "~/packages/yup";

export const getValidationSchema = () => yup.object({
    walletMerchantId: yup.string().required(),
    merchantId: yup.string().required(),
    enabled: yup.boolean().required(),
    publicKeyId: yup.string().required(),
    privateKeyId: yup.string().required(),
  description: yup.string()
  });

const ApplePayWallet = ({ wallet }: { wallet?: WalletsApi.WalletDto }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControlLabel label={t("label.appleMerchantId")}>
        <OutlinedInput name="walletMerchantId" formikControll fullWidth />
      </FormControlLabel>
      <FormControlLabel label={t("label.enabled")}>
        <Switch name="enabled" formikControll />
      </FormControlLabel>
      <FormControlLabel label={t("label.merchant")}>
        <SelectMerchant
          defaultInputValue={wallet?.merchantName}
          name="merchantId"
        />
      </FormControlLabel>
      <FormControlLabel label={t("label.privateKeyID")}>
        <SelectPurposeKey
          defaultInputValue={wallet?.privateKeyName}
          name="privateKeyId"
          keyPurpose={KEY_PURPOSE.APPLE_PRIVATE_KEY}
        />
      </FormControlLabel>
      <FormControlLabel label={t("label.publicKeyID")}>
        <SelectPurposeKey
          defaultInputValue={wallet?.publicKeyName}
          name="publicKeyId"
          keyPurpose={KEY_PURPOSE.APPLE_PUBLIC_KEY}
        />
      </FormControlLabel>
      <FormControlLabel label={t("label.description")}>
        <OutlinedInput
          name="description"
          formikControll
          multiline
          rows={4}
          fullWidth
        />
      </FormControlLabel>
    </>
  );
};

export default ApplePayWallet;
