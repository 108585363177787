import {
  Box,
  GridList,
  GridListTile,
  CircularProgress,
  withStyles,
  createStyles
} from "~/components/UI";
import clsx from "clsx";
import Metrics from "./Components/Metrics";
import Journals from "./Components/Journals";
import { useSelector } from "react-redux";
import usePermissions from "~/hook/usePermissions";
import { METRICS } from "~/api/permissions/enums";

/**
 * @namespace Dashboard
 */

const ProgressContainer = withStyles(() =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    }
  })
)(Box);

const DashboardRoutes = () => {
  const { hasAny } = usePermissions();
  const settings = useSelector(
    (state: ReduxStore.State) => state.settings.dashboardSettings
  );

  if (!settings) {
    return (
      <ProgressContainer>
        <CircularProgress />
      </ProgressContainer>
    );
  }

  return (
    <GridList cols={2} spacing={20} cellHeight="auto">
      <GridListTile cols={2} className={clsx({ hideBlock: !hasAny(METRICS) })}>
        <Metrics settings={settings && settings.metrics} />
      </GridListTile>
      <GridListTile cols={2}>
        <Journals settings={settings && settings.widgets} />
      </GridListTile>
    </GridList>
  );
};

export default DashboardRoutes;
