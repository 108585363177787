import { useState, useEffect } from "react";
import { Box, Label, Typography, LinearProgress } from "~/components/UI";
import { useTranslation } from "react-i18next";
import api from "~/api";

interface AdditionalInformationProps {
  outboundVirtualTerminal: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto;
}

const AdditionalInformation = ({
  outboundVirtualTerminal,
}: AdditionalInformationProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [additionalInformation, setAdditionalInformation] =
    useState<OutboundVirtualTerminalsApi.OutboundVirtualTerminalDetailsDto>();

  const getDetails = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await api.outboundVirtualTerminals.getTerminal(
        outboundVirtualTerminal.id
      );
      setAdditionalInformation(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Box p={2}>
        <Label text={t("label.signKeyId")}>
          <Typography variant="body1">
            {additionalInformation?.signKeyId}
          </Typography>
        </Label>
        <Label text={t("label.verifyKeyId")}>
          <Typography variant="body1">
            {additionalInformation?.verifyKeyId}
          </Typography>
        </Label>
        <Label text={t("label.contractName")}>
          <Typography variant="body1">
            {additionalInformation?.accountNumber}
          </Typography>
        </Label>
      </Box>
    </>
  );
};

export default AdditionalInformation;
