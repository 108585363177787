import { useState, useEffect } from "react";
import { Box, Label, Typography, LinearProgress } from "~/components/UI";
import api from "~/api";

interface AdditionalInformationProps {
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
}

const AdditionalInformation = ({
  inboundVirtualTerminal,
}: AdditionalInformationProps) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] =
    useState<InboundVirtualTerminalsApi.InboundVirtualTerminalDetailsDto>();

  const getAcquirerEndpoints = async () => {
    try {
      setLoading(true);
      const { data } = await api.inboundVirtualTerminals.getTerminalDetails(
        inboundVirtualTerminal.id
      );
      setDetails(data.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAcquirerEndpoints();
  }, []);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Box p={2}>
        <Label text="Sign Key Id">
          <Typography variant="body1">
            {details && details.signKeyId}
          </Typography>
        </Label>
        <Label text="Verify Key Id">
          <Typography variant="body1">
            {details && details.verifyKeyId}
          </Typography>
        </Label>
      </Box>
    </>
  );
};

export default AdditionalInformation;
