import { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Section,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import Filter from "./Filter";
import { getSortStr } from "~/utils/helpers";
import { useTranslation } from "react-i18next";
import { useQueryParams, StringParam } from "use-query-params";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const CardOnFiles = () => {
  const [cardOnFiles, setCardOnFile] =
    useState<CardOnFileApi.CardOnFileDto[]>();
  const [query, setQuery] = useState<CardOnFileApi.GetCardOnFileQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const [queryParams] = useQueryParams({
    search: StringParam
  });
  const { t } = useTranslation();

  const getCardOnFile = useCallback(
    async (page?: number) => {
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.cardOnFile.getAll(limit, undefined, query);
        data && setCardOnFile(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onChangePagination = (page: number) => {
    getCardOnFile(page);
  };

  const onChangeFilters = (filters: CardOnFileApi.GetCardOnFileQuery) => {
    pagination.current = undefined;
    setCardOnFile([]);
    setQuery(filters);
  };

  useEffect(() => {
    setQuery(queryParams as CardOnFileApi.GetCardOnFileQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getCardOnFile();
  }, [query, getCardOnFile, limit]);

  return (
    <>
      <Section
        title={t("title.cardOnFile")}
        extra={
          <ButtonGroup>
            <Filter onChangeFilter={onChangeFilters} />
          </ButtonGroup>
        }
      />
      <LinearProgress hidden={!loading} />
      <Box flexGrow={1} overflow="auto" bgcolor="background.paper">
        <Table
          dataSource={cardOnFiles}
          onRequestSort={onSort}
          rowKey="createdAt"
        />
      </Box>
      {pagination.current && (
        <Pagination
          prefContent={<RowsPerPage initialValue={limit} onChange={setLimit} />}
          count={pagination.current.totalPages}
          page={pagination.current.page}
          defaultPage={1}
          onChange={(_e, page) => {
            onChangePagination(page);
          }}
        />
      )}
    </>
  );
};

export default CardOnFiles;
