import * as React from "react";

export type Options = {
  onCreated?: (incident: IncidentsApi.IncidentDto) => void,
  sourceType?:  Api.eSourceType
}

export type CreateIncident = (
  sourceId: string,
  options: Options
) => void;

/**
 * The shape of the Incidents context
 */
export interface IncidentsContextType {
  createIncident: CreateIncident;
}

/**
 * Throw error when IncidentsContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    "Attempted to call useIncidents outside of modal context. Make sure your app is rendered inside IncidentsProvider."
  );
};

/**
 * Incidents Context Object
 */
export const IncidentsContext = React.createContext<IncidentsContextType>({
  createIncident: invariantViolation
});

IncidentsContext.displayName = 'IncidentsContext';