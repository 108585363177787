import { useEffect, useState, useCallback } from "react";
import { Box, LinearProgress } from "~/components/UI";
// import EnumValue from "~/components/EnumValue";
import Search from "~/components/Search";
import TerminalOptions from "./TerminalOptions";
import Table from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
// import { PRIORITY_I18n } from "~/api/common";
import api from "~/api";

const defaultColumns = [
  {
    key: "outboundTerminalId",
    i18nKey: "outboundTerminalID",
  },
  {
    key: "merchantName",
    i18nKey: "merchantName",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "accountNumber",
    sort: true,
    i18nKey: "accountNumber"
  },
  // {
  //   key: "priority",
  //   i18nKey: "priority",
  //   render: (priority: string) => (
  //     <EnumValue value={priority} enumI18n={PRIORITY_I18n} />
  //   ),
  // },
  {
    key: "options",
  },
];

interface TerminalBindingsProps {
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
}

const TerminalBindings = ({
  inboundVirtualTerminal,
}: TerminalBindingsProps) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] =
    useState<InboundVirtualTerminalsApi.InboundTerminalBindingDto[]>();
  const { t } = useTranslation();

  const getAcquirerEndpoints = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.inboundVirtualTerminals.getTerminalBindings(
        inboundVirtualTerminal.id,
        100,
        1,
        { search }
      );
      setData(data.data);
    } finally {
      setLoading(false);
    }
  }, [search]);

  const onDeleteTerminal = useCallback(async (outboundTerminalId: string) => {
    try {
      await api.inboundVirtualTerminals.deleteOutboundTerminal(
        inboundVirtualTerminal.id,
        outboundTerminalId
      );
      setData((data) =>
        data
          ? data.filter((t) => t.outboundTerminalId !== outboundTerminalId)
          : data
      );
    } finally {
      return;
    }
  }, []);

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "options":
        return {
          ...column,
          render: (
            value: unknown,
            outboundTerminal: InboundVirtualTerminalsApi.OutboundTerminalsDto
          ) => (
            <TerminalOptions
              inboundVirtualTerminal={inboundVirtualTerminal}
              onDelete={() =>
                onDeleteTerminal(outboundTerminal.outboundTerminalId)
              }
            />
          ),
        };
      default:
        return column;
    }
  });

  useEffect(() => {
    getAcquirerEndpoints();
  }, [search]);

  useEffect(() => {
    Boolean(inboundVirtualTerminal?.updatedAt) && getAcquirerEndpoints();
  }, [inboundVirtualTerminal.updatedAt]);

  return (
    <>
      <LinearProgress hidden={!loading} />
      <Box ml={4} width={375}>
        <Search placeholder={t("text.searchByID")} onSearch={setSearch} />
      </Box>
      <Box maxWidth={1024}>
        <Table size="small" columns={columns} dataSource={data} stickyHeader />
      </Box>
    </>
  );
};

export default TerminalBindings;
