import { enumToI18nKey } from "~/utils/helpers";

export const KEY_PURPOSE = {
  APPLE_PUBLIC_KEY: "Apple 3d party developer certificate",
  APPLE_PRIVATE_KEY: "Apple Private Key",
  GOOGLE_PUBLIC_KEY: "Google Public Key",
  GOOGLE_PRIVATE_KEY: "Google Private Key",
  INBOUND_VT_SIGN_KEY: "Inbound VT Sign Key",
  INBOUND_VT_VERIFY_KEY: "Inbound VT Verify Key",
  MASTER_PASS_ENCRYPTION_KEY: "Master Pass Encryption Key",
  MASTER_PASS_MAC_KEY: "Master Pass Mac Key",
  MDES_SIGNING_KEY: "MDES Signing Key",
  MDES_CONSUMER_KEY: "MDES Consumer Key",
  MDES_ENCRYPTION_KEY: "MDES Encryption Key",
  MDES_DECRYPTION_KEY: "MDES Decryption Key",
 
  OUTBOUND_VT_SIGN_KEY: "Outbound VT Sign Key",
  OUTBOUND_VT_VERIFY_KEY: "Outbound VT Verify Key",

  PAYMENT_ADMIN_PANEL_SIGN_KEY: "Payment Admin Panel Sign Key",
  PAYMENT_ADMIN_PANEL_VERIFY_KEY: "Payment Admin Panel Verify Key",
  PAYMENT_SERVICE_SIGN_KEY: "Payment Service Sign Key",
  PAYMENT_SERVICE_VERIFY_KEY: "Payment Service Verify Key",

  FISHKA_SIGN_KEY: "Fishka Sign Key",
} as { [key: string]: KeyManagementApi.eKeyPurpose };

export const KEY_PURPOSE_I18n = enumToI18nKey(KEY_PURPOSE, "KEY_PURPOSE");
