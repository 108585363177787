import { AxiosInstance } from "axios";
import { deleteEmptyFields  } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class FinancialTransactionsApi {
  rootPath: string = "/journal-financial-transactions";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.financialTransactions')
  }

  getFinancialTransactions = (limit: number = 10, page: number = 1, query?: FinancialTransactionsApi.GetFinancialTransactionsQuery): Promise<Api.GetAll<FinancialTransactionsApi.FinancialTransactionDto>> =>
    this.instance.get(this.rootPath, { params: { limit, skip: (page - 1) * limit, ...deleteEmptyFields(query || {}) } });
  getTransactionDetails = (id: string): Promise<Api.GetOne<FinancialTransactionsApi.FinancialTransactionDetailsDto>> =>
    this.instance.get(`${this.rootPath}/${id}`);
}
