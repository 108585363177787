import { useState, useCallback } from "react";
import { Dropdown } from "~/components/UI";
import { useTranslation } from "react-i18next";
import { SOURCE_TYPE } from "~/api/common";
import useCreateIncidentDialog from "~/components/Incidents/CreateIncident/useCreateIncidentDialog";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

interface EndpointOptionsProps {
  endpoint: AcquirersApi.EndpointDto;
  onUpdate: (endpoint: AcquirersApi.EndpointDto) => Promise<void>;
  onEdit: (endpoint?: AcquirersApi.EndpointDto) => void;
  onUpdateItem: (endpoint: AcquirersApi.EndpointDto) => void;
  onDelete: (endpoint: AcquirersApi.EndpointDto) => Promise<void>;
}

/**
 * @memberof Acquirers
 * @component
 * @desc Endpoint options. Using a AcquirersContext.
 * @property {AcquirersApi.AcquirerDto} endpoint - Acquirer endpoint data;
 * @requires AcquirersContext
 */

const EndpointOptions = ({
  endpoint,
  onUpdate,
  onEdit,
  onUpdateItem,
  onDelete
}: EndpointOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const [createIncident] = useCreateIncidentDialog(
    SOURCE_TYPE.ACQUIRER_ENDPOINT
  );
  const { enabled } = endpoint;
  const { hasAll, hasAny } = usePermissions();
  const { t } = useTranslation();

  const onUpdateHandler = useCallback((res: Promise<unknown> | void) => {
    if (res) {
      setLoading(true);
      res.finally(() => {
        setLoading(false);
      });
    }
  }, []);

  const onCreatedIncident = useCallback(
    (incident: IncidentsApi.IncidentDto) => {
      if (endpoint.incidents) {
        endpoint.incidents.count++;
        endpoint.incidents.incidentIds.push(incident.incidentId);
      } else {
        endpoint.incidents = {
          count: 1,
          incidentIds: [incident.incidentId]
        };
      }
      onUpdateItem && onUpdateItem(endpoint);
    },
    [endpoint, onUpdateItem]
  );

  if (
    !hasAny([
      PERMISSION.EDIT_ACQUIRER,
      PERMISSION.DELETE_ACQUIRER,
      PERMISSION.CREATE_JOURNAL_INCIDENTS
    ])
  ) {
    return null;
  }

  const options = [
    {
      label: enabled ? t("option.disable") : t("option.enable"),
      hidden: !hasAll(PERMISSION.EDIT_CONTRACT),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () =>
          onUpdate &&
          onUpdateHandler(onUpdate({ ...endpoint, enabled: !enabled }))
      }
    },
    {
      label: t("option.edit"),
      hidden: !hasAll(PERMISSION.EDIT_ACQUIRER),
      onClick: () => onEdit && onEdit(endpoint)
    },
    {
      label: t("option.delete"),
      hidden: !hasAll(PERMISSION.DELETE_ACQUIRER),
      confirmation: {
        cancelText: t("button.no"),
        okText: t("button.yes"),
        title: t("text.confirmationRequest"),
        onConfirm: () => onDelete && onUpdateHandler(onDelete(endpoint))
      }
    },
    {
      label: t("option.createIncident"),
      hidden: !hasAll(PERMISSION.CREATE_JOURNAL_INCIDENTS),
      onClick: () => createIncident(endpoint.endpointId, onCreatedIncident)
    }
  ];

  return <Dropdown loading={loading} options={options} />;
};

export default EndpointOptions;
