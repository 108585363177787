import { Box, Tabs, Grid, styled } from "~/components/UI";
import { useTranslation } from "react-i18next";
import TerminalBindings from "./TerminalBindings";
import AdditionalInformation from "./AdditionalInformation";

interface InboundVirtualTerminalProps {
  inboundVirtualTerminal: InboundVirtualTerminalsApi.InboundVirtualTerminalDto;
}

const Container = styled(Grid)(() => ({
  maxHeight: 300,
  minHeight: 200,
  overflow: "auto",
}));

/**
 * @memberof InboundVirtualTerminals
 * @component
 * @desc Render detailed information of inboundVirtualTerminal.
 * @property { InboundVirtualTerminalsApi.InboundVirtualTerminalDto} inboundVirtualTerminal - inboundVirtualTerminal data;
 */

const InboundVirtualTerminalDetails = ({
  inboundVirtualTerminal,
}: InboundVirtualTerminalProps) => {
  const { t } = useTranslation();

  return (
    <Container container direction="column" alignItems="stretch" wrap="nowrap">
      <Box flexGrow={1}>
        <Tabs
          tabs={[
            {
              label: t("title.outboundTerminalBindings"),
              content: (
                <TerminalBindings
                  inboundVirtualTerminal={inboundVirtualTerminal}
                />
              ),
            },
            {
              label: t("title.additionalInformation"),
              content: (
                <AdditionalInformation
                  inboundVirtualTerminal={inboundVirtualTerminal}
                />
              ),
            },
          ]}
        />
      </Box>
    </Container>
  );
};

export default InboundVirtualTerminalDetails;
