import { AxiosInstance } from "axios";
import { getLogoUrl } from '~/utils/helpers';
import { httpError } from "~/api/common";

export default class SystemSettingsApi {
  rootPath: string = "/system-settings";
  instance: AxiosInstance = {} as AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    httpError.addSource(this.rootPath, 'title.systemSettings')
  }

  getSettings = (): Promise<Api.GetOne<SystemSettingsApi.Settings>> =>
    this.instance.get(this.rootPath).then((responce) => ({
      ...responce,
      data: {
        ...responce.data,
        data: { ...responce.data.data, logoUrl: getLogoUrl(responce.data.data.logoUrl) }
      }
    })
    )

  updateSettings = (data: SystemSettingsApi.UpdateSettings): Promise<Api.GetOne<SystemSettingsApi.Settings>> =>
    this.instance.put(this.rootPath, data, { headers: { "X-Content-Type-Options": "nosniff" } }).then((responce) => ({
      ...responce,
      data: {
        ...responce.data,
        data: { ...responce.data.data, logoUrl: getLogoUrl(responce.data.data.logoUrl) }
      }
    })
    )

  deleteLogo = (): Promise<Api.GetOne<DashboardSettingsApi.Settings>> =>
    this.instance.delete(this.rootPath)

  updateLogo = (formData: FormData): Promise<Api.GetOne<SystemSettingsApi.Settings>> =>
    this.instance.put(`${this.rootPath}/logo`, formData)
}
