import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput,
  Switch,
  Divider
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import SelectMerchant from "~/components/CommonFormControls/SelectMerchant";
import SelectAcquirerEndpoint from "~/components/CommonFormControls/SelectAcquirerEndpoint";
import SelectAcquirer from "~/components/CommonFormControls/SelectAcquirer";
import SelectPurposeKey from "~/components/CommonFormControls/SelectPurposeKey";
import { SelectContract } from "~/components/CommonFormControls/SelectContract";
import { KEY_PURPOSE } from "~/api/keyManagement/enums";
import { CONNECTOR_TYPE } from '~/api/acquirers/enums';
import yup from "~/packages/yup";
import api from "~/api";

interface CreateOrUpdateOutboundVirtualTerminalDialogProps
  extends Omit<DialogProps, "onClose"> {
  outboundVirtualTerminal?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto;
  onClose?: (
    outboundVirtualTerminal?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto
  ) => void;
}

type FromState =
  | OutboundVirtualTerminalsApi.CreateOutboundVirtualTerminal
  | OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal;

const defaultValues: FromState = {
  acquirerId: "",
  externalTerminalId: "",
  externalMerchantId: "",
  accountNumber: "",
  signKeyId: "",
  verifyKeyId: "",
  endpointId: "",
  merchant: "",
  enabled: false,
  description: ""
};

const getKeysFieldsByConnectorType = (
  outbound: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto | undefined,
  connectorType: string | undefined,
  isEditMode: boolean,
  t: TFunction<"translation", undefined>
) => {

  switch(connectorType) {
    case CONNECTOR_TYPE.MASTERPASS:
      if (isEditMode) {
        return (
            <>
              <FormControlLabel label={t("label.signKeyName")}>
                {outbound?.signKeyName}
              </FormControlLabel>
              <FormControlLabel label={t("label.verifyKeyName")}>
                {outbound?.verifyKeyName}
              </FormControlLabel>
            </>
        )
      } else {
        return null
      }
    case CONNECTOR_TYPE.OKKO_ONLINE:
      return (
        <FormControlLabel label={t("label.signKeyName")}>
          <SelectPurposeKey
            defaultInputValue={outbound?.signKeyName}
            name="signKeyId"
            keyPurpose={KEY_PURPOSE.OUTBOUND_VT_SIGN_KEY}
          />
        </FormControlLabel>
      )
    case CONNECTOR_TYPE.FISHKA_LOYALTY:
      return (
        <FormControlLabel label={t("label.signKeyName")}>
          <SelectPurposeKey
            defaultInputValue={outbound?.signKeyName}
            name="signKeyId"
            keyPurpose={KEY_PURPOSE.FISHKA_SIGN_KEY}
          />
        </FormControlLabel>
      )
    default:
      return (
        <>
          <FormControlLabel label={t("label.signKeyName")}>
            <SelectPurposeKey
              defaultInputValue={outbound?.signKeyName}
              name="signKeyId"
              keyPurpose={KEY_PURPOSE.OUTBOUND_VT_SIGN_KEY}
            />
          </FormControlLabel>
          <FormControlLabel label={t("label.verifyKeyName")}>
            <SelectPurposeKey
              defaultInputValue={outbound?.verifyKeyName}
              name="verifyKeyId"
              keyPurpose={KEY_PURPOSE.OUTBOUND_VT_VERIFY_KEY}
            />
          </FormControlLabel>
        </>
      )
  }
}

/**
 * @memberof OutboundVirtualTerminals
 * @component
 * @desc Dialog for creating or editing a OutboundVirtualTerminal.
 * @property {OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto} outboundVirtualTerminal a OutboundVirtualTerminal data
 * @property {Function} onClose - passes OutboundVirtualTerminal data if the OutboundVirtualTerminal was created/updated successfully. (outboundVirtualTerminal?: OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto) => void;
 */

const CreateOrUpdateOutboundVirtualTerminalDialog = ({
  outboundVirtualTerminal,
  open,
  onClose
}: CreateOrUpdateOutboundVirtualTerminalDialogProps) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isEditMode, setEditMode] = useState(false);
  const [connectorType, setConnectorType] = useState<undefined | string>();
  const [loading, setLoading] = useState(false);
  const validationSchemas = {
    [CONNECTOR_TYPE.MASTERPASS]: yup.object().shape({
      acquirerId: yup.string().required(),
      externalTerminalId: yup.string().required(),
      externalMerchantId: yup.string().required(),
      accountNumber: yup.string().required(),
      description: yup.string()
    }),
    [CONNECTOR_TYPE.OKKO_ONLINE]: yup.object().shape({
      acquirerId: yup.string().required(),
      externalTerminalId: yup.string().required(),
      externalMerchantId: yup.string().required(),
      accountNumber: yup.string().required(),
      signKeyId: yup.string().required(),
      description: yup.string()
    }),
    [CONNECTOR_TYPE.FISHKA_LOYALTY]: yup.object().shape({
      acquirerId: yup.string().required(),
      externalTerminalId: yup.string().required(),
      externalMerchantId: yup.string().required(),
      accountNumber: yup.string().required(),
      description: yup.string()
    }),
    default: yup.object().shape({
      acquirerId: yup.string().required(),      
      externalTerminalId: yup.string().required(),
      externalMerchantId: yup.string().required(),
      accountNumber: yup.string().required(),
      signKeyId: yup.string().required(),
      verifyKeyId: yup.string().required(),
      description: yup.string()
    })
  }
  const [validationSchema, setValidationSchema] = useState(validationSchemas.default);

  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromState) => {
      setLoading(true);
      try {
        if (isEditMode && outboundVirtualTerminal) {
          await api.outboundVirtualTerminals.update(
            outboundVirtualTerminal.id,
            formData as OutboundVirtualTerminalsApi.UpdateOutboundVirtualTerminal
          );
          toast.success(t("text.recordWasSuccessfullyEdited"));
        } else {
          await api.outboundVirtualTerminals.create(
            formData as OutboundVirtualTerminalsApi.CreateOutboundVirtualTerminal
          );
          toast.success(t("text.recordWasSuccessfullyCreated"));
        }
        onClose &&
          onClose({} as OutboundVirtualTerminalsApi.OutboundVirtualTerminalDto);
      } finally {
        setLoading(false);
      }
    },
    [isEditMode, outboundVirtualTerminal, onClose, t]
  );

  const onChangeEndpoint = useCallback((e) => {
    setConnectorType(e?.connectorType)
    const schema = validationSchemas[e?.connectorType]
    setValidationSchema(schema ? schema : validationSchemas.default)
  }, []);

  useEffect(() => {
    if (outboundVirtualTerminal) {
      const {
        enabled,
        endpointId,
        acquirerId,
        externalTerminalId,
        externalMerchantId,
        accountNumber,
        signKeyId,
        verifyKeyId,
        internalMerchantId,
        description
      } = outboundVirtualTerminal;
      setInitialValues({
        enabled,
        endpointId,
        acquirerId,
        externalTerminalId,
        externalMerchantId,
        accountNumber,
        signKeyId,
        verifyKeyId,
        internalMerchantId,
        description
      });
      setEditMode(true);
    } else {
      setInitialValues(defaultValues);
      setEditMode(false);
    }
    onChangeEndpoint(undefined)
  }, [outboundVirtualTerminal]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      title={
        isEditMode
          ? t("title.editOutboundVirtualTerminal")
          : t("title.addOutboundVirtualTerminal")
      }
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <Formik
        {...{
          initialValues,
          onSubmit,
          validationSchema,
          enableReinitialize: true
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <>
            <DialogContent>
              <Box mb={3}>
                {outboundVirtualTerminal && (
                  <FormControlLabel label="ID">
                    {outboundVirtualTerminal.id}
                  </FormControlLabel>
                )}
                <FormControlLabel label={t("label.externalTerminalId")}>
                  <OutlinedInput name="externalTerminalId" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.externalMerchantId")}>
                  <OutlinedInput name="externalMerchantId" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.merchant")}>
                  <SelectMerchant 
                    defaultInputValue={
                      outboundVirtualTerminal &&
                      outboundVirtualTerminal.internalMerchantName
                    }
                    name="internalMerchantId"
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.acquirerName")}>
                  <SelectAcquirer
                    defaultInputValue={
                      outboundVirtualTerminal &&
                      outboundVirtualTerminal.acquirerName
                    }
                    name="acquirerId"
                    onChangeValue={() => {
                      setFieldValue("endpointId", null);
                    }}
                  />
                </FormControlLabel>
                <FormControlLabel label={t("label.acquirerEndpoint")}>
                  {values.acquirerId && (
                    <SelectAcquirerEndpoint
                      name="endpointId"
                      acquirerId={values.acquirerId}
                      onUpdateEndpointData={(e) => {
                        if (!isEditMode && e?.connectorType === CONNECTOR_TYPE.MASTERPASS) {
                          setFieldValue("signKeyId", undefined);
                          setFieldValue("verifyKeyId", undefined);
                        }
                        onChangeEndpoint(e)
                      }}
                      fullWidth
                      formikControll
                    />
                  )}
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    formikControll
                    multiline
                    rows={4}
                    fullWidth
                  />
                </FormControlLabel>
                <Box py={2}>
                  <Divider />
                </Box>

                <FormControlLabel label={t("label.enabled")}>
                  <Switch name="enabled" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.contractName")}>
                  <SelectContract
                    inputValue={
                      outboundVirtualTerminal &&
                      outboundVirtualTerminal.accountNumber
                    }
                    onChange={(contract) =>
                      setFieldValue("accountNumber", contract ? contract.contractName : "")
                    }
                    error={errors && errors["accountNumber"]}
                    touched={Boolean(errors && errors["accountNumber"])}
                  />
                </FormControlLabel>
                {getKeysFieldsByConnectorType(outboundVirtualTerminal, connectorType, isEditMode, t)}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                {isEditMode ? t("button.save") : t("button.create")}
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => onClose && onClose()}
              >
                {t("button.cancel")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateOrUpdateOutboundVirtualTerminalDialog;
