import Table, { TableProps } from "~/components/UI/Table/Table";
import UserActivityDetails from "./UserActivityDetails";
import UserActivityStatus from "./UserActivityStatus";
import UserActivityType from "./UserActivityType";
import UserActivitySource from "./UserActivitySource";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { formatDateWithTime } from "~/utils/format";
import LinkToObject from "~/components/LinkToObject";
import { SOURCE_TYPE } from "~/api/common";

interface UserActivityTableProps
  extends Omit<
    TableProps<UserActivityJournalApi.UserActivityDto>,
    "columns" | "rowSelection"
  > {
  onSort?: (field: string, order: "desc" | "asc") => void;
}

const defaultColumns = [
  // {
  //   key: "activityId",
  //   title: "ID",
  //   sort: true,
  //   cellOptions: {
  //     width: 300,
  //   },
  // },
  {
    key: "principalName",
    i18nKey: "userPrincipalName",
    sort: true,
    render: (name: string, row: UserActivityJournalApi.UserActivityDto) => (
      <LinkToObject sourceType={SOURCE_TYPE.USER} sourceId={row.userId}>
        {name || " "}
      </LinkToObject>
    )
  },
  {
    key: "createdAt",
    i18nKey: "timestamp",
    sort: true,
    render: formatDateWithTime,
    cellOptions: {
      width: 180
    }
  },
  {
    key: "type",
    i18nKey: "actionType",
    render: (type: UserActivityJournalApi.eUserActivityType) => (
      <UserActivityType type={type} />
    )
  },
  {
    key: "status",
    i18nKey: "status",
    render: (status: UserActivityJournalApi.eUserActivityStatus) => (
      <UserActivityStatus status={status} />
    ),
    cellOptions: {
      width: 85
    }
  },
  {
    key: "sourceType",
    i18nKey: "source",
    render: (source: Api.eSourceType) => <UserActivitySource source={source} />
  },
  {
    key: "sourceId",
    i18nKey: "object",
    render: (id: string, row: UserActivityJournalApi.UserActivityDto) => (
      <LinkToObject sourceType={row.sourceType} sourceId={id} />
    )
  }
];

/**
 * @memberof UserActivity
 * @component
 * @desc Render table of User Activity.
 * @property {UserActivityJournalApi.UserActivityDto[]} dataSource - UserActivity data
 */

const UserActivityTable = ({ onSort, ...props }: UserActivityTableProps) => {
  const { t } = useTranslation();
  const columns = defaultColumns.map((c) => i18nTableColumn(c, t));

  return (
    <Table
      {...props}
      columns={columns}
      expandable={{
        highlightRow: true,
        expandedRowRender: (
          data: UserActivityJournalApi.UserActivityDetailsDto
        ) => <UserActivityDetails data={data} />
      }}
      onRequestSort={onSort}
      rowKey="activityId"
      stickyHeader
    />
  );
};

export default UserActivityTable;
