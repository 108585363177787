import { ThunkAction } from 'redux-thunk';
import Constants from "./constants";
import api from '~/api';

const { ACTIONS } = Constants;

export const loggedIn = (payload: AuthApi.AuthData): AuthStore.LoggedIn => ({
  type: ACTIONS.LOGGED_IN,
  payload,
});

export const logInError = (error: string): AuthStore.LogInError => ({
  type: ACTIONS.LOGIN_ERROR,
  error
});

export const updateToken = (payload: AuthApi.AuthData): AuthStore.UpdateToken => ({
  type: ACTIONS.UPDATE_TOKEN,
  payload,
});

export const setUserData = (payload: UsersApi.AzureUserDto): AuthStore.SetUserData => ({
  type: ACTIONS.USER_AUTHORIZED,
  payload,
});

export const getUserData = (): ThunkAction<void, ReduxStore.State, SearchStore.eActionType, any> => async (dispatch, getState) => {
  const { auth } = getState()

  if (!auth.authData?.accessToken) return

  try {
    const { data: { data: userData } } = await api.auth.getUserData(auth.authData?.accessToken);
    dispatch(setUserData(userData));
  } catch (e) {
    console.error(e);
  }
};


export const setLogo = (payload: string): AuthStore.SetLogo => ({
  type: ACTIONS.SET_LOGO,
  payload,
}) 

export const loggedOut = (): AuthStore.LoggedOut => ({
  type: ACTIONS.LOGGED_OUT,
});
