import { useCallback } from "react";
import { Breadcrumbs } from "~/components/UI";
import InboundVirtualTerminals from "./Components/InboundVirtualTerminals";
import { useTranslation } from "react-i18next";

/**
 * @namespace InboundVirtualTerminalsPage
 */

const InboundVirtualTerminalsPage = () => {
  const { t } = useTranslation();

  const getBreadcrumbs = useCallback(() => {
    return [
      { label: t("title.catalogs") },
      { label: t("title.inboundVirtualTerminals") },
    ];
  }, [t]);

  return (
    <>
      <Breadcrumbs links={getBreadcrumbs()} />
      <InboundVirtualTerminals />
    </>
  );
};

export default InboundVirtualTerminalsPage;
