import { StatusIndicator } from "~/components/UI";
import { useTranslation } from "react-i18next";

const StatusBadge = (status: boolean | null) => {
  const { t } = useTranslation();

  if (status === null) {
    return null;
  }

  return (
    <StatusIndicator
      label={status ? t("text.enabled") : t("text.disabled")}
      status={status ? "active" : "error"}
    />
  );
};

export default StatusBadge;
