import Select, { SelectEnumProps } from "./SelectEnum";
import { SOURCE_TYPE_I18n } from "~/api/common";
import { INCIDENT_SOURCE_TYPE } from '~/api/incidents/enums';

const SelectIncidentSourceType = (
  props: Omit<SelectEnumProps, "enumValue" | "enumI18n">
) => {
  return (
    <Select {...props} enumValue={INCIDENT_SOURCE_TYPE} enumI18n={SOURCE_TYPE_I18n} />
  );
};

export default SelectIncidentSourceType;
