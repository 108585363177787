import {
  Icon,
  Grid,
  Box,
  Typography,
  makeStyles,
  createStyles,
} from "~/components/UI";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
    },
  })
);

const NoIncidents = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box fontSize={42} color="text.secondary">
        <Icon name="AlertError" />
      </Box>
      <Typography variant="body2" color="textSecondary">
        {t("text.noIncidents")}
      </Typography>
    </Grid>
  );
};

export default NoIncidents;
