import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { GROUP_STATUS, GROUP_STATUS_I18n } from "~/api/groups/enums";

interface GroupStatusProps {
  status: GroupsApi.eGroupStatus;
}

/**
 * @memberof Groups
 * @component
 * @desc Render status of a Group.
 * @property {GroupsApi.eGroupStatus} status - Group status;
 */

const GroupStatus = ({ status }: GroupStatusProps) => {
  const { t } = useTranslation("enums");
  let color = "primary";

  switch (status) {
    case GROUP_STATUS.ACTIVE:
      color = "primary";
      break;
    case GROUP_STATUS.BLOCKED:
    case GROUP_STATUS.DELETED:
      color = "error";
      break;
    default:
      return null;
  }

  return (
    <Typography variant="body1" color={color as "primary"}>
      {t(GROUP_STATUS_I18n[status])}
    </Typography>
  );
};

export default GroupStatus;
