import { useState, useCallback, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  OutlinedInput
} from "~/components/UI";
import Dialog, { DialogProps } from "~/components/UI/Dialog/Dialog";
import SelectIncidentSourceType from "~/components/CommonFormControls/SelectIncidentSourceType";
import SelectIncidentType from "~/components/CommonFormControls/SelectIncidentType";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import yup from "~/packages/yup";
import api from "~/api";

interface FromData extends IncidentsApi.CreateIncident {}

interface CreateIncidentProps extends Omit<DialogProps, "onClose" | "role"> {
  sourceType?: Api.eSourceType;
  sourceId?: string;
  onClose?: (incident?: IncidentsApi.IncidentDto) => void;
}

const defaultValues = {
  sourceType: "",
  sourceId: "",
  subject: "",
  description: ""
};

/**
 * @memberof Incidents
 * @component
 * @desc Create an Incident Dialog.
 * @property {Function} onClose - (result?: boolean) => void;
 */

const CreateIncidentDialog = ({
  sourceType,
  sourceId,
  open,
  onClose
}: CreateIncidentProps) => {
  const [initialValues, setInitialValues] = useState<FromData>(defaultValues);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (formData: FromData) => {
      setLoading(true);
      try {
        const {
          data: { data }
        } = await api.incidents.createIncident(formData);
        toast.success(t("text.recordWasSuccessfullyCreated"));
        setTimeout(() => {
          onClose && onClose(data);
        }, 0);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClose, t]
  );

  const updateInitialValue = useCallback(() => {
    setInitialValues(Object.assign(defaultValues, { sourceType, sourceId }));
  }, [sourceType, sourceId]);

  const validationSchema = yup.object().shape({
    sourceType: yup.string().required(),
    sourceId: yup.string().required(),
    type: yup.string().required(),
    subject: yup.string().required(),
    description: yup.string().required()
  });

  useEffect(updateInitialValue, [open, updateInitialValue]);

  return (
    <Dialog
      open={open}
      title={t("title.createIncident")}
      onClose={() => !loading && onClose && onClose()}
      closable
    >
      <>
        <Formik
          {...{
            initialValues,
            onSubmit,
            validationSchema,
            enableReinitialize: true
          }}
        >
          {({ handleSubmit }) => (
            <>
              <DialogContent>
                <FormControlLabel label={t("label.sourceType")}>
                  <SelectIncidentSourceType name="sourceType" />
                </FormControlLabel>
                <FormControlLabel label={t("label.sourceId")}>
                  <OutlinedInput name="sourceId" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.type")}>
                  <SelectIncidentType name="type" formikControll />
                </FormControlLabel>
                <FormControlLabel label={t("label.subject")}>
                  <OutlinedInput name="subject" formikControll fullWidth />
                </FormControlLabel>
                <FormControlLabel label={t("label.description")}>
                  <OutlinedInput
                    name="description"
                    multiline
                    rows={4}
                    formikControll
                    fullWidth
                  />
                </FormControlLabel>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  {t("button.create")}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => onClose && onClose()}
                >
                  {t("button.cancel")}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

export default CreateIncidentDialog;
