import MUInput, { InputProps } from "@material-ui/core/Input";
import { useField } from "formik";
import FieldError from "../Form/FieldError";
import { withStyles, createStyles } from "@material-ui/core/styles";

const BaseInput = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      height: 42,
      backgroundColor: "transparent",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
      fontSize: 16,
      boxSizing: "border-box",
      "& label.Mui-focused": {
        color: "green"
      },
      "&.MuiInput-underline:after": {
        border: "none",
        content: ""
      },
      "&.MuiInput-underline:before": {
        border: "none",
        content: ""
      },
      "& > :not(input)": {
        marginRight: 15
      }
    },
    input: {
      fontSize: theme.typography.fontSize,
      lineHeight: 1.4
    }
  })
)(MUInput);

const FormikInput = ({ name, ...other }: InputProps & { name: string }) => {
  const [field, meta] = useField(name);
  const { error, touched } = meta;

  return (
    <>
      <BaseInput
        {...field}
        {...other}
        value={field.value || ""}
        error={touched && !!error}
        disableUnderline
      />
      {touched && error && <FieldError>{error}</FieldError>}
    </>
  );
};

const Input = ({
  name,
  formikControll,
  ...inputProps
}: InputProps & { formikControll?: boolean }) =>
  formikControll && name ? (
    <FormikInput name={name} {...inputProps} />
  ) : (
    <BaseInput {...inputProps} disableUnderline />
  );

export default Input;
