import MUITableHead from "@material-ui/core/TableHead";
import { withStyles, createStyles } from "@material-ui/core/styles";

const TableHead = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: "20px 10px",
      "&.hidden": {
        display: "none",
      },
      textTransform: "capitalize",
    },
  })
)(MUITableHead);

export default TableHead;
