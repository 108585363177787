import { Box, Typography } from "~/components/UI";
import { useTranslation } from "react-i18next";
import NoPermissionsForMetrics from "~/components/Metrics/NoPermissionsForMetrics";

import FinancialTransactionsByAcquirers from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByAcquirers";
import FinancialTransactionsByWallets from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByWallets";
import FinancialTransactionsByPaymentSystem from "~/components/Metrics/FinancialTransactionCounts/FinancialTransactionsByPaymentSystem";

import ClientPaymentDurationByAcquirers from "~/components/Metrics/ClientPaymentDuration/ClientPaymentDurationByAcquirers";
import ClientPaymentDurationByWallets from "~/components/Metrics/ClientPaymentDuration/ClientPaymentDurationByWallets";

import PSPPaymentDurationByAcquirers from "~/components/Metrics/PSPPaymentDuration/PSPPaymentDurationByAcquirers";
import PSPPaymentDurationByWallets from "~/components/Metrics/PSPPaymentDuration/PSPPaymentDurationByWallets";

import CanIUse from "~/components/CanIUse";
import MetricGroup from "~/components/Metrics/MetricsGroup";
import { PERMISSION } from "~/api/permissions/enums";

const Metrics = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4">{t("title.metrics")}</Typography>
      <Box pt={6}>
        <NoPermissionsForMetrics>
          <CanIUse
            permissions={PERMISSION.READ_FINANCIAL_TRANSACTION_COUNTS_METRICS}
          >
            <MetricGroup title={t("title.financialTransactionCounts")}>
              <FinancialTransactionsByAcquirers />
              <FinancialTransactionsByPaymentSystem />
              <FinancialTransactionsByWallets />
            </MetricGroup>
          </CanIUse>
          <CanIUse
            permissions={PERMISSION.READ_CLIENT_PAYMENT_DURATION_METRICS}
          >
            <MetricGroup title={t("title.clientPaymentDuration")}>
              <ClientPaymentDurationByWallets />
              <ClientPaymentDurationByAcquirers />
            </MetricGroup>
          </CanIUse>
          <CanIUse permissions={PERMISSION.READ_PSP_PAYMENT_DURATION_METRICS}>
            <MetricGroup title={t("title.PSPPaymentDuration")}>
              <PSPPaymentDurationByWallets />
              <PSPPaymentDurationByAcquirers />
            </MetricGroup>
          </CanIUse>
        </NoPermissionsForMetrics>
      </Box>
    </>
  );
};

export default Metrics;
