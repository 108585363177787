import { enumToI18nKey } from '~/utils/helpers';

export const TIME_PERIOD = {
  LAST_MINUTE: '1m',
  LAST_HOUR: '1h',
  LAST_24H: '24h',
} as { [key: string]: MetricsAPI.eTimePeriod }

export const TRANSACTION_COUNT_FILTER = {
  SUCCESS: 'Success',
  FAILED_BY_CLIENT: 'Failed by client',
  FAILED_BY_INTERNAL: 'Failed by internal',
} as { [key: string]: MetricsAPI.eTransactionCountFilter }

export const TRANSACTION_COUNT_FILTER_I18n = enumToI18nKey(TRANSACTION_COUNT_FILTER, 'TRANSACTION_COUNT_FILTER');