import React, { useRef, useEffect, useCallback } from "react";
import AlertCard from "./AlertCard";
import { Grid, makeStyles, createStyles } from "~/components/UI";
import { addAlert, skipAlert, setAlerts } from "~/store/alerts/actions";
import AlertsSocket from "~/api/alertsSocket";
import { useDispatch, useSelector } from "react-redux";
import NoIncidents from "./NoIncidents";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
);

const Alerts = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((store: ReduxStore.State) => store.alerts.alerts);
  const alertsSocketRef = useRef<any>(null);
  const classes = useStyles();

  const onUpdateAlerts = useCallback(
    (alerts: AlertsApi.AlertDto[]) => {
      dispatch && dispatch(setAlerts(alerts));
    },
    [dispatch]
  );

  const addNewAlertHandler = useCallback(
    (alert: AlertsApi.AlertDto) => dispatch && dispatch(addAlert(alert)),
    [dispatch]
  );

  const skipAlertHandler = useCallback(
    (alert: AlertsApi.AlertDto) =>
      dispatch && dispatch(skipAlert(alert.alertId)),
    [dispatch]
  );

  const onCloseAlert = useCallback(
    (alertId: string) => {
      if (alertsSocketRef.current) {
        alertsSocketRef.current.skipAlert(alertId);
      }
    },
    [alertsSocketRef]
  );

  useEffect(() => {
    alertsSocketRef.current = new AlertsSocket();
    alertsSocketRef.current.handleAlerts(onUpdateAlerts);
    alertsSocketRef.current.handleNewAlert(addNewAlertHandler);
    alertsSocketRef.current.handleSkipAlert(skipAlertHandler);

    alertsSocketRef.current.getAlerts();
    return () => {
      alertsSocketRef.current.disconnect();
    };
  }, []);

  if (alerts.length) {
    return (
      <Grid container direction="column-reverse" className={classes.root}>
        {alerts.map((alert) => (
          <AlertCard key={alert.alertId} alert={alert} onClose={onCloseAlert} />
        ))}
      </Grid>
    );
  }

  return <NoIncidents />;
};

export default React.memo(Alerts);
