import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Section,
  Icon,
  ButtonGroup,
  Pagination,
  LinearProgress
} from "~/components/UI";
import Table from "./Table";
import { TableRef } from "~/components/UI/Table/Table";
import WalletsCredentialsContext from "./walletsCredentialsContext";
import CreateAndUpdateWallet from "./CreateAndUpdateWallet";
import Filter from "./Filter";
import { PERMISSION } from "~/api/permissions/enums";
import CanIUse from "~/components/CanIUse";
import { updateArrayItem, getSortStr } from "~/utils/helpers";
import useConfirmDialog from "~/hook/useConfirmDialog";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryParams, StringParam } from "use-query-params";
import usePermissions from "~/hook/usePermissions";
import RowsPerPage from "~/components/RowsPerPage";
import api from "~/api";

const WalletsCredentials = () => {
  const [editWallet, setEditWallet] = useState<WalletsApi.WalletDto>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const [wallets, setWallets] = useState<WalletsApi.WalletDto[]>();
  const [query, setQuery] = useState<WalletsApi.GetWalletsQuery>();
  const [limit, setLimit] = useState<number>();
  const pagination = useRef<Api.PaginationSchema>();
  const [loading, setLoading] = useState(false);
  const deleteConfirm = useConfirmDialog("delete");
  const [queryParams] = useQueryParams({
    search: StringParam,
    id: StringParam
  });
  const tableRef = useRef<TableRef>(null);
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  const [rowSelection] = useState(
    hasAll(PERMISSION.DELETE_CONTRACT)
      ? {
          hideSelectAll: false,
          onChange: setSelectedWallets
        }
      : undefined
  );

  const getWallets = useCallback(
    async (page?: number) => {
      const currentPage = pagination.current ? pagination.current.page : 1;
      setLoading(true);
      try {
        const {
          data: { data, meta }
        } = await api.wallets.getAll(limit, page || currentPage, query);
        data && setWallets(data);
        pagination.current = meta.pagination;
      } finally {
        setLoading(false);
      }
    },
    [pagination, query, limit]
  );

  const onSort = useCallback(
    (field: string, order: "desc" | "asc") => {
      setQuery({ ...query, sort: getSortStr(field, order) });
    },
    [query]
  );

  const onCreateWallet = () => {
    setEditWallet(undefined);
    setShowEditDialog(true);
  };

  const onCloseEditDialog = (wallet?: WalletsApi.WalletDto) => {
    setShowEditDialog(false);
    setEditWallet(undefined);
    wallet && getWallets();
  };

  const onUpdateItem = useCallback((wallet: WalletsApi.WalletDto) => {
    setWallets((wallets) =>
      wallet ? updateArrayItem(wallets, wallet, "walletId") : []
    );
  }, []);

  const onEdit = useCallback((wallet: WalletsApi.WalletDto) => {
    setEditWallet(wallet);
    setShowEditDialog(true);
  }, []);

  const onUpdate = useCallback(
    async (id: string, formData: WalletsApi.UpdateWallet) => {
      try {
        const {
          data: { data: wallet }
        } = await api.wallets.updateWallet(id, formData);
        onUpdateItem(wallet);
        toast.success(t("text.recordSuccessfullyUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, onUpdateItem]
  );

  const onDelete = useCallback(
    async (wallet: WalletsApi.WalletDto) => {
      await deleteConfirm(wallet?.walletId);
      try {
        await api.wallets.deleteWallet(wallet.walletId);
        getWallets();
        toast.success(t("text.recordSuccessfullyDeleted"));
      } catch (e) {
        console.error(e);
      }
    },
    [t, getWallets, deleteConfirm]
  );

  const onDeleteWallets = useCallback(async () => {
    await deleteConfirm(t("text.records", { count: selectedWallets?.length }));
    try {
      await api.wallets.deleteWallets(selectedWallets);
      toast.success(t("text.recordSuccessfullyDeleted"));
      await getWallets();
      tableRef.current && tableRef.current.resetSelectedRows();
    } catch (e) {
      console.error(e);
    }
  }, [selectedWallets]);

  const onChangePagination = (page: number) => {
    getWallets(page);
  };

  const onChangeFilters = (filters: WalletsApi.GetWalletsQuery) => {
    pagination.current = undefined;
    setWallets([]);
    setQuery(filters);
  };

  const contextValue = useMemo(
    () => ({
      onSort,
      onUpdateItem,
      onEdit,
      onUpdate,
      onDelete
    }),
    [onSort, onUpdateItem, onEdit, onUpdate, onDelete]
  );

  useEffect(() => {
    setQuery(queryParams as WalletsApi.GetWalletsQuery);
  }, [queryParams]);

  useEffect(() => {
    query && getWallets();
  }, [query, getWallets, limit]);

  return (
    <WalletsCredentialsContext.Provider value={contextValue}>
      <Section
        title={t("title.walletCredentials")}
        extra={
          <ButtonGroup>
            <CanIUse permissions={PERMISSION.DELETE_WALLET_CREDENTIALS}>
              <Button
                hidden={!selectedWallets.length}
                prefixIcon={<Icon name="Delete" />}
                variant="contained"
                onClick={onDeleteWallets}
              >
                {t("button.delete")}
              </Button>
            </CanIUse>
            <Filter onChangeFilter={onChangeFilters} />
            <CanIUse permissions={PERMISSION.CREATE_WALLET_CREDENTIALS}>
              <Button
                prefixIcon={<Icon name="Plus" />}
                variant="contained"
                onClick={onCreateWallet}
              >
                {t("button.addWallet")}
              </Button>
            </CanIUse>
          </ButtonGroup>
        }
      />
      <LinearProgress hidden={!loading} />
      <Box flexGrow={1} overflow="auto" bgcolor="background.paper">
        <Table
          dataSource={wallets}
          onRequestSort={onSort}
          rowKey="walletId"
          rowSelection={rowSelection}
        />
      </Box>
      {pagination.current && (
        <Pagination
          prefContent={<RowsPerPage initialValue={limit} onChange={setLimit} />}
          count={pagination.current.totalPages}
          page={pagination.current.page}
          defaultPage={1}
          onChange={(_e, page) => {
            onChangePagination(page);
          }}
        />
      )}
      { showEditDialog && <CreateAndUpdateWallet open={showEditDialog} wallet={editWallet} onClose={onCloseEditDialog} /> }
    </WalletsCredentialsContext.Provider>
  );
};

export default WalletsCredentials;
