import i18n, { TFunction } from "i18next";
import backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    load: "currentOnly",
    react: {
      useSuspense: false,
    },
  });

export const i18nTableColumn = <T>({ i18nKey, ...other }: T & { i18nKey?: string }, t: TFunction<string>): Omit<T, "i18nKey"> => {
  if(!i18nKey) return other;
  return ({ ...other, title: t(`table.${i18nKey}`)});
}

export default i18n;
