import { Switch } from "~/components/UI";
import ConfirmDialog from "~/components/BaseConfirmDialog";
import Table, { TableProps } from "~/components/UI/Table/Table";
import { useTranslation } from "react-i18next";
import { i18nTableColumn } from "~/packages/i18n";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";

const defaultColumns = [
  {
    key: "id",
    i18nKey: "merchantID",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "merchantName",
    i18nKey: "merchantName",
    cellOptions: {
      width: 300,
    },
  },
  {
    key: "enabled",
    i18nKey: "enabled",
    cellOptions: {
      width: 50,
    },
  },
  {
    key: "description",
    i18nKey: "description",
  },
];

interface MerchantsTableProps
  extends Omit<TableProps<MerchantsApi.MerchantDto>, "columns"> {
  onUpdate: (id: string, data: MerchantsApi.UpdateMerchant) => void;
}

const MerchantsTable = ({ onUpdate, ...props }: MerchantsTableProps) => {
  const { t } = useTranslation();
  const { hasAll } = usePermissions();

  const columns = defaultColumns.map((c) => {
    const column = i18nTableColumn(c, t);
    switch (column.key) {
      case "enabled":
        return {
          ...column,
          render: (enabled: boolean, merchant: MerchantsApi.MerchantDto) => (
            <ConfirmDialog
              placement="top"
              disabled={!hasAll(PERMISSION.EDIT_MERCHANT)}
              onConfirm={() =>
                onUpdate && onUpdate(merchant.id, { enabled: !enabled })
              }
            >
              <Switch
                checked={enabled}
                size="small"
                disabled={!hasAll(PERMISSION.EDIT_MERCHANT)}
              />
            </ConfirmDialog>
          ),
        };
      default:
        return column;
    }
  });

  return <Table {...props} size="small" columns={columns} stickyHeader />;
};

export default MerchantsTable;
