import MUICollapse, {
  CollapseProps as MUICollapseProps,
} from "@material-ui/core/Collapse";

export type CollapseProps = { in?: boolean } & MUICollapseProps;

const Collapse = ({ in: open, children, ...other }: CollapseProps) => (
  <MUICollapse in={open} {...other}>
    {typeof open === "boolean" ? children : null}
  </MUICollapse>
);

export default Collapse;
