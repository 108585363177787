import PSPPaymentDurationCard from "./PSPPaymentDurationCard";
import api from "~/api";
import { useTranslation } from "react-i18next";

const PSPPaymentDurationByWallets = () => {
  const { t } = useTranslation();
  const getPSPPaymentDurationMetrics = async (
    params: MetricsAPI.GetMetricQuery
  ) => {
    try {
      const { data } = await api.metrics.getPSPPaymentDurationByWallets(params);
      if (data) {
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PSPPaymentDurationCard
      title={`${t("title.pspPaymentDurationBy")} ${t("title.wallets")}`}
      onUpdate={getPSPPaymentDurationMetrics}
    />
  );
};

export default PSPPaymentDurationByWallets;
