import { useContext } from "react";
import SideBarContext from "./SideBarFilterContext";

export const useSideBarFilter = (): [boolean, () => void, () => void] => {
  const { isOpen, open, close } = useContext(SideBarContext);
  
  return [isOpen, open, close];
};

export default useSideBarFilter;
