import Constants from './constants';

const { ACTIONS } = Constants;

export const openSidebar = (): AlertsStore.OpenSidebarAction => ({
  type: ACTIONS.OPEN_SIDEBAR,
});

export const closeSidebar = (): AlertsStore.OpenSidebarAction => ({
  type: ACTIONS.CLOSE_SIDEBAR
})


export const addAlert = (alert: AlertsApi.AlertDto): AlertsStore.AddAlert => ({
  type: ACTIONS.ADD_ALERT,
  payload: alert
})

export const skipAlert = (alertId: string): AlertsStore.SkipAlert => ({
  type: ACTIONS.SKIP_ALERT,
  payload: alertId
})

export const setAlerts = (alerts: AlertsApi.AlertDto[]): AlertsStore.SetAlerts => ({
  type: ACTIONS.SET_ALERTS,
  payload: alerts
})