import { useCallback } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import { Box, Button, List, ListItem } from "~/components/UI";
import Highlighter from "react-highlight-words";
import history from "~/packages/history";
import ROUTES from "~/constants/routes";
import { useTranslation } from "react-i18next";

interface RolesResultProps {
  data?: Api.SearchResult<RolesApi.RoleDto>;
  searchQuery: string;
}

const Role = ({
  role,
  searchQuery,
}: {
  searchQuery: string;
  role: RolesApi.RoleDto;
}) => {
  const openGroup = useCallback(() => {
    history.push(`${ROUTES.USER_ROLES}?roleId=${role.roleId}`);
  }, [role.roleId]);

  return (
    <ListItem button onClick={openGroup}>
      <Typography variant="body2" color="textSecondary">
        <Highlighter searchWords={[searchQuery]} textToHighlight={role.name} />{" "}
        [
        <Typography variant="body2" component="span" color="primary">
          ID
        </Typography>
        :{" "}
        <Highlighter
          searchWords={[searchQuery]}
          textToHighlight={role.roleId}
        />
        ]
      </Typography>
      <Divider />
    </ListItem>
  );
};

/**
 * @memberof GlobalSearch
 * @component
 * @desc Render of search results for the Roles catalog.
 * @property {string} searchQuery - the text we are looking for
 * @property {Api.SearchResult<RolesApi.RoleDto>} data - api response
 */

const Roles = ({ data, searchQuery }: RolesResultProps) => {
  const { t } = useTranslation();

  const onShowAll = useCallback(() => {
    setTimeout(() => {
      history.push(`${ROUTES.USER_ROLES}?search=${searchQuery}`);
    }, 0);
  }, [searchQuery]);

  if (data && data.count) {
    return (
      <Box mb={4}>
        <Box py={4}>
          <Grid container alignItems="center">
            <Grid item sm>
              <Typography variant="subtitle1">
                {t("title.catalogRoles")} ({data.count})
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onShowAll}>
                {t("button.showAll")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <List>
          {data.data.map((r, index) => (
            <Role key={index} searchQuery={searchQuery} role={r} />
          ))}
        </List>
      </Box>
    );
  } else {
    return null;
  }
};

export default Roles;
