import { ThunkAction } from 'redux-thunk';
import Constants from "./constants";
import api from '~/api';

const { ACTIONS } = Constants;

export const clearSearchResults = (): SearchStore.ClearSearchResults => {
  return ({
    type: ACTIONS.CLEAR_SEARCH_RESULTS,
  });
}

export const globalSearch = (searchQuery: string): ThunkAction<void, ReduxStore.State, SearchStore.eActionType, any> => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIONS.GLOBAL_SEARCH_REQUEST,
      });
      const { data } = await api.search.globalSearch(searchQuery, 10);

      dispatch({
        type: ACTIONS.SET_SEARCH_RESULTS,
        payload: {
          result: data.data,
          searchQuery
        }
      });
    } catch (e) {
      console.error(e);
      dispatch(clearSearchResults());
    }
  }
}