import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export interface StatusIndicatorProps {
  label?: string;
  status?: "active" | "disabled" | "error";
}

const useStyles = makeStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      position: "relative",
      display: "inline-block",
      paddingLeft: 15,
      height: "1em",
      lineHeight: "1em",
      verticalAlign: "middle",
      textTransform: "capitalize",
      "&:before": {
        position: "absolute",
        borderRadius: "50%",
        left: 0,
        top: "50%",
        marginTop: "-3px",
        content: '""',
        width: 6,
        height: 6,
      },
    },
    active: {
      color: theme.palette.success.main,
      "&:before": {
        backgroundColor: theme.palette.success.main,
      },
    },
    disabled: {
      color: theme.palette.grey["400"],
      "&:before": {
        backgroundColor: theme.palette.grey["400"],
      },
    },
    error: {
      color: theme.palette.error.main,
      "&:before": {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

const StatusIndicator = ({ label, status }: StatusIndicatorProps) => {
  const classes = useStyles();
  return (
    <span
      className={clsx(classes.root, {
        [classes.active]: status === "active",
        [classes.disabled]: status === "disabled",
        [classes.error]: status === "error",
      })}
    >
      {label}
    </span>
  );
};

export default StatusIndicator;
