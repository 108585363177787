import MUIListItem from "@material-ui/core/ListItem";
import { withStyles, createStyles } from "@material-ui/core/styles";

const ListItem = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  })
)(MUIListItem);

export default ListItem;
