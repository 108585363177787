import React from "react";
import { CircularProgress } from "../";
import MUIOutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import { useField } from "formik";
import FieldError from "../Form/FieldError";
import { withStyles, createStyles } from "@material-ui/core/styles";

type InputProps = Omit<OutlinedInputProps, "error"> & {
  loading?: boolean;
  name?: string;
  touched?: boolean;
  error?: string | boolean;
};

const OutlinedInput = React.memo(
  withStyles((theme: CustomTheme.RootObject) =>
    createStyles({
      root: {
        backgroundColor: "transparent",
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        padding: "10px 10px 10px 20px",
        boxSizing: "border-box",
        minWidth: 205,
      },
      input: {
        padding: 0,
      },
      error: {
        borderColor: theme.palette.error.main,
      },
    })
  )(({ touched, error, name, loading, ...props }: InputProps) => (
    <>
      <MUIOutlinedInput
        {...props}
        name={name}
        error={Boolean(error)}
        endAdornment={
          <>
            {loading && <CircularProgress size={15} />}
            {props.endAdornment}
          </>
        }
      />
      {error && <FieldError>{error}</FieldError>}
    </>
  ))
);

const FormikInput = ({ name, ...props }: InputProps) => {
  const [field, { error, touched }] = useField(name || "");
  return (
    <OutlinedInput
      {...field}
      value={field.value || ""}
      {...props}
      {...{ touched, error, name }}
    />
  );
};

const Input = ({
  formikControll,
  ...inputProps
}: InputProps & { formikControll?: boolean }) => {
  return formikControll && inputProps.name ? (
    <FormikInput {...inputProps} />
  ) : (
    <OutlinedInput {...inputProps} />
  );
};

export default React.memo(Input);
