import { Tabs } from '~/components/UI';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PERMISSION } from "~/api/permissions/enums";
import usePermissions from "~/hook/usePermissions";
import WalletsCredentials from './WalletsCredentials';
import AdditionalInformation from './AdditionalInformation';

const getStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 285,
    },
    growBox: {
      overflow: "auto",
    },
    tabsContainer: {
      height: "calc(100% - 35px)",
    },
  })
);

interface MerchantDetailsProps {
  merchant: MerchantsApi.MerchantDto;
}

const MerchantDetails = ({merchant}: MerchantDetailsProps) => {
  const classes = getStyles();
  const { t } = useTranslation();
  const { hasAll } = usePermissions();
  
  return (<Tabs
    className={classes.tabsContainer}
    variant="scrollable"
    scrollButtons="auto"
    tabs={[
      {
        label: `${t("title.walletsCredentials")}`,
        hidden: !hasAll(PERMISSION.READ_WALLET_CREDENTIALS),
        content: (
          <WalletsCredentials merchant={merchant} />
        ),
      },
      {
        label: t("title.additionalInformation"),
        content: <AdditionalInformation merchant={merchant} />,
      },
    ]}
  />)
}

export default MerchantDetails;